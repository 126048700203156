
export type numFunction = (data:any) => number;
export type nodeListFunction<T> = (nodes: GenericNode<T>[]) => void;
export type nodeFunction<T> = (node: GenericNode<T>) => void;
export type nodePairFunction<T> = (node1: GenericNode<T>, node2: GenericNode<T>) => void;
export type nodePairToNumberFunction<T> = (node1: GenericNode<T>, node2: GenericNode<T>) => number;
export type anyPairToNumberFunction<T> = (d1: T, d2: T) => number;
export type parentChildrenFunction<T> = (parent: GenericNode<T>, children: GenericNode<T>[]) => void;

export type nodeListFunctionBool<T> = (nodes: GenericNode<T>[]) => boolean;

export class GenericNode<T> {

  data:T;
  children: GenericNode<T>[];
  parent: GenericNode<T>;
  treeDepth: number;

  constructor (data: T= null, treeDepth: number = -1) {
    this.data = data;
    this.children = [];
    this.parent = null;
    this.treeDepth = treeDepth;

  }

  public buildTreeFromDataWithDepth(data:T[], getDepth: numFunction): GenericNode<T> {
    // assume I am empty
    console.assert(this.children.length == 0);

    let last: GenericNode<T> = this;
    //if the data is not defined (e.g. for the this - the root), give a large negative level
    const safeGetDepth =  (node: GenericNode<T>) =>  node.data != null ? getDepth(node.data) : -9999999;
    for( let each of data) {
      const depth = getDepth(each);
      const newNode: GenericNode<T> = new GenericNode(each);
     // console.log(each);
      if (depth > safeGetDepth(last)) {
        last.appendChild(newNode);
      } else {
        let parent: GenericNode<T> = last.parent;
        while(depth <= safeGetDepth(parent)) {
          parent = parent.parent;
        }
        parent.appendChild(newNode);
      }
      last = newNode;

    }

    return this;

  }

  public reset():void{
    this.data = null;
    this.children = [];
  }
  public buildTreeFromDataWithChildren(node:T, getChildren: (node:T)=>T[]) : GenericNode<T> {

    this.data = node;

    for(let child of getChildren(node)) {
      const gnChild = new GenericNode<T>() ;
      this.appendChild(gnChild);
      gnChild.buildTreeFromDataWithChildren(child, getChildren);
    }

    return this;
  }


  protected appendChild(node: GenericNode<T>) {
    node.treeDepth = this.treeDepth + 1 ;
    this.children.push(node);
    node.parent = this;
  }


  public deleteChild(node: GenericNode<T>) : GenericNode<T> {

    const l1 = this.children.length;
    this.children = this.children.filter(item => item !== node);
    const l2 = this.children.length

    if(l2 < l1) {
      node.parent = null;
    }

    return this;

  }

  /**
   * Enumerate the nodes with the same order as provided to the method buildTreeFromDataWithDepth.
   * The first node is the root that acts as a container
   * @param {nodeFunction<T>} f
   */
  public enumerate(f:  nodeFunction<T>): void {
    f(this);
    for(let child of this.children) {
      child.enumerate(f);
    }

  }
  /**
   * Recursive enumeration of the nodes starting with the deepest nodes first (Depth First Search).
   * @param {nodeFunction<T>} f
   */
  public enumerateDepthFirst(f:  nodeFunction<T>): void {
    for(let child of this.children) {
      child.enumerateDepthFirst(f);
    }
    f(this);

  }
  /**
   * Enumerate each parent - child pair for which for which the depth in the tree is different
   * @param f
   */
  public enumerateDepthTransition(f: nodePairFunction<T>) : void {
    this.enumerate((parent:GenericNode<T>) => {
      for( let child of parent.children) {
        if(child.treeDepth != parent.treeDepth) {
          f(parent, child);
        }
      }

    });

  }
  // method enumerateParentChildren with parent that have no children needed as well?
  /**
   *  Enumerate each parent , children if the parent has data and the parent has children
   * @param {parentChildrenFunction<T>} f
   */
  public enumerateParentChildren(f: parentChildrenFunction<T>) : void {
    this.enumerate((parent:GenericNode<T>) => {
      if ( ! parent.isLeaf() && parent.data) {
        f(parent, parent.children);
      }
    });

  }

  protected enumerateChildParentsRECURSIVE(f: parentChildrenFunction<T>, parentStack: GenericNode<T>[]) {

    f(this, parentStack);
    parentStack.unshift(this);

    for( let child of this.children) {
      child.enumerateChildParentsRECURSIVE(f, parentStack);
    }
    parentStack.shift();
  }

  /**
   *  Enumerate each child - parents
   * @param {nodePairFunction<T>} f
   */
  public enumerateChildParents(f: parentChildrenFunction<T>) : void {
    let parents: GenericNode<T>[] = [];

    this.enumerateChildParentsRECURSIVE(f, parents);
  }

    /**
   *  Enumerate each parent , child if the parent has data and the child has data
   * @param {nodePairFunction<T>} f
   */
  public enumerateParentsChild(f: nodePairFunction<T>) : void {
    this.enumerate((parent:GenericNode<T>) => {
      if ( ! parent.isLeaf() && parent.data) {
        for (let child of parent.children) {
          if( child.data) {
            f(parent, child);
          }
        }
      }
    });

  }

  /**
   * Collect all nodes with non null data - the order is the same provided to the method buildTreeFromDataWithDepth
   * @returns {GenericNode<T>[]}
   */
  public collectNodeWithData() : GenericNode<T>[] {
    const results: GenericNode<T>[] = [];
    this.enumerate((node) => {
      if (node.data) {
        results.push(node);
      }

    });

    return results;

  }
  //protected lastChild():  GenericNode<T> {
  //  return this.children
 // }

  /**
   * Recursive enumeration of children
   * @param {nodeListFunction<T>} f
   */
  public enumerateChildren(f: nodeListFunction<T>): void {
    if(this.children.length == 0) {
      return;
    }
    f(this.children);
    this.children.forEach( (each: GenericNode<T> ) => each.enumerateChildren(f));
  }

  /**
   * Return the total number of children nodes in the whole tree
   * @returns {number}
   */
  public countAllChildren(): number {
    let count = 0;
    this.enumerateChildren( (children:GenericNode<T>[])=> count+=children.length);

    return count;

  }

  public countAllLeaves(): number {
    let count = 0;

    this.enumerate((node:GenericNode<T>) => {node.isLeaf() && count++});

    return count;
  }

  /**
   * How deep is the tree?
   * @returns {number}
   */
  public computeMaxDepth(): number {
    let max = this.treeDepth;
     this.enumerate((node: GenericNode<T>) => { max = Math.max(max, node.treeDepth)});

     return max - this.treeDepth;

  }
  public searchChildren(f: nodeListFunctionBool<T>)  {
    this.enumerateChildren((children: GenericNode<T>[]) => {
      if(f(children)) {
        return true;
      }
    });

    return false;

  }
  public isRoot() : boolean {
    return this.parent == null || this.parent == undefined;
  }

  public isLeaf() : boolean {
    return this.children.length == 0;
  }
  //protected findLastParent

  public isFirstChild() : boolean {
    if(this.isRoot())
      return true;

    return this.parent.children.indexOf(this) == 0;
  }

  /**
   * delete recursively all nodes that matches
   * @param {(node: GenericNode<T>) => boolean} matcher
   * @returns {GenericNode<T>}
   */
  public deleteNodesDepthFirst(matcher: (node: GenericNode<T>)=>boolean): GenericNode<T> {

    for(let child of this.children) {
      child.deleteNodesDepthFirst(matcher);
    }

    for(let child of this.children.slice(0)) {
      if(matcher(child)) {
        this.deleteChild(child);
      }
    }

    return this;
  }


  /**
   * delete recursively all nodes that matches
   * @param {(node: GenericNode<T>) => boolean} matcher
   * @returns {GenericNode<T>}
   */
  public deleteNodesBreadFirst(matcher: (node: GenericNode<T>)=>boolean): GenericNode<T> {


    for(let child of this.children.slice(0)) {
      if(matcher(child)) {
        this.deleteChild(child);
      }
    }

    for(let child of this.children) {
      child.deleteNodesDepthFirst(matcher);
    }

    return this;
  }
  /**
   * Return a copy of the tree - the data is not deep copied
   * @returns {GenericNode<T>}
   */
  public shallowCopy() : GenericNode<T> {

    const copy: GenericNode<T> = new GenericNode(this.data, this.treeDepth);

    for( let child of this.children) {
      copy.appendChild(child.shallowCopy());
    }

    return copy;
  }

  /**
   * sort and or reverse the nodes within one level
   */
  public sort( dataComparator: anyPairToNumberFunction<T> = undefined, reverse: boolean = false) {
    let nodeComparator:  nodePairToNumberFunction<T> = undefined;
    if (dataComparator) {
      nodeComparator = (n1: GenericNode<T>, n2: GenericNode<T>) => {
        return dataComparator(n1.data, n2.data);
      }
    }
    this.enumerateChildren((children) => {
      if(nodeComparator) {
        children.sort(nodeComparator);
      }

      if(reverse) {
        children.reverse();
      }

    });


  }


}
