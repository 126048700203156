/**
 * Created by joerg on 7/17/17.
 */

// angular
import {Component} from '@angular/core';

// mn
import {MnBackend} from "@mn/core";

//
import {Backend} from "../../BackendInterfaces";
import {MnWorkflowComponentStatic} from "../../MnWorkflowComponent";
import {MnActionConfigurator} from "../MnActionConfigurator";
import {MnActionConfiguratorList} from "../MnActionConfiguratorList";
import {MnActionConfigurationService} from "../MnActionConfigurationService";
import {MnWorkflow} from "../../MnWorkflow";

export namespace SearchAnalogs {
    export class Configuration {
        constructor(
            public mode:string,
            public properties:string[] = [],
            public scaffold:string = "",
            public mol:string = "",
            public threshold:number = 75,
            public limit:number = 8,
            public databases:string[] = ['ChemTunes']) {}
    }
}

const el_rows:string = `
            <mn-action-configurator-row-marks
                    line1
            ></mn-action-configurator-row-marks>
`;

const el_similarity:string = `
            <div line2>
                <div> Minimal similarity threshold: {{mConfiguration.threshold}}</div>
                <mat-slider
                        style="width: 100%; padding: 0;"
                        [max]="100"
                        [min]="1"
                        [step]="1"
                        [thumb-label]="true"
                        [tick-interval]="false"
                        [(ngModel)]="mConfiguration.threshold"
                ></mat-slider>
            </div>
`;

const el_hits:string = `
            <div line3>
                <div> Maximum number of hits per compound: {{mConfiguration.limit}}</div>
                <mat-slider
                        style="width: 100%; padding: 0;"
                        [max]="20"
                        [min]="1"
                        [step]="1"
                        [thumb-label]="true"
                        [tick-interval]="false"
                        [(ngModel)]="mConfiguration.limit"
                ></mat-slider>
            </div>
`;

const el_scaffold:string = `
            <div line4>
                <div> Scaffold: </div>
                 <mn-action-configurator-jsme
                        [(smiles)]="mConfiguration.scaffold"
                        [(mol)]="mConfiguration.mol"                            
                ></mn-action-configurator-jsme>
            </div>
`;

@Component({
    selector: 'mn-action-configurator-search-analogs-structure',
    template: `
        <mn-action-configurator-frame [scroll]="false">
            ${el_rows}
            ${el_similarity}
            ${el_hits}
            ${el_scaffold}
        </mn-action-configurator-frame>
     `,
    styles: []
})
export class SearchAnalogsStructure extends MnActionConfigurator {

    private mConfiguration:SearchAnalogs.Configuration = new SearchAnalogs.Configuration('structure');

    constructor(private mBackend:MnBackend, acs:MnActionConfigurationService) {
        super(acs);
        this.enable(true);
    }

    protected onInit(configuration:any) {
    };

    protected onStart() {
        this.finish(this.mConfiguration);
    };

    ngOnDestroy() {
        super.destroy()
    }
}
MnActionConfigurator.Registry.register("SEARCH_ANALOGS",new MnWorkflowComponentStatic(SearchAnalogsStructure));


const el_props:string = `
            <mn-action-configurator-list-controller 
                    line10
                    [label]="'Selected Properties: '+props.count()"
                    (onSelectAll)="props.selectAll()"
                    (onDeselectAll)="props.deselectAll()"
            ></mn-action-configurator-list-controller>
            <mn-action-configurator-property-list
                    scroll
                    #props
                    [(selection)]="mConfiguration.properties"
            ></mn-action-configurator-property-list>
`;

@Component({
    selector: 'mn-action-configurator-search-analogs-property',
    template: `
        <mn-action-configurator-frame>
            ${el_rows}
            ${el_hits}
            ${el_scaffold}
            ${el_props}
        </mn-action-configurator-frame>
     `,
    styles: []
})
export class SearchAnalogsProperty extends MnActionConfigurator {

    private mConfiguration:SearchAnalogsProperty.Configuration = new SearchAnalogsProperty.Configuration('property');

    constructor(private mBackend:MnBackend, acs:MnActionConfigurationService) {
        super(acs);
        this.enable(true);
    }

    protected onInit(configuration:any) {
    };

    protected onStart() {
        this.finish(this.mConfiguration);
    };

    ngOnDestroy() {
        super.destroy()
    }
}
export namespace SearchAnalogsProperty {
    export class Configuration {
        constructor(
            public mode:string,
            public properties:string[] = [],
            public scaffold:string = "",
            public mol:string = "",
            public limit:number = 8,
            public databases:string[] = ['ChemTunes']) {}
    }
}

MnActionConfigurator.Registry.register("SEARCH_PROPERTY_ANALOGS",new MnWorkflowComponentStatic(SearchAnalogsProperty));


