/**
 * Created by joerg on 7/17/17.
 */

// rxjs
import 'rxjs/Rx';
import { Subscription } from 'rxjs/Subscription';

//
import {MnWorkflow} from './MnWorkflow';
import {CtTable} from "./CtTable/CtTable";
import {Backend} from "./BackendInterfaces";

export class MnWorkflowObserver {

    private mWorkflowStatusSubscription:Subscription;
    private mStateStatusSubscription:Subscription;
    private mTransitionStatusSubscription:Subscription;
    private mWorkflowSubscription:Subscription;
    private mStateSubscription:Subscription;
    private mTransitionSubscription:Subscription;
    private mTreeSubscription:Subscription;
    private mTableSubscription:Subscription;
    private mActionsSubscription:Subscription;
    private mIdentifiersSubscription:Subscription;
    private mIdentifiersExternalSubscription:Subscription;

    constructor(private mMnWorkflow:MnWorkflow) {
        this.mWorkflowStatusSubscription = mMnWorkflow.WorkflowStatusObservable.subscribe(status => this.onWorkflowStatus(status));
        this.mStateStatusSubscription = mMnWorkflow.StateStatusObservable.subscribe(status => this.onStateStatus(status));
        this.mTransitionStatusSubscription = mMnWorkflow.TransitionStatusObservable.subscribe(status => this.onTransitionStatus(status));
        this.mWorkflowSubscription = mMnWorkflow.WorkflowObservable.subscribe(workflow => this.onWorkflow(workflow));
        this.mStateSubscription = mMnWorkflow.StateObservable.subscribe(state => this.onState(state));
        this.mTransitionSubscription = mMnWorkflow.TransitionObservable.subscribe(transition => this.onTransition(transition));
        this.mTreeSubscription = mMnWorkflow.TreeObservable.subscribe(tree => this.onTree(tree));
        this.mTableSubscription = mMnWorkflow.TableObservable.subscribe(table => this.onTable(table));
        this.mActionsSubscription = mMnWorkflow.ActionsObservable.subscribe(actions => this.onActions(actions));
        this.mIdentifiersSubscription = mMnWorkflow.IdentifiersObservable.subscribe(idents => this.onIdentifiers(idents));
        this.mIdentifiersExternalSubscription = mMnWorkflow.IdentifiersExternalObservable.subscribe(idents => this.onIdentifiersExteranl(idents));
    }

    public get Workflow():MnWorkflow {
        return this.mMnWorkflow;
    }

    protected onWorkflowStatus(ws:MnWorkflow.WorkflowStatus) {}

    protected onStateStatus(ss:MnWorkflow.StateStatus) {}

    protected onTransitionStatus(ts:MnWorkflow.TransitionStatus) {}

    protected onWorkflow(workflow:Backend.Workflow) {}

    protected onState(state:Backend.FullState) {}

    protected onTransition(transition:Backend.FullTransition) {}

    protected onTree(tree:MnWorkflow.Tree) {}

    protected onTable(table:CtTable) {}

    protected onActions(actions:Backend.Action[]) {}

    protected onIdentifiers(idents:MnWorkflow.Identifiers) {}

    protected onIdentifiersExteranl(idents:MnWorkflow.Identifiers) {}

    protected destory() {
        this.mWorkflowStatusSubscription.unsubscribe();
        this.mStateStatusSubscription.unsubscribe();
        this.mTransitionStatusSubscription.unsubscribe();
        this.mTreeSubscription.unsubscribe();
        this.mTableSubscription.unsubscribe();
        this.mActionsSubscription.unsubscribe();
        this.mIdentifiersSubscription.unsubscribe();
        this.mIdentifiersExternalSubscription.unsubscribe();
    }

}