/**
 * Created by joerg on 7/17/17.
 */

// rxjs
import 'rxjs/Rx';
import { Observable } from 'rxjs/Observable';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Subject } from 'rxjs/Subject';
import { Subscription } from 'rxjs/Subscription';

// angular
import {Component,Input,Injectable,Optional} from '@angular/core';

//
import {MnTable} from "./CtTable";

@Injectable() export class MnTableSelection {

    public ModelSubject:BehaviorSubject<MnTableSelection.Model> = new BehaviorSubject(MnTableSelection.Model.None);
    public RowsMaxSubject:BehaviorSubject<number>;
    public RowsCurrentSubject:BehaviorSubject<Set<string>>;
    public ColsMaxSubject:BehaviorSubject<number>;
    public ColsCurrentSubject:BehaviorSubject<Set<string>>;
    public Table:MnTable;

    private Rows:MnTableSelection.Holder = new MnTableSelection.Holder();
    private Cols:MnTableSelection.Holder = new MnTableSelection.Holder();

    constructor() {
        this.RowsMaxSubject = this.Rows.MaxSubject;
        this.RowsCurrentSubject = this.Rows.CurrentSubject;
        this.ColsMaxSubject = this.Cols.MaxSubject;
        this.ColsCurrentSubject = this.Cols.CurrentSubject;
    }

    public reset(model:MnTableSelection.Model, arg1?:any, arg2?:any, arg3?:any, arg4?:any) {
        if (model == MnTableSelection.Model.None) {
            this.Cols.reset(0);
            this.Rows.reset(0);
        } else if (model == MnTableSelection.Model.Rows) {
            this.Cols.reset(0);
            this.Rows.reset(arg1,arg2);
        } else if (model == MnTableSelection.Model.Cols) {
            this.Rows.reset(0);
            this.Cols.reset(arg1,arg2);
        } else if (model == MnTableSelection.Model.Both) {
            this.Rows.reset(arg1,arg3);
            this.Rows.reset(arg2,arg4);
        }
    }

    public rowAdd(id:string) {this.Rows.add(id)}
    public rowRemove(id:string) {this.Rows.remove(id)}
    public rowToggle(id:string) { return this.Rows.toggle(id) }
    public rowHas(id:string) { return this.Rows.has(id) }
    public colAdd(id:string) {this.Cols.add(id)}
    public colRemove(id:string) {this.Cols.remove(id)}
    public colToggle(id:string) { return this.Cols.toggle(id) }
    public colHas(id:string) { return this.Cols.has(id) }
}
export namespace MnTableSelection {
    export class Holder {
        public MaxSubject:BehaviorSubject<number> = new BehaviorSubject(0);
        public CurrentSubject:BehaviorSubject<Set<string>> = new BehaviorSubject(new Set());

        public get Max():number { return this.MaxSubject.getValue(); }
        public get Current():Set<string> { return this.CurrentSubject.getValue(); }

        public reset(mode:number,current?:Set<string>) {
            console.log(mode);
            this.MaxSubject.next(mode);
            if (!current) {
                current = new Set();
            }
            this.CurrentSubject.next(current);
        }
        public add(id:string) {
            console.log(id);
            if (this.Max == 0) {
                return;
            } else if (this.Max == 1) {
                if (this.Current.size > 0) {
                    this.Current.clear();
                }
                this.Current.add(id);
                this.CurrentSubject.next(this.Current);
            } else if (this.Max > 1 && this.Current.size < this.Max) {
                this.Current.add(id);
                this.CurrentSubject.next(this.Current);
            }
        }
        public remove(id:string) {
            this.Current.delete(id);
            this.CurrentSubject.next(this.Current);
        }
        public has(id:string) {
            return this.Current.has(id);
        }
        public toggle(id:string):boolean {
            console.log(id);
            if (this.Max == 0) {
                return false;
            } else if (this.Max == 1) {
                if (this.Current.has(id)) {
                    this.Current.clear();
                    this.CurrentSubject.next(this.Current);
                    return false;
                } else {
                    this.Current.clear();
                    this.Current.add(id);
                    this.CurrentSubject.next(this.Current);
                    return true;
                }
            } else if (this.Max > 1 && this.Current.size < this.Max) {
                if (this.Current.has(id)) {
                    this.Current.delete(id);
                    this.CurrentSubject.next(this.Current);
                    return false;
                } else {
                    if (this.Current.size < this.Max) {
                        this.Current.add(id);
                        this.CurrentSubject.next(this.Current);
                        return true;
                    }
                    return false;
                }
            }
        }
    }

    export enum Model {
        None = 'None',
        Rows = 'Rows',
        Cols = 'Cols',
        Both = 'Both',
        Cell = 'Cell',
    }
}


export class MnTableSelectionObserver {

    private mCancelSelectionObserver = new Subject<void>();
    /*private mModelSubscription:Subscription;
    private mRowMaxSubscription:Subscription;
    private mColMaxSubscription:Subscription;
    private mRowsSubscription:Subscription;
    private mColsSubscription:Subscription;*/

    constructor(private mMySelection:MnTableSelection) {
        if (this.mMySelection) {
            //fixme not sure if delay is the right thing to do here
            mMySelection.ModelSubject.takeUntil(this.mCancelSelectionObserver).delay(100).subscribe(value => this.onSelectionModel(value));
            mMySelection.RowsMaxSubject.takeUntil(this.mCancelSelectionObserver).delay(100).subscribe(value => this.onSelectionRowMax(value));
            mMySelection.ColsMaxSubject.takeUntil(this.mCancelSelectionObserver).delay(100).subscribe(value => this.onSelectionColMax(value));
            mMySelection.RowsCurrentSubject.takeUntil(this.mCancelSelectionObserver).delay(100).subscribe(value => this.onSelectionRows(value));
            mMySelection.ColsCurrentSubject.takeUntil(this.mCancelSelectionObserver).delay(100).subscribe(value => this.onSelectionCols(value));
        }
    }

    protected onSelectionModel(model:MnTableSelection.Model) {}
    protected onSelectionRowMax(max:number) {}
    protected onSelectionColMax(max:number) {}
    protected onSelectionRows(value:Set<string>) {}
    protected onSelectionCols(value:Set<string>) {}

    protected selectionReset(model:MnTableSelection.Model, arg1?:any, arg2?:any, arg3?:any, arg4?:any) {
        if (this.mMySelection) { this.mMySelection.reset(model,arg1,arg2,arg3,arg4); }
    }
    protected selectionRowAdd(id:string) { if (this.mMySelection) this.mMySelection.rowAdd(id) };
    protected selectionRowRemove(id:string) { if (this.mMySelection) this.mMySelection.rowRemove(id) };
    protected selectionRowToggle(id:string) { if (this.mMySelection) return this.mMySelection.rowToggle(id) };
    protected selectionRowHas(id:string) {
        if (this.mMySelection) { return this.mMySelection.rowHas(id); }
        return false;
    };
    protected selectionColAdd(id:string) { if (this.mMySelection) this.mMySelection.colAdd(id) };
    protected selectionColRemove(id:string) { if (this.mMySelection) this.mMySelection.colRemove(id) };
    protected selectionColToggle(id:string) { if (this.mMySelection) return this.mMySelection.colToggle(id) };
    protected selectionColHas(id:string) {
        if (this.mMySelection) { return this.mMySelection.colHas(id); }
        return false;
    };

    protected destory() {
        this.mCancelSelectionObserver.next();
        /*if (this.mMySelection) {
            this.mModelSubscription.unsubscribe();
            this.mRowMaxSubscription.unsubscribe();
            this.mColMaxSubscription.unsubscribe();
            this.mRowsSubscription.unsubscribe();
            this.mColsSubscription.unsubscribe();
        }*/
    }

}