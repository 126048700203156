import {AfterViewInit, ElementRef, Input} from '@angular/core';
import * as d3 from 'd3';


export interface Margin {
  top: number;
  right: number;
  bottom: number;
  left: number;
}
// Note: this is not a component - don't import it into the app.module.ts

export abstract class BasePlotComponent<T> implements AfterViewInit {

  // subclasses should redefined it
  protected myType = 'BasePlotComponent'; // used for debugging/logging

  @Input() width = 100;
  @Input() height = 80;
  @Input() margin: number | Margin = 3;

  @Input() showPlotTooltip = true; // tooltip for the whole plot, the tooltip label is provided by the dataObject

  // for plots that do not grow in size
  @Input() useParentSize = false; // the size is set to the parent container
  @Input() preserveAspectRatioSVGpar: string; // SVG preserveAspectRatioSVGpar argument - set "none" to allow stretching

  @Input() zoomAndSpan = false;

  @Input() dataObject: T;

  @Input() debug = false;

  protected sizeIsDynamic = false; // true for navigation bars because they can grow

  svgPlotD3: any; // TODO: find type


  // TypeScript constraint:  the constructor must be redefined in subclasses otherwise
  // the console of the browser generates a cryptic error message:
  // Uncaught Error: Can't resolve all parameters for ProbabilityPlotD3Component:

  constructor(public elementRef: ElementRef) {

  }

  /**
   * set my internal object and plot it
   * @param {T} d
   */
  public setDataAndUpdatePlot(d: T): void {

    if (! d) {
      return;
    }
    if (! this.checkDataValidity(d)) {
      return ;
    }

    this.dataObject = d;

    this.plot();
  }


  public abstract checkDataValidity(d: T): boolean ;
  public abstract plot(): void;

  /**
   * If the option useParentSize is defined, then the width
   * will be the parent element  width.
   * NOT TESTED !!!!
   * @returns {number}
   */
  public getWidth___(): number {

    let width = 0;
    if (!this.useParentSize) {
      width =  this.width;
    } else {
      let parent = this.elementRef.nativeElement.parentElement;

      while (parent) {
        width = parent.offsetWidth;
        if (width) {
          break;
        }
        parent = parent.parentElement;
      }
    }

    return width;

  }

  /**
   * display the plot if the dataObject has been provided and is valid
   * and the plot parameters are valid
   */
  ngAfterViewInit(): void {
    if (this.useParentSize && this.debug) {
      this.log('use parent size');
    }
    this.setDataAndUpdatePlot(this.dataObject);
  }



 public log(message: string): BasePlotComponent<T> {
    console.log(this.myType + ': ' + message);
    return this;
 }

 protected getMargin(): Margin {

   let margin: Margin;
   if (typeof this.margin === 'number') {
     const m: number = this.margin;
     margin = {top: m, left: m, bottom: m, right: m};
   } else {
     margin = <Margin> this.margin;
   }

   return margin;
 }
  /**
   * This method creates the D3 SVG and add it the component
   * This method is intended to be called by plot().
   * remove any previous svg DOM element - useful if the data has changed
   * or the plot must be resized, or other parameters were changed.
   * If the argument plotLabel is not null, a tooltip will be added to the plot.
   * @param {string} plotLabel
   * @returns {any}: the first group added to D3 SVG object
   */
 protected startD3Plot(plotLabel: string, centerPlot: boolean): any {


  const margin: Margin = this.getMargin();


   d3.select(this.elementRef.nativeElement).select('svg').remove();

   if (! this.checkPlotParameters()) {
     return null;
   }



   // Add the SVG to the component and center it using the margin
   let svg = this.svgPlotD3 = d3.select(this.elementRef.nativeElement).append('svg');

   if ( ! this.useParentSize) {
     svg.attr('width', this.width)
     .attr('height', this.height);
   } else {
     svg.attr('width', '100%')
     .attr('height', '100%')
      .attr('viewBox',  '0 0 ' + this.width + ' '  + this.height);
     if ( this.preserveAspectRatioSVGpar) {
       svg.attr('preserveAspectRatioSVGpar', this.preserveAspectRatioSVGpar);
     }
   }

    // https://coderwall.com/p/psogia/simplest-way-to-add-zoom-pan-on-d3-js
    // must be applied on the whole svg , not a subgroup - otherwise does not work smoothly
    if (this.zoomAndSpan) {
      svg.call(d3.zoom().on('zoom', function () {
        svg.attr('transform', d3.event.transform);
      }));
    }
    svg = svg.append('g');

    if (centerPlot) {
     const translation = 'translate(' + margin.left + ',' + margin.top + ') ';
     svg.attr('transform', translation);
   }



   this.createTooltipForTheWholePlot(svg, plotLabel);


   return svg;

 }

    /**
     * Return the graphics content as an SVG string
     * @returns {string}
     */
    public getSVG(): string {
        let svgRoot = this.svgPlotD3.node();
        let svgText = svgRoot.parentElement.innerHTML;
        return svgText;

    }
 protected  isNonEmptyString(s: string): boolean {
   return s && s.length > 0 && !/^\s*$/.test(s);
 }

 // TODOy7h7 one could use the Angular decimal pipe

  /**
   * DEPRECATED use d3.format  - see http://bl.ocks.org/zanarmstrong/05c1e95bf7aa16c4768e
   * Goal is to limit the text width of a number
   * to be displayed on the plot
   * poor man sprintf  - to be improved
   * @param {number} number
   * @returns {string}
   */
 protected formatNumber(number: number) {
   return d3.format('.2f')(number);
 }

  /**
   * Check width, height and margin parameters.
   * log a message to the console if an error has been found
   * @returns {boolean}
   */
 public checkPlotParameters(): boolean {
  let result = true;

    const margin: Margin = this.getMargin();

    if ( !(margin.top >= 0 && margin.right >= 0 && margin.bottom >= 0 && margin.left >= 0)) {
     this.log('invalid margin');
     result = false;
   }

   if (this.width <= 0 || this.height <= 0 ) {
     this.log('invalid width or height');
     result =  false;
   }

   if (!result) { return result; }

   // plot width and plot height
    if (! this.sizeIsDynamic) {
      const [pWidth, pHeight] = this.computePlotDimension();

      if (pWidth <= 0 || pHeight <= 0) {
        this.log('margin is too large for the given width or height');
        return false;
      }
    }
   return true;
 }

  /**
   * Compute the plot dimension decreased by the margin
   * @returns {number[]}
   */
 protected computePlotDimension(): number[] {

   const margin: Margin = this.getMargin();

   // plot width and plot height
   const pWidth: number = this.width - margin.left - margin.right;
   const pHeight: number = this.height - margin.top - margin.bottom;

   return [pWidth, pHeight];
 }


  /**
   * provide a tooltip for the whole plot if a non null label is provided
     use title attribute
   * @param svgD3
   * @param {string} plotToolTipLabel
   */
 protected createTooltipForTheWholePlot( svgD3: any, plotToolTipLabel: string) {

   if (this.showPlotTooltip && this.isNonEmptyString(plotToolTipLabel)) {

     const [pWidth, pHeight ] = this.computePlotDimension();
     // need a background rect to attach the tooltip to
     const backgroundGroup = svgD3.append('g');
     backgroundGroup.append('title').html(plotToolTipLabel);
     // backgroundGroup.attr('mattooltip' , 'backgroundRect Tooltip') //does not work

     // we need a rectangle that covers the whole plot - setting  width and height to the <g> does not work
     const backgroundRect = backgroundGroup.append('rect').attr('width', pWidth)
         .attr('height', pHeight)
         .attr('stroke', 'transparent')
         .attr('fill', 'transparent')
       // material tooltip does not work
       // .attr('matTooltip' , 'backgroundRect Tooltip') // does not work
       // .attr('mattooltip' , 'backgroundRect Tooltip') //does not work
     ;

   }
 }

  /**
   * It can happen that the displayed text is cropped because its position
   * is too close to right or to the left of the plot, if this happens,
   * this method moves the text element to the left or to the right such
   * that it is not cropped anymore.
   * If the test is larger than the plot width, do nothing
   * @param svgElement - the element to correct
   * @param {number} pWidth - plot width
   */
 public correctHorizontalPositionIfNeeded(svgElementD3, pWidth: number) {

   if (pWidth <= 0) {
     return;
    }

    // get the bounding box of the svg element
    const bbox: SVGRect = svgElementD3.node().getBBox();
    if (bbox.width > pWidth) {
     return;
    }
    const x: number = Number(svgElementD3.attr('x'));
    if (bbox.x > 0) {
      const moveX = pWidth - (bbox.x + bbox.width);
      if (moveX < 0) {
        svgElementD3.attr('x', x + moveX);
      }
    } else {
      svgElementD3.attr('x', x + -bbox.x); // bbox.x is negative
    }

 }

  /**
   * TODO: maxHeight is not used
   * @param svgText
   * @param {number} maxWidth
   * @param {string} maxHeight
   * @returns {string} e.g. 25px
   */
  protected getFontSizeTofit(svgText: any, maxWidth: number, maxHeight: number) {
    const lx = svgText.getComputedTextLength();
    const style: any = window.getComputedStyle(svgText); // return CSSStyleDeclaration instance
    let fSize: string = style.getPropertyValue('font-size');
    if (lx > maxWidth) {
      const sizeRe = /([0-9]*\.?[0-9]*)(\w+)?/;
      const matchArray: string[] = sizeRe.exec(fSize);
      if (matchArray && matchArray.length === 3) {
        const realSize: number = +matchArray[1];
        const unit: string = matchArray[2] ? matchArray[2] : '';
        const newSize: number = realSize / lx * maxWidth;
        fSize = newSize + unit;
      }
      return fSize;
    }
  }
}
