
//import {math} from 'maths.ts';
//import * as math from 'mathjs';

import { tanimotoBinaryDistance, tanimotoBinarySimilarity } from './tanimoto/Tanimoto';

export class ArrayUtils { //should we use a namesapce instead?
  // missing mathJS functions
  /*
    public getRows(M:  mathjs.Matrix): number[][] {
      const [m,n]: number[] = M.size();
      const rows: number[][] = []
      for( let i = 0; i < m; i++) {
        rows[i] = M._data[i]; // works only for a dense matrix, violate data encapsulation
       }
      return rows;
    }

    public getColumns(M: mathjs.Matrix): number[][] {
      return this.getRows(<any>math.transpose(M));
    }
  */

  public map2(X: any[], Y: any[], f: Function): any[] {
    const n: number = Math.min(X.length, Y.length);

    return this.range(n).map( i => f(X[i], Y[i], i));
  }

  public mapn(f: Function, ...args: any[]) {
    const n: number = Math.min.apply(null, args.map(a=>a.length));

    return this.range(n).map( i => f.apply(null, args.map(a=>a[i])));
  }
  /**
   * Same a Python range : range([start], stop[, step])
   * @returns {number[]}
   */
  public range(...args: number[]) : number[] {
    let start = 0;
    let stop = 0;
    let step = 1;

    console.assert(args.length>0 && args.length<=3);
    if (args.length == 1) {
      stop = args[0];
    }
    if (args.length >= 2) {
      start = args[0];
      stop = args[1]
    }
    if (args.length == 3) {
      step = args[2];
    }

    const results: number[] = [];
    for( let i = start; i < stop ; i += step) {
      results.push(i);
    }

    return results;


  }

  public geTranspose(m: number[][]): number[][] {
    if(m.length == 0) {
      return m;
    }


    let colIndices = this.range(m[0].length);

    let cols = colIndices.map(i => m.map(row => row[i]));

    return cols;


  }

  public mean(a: number[]): number {
    const n = a.length;
    if ( n <= 0) {
      return NaN;
    }

    return this.sum(a) / n;
  }

  public sum(a: number[]): number {
    return  a.reduce( (sum, value) => sum + value, 0);
  }

  public product(a: number[]): number {
    return  a.reduce( (p, value) => p * value, 1);
  }

  /**
   * Standard deviation
   * @param {number[]} a
   * @returns {number}
   */
  public std(a: number[]) {
    const n = a.length;
    if ( n <= 1) { // because we will divide by n-1
      return NaN;
    }
    let mean: number = this.mean(a);
    let squaredDiff: number[] = a.map( v => (v-mean)**2);
    let meanSquaredDiff: number = this.sum(squaredDiff)/ (n-1);

    let result = Math.sqrt(meanSquaredDiff);


    return result;


  }

  /**
   * Return a new matrix for which each value has been standardized using the mean
   * and standard deviation of each column. If a column has a standard deviation of 0
   * all values in the column are set to 0.
   * @param {number[][]} m
   * @returns {number[][]}
   */
  public standardize(m: number[][]):  number[][] {
    let averages: number[];
    let stds: number[];

    // I forgot how to do multiple assigmanr
    const averagesAndStds: number[][] = this.computeAveragesAndStandardDeviations(m);
    averages = averagesAndStds[0];
    stds = averagesAndStds[1];

    console.assert(averagesAndStds.length == 2);
     console.assert(averages.length == stds.length);


     const results = this.customStandardize(m, averages, stds);

    return results;


  }

    /**
     * Standardization using pre-calculated averages and standard deviations
     * @param {number[][]} m
     * @param {number[]} averages
     * @param {number[]} stds
     */
  public customStandardize(m: number[][], averages: number[], stds: number[]) {

    const results = m.map( r =>  r.map((v,j) => stds[j] == 0 ? 0 : (v-averages[j])/stds[j]));
    return results;
  }

  /**
   * Return a [averages, stds]
   * @param {number[][]} m
   * @returns {number[][]}
   */
  public computeAveragesAndStandardDeviations(m: number[][]) :  number[][] {
    const cols: number[][] = this.geTranspose(m);
    const stds: number[] = cols.map(c => this.std(c));
    const averages: number[] = cols.map(c => this.mean(c));

    return [averages, stds];


  }


  /**
   * Compare two 2D matrices with the same size. If the two matrices have different size, than returns -1.
   * Otherwise return the maximum absolute difference between two values.
   * This method can be used to test for matrix numeric equality
   * @param {number[][]} m1
   * @param {number[][]} m2
   * @returns {number}
   */

  public max2DmatrixDifference(m1: number[][], m2:  number[][] ) {
    let maxDiff = 0;
    let error = NaN;
    if (m1.length != m2.length) {
      return error;
    }
    for( let i = 0 ; i < m1.length; i ++) {
      const r1: number[] = m1[i];
      const r2: number[] = m2[i];
      if (r1.length != r2.length) {
        return error;
      }

      maxDiff = Math.max(this.maxArrayDifference(r1, r2));

    }
    return maxDiff;

  }

  ///////////////// useful for testing \\\\\\\\\\\\\\\\\\\\\\

  public maxArrayDifference(a1: number[], a2:  number[] ) {
    let maxDiff = 0;

    let n = Math.min(a1.length, a2.length)


    for( let j = 0 ; j < n ; j ++) {
      maxDiff = Math.max(Math.abs(a1[j]-a2[j]), maxDiff);
    }

    return maxDiff;

  }

  public parse2DmatrixData(m: string): number[][] {
    return m.trim().split(/\n/).map((line:string)=>line.trim().split(/\s+/).map(s=>+s));
  }

  public parseArrayData(a: string): number[] {
    return a.trim().split(/\s+/).map(s=>+s);
  }


  /*
def combinations(iterable, r):
  # combinations('ABCD', 2) --> AB AC AD BC BD CD
  # combinations(range(4), 3) --> 012 013 023 123
  pool = tuple(iterable)
  n = len(pool)
  if r > n:
      return
  indices = range(r)
  yield tuple(pool[i] for i in indices)
  while True:
      for i in reversed(range(r)):
          if indices[i] != i + n - r:
              break
      else:
          return
      indices[i] += 1
      for j in range(i+1, r):
          indices[j] = indices[j-1] + 1
      yield tuple(pool[i] for i in indices)
 */

  public *yieldCombinations( coll: any[], r: number) {
    const n: number = coll.length;

    if( r > n) {
      return;
    }

    const indices: number[] = this.range(r);

    yield indices.map(i=> coll[i]);

    //console.log(indices);

    while(true) {
      let found = false;
      let i: number;
      for( i of this.range(r).reverse() ) {
        if (indices[i] != i + n - r) {
          found = true; break;
        }
      }
      if(!found) {
        return;
      }

      indices[i] += 1;

      for( let j of this.range(i+1, r)) {
        indices[j] = indices[j - 1] + 1;
      }
      yield indices.map(i => coll[i]);

    } // while(true)
  }

  public combinations( coll: any[], r: number) {
    const cGen = this.yieldCombinations(coll, r);
    const results: any[]  = [];

    let genResult: any =  cGen.next();
    while(genResult.done == false) {
      results.push(genResult.value);
      genResult = cGen.next();
    }


    return results;

  }

  public yieldCombinationWithDifferentIndices( coll1: any[], coll2: any[], n: number) {


  }
}

export class SimilaritiesDistances extends ArrayUtils { //should we use a namesapce instead?





  // X and Y must be standardized
  public pearsonCorrelationCoefficient(X:  number[], Y: number[]): number {
    if( X.length != Y.length) {
      console.assert(false, 'pearsonCorrelationCoefficient: Different array length');
      return NaN;
    }
    const n = Math.min(X.length, Y.length);
    if (n <= 1) {
      return NaN;
    }

    const xMean = this.mean(X);
    const yMean = this.mean(Y);

    const xStd = this.std(X);
    const yStd = this.std(Y);

    if( xStd == 0 || yStd == 0) {
      return NaN;
    }

    let sum = 0;
    for( let i = 0; i < n ; i++) {
      sum += (X[i] - xMean) * (Y[i] - yMean) / xStd / yStd;

    }

    return sum / (n - 1);

  }

  /**
   * The first row is the reference. The Pearson coefficient is computed for each pair
   * first row - each other row. The input data is standardized using the whole matrix.
   * @param {number[][]} m
   * @returns {number[]}
   */
  public computePearsonCorrelationCoefficientMatrix(m: number[][]) : number[] {
    let stdMatrix: number[][] = this.standardize(m);
    const first: number[] = stdMatrix.shift();

    const results: number[] = stdMatrix.map( (other: number[]) => this.pearsonCorrelationCoefficient(first, other));

    return results;


  }

    /**
     * The first row is the reference. The Pearson coefficient is computed for each pair
     * first row - each other row. The input data is standardized using the provided averages and
     * standard deviations.

     * @param {number[][]} m
     * @param {number[]} averages
     * @param {number[]} stds
     * @returns {number[]}
     */
  public computePearsonCorrelationCoefficientMatrixWithCustomStandardization(m: number[][], averages: number[], stds: number[]): number[] {
    let stdMatrix: number[][] = this.customStandardize(m, averages, stds);
    const first: number[] = stdMatrix.shift();

    const results: number[] = stdMatrix.map( (other: number[]) => this.pearsonCorrelationCoefficient(first, other));

    return results;


  }

   // X and Y should be standardized
  public euclidianDistance(X:  number[], Y: number[]): number {
    if( X.length != Y.length) {
      console.assert(false, 'euclidianDistance: Different array length');
      return NaN;
    }
    const n = Math.min(X.length, Y.length);

    if (n < 1) {
      return NaN;
    }
    let sum = 0;
    for( let i = 0; i < n ; i++) {
      sum += (X[i] - Y[i]) ** 2;
    }

    return Math.sqrt(sum);

  }
  /**
   * The first row is the reference. The Euclidean distance is computed for each pair
   * first row - each other row. The input data is standardized using the whole matrix.
   * @param {number[][]} m
   * @returns {number[]}
   */
  public computeEuclidianDistanceMatrix(m: number[][]) : number[] {
    let stdMatrix: number[][] = this.standardize(m);
    const first: number[] = stdMatrix.shift();

    const results: number[] = stdMatrix.map( (other: number[]) => this.euclidianDistance(first, other));

    return results;


  }

  // NOT TESTED!!!!
  public computePcomputeEuclidianDistanceMatrixWithCustomStandardization(m: number[][], averages: number[], stds: number[]): number[] {
    let stdMatrix: number[][] = this.customStandardize(m, averages, stds);
    const first: number[] = stdMatrix.shift();

    const results: number[] = stdMatrix.map( (other: number[]) => this.euclidianDistance(first, other));

    return results;


  }

    // padding is needed to compute Tanimoto coefficients
    protected zeros:string = "00000000000000000000000000000000";

    protected fillZeros(v:string)  {
        let r = v.length % 32;
        if (r != 0) {
            return v + this.zeros.substr(0,32-r);
        }
        return v;
    };


  public tanimotoBinaryDistance(v1: string, v2: string) : number {
    console.assert(v1.length == v2.length);

    const result: number = tanimotoBinaryDistance(this.fillZeros(v1),this.fillZeros(v2));

    return result;

  }

  public tanimotoBinarySimilarity(v1: string, v2: string) : number {
    console.assert(v1.length == v2.length);

    const result: number = tanimotoBinarySimilarity(this.fillZeros(v1),this.fillZeros(v2));

    return result;

  }
}
