/**
 * Created by joerg on 5/3/17.
 */

// angular
import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

// uirouter
import { UIRouterModule } from '@uirouter/angular';

// mn
import { MnCoreModule } from '@mn/core';
import { MnRouteModule } from '@mn/route';
import { MnCommonModule } from '@mn/common';
import { MnD3Module } from '@mn/d3';
//import { MnMaterialModule } from '@mn/material';
import { MnGridModule } from '@mn/grid';

import {
    MatRippleModule,
    MatListModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatSlideToggleModule,
    MatNativeDateModule,
    MatInputModule,
    MatTabsModule,
    MatSliderModule,
    MatDialogModule,
    MatMenuModule,
    MatChipsModule,
    MatPaginatorModule,
    MatIconModule,
} from '@angular/material';

let material_modules = [
    //ScrollDispatchModule,
    MatRippleModule,
    MatListModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatSlideToggleModule,
    MatNativeDateModule,
    MatInputModule,
    MatTabsModule,
    MatSliderModule,
    MatDialogModule,
    MatMenuModule,
    MatChipsModule,
    MatPaginatorModule,
    MatIconModule,
];

//
import { CtDetailsView } from './CtDetailsView';


let directives:any[] = [];
let components:any[] = [CtDetailsView];

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        UIRouterModule.forChild(),
        MnCoreModule,
        MnRouteModule,
        MnCommonModule,
        MnGridModule,
        ...material_modules,
        //MnMaterialModule,
        //MnAnimationsModule,
    ],
    providers: [],
    exports: components.concat(directives),
    declarations: components.concat(directives),
    entryComponents: components
})
export class CtDetailsModule {
    constructor() {
        console.log("constructor CtDetailsModule")
    }
    static forRoot(): ModuleWithProviders {
        return {
            ngModule: CtDetailsModule,
            providers: []
        };
    }
}

export class ct_details {
    static configure():any {
        return [
            CtDetailsModule.forRoot()
        ];
    }
}