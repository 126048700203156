/**
 * Created by joerg on 7/17/17.
 */

// rxjs
import 'rxjs/Rx';
import { Observable } from 'rxjs/Observable';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Subject } from 'rxjs/Subject';
import { Subscription } from 'rxjs/Subscription';

// angular
import {Component,Input,HostListener,Output,EventEmitter} from '@angular/core';

//

@Component({
    selector: 'ct-details-view',
    templateUrl: './CtDetailsView.html',
    styleUrls: ['./CtDetailsView.css']
})
export class CtDetailsView {

    constructor() {
    }

    ngOnDestroy() {
    }

}


