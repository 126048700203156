// rxjs
import 'rxjs/Rx';

// import { Request, RequestOptionsArgs, Response } from "@angular/http";
// import { Observable } from "rxjs/Observable";

// import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Subject } from 'rxjs/Subject';


import {Component, ElementRef} from "@angular/core";
import {IHeaderParams} from "ag-grid/main";
import {IHeaderAngularComp} from "ag-grid-angular/main";

// ag-grid bug
// import {IHeaderCompParams} from ??;

import {CtSelectionService} from "../CtSelectionService"
import {CtTable} from "../CtTable";

@Component({
    templateUrl: './AgGridHeader.html',
    styleUrls: ['./AgGridHeader.css']
})

// one instance per column
export class AgGridHeader implements IHeaderAngularComp {

    mIcons:any = {
        '':'fa-sort',
        'asc':'fa-sort-up',
        'desc':'fa-sort-down',
    };

    mSortNext:any = {
        '':'asc',
        'asc':'desc',
        'desc':'',
    };

    public params: IHeaderParams;
    public sorted: string;
    public sortable: boolean = true;
    public selected:boolean = false;
    public selectable:boolean = false;
    private elementRef: ElementRef;
    private help:string;
    private mDestroy:Subject<any> = new Subject();

    protected table: CtTable;

    constructor(elementRef: ElementRef,private mSelectionService:CtSelectionService) {
        this.elementRef = elementRef;
        this.mSelectionService.ColChangeSubject
            .takeUntil(this.mDestroy)
            .subscribe(()=> {
                this.updateSelection();
            });
        //console.log(mSelectionService);
        this.table = mSelectionService.ctTable;

        // mSelectionService contains the data of the table but not the CtTable it self from which one could get the virtual rows.
    }

    agInit(params): void {
        this.params = params;
        this.params.column.addEventListener('sortChanged', this.onSortChanged.bind(this));
        this.onSortChanged();
        this.sortable = params.enableSorting;
        this.selectable = this.mSelectionService.isColSelectable(this.params.column.getColId());
        this.selected = this.mSelectionService.isColSelected(this.params.column.getColId());
        if ((<any>params.column.getColDef()).help) {
            this.help = (<any>params.column.getColDef()).help
        }
    }

    updateSelection() {
        if (!this.params) {
            return;
        }
        console.log(this.params);
        this.selectable = this.mSelectionService.isColSelectable(this.params.column.getColId());
        this.selected = this.mSelectionService.isColSelected(this.params.column.getColId());
    }

    ngOnDestroy() {
        //console.log(`Destroying HeaderComponent`);
        this.mDestroy.next();
    }

    // called after  onSort() has completed
    onSortChanged() {
        // console.log('onSortChanged');
        if (this.params.column.isSortAscending()) {
            this.sorted = 'asc'
        } else if (this.params.column.isSortDescending()) {
            this.sorted = 'desc'
        } else {
            this.sorted = ''
        }

     };

    onSort(event) {

        // prepare the table for sorting
        // this will be used later in a custom comparator for sorting
        // see CtStateViewHelpers.ts
        this.table.sortMultiLevels(this.params, this.mSortNext[this.sorted], event.shiftKey);

        this.params.setSort(this.mSortNext[this.sorted], event.shiftKey);        //console.log("this.expanded",this.params);



    }

    onSelect() {
        // console.log("OnSelect");
        if (this.selectable) {
            this.selected = !this.selected;
            this.mSelectionService.selectCol(this.params.column.getColId(),this.selected);
        }
    }

}