/**
 * Created by joerg on 7/17/17.
 */

// rxjs
import 'rxjs/Rx';
import { Subject } from 'rxjs/Subject';

// angular
import {Component,Injector} from '@angular/core';

import { Observable } from 'rxjs/Observable';
import {MnBackend} from "@mn/core";


//
import {MnWorkflowComponentStatic} from "../MnWorkflowComponent";
import {MnStateView2} from "./MnStateView2";
import {MnWorkflow} from "../MnWorkflow";
import {MnTableFocus} from "../CtTable/MnTableFocus";
import {MnStateViewFocusView} from "./MnStateViewFocusView";
import {CtTable} from "../CtTable/CtTable";
import {parseChemotypeMatch} from '../CtValueViews/CtChemotypeProfileCountView';
import {ChemotypeProfileMatches, testmol} from "../ChemotypeProfiler/chemotypermatcher.models";

@Component({
    selector: 'mn-state-view-chemotype-profiles',
    template: `<div  style="width: 100%;height:100%; overflow:auto">
                <app-chemotypematcher [mol]="mol" [isRowSelected]="profileCanBeDisplayed" [cpMatches]="mData" [errorMessage]="errorMessage"></app-chemotypematcher>
            </div>
 
    `,
})
export class MnStateViewChemotypeProfile extends MnStateViewFocusView {

    protected mCancel:Subject<void> = new Subject();
    protected oneRowIsSelected = false;
    protected errorMessage = '';
    protected profileCanBeDisplayed = false;

    protected  table: CtTable ;

    protected mol: string;

    // whether or not one should create/show a new tab
    static createStateTabs(workflow:MnWorkflow):MnStateView2.Tab[] {

        // check if the table has data first
        if (workflow && workflow.Workflow && workflow.State && workflow.State.dataset && workflow.State.dataset.table && workflow.State.dataset.table.data.length > 0) {
            // check columns of table
            // workflow.Table -> CtTable check if column for Profile exists in table, otherwise a detail chemotype tab will not be created/shown.

            // does not work: the table has not been created yet
            // if (workflow.Table.getFlatNodesOfType(CtTable.ColumnType.ChemotypeProfile).length > 0) {
            if(workflow.State.dataset.table.spec.filter((colSpec)=>colSpec.type==CtTable.ColumnType.GroupChemotypeProfile).length > 0) {
                return [{
                    id: "",
                    label: "ChemoType Profiles", //translation needed
                    select: 1,
                    description: null,
                    closable: false
                }]
            } else {
                return [];
            }
        } else {
            return [];
        }
    }

    constructor(injector:Injector,  private backend:MnBackend) {
        super(injector);
    }

    // when the tab get the focus = > display the profile matching
    protected onTableFocus(cell:MnTableFocus.Cell) {

        this.profileCanBeDisplayed = false;

        if(!cell) {
            return;
        }
        if(!cell.row || !cell.col) {
            return;
        }

        const table : CtTable = this.getTable();
        if( !table) {
            this.errorMessage = 'No table';
            return;

        }
        this.table = table;
        let row: CtTable.SortedRow = this.getRowData(cell);

        // find the chemotype profiles
        let nodes: CtTable.Node[] = table.getFlatNodesOfType(CtTable.ColumnType.ChemotypeProfile);
        if(! nodes) {
            this.errorMessage = 'No chemotype profiles';
            return;
        }

        // the data is a python string : bug to be fixed by Thomas

        let data: any[] = nodes.map((n:CtTable.Node)=> {
                let profiler: string = n.ident;
                let result = {};
                let match: any = parseChemotypeMatch(n.getter(row));

                result[profiler] = match;

                return result;

            }
        );
        //console.log(data);


        this.mData = new ChemotypeProfileMatches(data);

        const fake = false ;

        if(!fake && ! this.backend) {
            this.errorMessage = 'this.backend is undefined';
            return;

        }
        if(!fake && this.backend) {
            this.mol = null;
            this.profileCanBeDisplayed = false;
            try {
                this.backend.get(`/datasets/record/${row.id}/mol/`)
                    .takeUntil(this.mDestroy)
                    .map(res => res.json())
                    .subscribe((data) => {
                        this.mol = data.mol;
                        //console.log(this.mol);
                        this.profileCanBeDisplayed = true;
                    }, (error) => {
                        console.log(error);

                        this.errorMessage = error;
                    });
            } catch(error) {
                this.errorMessage = error;
            }

        } else
        {
            this.mol = testmol;
            this.profileCanBeDisplayed = true;
        }



    }

    ngOnDestroy() {
        super.ngOnDestroy();
    }
}

MnStateView2.Registry.register("MnStateViewChemotypeProfile",new MnWorkflowComponentStatic(MnStateViewChemotypeProfile));
