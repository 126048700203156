/***
 * ides for a much simpler/faster implementation: an angular pipe instead of a component
 */


// angular
import {Component,Output,EventEmitter} from '@angular/core';

//
import {CtValueView, CtValueViewRegisterDecorator} from "../CtValueView";

let number_css = `
        :host {
            position: relative;
        }
        div {
            font-family: "Lucida Console", Monaco, "Courier New", Courier, monospace;
              position: absolute;
              top: 50%;
              right: 0;
              transform: translate(0,-50%);
        }
    `;

@Component({
    selector: 'ct-int-view',
    template: '<div class="ct-value-view" [innerHtml]="mValue"></div>',
    styles: [number_css]
})
@CtValueViewRegisterDecorator(CtValueView.Types.Int)
export class CtIntView extends CtValueView {

    private mValue:any;

    constructor(service:CtValueView.Service) {
        super(service);
    }

    public update(parameters:CtValueView.Parameters) {
        this.mValue = parameters.value;
    }

    ngOnDestroy() {
        this.destroy();
    }

    static render(parameters:CtValueView.Parameters):string {
        return '<div>'+parameters.value+'</div>';
    }

    static classes(parameters:CtValueView.Parameters) {
        return 'ct-int-view'
    }
}


@Component({
    selector: 'ct-float-view',
    template: '<div class="ct-value-view" [innerHtml]="mValue"></div>',
    styles: [number_css]
})

@CtValueViewRegisterDecorator(CtValueView.Types.Float)
export class CtFloatView extends CtValueView {

    digits = 2;

    private mValue:any;

    constructor(service:CtValueView.Service) {
        super(service);
    }

    // TODO:  use d3.format  - see http://bl.ocks.org/zanarmstrong/05c1e95bf7aa16c4768e
    // d3.format('.2f')(3.141567979797)

    public update(parameters:CtValueView.Parameters) {
        this.mValue = parameters.value;
        if (!isNaN(this.mValue) && this.mValue.toString().indexOf('.') != -1) {
            var pow = Math.pow(10, this.digits);
            this.mValue = Math.round(this.mValue*pow)/pow;
        }
    }

    ngOnDestroy() {
        this.destroy();
    }

    static render(parameters:CtValueView.Parameters):string {
        let v:any = '';
        if (!isNaN(parameters.value) && parameters.value.toString().indexOf('.') != -1) {
            var pow = Math.pow(10, 2);
            v = Math.round(parameters.value*pow)/pow;
        }
        else if (!isNaN(parameters.value) && parameters.value.toString().indexOf('.') == -1) {
            v = parameters.value.toString() + '.00';
        }
        return '<div>'+v+'</div>';
    }

    static classes(parameters:CtValueView.Parameters) {
        return 'ct-float-view'
    }

}
export namespace CtFloatView {
    export interface Options extends CtValueView.Options {
        digits:number;
    }
}
