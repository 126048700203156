/**
 * Created by joerg on 7/17/17.
 */

// rxjs
import 'rxjs/Rx';
import { Observable } from 'rxjs/Observable';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Subject } from 'rxjs/Subject';
import { Subscription } from 'rxjs/Subscription';

// angular
import {Component,Input,Output,EventEmitter} from '@angular/core';
import {trigger, state, style, transition, animate, keyframes, AnimationTriggerMetadata} from '@angular/animations';

//
import {MnBrandService, MnTextService} from "@mn/core";

const widths = {
    LeftOnly: {
        left: '100%',
        right: '0px',
    },
    LeftRight1: {
        left: '100%',
        right: '400px',
    },
    LeftRight2: {
        left: '100%',
        right: '240px',
    },
    RightOnly: {
        left: '100%',
        right: '100%',
    },
};

let createLeftState = function (key) {
    return state(key,
        style({ width: `calc(${widths[key].left} - ${widths[key].right})` }),
    )
};

let createRightState = function (key) {
    return state(key,
        style({ left: `calc(${widths[key].left} - ${widths[key].right})`, width: widths[key].right }),
    )
};

let aSlideLeft:AnimationTriggerMetadata = trigger('aSlideLeft', [
    createLeftState('LeftOnly'),
    createLeftState('LeftRight1'),
    createLeftState('LeftRight2'),
    createLeftState('RightOnly'),
    transition('* <=> *', animate('400ms cubic-bezier(0.25, 0.8, 0.25, 1)')),
]);

let aSlideRight:AnimationTriggerMetadata = trigger('aSlideRight', [
    createRightState('LeftOnly'),
    createRightState('LeftRight1'),
    createRightState('LeftRight2'),
    createRightState('RightOnly'),
    transition('* <=> *', animate('400ms cubic-bezier(0.25, 0.8, 0.25, 1)')),
]);

const States = {
    A0: {
        header: 'LeftRight2',
        content: 'LeftRight2',
    },
    A1: {
        header: 'LeftRight2',
        content: 'LeftOnly',
    },
    C0: {
        header: 'RightOnly',
        content: 'RightOnly',
    },
    C1: {
        header: 'LeftRight1',
        content: 'LeftRight1',
    },
    C2: {
        header: 'LeftRight1',
        content: 'RightOnly',
    },
};

@Component({
    selector: 'mn-workflow-frame',
    templateUrl: './MnWorkflowFrame.html',
    styleUrls: ['./MnWorkflowFrame.css'],
    animations: [
        aSlideLeft,
        aSlideRight,
        trigger(
            'myAnimation',
            [
                transition(
                    ':enter', [
                        style({opacity: 0}),
                        animate('800ms', style({opacity: 1}))
                    ]
                ),
                transition(
                    ':leave', [
                        style({opacity: 1}),
                        animate('800ms', style({opacity: 0})),

                    ]
                )]
        )

    ]
})
export class MnWorkflowFrame {

    private states = States;
    private mState:MnWorkflowFrame.State = MnWorkflowFrame.State.A0;
    @Input() set state(value:MnWorkflowFrame.State) { this.mState = value; }
    get state():MnWorkflowFrame.State { return this.mState; }

    @Input()
    get actionHeader():string { return this.mActionHeader; }
    set actionHeader(v:string) { this.mActionHeader = v; this.updateActionHeader(); }
    private mActionHeader:string;

    @Output() onActionClose: EventEmitter<void> = new EventEmitter();
    @Output() onActionShow: EventEmitter<void> = new EventEmitter();

    private mIconFa:string;
    private mIconSvg:string;
    private mActionHeaderText:string = "";

    constructor(private mBrand:MnBrandService, private mText:MnTextService) {}

    private updateActionHeader() {
        this.mIconSvg = undefined;
        this.mIconFa = undefined;
        if (!this.mActionHeader) return;
        let icons = this.mBrand.brand().data.ct.action.icons;
        let token = this.mActionHeader;
        if (icons[token] ) {
            if (icons[token].fa) {
                this.mIconFa = icons[token].fa
            }
            else if (icons[token].svg) {
                this.mIconSvg = icons[token].svg
            }
        }
        this.mActionHeaderText = (<string>this.mText.text('ct.action.title.'+this.mActionHeader)).replace('<br>',' ');
    }

    private close() {
        this.onActionClose.emit();
    }

    private show() {
        if (this.mState == MnWorkflowFrame.State.A1) {
            this.onActionShow.emit();
        }
    }

    public setState(state:MnWorkflowFrame.State) {
        this.mState = state;
    }

}
export namespace MnWorkflowFrame {
    export enum State {
        A0 = "A0",
        A1 = "A1",
        C0 = "C0",
        C1 = "C1",
        C2 = "C2",
    }
}
