/**
 * Created by joerg on 6/8/17.
 */


"use strict";

// rxjs
import {Observable} from "rxjs/Rx";
import { Subject } from 'rxjs/Subject';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';

// angular
import {Injectable} from '@angular/core';

//
import {Backend} from "./BackendInterfaces"
import FullState = Backend.FullState;
import {CtTable} from "./CtTable/CtTable";
import {MnTextService} from "@mn/core";

@Injectable()
export class CtStateViewService {

    public StateObservable:BehaviorSubject<Backend.FullState> = new BehaviorSubject(null);
    public State:Backend.FullState = null;
    public Data:any;
    public Table:CtTable;
    constructor(public Translate:MnTextService){
        console.log("constructor CtStateViewService",Translate);
        //console.log('Brand.Instance',Brand.Instance);
    };

    public setState(state: Backend.FullState) {
        this.State = state;
        this.StateObservable.next(state);
    }


}
export namespace CtStateViewService {
    export interface Data {
        State: Backend.FullState;
    }
}
