/**
 * Created by joerg on 7/17/17.
 */

// rxjs
//import 'rxjs/Rx';
//import { Subject } from 'rxjs/Subject';

// rxjs
import 'rxjs/Rx';
import { Observable } from 'rxjs/Observable';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Subject } from 'rxjs/Subject';
import { Subscription } from 'rxjs/Subscription';

// angular
import {Component,Injector,Injectable, ViewChild, ViewChildren, QueryList, AfterViewInit} from '@angular/core';

//
import {MnWorkflowComponentStatic} from "../MnWorkflowComponent";
import {MnStateView2} from "./MnStateView2";
import {MnWorkflow} from "../MnWorkflow";
import {MnTableFocus} from "../CtTable/MnTableFocus";
import {MnStateViewFocusView} from "./MnStateViewFocusView";
import {MnBackend} from "@mn/core";
import {Aggregate, DataAggregator, GroupBy} from "../../../utils/data-aggregator";
import {CtTable} from "../CtTable";
import sortableTypes = CtTable.sortableTypes;
import {MnJsmeDepict} from "../ChemotypeProfiler/MnJsmeDepict";

import {ToxCastEndpoint} from "./CtCompoundToxcast";

// interface for JSON data coming from http://yeni:8001/chemtunes/dataset/record/3/chem/
export  namespace  ChemRecord {

    export interface Attribute2 {
        name: string;
        domain: string;
        context: string;
        is_public: boolean;
        visible: boolean;
        comments: string;
    }

    export interface Attribute {
        attribute: Attribute2;
        value: string;
        value_type?: any;
        source?: any;
        serial: number;
        visible: boolean;
        harvested_from: string;
    }

    export interface Component2 {
        attributes: Attribute[];
        data: any[];
        owner: string[];
        related_to: any[];
        connection_table: string;
        inchi_key: string;
        is_public: boolean;
    }

    export interface Component {
        component: Component2;
        role: string;
    }

    export interface Name {
        value: string;
        value_type: string;
        source: string;
        preferred: boolean;
        serial: number;
        visible: boolean;
        harvested_from: string;
    }

    export interface RegNumber {
        value: string;
        value_type: string;
        source: string;
        serial: number;
        visible: boolean;
        harvested_from: string;
    }

    export interface Identifier {
        value: string;
        value_type: string;
        source: string;
        serial: number;
        visible: boolean;
        harvested_from: string;
    }

    export interface Attribute4 {
        name: string;
        domain: string;
        context: string;
        is_public: boolean;
        visible: boolean;
        comments: string;
    }

    export interface Attribute3 {
        attribute: Attribute4;
        value: string;
        value_type?: any;
        source?: any;
        serial: number;
        visible: boolean;
        harvested_from: string;
    }

    export interface RootObject {
        system_id: string;
        related_to: any[][];
        components: Component[];
        names: Name[];
        reg_numbers: RegNumber[];
        identifiers: Identifier[];
        attributes: Attribute3[];
        properties: any[];
        data: any[];
        tags: string[];
        uuid: string;
        status: string;
        owner?: any;
        id_suffix: string;
        is_public: boolean;
        gross_formula: string;
        comments: string;
    }

}




@Injectable() export class MnCompoundDetailsDBService {


    public DetailsSubject:BehaviorSubject<MnCompoundDetailsDBService.Details> = new BehaviorSubject(null);
    public ErrorRaisedSubject:BehaviorSubject<string> = new BehaviorSubject(null); //BB
    public LoadingIsInProgressSubject: BehaviorSubject<boolean> = new BehaviorSubject(null); //BB

    private mDummy:Observable<any> = Observable.of(null);

    constructor(private mBackend:MnBackend) {}

    public change(value:any) {
        //console.log("ddddddddddddd",value);
        this.LoadingIsInProgressSubject.next(false);
        this.DetailsSubject.next(null);
        this.ErrorRaisedSubject.next(null);

        if(value.chem || value.reg || value.tox) { //BB I added this - it does not make sense to start a query if  no compound is selected
            this.LoadingIsInProgressSubject.next(true);


            Observable.forkJoin(
                [
                    value.chem ? this.mBackend.get(value.chem).map((res) => res.json()) : this.mDummy,
                    value.reg ? this.mBackend.get(value.reg).map((res) => res.json()) : this.mDummy,
                    value.tox ? this.mBackend.get(value.tox).map((res) => res.json()) : this.mDummy,
                ]
            ).subscribe(details => {
                    const assembledDetails: any = this.assemble({
                        chem: details[0],
                        reg: details[1],
                        tox: details[2]
                    });
                    this.LoadingIsInProgressSubject.next(false);
                    this.DetailsSubject.next(assembledDetails);

                },
                error => {
                    console.log(error);
                    this.LoadingIsInProgressSubject.next(false);
                    this.ErrorRaisedSubject.next(error.toString());
                }
            );

        }
    }



    private assemble(data:any) : any {
        ///console.log(data);

        let pafa:any = null;
        let reg_data:MnCompoundDetailsDBService.Data = null;
        if (data.reg) {
            let inventories:MnCompoundDetailsDBService.Item[] = [];
            this.findData(data.reg,this.findInventories, inventories);
            reg_data = {
                label: "Regulatory Information",
                data: null,
                children: [
                    {
                        label: "Inventories",
                        type: MnCompoundDetailsDBService.Data.Type.INVENTORIES,
                        data: inventories,
                        children: null
                    },
                ]
            };
            this.findGenericData(data.reg,'attributes',reg_data);
            this.findGenericData(data.reg,'data',reg_data);
            let pafa_index = -1;
            pafa = reg_data.children.find((v,index) => {
                console.log(v);
                if (v.type == MnCompoundDetailsDBService.Data.Type.PAFA_CHEMICAL_INFORMATION) {
                    pafa_index = index;
                    return true;
                }
                return false;
            });
            if (pafa) {
                reg_data.children.splice(pafa_index,1);
            }
        }

        let details:MnCompoundDetailsDBService.Details = {
            databases: this.findDBs(data),
            compound: this.findCompoundData(data),
            compoundSummary: this.findCompoundSummaryInformation(data),
            data: [
                /*{
                    label: "Related Compounds",
                    data: null,
                    children: [
                    ]
                },*/
            ],
        };

        if (data.chem) {
            let names = this.findNamesData(data);
            let identifiers = this.findIdentifiersData(data);
            let regnos = this.findRegnosData(data);
            const props: MnCompoundDetailsDBService.Data[] = this.findCompoundProperties(data);

            if (names.length > 0 || identifiers.length > 0 || regnos.length > 0 || props.length > 0) {
                let parent = {
                    label: "Chemical Information",
                    data: null,
                    children: []
                };
                details.data.push(parent);
                if (names.length > 0) {
                    parent.children.push({
                        label: "Names",
                        type: MnCompoundDetailsDBService.Data.Type.NAMES,
                        data: names,
                        children: []
                    })
                }
                if (regnos.length > 0) {
                    parent.children.push({
                        label: "Registry Numbers",
                        type: MnCompoundDetailsDBService.Data.Type.REGNOS,
                        data: regnos,
                        children: []
                    })
                }
                if (identifiers.length > 0) {
                    parent.children.push({
                        label: "Identifiers",
                        type: MnCompoundDetailsDBService.Data.Type.IDENTIFIERS,
                        data: identifiers,
                        children: []
                    })
                }

                if(props.length > 0) {
                    parent.children.push(...props);
                }

            }


        }

        if (reg_data) {
            details.data.push(reg_data);
        }
        if (data.tox) {
            details.data.push(...this.findSafety(data.tox,pafa));
            details.data.push(...this.findTox(data.tox));


        }

        console.log(details);

        return details;
    }

    private findDBs(data:any):string[] {
        let result = [];
        let mergeDBs = (obj) => {
            if (!obj) return;
            for (var key in obj) {
                if(!obj.hasOwnProperty(key)) continue;
                if (result.indexOf(key) < 0) result.push(key);
            }
        };
        mergeDBs(data.chem);
        mergeDBs(data.reg);
        mergeDBs(data.tox);
        return result;
    }

    private findCompoundData(data:any):MnCompoundDetailsDBService.Details.Compound  {
        let result:MnCompoundDetailsDBService.Details.Compound = {
            id: null,
            fields: [],
            components: [],
        };

        if (data.chem) {
            for (var key in data.chem) {
                if(!data.chem.hasOwnProperty(key)) continue;
                this.findCompoundDataInDatabase(result, key, data.chem[key]);
            }
        }

        return result;
    }

    /**
     * Collect the data to be displayed in the compound summary view
     * @param data
     * @returns {MnCompoundDetailsDBService.Details.CompoundSummaryInformation}
     */
    protected findCompoundSummaryInformation(data: any) : MnCompoundDetailsDBService.Details.CompoundSummaryInformation {

        let result: MnCompoundDetailsDBService.Details.CompoundSummaryInformation = <MnCompoundDetailsDBService.Details.CompoundSummaryInformation>{};
         if (data.chem) {
              for (let db in data.chem) {
                  if (!data.chem.hasOwnProperty(db)) continue;
                  const chemRecord: ChemRecord.RootObject = data.chem[db];

                  if(! chemRecord) continue;

                  result.systemId = chemRecord.system_id;
                  //result.isPublic = chemRecord.is_public;
                  result.status = chemRecord.status; // "Active"

                  //select one name
                  if(chemRecord.names && chemRecord.names[0]) {
                      result.name = chemRecord.names[0].value;
                  }

                  if(chemRecord.components && chemRecord.components[0] && chemRecord.components[0].component) {
                      const component :ChemRecord.Component2 = chemRecord.components[0].component;
                      result.molFile = component.connection_table;
                      result.inchiKey = component.inchi_key;
                      //result.isPublic = component.is_public; //duplication


                  }
                  result.attributes = [];

                  //make the display easier
                  result.attributes.push(...[
                      {name: "System ID", value: result.systemId },
                      {name: "Name", value: result.name},
                      //{name: "InChI Key ", value: result.inchiKey},
                  ]);
                  result.inchiKey && result.attributes.push({name: "InChI Key ", value: result.inchiKey});

                  result.attributes.push(...[
                      //{name: 'Public', value: result.isPublic}, // removed from backend by AT June 4th 2018
                      {name: 'Status', value: result.status},
                  ]);

                  if(chemRecord.attributes) {
                      for( let attr of chemRecord.attributes) {
                          if(attr.attribute) {
                              const name: string = attr.attribute.name;
                              const value: any = attr.value;
                                if( name && value)
                                result.attributes.push({name: name, value: value});
                          }

                      }
                  }

                  return result; //implemented only for one DB
              }
         }

        return result;
    }
    /**
     * collect data in chem/properties
     * @param data
     */
    protected findCompoundProperties(data: any) : MnCompoundDetailsDBService.Data[] {
        let results: MnCompoundDetailsDBService.Data[] = [];
        if (data.chem) {
            for (let db in data.chem) {
                if (!data.chem.hasOwnProperty(db)) continue;

                if(! data.chem[db].hasOwnProperty('properties')) continue;

                const chemRecord: ChemRecord.RootObject = data.chem[db];
                const properties: any[] = chemRecord.properties;
                if(!properties || properties.length == 0) continue;
                // looks like there is a lot of duplicated data, even among the values
                const groups: Aggregate<string,any>[] = GroupBy(properties, (d: any)=> d.attribute.name, (d:any)=>d,  true, false, (d1: any, d2 :any)=>{
                    try { //precaution just in case the data structure would be different than CMS-216
                        const same: boolean = d1.value.exact_value == d2.value.exact_value && d1.value_type.id == d2.value_type.id;
                        return same;
                    } catch(err) {

                    }
                    return false;
                });

                let result :  MnCompoundDetailsDBService.Data = {label: 'Properties', data: groups, type: MnCompoundDetailsDBService.Data.Type.CHEMICAL_PROPERTIES, children: []};;
                results.push(result);


            }
        }


        return results;

    }
    private findNamesData(data:any):any[]  {
        let results:any[] = [];
        if (data.chem) {
            for (var db in data.chem) {
                if(!data.chem.hasOwnProperty(db)) continue;
                let names = data.chem[db].names;
                if (names && names.length) {
                    results.push(...names);
                }
            }
        }
        return results;
    }

    private findIdentifiersData(data:any):any[]  {
        let results:any[] = [];
        if (data.chem) {
            for (var db in data.chem) {
                if(!data.chem.hasOwnProperty(db)) continue;
                let identifiers = data.chem[db].identifiers;
                if (identifiers && identifiers.length) {
                    results.push(...identifiers);
                }
            }
        }
        return results;
    }

    private findRegnosData(data:any):any[]  {
        let results:any[] = [];
        if (data.chem) {
            for (var db in data.chem) {
                if(!data.chem.hasOwnProperty(db)) continue;
                let reg_numbers = data.chem[db].reg_numbers;
                if (reg_numbers && reg_numbers.length) {
                    results.push(...reg_numbers);
                }
            }
        }
        return results;
    }

    private findCompoundDataInDatabase(comp:MnCompoundDetailsDBService.Details.Compound, db:string, data:any) {
        // system_id
        if (data.system_id) {
            comp.id = MnCompoundDetailsDBService.Item.createOrMerge(comp.id,'system_id', db, data.system_id);
        }
        // names
        if (data.names && data.names.length > 0) {
            let preferred_term = data.names.find((name) => name.value_type == "PREFERRED TERM");
            if (preferred_term) {
                MnCompoundDetailsDBService.Item.createOrMergeInList(comp.fields,'preferred_term', db, preferred_term.value, MnCompoundDetailsDBService.Item.Layout.Long)
            }
            let systematic_name = data.names.find((name) => name.value_type == "SYSTEMATIC NAME");
            if (systematic_name) {
                MnCompoundDetailsDBService.Item.createOrMergeInList(comp.fields,'systematic_name', db, systematic_name.value, MnCompoundDetailsDBService.Item.Layout.Long)
            }
        }
        // attributes
        if (data.attributes) {
            data.attributes.forEach((a) => {
                MnCompoundDetailsDBService.Item.createOrMergeInList(comp.fields,a.attribute.name, db, a.value)
            });
        }
        // components fixme this is just an ugly hack
        if (data.components && data.components.length > 0) {
            MnCompoundDetailsDBService.Item.createOrMergeInList(comp.fields,'inchi_key', db, data.components[0].inchi_key)
            data.components[0].component.attributes.forEach((a) => {
                MnCompoundDetailsDBService.Item.createOrMergeInList(comp.fields,a.attribute.name, db, a.value)
            });
        }
    }

    private findData(data:any, finder:Function, result:any) {
        for (let key in data) {
            if(!data.hasOwnProperty(key)) continue;
            finder(key, data[key], result);
        }
    }

    private findInventories(db:string, data:any, result:MnCompoundDetailsDBService.Item[]) {
        console.log(data);
        if (data.inventories && data.inventories.length > 0) {
            data.inventories.forEach((inventory) => {
                console.log(inventory);
                MnCompoundDetailsDBService.Item.createOrMergeInList(result,inventory,db,"")
            })
        }
    }

    private findGenericData(data:any, group_key:string, result:MnCompoundDetailsDBService.Data) {

        const useAggregator = true;

        let groups = {};

        let allGroups: Aggregate<string, MnCompoundDetailsDBService.GenericDatabaseValue>[] = [];
        // data aggregation by db needed?
        //should db be an argument?

        for (var db in data) {
            if (!data.hasOwnProperty(db)) continue;
            let obj = data[db];
            let list = obj[group_key];
            if (!list) continue;

            if (useAggregator) {
                //the advantage is that it keeps the original key order of the data, or one can sort keys
                const aggArray: Aggregate<string, MnCompoundDetailsDBService.GenericDatabaseValue>[] = GroupBy(list,
                    (d: any) => d.attribute.name,
                    (d: any) => new MnCompoundDetailsDBService.GenericDatabaseValue(db, d),
                    true
                );
                allGroups.push(...aggArray);
            } else {

                list.forEach((v) => {
                    let group_list = groups[v.attribute.name];
                    if (!group_list) {
                        group_list = [];
                        groups[v.attribute.name] = group_list;
                    }
                    group_list.push(new MnCompoundDetailsDBService.GenericDatabaseValue(db, v))
                });
            }
        }

        if(useAggregator) {
             result.children.push(...allGroups.map((each:Aggregate<string, MnCompoundDetailsDBService.GenericDatabaseValue>) => {
                 return {
                     label: each.key,
                     type: each.key,
                     data: each.values,
                     children: null
                 };
             }));

        } else {
            for (var group_name in groups) { // the order of the keys might be lost
                if (!groups.hasOwnProperty(group_name)) continue;
                result.children.push({
                    label: group_name,
                    type: group_name,
                    data: groups[group_name],
                    children: null,
                })
            }
        }

    }

    private findSafety(data:any,pafa:any) {
        let result = [];
        let safety_evals:any[] = [];
        let unused_pods:any[] = [];
        for (let db in data) {
            if(!data.hasOwnProperty(db)) continue;
            let dbdata = data[db];
            if (dbdata.safety_evaluations) {
                safety_evals.push(...dbdata.safety_evaluations)
            }
            if (dbdata.unused_pods) {
                unused_pods.push(...dbdata.unused_pods);
            }
        }
        if (pafa || safety_evals.length > 0 || unused_pods.length > 0) {
            let se = {
                label: 'Safety Evaluation',
                data: null,
                children: [],
            };
            if (safety_evals.length > 0) {
                se.children.push({
                    label: 'ChemTunes',
                    type: MnCompoundDetailsDBService.Data.Type.SAFETY_EVAL,
                    data: safety_evals,
                    children: [],
                });
            }
            if (unused_pods.length > 0) {
                se.children.push({
                    label: 'Additional PODs',
                    type: MnCompoundDetailsDBService.Data.Type.UNUSED_PODS,
                    data: unused_pods,
                    children: [],
                });
            }
            if (pafa) {
                se.children.push(pafa);
            }
            result.push(se);
        }
        return result;
    }

    private findTox(data:any) {
        let result = [];
        let studies:MnCompoundDetailsDBService.Data[] = [];
        for (let db in data) {
            if(!data.hasOwnProperty(db)) continue;
            let dbdata = data[db];
            if (dbdata.studies) {
                dbdata.studies.forEach((study) => {
                   let h = study.type.hierarchy;
                   let cs = studies;
                   let s:MnCompoundDetailsDBService.Data = null;
                   for (let i = 0, l = h.length; i < l; i++) {
                        let n:string = h[i];
                        s = cs.find((ss) => ss.label == n);
                        if (s) {
                            cs = s.children;
                        } else {
                            let children = [];
                            s = {
                                label: n,
                                data: null,
                                children: children,
                            };
                            cs.push(s);
                            cs = children;
                        }
                   }
                   if (s) {
                       s.children.push({
                           label: study.display_label,
                           type: MnCompoundDetailsDBService.Data.Type.TOX_STUDY,
                           data: study,
                           children: [],
                       })
                   }
                });
            }
        }
        const children: MnCompoundDetailsDBService.Data[] = [];
        children.push(...studies);
        children.push(...this.findAdditionalData(data));
        children.push(...this.findToxCastData(data));

        if (children.length > 0) {
            result.push({
                label: 'Toxicity Studies',
                data: null,
                children: children,
            });
        }


        return result;
    }

    // written by BB
    protected findAdditionalData(data:any): MnCompoundDetailsDBService.Data[] {
        let results: MnCompoundDetailsDBService.Data[] = [];
        let additionalDataResult: MnCompoundDetailsDBService.Data =  {label: 'Additional Data', data: null, type: null, children: []};

        for (let db in data) {
            if (!data.hasOwnProperty(db)) continue;
            let extraData: any[] = data[db]['extra_data'];
            if(!extraData) {
                continue;
            }
            for(let ed of extraData) {
                const additionalData: any[] = ed['additional_data'];
                if(!additionalData || additionalData.length == 0) continue;

                // group by tab names, e.g. Pharmacokinetics, Skin adsorption
                const groups: Aggregate<string, any>[] = GroupBy(additionalData, (d:any)=>d.tab_name, (d:any)=>d.data, true);
                if(results.length == 0 && groups.length > 0) {
                    results.push(additionalDataResult);
                }
                for( let eachGroup of groups) {
                    const tableNode:  MnCompoundDetailsDBService.Data = {'label': eachGroup.key, type: MnCompoundDetailsDBService.Data.Type.ADDITIONAL_DATA_TABLE, children:[], data: eachGroup.values};

                    additionalDataResult.children.push(tableNode);

                }

            }
        }


        return results;


    }

    //BB : preliminary handling of TOXcast data
    protected findToxCastData(data: any) : MnCompoundDetailsDBService.Data[] {

        //TODO: duplicated code with findAdditionalData
        let results: MnCompoundDetailsDBService.Data[] = [];
        let toxcastDataResult: MnCompoundDetailsDBService.Data =  {label: 'TOXCAST Data', data: null, type: null, children: []};

        for (let db in data) {
            if (!data.hasOwnProperty(db)) continue;

            let extraData: any[] = data[db]['extra_data'];
            if(!extraData) {
                continue;
            }
            for(let ed of extraData) {
                const toxcastData: ToxCastEndpoint.RootObject[] = ed['toxcast_data'];
                if(!toxcastData || toxcastData.length == 0) continue;

                if(results.length == 0 && toxcastData.length > 0) {
                    results.push(toxcastDataResult);
                }
                /*
                for(let eachEndpoint of toxcastData) {
                    const tableNode:  MnCompoundDetailsDBService.Data = {'label': eachEndpoint.assay_component_endpoint_name,
                        type: MnCompoundDetailsDBService.Data.Type.TOXCAT_ENDPOINT, children:[], data: eachEndpoint};
                    toxcastDataResult.children.push(tableNode);

                }
                */
                const tableNode:  MnCompoundDetailsDBService.Data = {'label': 'Bioassays',
                        type: MnCompoundDetailsDBService.Data.Type.TOXCAT_ENDPOINTS, children:[], data: toxcastData};
                toxcastDataResult.children.push(tableNode);

            }

        }



        return results;

    }

}
export namespace MnCompoundDetailsDBService {

    export class Item {
        static createOrMerge(existing:Item,label:string, db:string, value:string, layout:Item.Layout = Item.Layout.Short, type:Item.Type = Item.Type.Any) {
            let new_item:Item = new Item(label,db,value,type,layout);
            if (existing) {
                existing.reduce(new_item);
                return existing;
            }
            return new_item;
        }

        static createOrMergeInList(items:Item[],label:string, db:string, value:string, layout:Item.Layout = Item.Layout.Short, type:Item.Type = Item.Type.Any) {
            let new_item:Item = new Item(label,db,value,type,layout);
            let old_item = items.find((it)=> it.label == label);
            if (old_item) {
                old_item.reduce(new_item);
            } else {
                items.push(new_item);
            }
        }

        constructor(public label:string, db:string, value:string, public type:Item.Type = Item.Type.Any, public layout:Item.Layout = Item.Layout.Short) {
            this.values = [{
                value: value,
                dbs: [db],
            }];
        }
        values: Item.DatabaseValue[];
        public reduce(item:Item) {
            let existing = this.values.find((dbv) => item.values[0].value == dbv.value);
            if (existing) {
                existing.dbs.push(item.values[0].dbs[0]);
            } else {
                this.values.push({
                    value: item.values[0].value,
                    dbs: [item.values[0].dbs[0]],
                })
            }
        }
    }
    export namespace Item {
        export enum Layout {
            Short = "short",
            Long = "long",
        }
        export enum Type {
            Any = "any"
        }
        export interface DatabaseValue {
            value: string;
            dbs: string[];
        }
    }
    export class GenericDatabaseValue {
        constructor(public db:string, public value:any) {}
    }

    //this is not a good variable name

    /**
     * Tree node:
     *  if it is not a leaf, then it will be shown in the hierarchy view with all data types (label is used)
     *  otherwise, the data will be provide to the detail view
     *
     */

    export interface Data {
        label: string;
        type?: Data.Type | string;
        data: any;
        children: Data[]
    }

// Definition to be used in the template for static checking
// looks like this is need for PAFA Chemical Information"
// might be convention defined in the incoming JSON data object

    export namespace Data {
        export enum Type {

            NAMES = "names",
            REGNOS = "regnos",
            INVENTORIES = 'Inventories',
            IDENTIFIERS = 'identifiers',
            PAFA_CHEMICAL_INFORMATION = "PAFA Chemical Information",
            COMPOUND_PROFILE = "Compound Profile", // to check
            SAFETY_EVAL = 'safety_eval',
            UNUSED_PODS = 'unused_pods',
            COMPOUND_CLASSIFICATION = 'Compound Classification', // not referenced in this file -type definition form data JSON
            TOX_STUDY='study',
            USE_FUNCTION = "Use Function",// not referenced in this file - dynamic type definition from the data JSON object
            USE_TYPE = "Use Type", // not referenced in this file - dynamic type definition from the data JSON object

            ADDITIONAL_DATA_TABLE = 'additional_data_table',

            CHEMICAL_PROPERTIES = 'chemical_properties',
            TOXCAT_ENDPOINT = 'toxcast_endpoint',
            TOXCAT_ENDPOINTS = 'toxcast_endpoints',

        // add new types here

    }
    }

    export interface Details {
        databases: string[];
        compound: Details.Compound;
        compoundSummary: Details.CompoundSummaryInformation;
        data: Data[];
    }
    export namespace Details {
        export interface Compound {
            id: Item;
            fields: Item[];
            components: Item[];
        }
        // used to display the structure and some summary information
        export interface CompoundSummaryInformation {
            molFile: string;
            structureQuality: string;
            harvestedFrom: string;
            inchiKey: string;
            systemId: string,
            name: string;
            isPublic: boolean;
            status: string;
            attributes:{name: string, value: any}[];


        }
    }
}

let empty_details = {
    databases: [],
    compound: null,
    compoundSummary: null,
    data: [],
};

@Component({
    selector: 'ct-compound-details-db',
    templateUrl: './CtCompoundDetailsDB.html',
    styles: [`
        .ctd-box {
            font-family: Roboto, "Helvetica Neue", Helvetica, Arial, sans-serif;
            display: flex;
            flex-flow: column;
            height: 100%;
            /*padding-left: 16px;*/
        }

        .ctd-box > .ctd-fixed {
            display: block;
            flex: 0 1 auto;
            /*background: lightcyan;*/
            /*padding: 0 16px;*/
        }

        .ctd-box > .ctd-scroll {
            width: 100%;
            height: 100%;
            flex: 1 1 auto;
            /*background: lightgoldenrodyellow;*/
        }

        .ctd-header {
            color: darkblue;
            font-weight: bold;
            font-size: 16px;
            line-height: 28px;
            padding: 0 8px;
            background: #eaeaea;
            border-bottom: 1px solid darkgrey;
        }

        .ctd-tree {
            font-size: 14px;
            font-weight: bold;
            color: #606060;
        }

        .ctd-tree li.ctd-item {
            cursor: pointer;
            font-weight: normal;
            color: #404040;
            font-size: 12px;
            margin-left: -19px;
            padding-left: 19px;
        }
        .ctd-tree li.ctd-item:hover {
            outline: 0;
            -webkit-box-shadow: 0 0 2px 1px rgba(97, 145, 255, .93);
            -moz-box-shadow: 0 0 2px 1px rgba(97, 145, 255, .93);
            box-shadow: 0 0 2px 1px rgba(97, 145, 255, .93);
        }

 /*
 
     .x-selected > div {
      //background: red;
      background: rgba(25, 118, 210, 0.27);
      font-weight: bold;
      outline: 0;
      -webkit-box-shadow: 0 0 2px 1px rgba(97, 145, 255, .93);
      -moz-box-shadow: 0 0 2px 1px rgba(97, 145, 255, .93);
      box-shadow: 0 0 2px 1px rgba(97, 145, 255, .93);
    }
    .y-selected > div {
      outline: 0;
      -webkit-box-shadow: 0 0 2px 1px rgba(97, 145, 255, .93);
      -moz-box-shadow: 0 0 2px 1px rgba(97, 145, 255, .93);
      box-shadow: 0 0 2px 1px rgba(97, 145, 255, .93);
    }

 
 */       
        .ctd-tree ul {
            padding: 0;
            padding-left: 16px;
            list-style: none;
        }

        .ctd-tree li {
            cursor: default;
            padding-left: 0;
            white-space: nowrap;
        }

        .ctd-tree li:before {
            content: "\\f0da";
            font-family: FontAwesome;
            display: inline-block;
            margin-left: -12px;
            width: 8px;
        }

        .ctd-tree li.ctd-item:before {
            content: "";
            font-family: FontAwesome;
            display: inline-block;
            margin-left: -19px;
            width: 0;
        }
    `],

})
export class CtCompoundDetailsDB implements  AfterViewInit{

    private mDestroy:Subject<void> = new Subject();
    private mDetails:any = empty_details;
    private mData:MnCompoundDetailsDBService.Data = null;
    private mCompoundInfo:boolean = false; //looks like this shows if a compound is selected
    private mCompoundInfoHasData:boolean = false; //looks like this shows if a compound is selected

    //@ViewChild('jsme') mJsme: MnJsmeDepict; // does not work because the editor is embedded inside a *ngIf
    @ViewChildren("jsme")
    protected tmpJsmeList: QueryList<MnJsmeDepict>;
    protected  mJsme: MnJsmeDepict;
    protected mMol: string;


    protected dataIsLoading = false;
    protected error = null;

    protected DT = MnCompoundDetailsDBService.Data.Type; //to be used in the template

    protected compoundSummaryInformation: MnCompoundDetailsDBService.Details.CompoundSummaryInformation;
    protected compoundSummaryInformationList: {name: string, value: any}[] = [];

    constructor(service:MnCompoundDetailsDBService) {
        service.DetailsSubject.takeUntil(this.mDestroy).subscribe((details)=>this.onDetails(details));
        service.ErrorRaisedSubject.takeUntil(this.mDestroy).subscribe((error:string)=>this.onError(error));
        service.LoadingIsInProgressSubject.takeUntil(this.mDestroy).subscribe((loadingStatus:boolean)=>this.onDataLoading(loadingStatus));
    }

    public ngAfterViewInit(): void
    {
        // the child #jsme is not always present because it is embedded inside an *ngIf
        this.tmpJsmeList.changes.subscribe((comps: QueryList <MnJsmeDepict>) =>
        {
            this.mJsme = comps.first; //could be null
            if(this.mJsme && this.mMol) {
                this.mJsme.setMol(this.mMol);
            }
        });
    }

    // data is coming from the backend here
    private onDetails(details:MnCompoundDetailsDBService.Details) {
        // BB: when the tab is first selected, a null details is sent here but not from the service?
        // BB: if an error has occured then a null details is sent here
        if (details == null) {
            //details = empty_details;
            this.dataIsLoading = false;

        } else {
            this.error = null;
        }

        // AT bug fix: Whatever section in details' tree is selected, it displays, say, names for the active compound.
        // Now, switching to another compound does not clean up the shown names until one explicitly clicks something in
        // the details tree again
        this.mData = null; // force redraw of the detail view otherwise old content might still be shown



       this.mCompoundInfoHasData = !(details === null || details === empty_details);
        //console.log(details);
        this.mDetails = details;

        if(this.mCompoundInfoHasData) {
            this.updateCompoundInformationView(this.mDetails.compoundSummary);
        }
    }

    protected getCompoundSystemID(): string {
        if(this.mCompoundInfoHasData && this.mDetails && this.mDetails.compoundSummary) {
            return this.mDetails.compoundSummary.systemId
        }

        return null;
    }
    protected onError(error: string) { // error is not a string
        // BB: when the tab is first selected, a null error is sent here
        if(error) {
            //alert("Loading data error " + error);
            if(error.search('404')) { // 404 not found error - happens for a non DB compound that was searched
                error = "No details available for the selected compound"
            }
        }

        this.error = error;


    }
    //BB
    protected onDataLoading(loadingStatus: boolean) {
        this.dataIsLoading = loadingStatus;
    }

    // event triggered when one clicks on an item on the master left part
    private onData(data:MnCompoundDetailsDBService.Data) {
        this.mData = data;
    }

    private onToggleCompound() {
        this.mCompoundInfo = !this.mCompoundInfo;
        if(!this.mCompoundInfoHasData) {
            this.mCompoundInfo = false;
        }
    }

    //BB
    protected updateCompoundInformationView(compoundData: MnCompoundDetailsDBService.Details.CompoundSummaryInformation) : void {


        if( compoundData) {
            // show the structure in JSME
            if (compoundData.molFile) {
                this.mMol = compoundData.molFile;
                // the template should update it self?
            } else {
                this.mMol = null;
            }
            this.compoundSummaryInformationList = compoundData.attributes;

        } else {
              this.compoundSummaryInformationList = [];

        }


        this.compoundSummaryInformation = compoundData;

    }

    ngOnDestroy() {
        this.mDestroy.next();
    }
}


