import {MnWorkflow} from "../MnWorkflow";
import {Backend} from "../BackendInterfaces";

export class MnActionAdapterRemoveRecords extends MnWorkflow.ActionAdapter{

    protected transform(workflow:Backend.Workflow, state:Backend.FullState, actions:MnWorkflow.Action[]):MnWorkflow.Action[] {
        if (state && state.dataset && state.dataset.table && state.dataset.table.data && state.dataset.table.data.length > 0) {

            let keep_or_erase = {
                keep: null,
                erase: null,
            };

            actions = actions.filter((action)=>{
                if (action.title == "DELETE_SELECTED_RECORDS") {
                    keep_or_erase.erase = action;
                    return false;
                } else if (action.title == "KEEP_ONLY_SELECTED_RECORDS") {
                    keep_or_erase.keep = action;
                    return false;
                }
                return true;
            });

            if (keep_or_erase.keep || keep_or_erase.erase) {
                actions.push({
                    id: "frontend",
                    config_template: {},
                    settings: "",
                    title: "REMOVE_RECORDS",
                    category: "DATASET",
                    comments: "",
                    task_class: "",
                    needs_data: false,
                    can_continue: false,
                    interactive: false,
                    needs_config: false,
                    data: keep_or_erase,
                });
            }
        }
        return actions;
    }
}
MnWorkflow.ActionAdapter.Registry.register('REMOVE_RECORDS',new MnActionAdapterRemoveRecords());
