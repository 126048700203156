/**
 * Created by joerg on 7/17/17.
 */

/**
 * This action is almost identical as the one for calculating molecular properties
 */
// angular
import {Component} from '@angular/core';

//
import {MnWorkflowComponentStatic} from "../../MnWorkflowComponent";
import {MnActionConfigurator} from "../MnActionConfigurator";
import {MnActionConfigurationService} from "../MnActionConfigurationService";

export namespace AddChemotypeProfiles {
    export interface Configuration {
        properties: string[]
    }
}
@Component({
    selector: 'mn-action-configurator-add-chemotype-profiles',
    template: `
        <mn-action-configurator-frame>

            <mn-action-configurator-row-marks
                    line1
            ></mn-action-configurator-row-marks>
            <mn-action-configurator-list-controller 
                    line2
                    [label]="mLabel"
                    (onSelectAll)="props.selectAll()"
                    (onDeselectAll)="props.deselectAll()"
            ></mn-action-configurator-list-controller>
            <mn-action-configurator-property-list
                    scroll
                    #props
                    (selectionChange)="onPropSelection($event)"
                    kind = "chemotype_profiles"
            ></mn-action-configurator-property-list>
            
        </mn-action-configurator-frame>
     `,
    styles: []
})
export class AddChemotypeProfiles extends MnActionConfigurator {

    private mLabel:string = "";

    private mConfiguration:AddChemotypeProfiles.Configuration = {
        properties: []
    };

    constructor(acs:MnActionConfigurationService) {
        super(acs);
        this.enable(false);
    }

    protected onInit(configuration:any) {
        this.mConfiguration.properties = configuration.properties ? configuration.properties : [];
        this.mLabel = 'Selected Profiles: '+this.mConfiguration.properties.length;
    };

    protected onStart() {
        this.finish(this.mConfiguration);
    };

    onPropSelection(v) {
        //console.log(v);
        this.enable(v.length > 0);
        this.mConfiguration.properties = v;
        this.mLabel = 'Selected Profiles: '+this.mConfiguration.properties.length;
    }
    // never called
    private onSelectedRows(v) {
        //this.mConfiguration.rows = v;
        //this.checkEnabled();
          console.log(v);

    }
    ngOnDestroy() {
        super.destroy()
    }
}
MnActionConfigurator.Registry.register("ADD_CHEMOTYPE_PROFILE",new MnWorkflowComponentStatic(AddChemotypeProfiles));
// strings must match ADD_CHEMOTYPE_PROFILES with backend