/**
 * Created by joerg on 7/17/17.
 */

// rxjs
import 'rxjs/Rx';
import { Observable } from 'rxjs/Observable';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Subject } from 'rxjs/Subject';
import { Subscription } from 'rxjs/Subscription';

import * as BlobStream from 'blob-stream';
//console.log('BlobStream',BlobStream);

// angular
import {Component,Output,EventEmitter} from '@angular/core';

//
import {MnWorkflow} from './MnWorkflow';
import {Backend} from "./BackendInterfaces";
import {CtSelectionService} from "./CtSelectionService"
import {MnWorkflowService} from "./MnWorkflowService";
import {MnWorkflowObserver} from "./MnWorkflowObserver";
import {CtTable} from "./CtTable/CtTable";
import {MnWorkflowFrame} from "./MnWorkflowFrame"
import {MnTableSelection} from "./CtTable/MnTableSelection";
import {MnTableRowMarker} from "./CtTable/MnTableMarkers";
import {MnTableFocus} from "./CtTable/MnTableFocus";
import {MnRowMarker} from "./MnMarker";

@Component({
    selector: 'mn-workflow-view',
    templateUrl: './MnWorkflowView.html',
    providers: [
        MnWorkflowService,
        CtSelectionService,
        MnTableSelection,
        MnTableFocus,
        {
            provide: MnRowMarker,
            useClass: MnTableRowMarker
        }
    ],
})
export class MnWorkflowView extends MnWorkflowObserver {

    private mActionConfiguration = null;
    private mFrameState:MnWorkflowFrame.State = MnWorkflowFrame.State.A0;
    private mPendingWorkflow:boolean = false;
    private mPendingState:boolean = false;
    private mTransitionState:boolean = false;

    /*@Input() set idents(value) { this.mWorkflowService.goTo(value); }
    get idents():MnWorkflow.Idents { return this.mWorkflowService.Idents; }*/

    @Output() identifiersChange: EventEmitter<MnWorkflow.Identifiers> = new EventEmitter();

    constructor(private mWorkflowService:MnWorkflowService, private mSelectionService:CtSelectionService, private mRowMarker:MnRowMarker) {
        super(mWorkflowService);
        this.mSelectionService.init(new CtTable({spec:[],data:[]}, {}));
        console.log("mRowMarker",mRowMarker);
    }

    public setIdentifiers(identifiers:MnWorkflow.Identifiers) {
        this.mWorkflowService.goToExternal(identifiers);
    }

    public onIdentifiers(identifiers:MnWorkflow.Identifiers) {
        console.log("onIdentifiers",identifiers);
        /*this.mConfigure = null;
        if (identifiers) {
            if (this.Workflow.Workflow && identifiers.tid) {
                let t:Backend.Transition = this.Workflow.Workflow.transitions[identifiers.tid];
                if (t) {
                    this.mConfigure = this.Workflow.action(t.title);
                }
            } else if (identifiers.aid) {
                this.mConfigure = this.Workflow.action(identifiers.aid);
            }
        }
        this.updateFrameState();*/
        this.mActionConfiguration = null;
    }

    public onIdentifiersExteranl(identifiers:MnWorkflow.Identifiers) {
        console.log("onIdentifiersExteranl",identifiers);
        this.identifiersChange.emit(identifiers);
    }

    protected onWorkflowStatus(ws:MnWorkflow.WorkflowStatus) {
        console.log(ws);
        this.mPendingWorkflow = (ws == MnWorkflow.WorkflowStatus.Pending);
    }

    protected onStateStatus(ss:MnWorkflow.StateStatus) {
        console.log(ss);
        this.mPendingState = (ss == MnWorkflow.StateStatus.Pending);
        this.mTransitionState = (ss == MnWorkflow.StateStatus.Transition);
    }

    protected onTree(tree:MnWorkflow.Tree) {
        console.log('onTree',tree);
    }

    protected onTable(table:CtTable) {
        console.log('onTable',table);
        if (this.mSelectionService) this.mSelectionService.init(table);
        if (this.mRowMarker) {
            (<MnTableRowMarker>this.mRowMarker).init(table);
            (<MnTableRowMarker>this.mRowMarker).setup(9999999,[],[]);
        }
    }

    protected onActions(actions:Backend.Action[]) {
        let identifiers:MnWorkflow.Identifiers = this.Workflow.Identifiers;
        if (identifiers) {
            if (this.Workflow.Workflow && identifiers.tid) {
                let t:Backend.Transition = this.Workflow.Workflow.transitions[identifiers.tid];
                if (t) {
                    // fixme - this needs to done differently
                    if (t.title == 'DELETE_SELECTED_RECORDS' || t.title == 'KEEP_ONLY_SELECTED_RECORDS') {
                        t.title = 'REMOVE_RECORDS';
                    }
                    this.mActionConfiguration = new MnWorkflow.ActionConfiguration(this.Workflow.action(t.title),{});
                }
            } else if (identifiers.aid) {
                this.mActionConfiguration = new MnWorkflow.ActionConfiguration(this.Workflow.action(identifiers.aid),{});
            } else {
                this.mActionConfiguration = null;
            }
        }
        this.updateFrameState();
    }

    // user navigation events

    private onStateSelect(state) {
        console.log("onStateSelect",state);
        this.mActionConfiguration = null;
        this.updateFrameState();
        this.mWorkflowService.goToInternal({
            wid: this.Workflow.wid,
            sid: state.id,
            tid: undefined,
            aid: undefined,
        });
    }

    private onTransitionSelect(transition) {
        console.log("onTransitionSelect",transition);
        this.mActionConfiguration = null;
        this.updateFrameState();
        this.mWorkflowService.goToInternal({
            wid: this.Workflow.wid,
            sid: transition.source,
            tid: transition.id,
            aid: undefined,
        });
      }

    onActionApply(action_configuration:MnWorkflow.ActionConfiguration) {
        console.log(action_configuration);
        /*if (action_configuration.Action.id == "frontend") {
            console.log("Running frontend only action");
        } else {
        }*/

        if (action_configuration.Action.id == "create_summary_table") {
            this.Workflow.addWorkflowSetting("create_summary_table"+action_configuration.Configuration.title,action_configuration.Configuration,()=>{
                // fixme this.Workflow.reload();
            });
        } else {
            //this.mConfigure = null;
            this.mActionConfiguration = null;
            this.updateFrameState();
            this.mWorkflowService.goToStartTransition(
                {
                    wid: this.Workflow.wid,
                    sid: undefined,
                    tid: undefined,
                    aid: undefined
                },
                action_configuration
            );
        }

    }

    // other user events

    private onActionClose() {
        if (this.mActionConfiguration) {
            //this.mConfigure = null;
            this.mActionConfiguration = null;
            this.updateFrameState();
        } else {
            this.updateFrameState(true);
        }
    }

    private onActionShow() {
        this.mFrameState = MnWorkflowFrame.State.A0;
    }

    private onActionConfigure(action_configuration:MnWorkflow.ActionConfiguration) {
        this.mActionConfiguration = action_configuration;
        this.updateFrameState();
    }

    private updateFrameState(hide_actions?:boolean) {
        let wf:Backend.Workflow = this.Workflow.Workflow;
        let ids:MnWorkflow.Identifiers = this.Workflow.Identifiers;
        if (this.mActionConfiguration) {
            if (wf) {
                if (wf.root == ids.sid) {
                    let state_count:number = Object.keys(wf.states).length;
                    console.log("state_count",state_count);
                    if (state_count == 1) {
                        this.mFrameState = MnWorkflowFrame.State.C0;
                    } else {
                        this.mFrameState = MnWorkflowFrame.State.C2;
                    }
                } else {
                    this.mFrameState = MnWorkflowFrame.State.C1;
                }
            } else {
                this.mFrameState = MnWorkflowFrame.State.A0;
            }
        } else {
            if (hide_actions) {
                this.mFrameState = MnWorkflowFrame.State.A1;
            } else {
                this.mFrameState = MnWorkflowFrame.State.A0;
            }
        }

    }

    protected onTransitionStatus(ts:MnWorkflow.TransitionStatus) {
        console.log(ts);
    }

    ngOnDestroy() {
        this.destory();
        this.mWorkflowService.destroy();
    }
}
