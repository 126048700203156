/**
 * Created by joerg on 7/17/17.
 */

// angular
import {Component} from '@angular/core';

//
import {MnWorkflowComponentStatic} from "../../MnWorkflowComponent";
import {MnActionConfigurator} from "../MnActionConfigurator";
import {MnActionConfigurationService} from "../MnActionConfigurationService";

export namespace AddComputedProperty {
    export interface Configuration {
        properties: string[]
    }
}
@Component({
    selector: 'mn-action-configurator-add-computed-property',
    template: `
        <mn-action-configurator-frame>

            <mn-action-configurator-row-marks
                    line1
            ></mn-action-configurator-row-marks>
            <mn-action-configurator-list-controller 
                    line2
                    [label]="mLabel"
                    (onSelectAll)="props.selectAll()"
                    (onDeselectAll)="props.deselectAll()"
            ></mn-action-configurator-list-controller>
            <mn-action-configurator-property-list
                    scroll
                    #props
                    (selectionChange)="onPropSelection($event)"
            ></mn-action-configurator-property-list>
            
        </mn-action-configurator-frame>
     `,
    styles: []
})
export class AddComputedProperty extends MnActionConfigurator {

    private mLabel:string = "";

    private mConfiguration:AddComputedProperty.Configuration = {
        properties: []
    };

    constructor(acs:MnActionConfigurationService) {
        super(acs);
        this.enable(false);
    }

    protected onInit(configuration:any) {
        this.mConfiguration.properties = configuration.properties ? configuration.properties : [];
        this.mLabel = 'Selected Properties: '+this.mConfiguration.properties.length;
    };

    protected onStart() {
        this.finish(this.mConfiguration);
    };

    onPropSelection(v) {
        //console.log(v);
        this.enable(v.length > 0);
        this.mConfiguration.properties = v;
        this.mLabel = 'Selected Properties: '+this.mConfiguration.properties.length;
    }
    // never called
    private onSelectedRows(v) {
        //this.mConfiguration.rows = v;
        //this.checkEnabled();
          console.log(v);

    }
    ngOnDestroy() {
        super.destroy()
    }
}

// ADD_COMPUTED_PROPERTY must be the same key as the one used in the backend
MnActionConfigurator.Registry.register("ADD_COMPUTED_PROPERTY",new MnWorkflowComponentStatic(AddComputedProperty));
