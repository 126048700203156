/**
 * Created by joerg on 7/17/17.
 */

// angular
import {Component,Input,Output,EventEmitter,ViewChild} from '@angular/core';

//
import {MnWorkflow} from './MnWorkflow';
import {Backend} from "./BackendInterfaces";
import {BreadcrumpD3Component,NavigationTreeD3Component,NavigationTreeEvent} from './d3/index'
import {MnBrandService, MnTextService} from "@mn/core";

@Component({
    selector: 'ct-workflow-nav',
    templateUrl: './CtWorkflowNav.html',
    styleUrls: ['./CtWorkflowNav.css']
})
export class CtWorkflowNav {

    private mExpanded:boolean = false;

    @Input()
    get tree():MnWorkflow.Tree { return this.mTree; }
    set tree(value:MnWorkflow.Tree) {
        this.mTree = value;
        this.updateComponent(this.mLinearComponent);
        this.updateComponent(this.mTreeComponent);
    }
    private mTree:MnWorkflow.Tree;

    @Output() stateSelect: EventEmitter<Backend.State> = new EventEmitter();
    @Output() transitionSelect: EventEmitter<Backend.Transition> = new EventEmitter();

    @ViewChild(NavigationTreeD3Component)
    set treeComp(v) {
        this.mTreeComponent = v;
        this.initComponent(v);
    }
    mTreeComponent: NavigationTreeD3Component;

    @ViewChild(BreadcrumpD3Component)
    set linearComp(v) {
        this.mLinearComponent = v;
        this.initComponent(v);
    }
    mLinearComponent: BreadcrumpD3Component;

    constructor(private mBrand:MnBrandService, private mText:MnTextService) {}

    private initComponent(comp:NavigationTreeD3Component) {
        if (comp == undefined) {
            return;
        }

        comp.getNodeText = (d: MnWorkflow.Node) => {
            if (d === this.mTree.root && d.state.size == 0) {
                return "";
            } else {
                return '' + d.state.size
            }

        };
        //comp.getEdgeText = (d: MnWorkflow.Node) => this.lowerCaseAllWordsExceptFirstLetters('' + d.state.title.replace(/_/g, ' '));
        comp.getEdgeText = (d: MnWorkflow.Node) => {
            let dd:string = <string>this.mText.text('ct.action.title.'+d.transition.title); // translation stuff
           // return dd;
            // the dd text already contains the <BR>
            return dd.replace('<br>','\n').replace('&amp;','&') // handle new lines
        };

        // used for tooltip on the states
        comp.getNodeTitle = (d: MnWorkflow.Node) => {
            let title:string = '';

            // "2019-03-08T12:07:10.354702+01:00" this is ISO Date-time format
            const changedDate: Date = new Date(Date.parse(d.state.changed));
            const now: Date = new Date();
            const oneDay: number = <any>new Date(2018, 1, 2) - <any>new Date(2018, 1, 2);
           // const oneMonth =  new Date(2018, 1, 2) - new Date(2018, 2, 1);

            // why does this show the US time? May be because I set my browser to US locale
            const time: string =  changedDate.toLocaleTimeString();
            title = time;
            // if less than one day: show only time
            if (now.getTime() - changedDate.getTime() > oneDay) {
                title = time + " " + changedDate.toLocaleDateString();
            }


            return  title;
        }

        // customization of the node size: root must be smaller

        comp.getCustomNodeWidth = (d: MnWorkflow.Node, defaultNodeWidth: number): number => {
            if (d === this.mTree.root && d.state.size == 0) {
                return defaultNodeWidth * 0.75;
            } else {
                return defaultNodeWidth;
            }
        };

        // tooltip for the edges of the tree
        if (!(comp instanceof BreadcrumpD3Component)) {
            comp.getEdgeTitle = (d: MnWorkflow.Node) => {
                let dd:string = <string>this.mText.text('ct.action.title.'+d.transition.title); // translation stuff
                // the dd text already contains the <BR>
                // need an option for not HTML formating text - doc is needed for mText
                dd = dd.replace('<br>',' ').replace('&amp;','&');
                const message = d.transition.task.message;
                dd = `${dd}. ${message}` ;

                return dd;
            };

        }
        //comp.getNodeTitle = (d: MnWorkflow.Node) => '' + d.state.title;


        this.updateComponent(comp);
    }

    lowerCaseAllWordsExceptFirstLetters(string) {
        return string.replace(/\w\S*/g, function (word) {
            return word.charAt(0) + word.slice(1).toLowerCase();
        });
    }

    private updateComponent(comp:any) {
        if (this.mTree && comp) {
            comp.setDataAndUpdatePlot(this.mTree.root);
        }
    }

    /**
     * method set in the html parameter
     * @param {NavigationTreeEvent} event
     */
    public handleEventFromNavigator(event: NavigationTreeEvent): void {
        console.log('Event from sub component: ' + event.typeName + ' is edge: ' + event.isEdge +
            ' x:' + event.x + ' y:' + event.y +
            ' pageX: ' + event.d3Event.pageX, ' page: ' + event.d3Event.pageX);

        // for an edge we have event.sourceData and event.targetData
        // for a node we have event.sourceData

        if (!event.isEdge) {
            if (event.sourceData.busy) {
                // the busy node cannot be selected
                alert('busy ...');
            } else {
                event.component.toggleNodeSelection(event.sourceData);

            }
        }
        console.log(event);
        if (event.isEdge) {
            this.transitionSelect.emit(event.targetData.transition);
        } else {
            this.stateSelect.emit(event.sourceData.state);
        }

    }

    onSize(event) {
        console.log(event);
    }

    private onToggleExpand() {
        this.mExpanded = !this.mExpanded;
    }

    private onClickOutside() {
        console.log("onClickOutside",this.mExpanded);
        if (this.mExpanded == false) {
            return;
        }
        this.onToggleExpand();
    }

    ngOnDestroy() {
    }

}

