// angular
import {Component} from '@angular/core';

//
import {CtValueView, CtValueViewRegisterDecorator} from "../CtValueView";

@Component({
    selector: 'ct-boolean-view',
    template: '{{mValue}}'
})

@CtValueViewRegisterDecorator(CtValueView.Types.Boolean)
export class CtBooleanView extends CtValueView {

    private mValue;

    constructor(service:CtValueView.Service) {
        super(service);
    }

    public update(parameters:CtValueView.Parameters) {
        this.mValue = parameters.value;
    }

    ngOnDestroy() {
        this.destroy();
    }
}

