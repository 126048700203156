'use strict';







// angular2
import {Component, Input, Output, EventEmitter} from '@angular/core';
import {AfterViewInit, OnDestroy} from '@angular/core';
//import {LocalStorageService} from 'ngx-webstorage';




// custom decorators
// https://gist.github.com/remojansen/16c661a7afd68e22ac6e
function log() {
  return function (target, key, descriptor) {

    // save a reference to the original method this way we keep the values currently in the
    // descriptor and don't overwrite what another decorator might have done to the descriptor.
    if (descriptor === undefined) {
      descriptor = Object.getOwnPropertyDescriptor(target, key);
    }
    const originalMethod = descriptor.value;

    // editing the descriptor/value parameter
    descriptor.value = function () {
        const args = [];
        for (let _i = 0; _i < arguments.length; _i++) {
            args[_i - 0] = arguments[_i];
        }
        const a = args.map(function (ag) { return JSON.stringify(ag); }).join();
        // note usage of originalMethod here
        const result = originalMethod.apply(this, args);
        const r = JSON.stringify(result);
        console.log('Call: ' + key + '(' + a + ') => ' + r);
        return result;
    };

    // return edited descriptor as opposed to overwriting the descriptor
    return descriptor;
  };
}
/**
 * Delay the execution of a method until JSME is loaded and ready
 */
// based on https://gist.github.com/remojansen/16c661a7afd68e22ac6e
function postponeUntilInitialized() {
  return function(target: any, key: string| symbol, descriptor: PropertyDescriptor) {
    // save a reference to the original method this way we keep the values currently in the
    // descriptor and don't overwrite what another decorator might have done to the descriptor.
    if (descriptor === undefined) {
      descriptor = Object.getOwnPropertyDescriptor(target, key);
    }
    const originalMethod = descriptor.value;

    // editing the descriptor/value parameter
    descriptor.value = function () {
        const args = [];
        for (let _i = 0; _i < arguments.length; _i++) {
            args[_i - 0] = arguments[_i];
        }
        // note usage of originalMethod here
        const f = function(mnJsme: MnJsmeDepict): void {originalMethod.apply(mnJsme, args); };
        this.addInitCallBack(f);

    };

    // return edited descriptor as opposed to overwriting the descriptor
    return descriptor;


  };
}

function jsmeNotNull() {
      return function (target: any, key: string, descriptor: PropertyDescriptor) {
        if (descriptor === undefined) {
          descriptor = Object.getOwnPropertyDescriptor(target, key);
        }
        const originalMethod = descriptor.value;

        descriptor.value = function () {
          const args = [];
          for (let _i = 0; _i < arguments.length; _i++) {
              args[_i - 0] = arguments[_i];
          }
          // note usage of originalMethod here
          let  result;
          if (this.mJSME !== null) {
            result = originalMethod.apply(this, args);
          } else {
            console.log('!!!call ' + key + '() when jsme is null');
          }
          return result;
        };

            // return edited descriptor as opposed to overwriting the descriptor
    return descriptor;

  };

}




// jsme
//import {MnJsmeServiceDepict} from './MnJsmeServiceDepict';
import {MnJsmeService} from '@mn/jsme'
import {JSME, JSMEevent} from './JSME.d';
//import {CallBackName} from "./JSME.d"; //geenrates a an error

declare var JSApplet: any;

function afterInit() {
  console.log('');
}

@Component({
  selector: 'mn-jsme-depict',
  // moduleId: module.id,
  template: '<div id=\'{{mId}}\' #jsmeTag></div>'
})
export class MnJsmeDepict implements AfterViewInit, OnDestroy {

  // initial size values
  static InitHeight = '300px';
  static InitWidth = '400px';


  mId = '';


  mJSME: JSME = null;
  mSmiles = '';

  mInitCallBacks: ((mnJsme: MnJsmeDepict) => void)[] = [];
  mReady = false;


  @Input('height') set height(value: string | number) {
    this.setHeight(value);
  }
  @Input('width') set width(value: string | number) {
    this.setWidth(value);
  }

  @Input('options') set options(jmeOptions: string) {
    // console.log('Set options: ' + jmeOptions);
    this.setOptions(jmeOptions);
  }

  @Input('mol') set mol(value: string) {
    this.setMol(value);
  }

  // local storage
  @Input()
  storeKey: string;

  @Input()
  storeType: string;

  @Output('smilesChange') mSmilesChange = new EventEmitter();


  constructor(private mJsmeService: MnJsmeService /*MnJsmeServiceDepict*/) {
    this.mId = 'jsme_' + this.mJsmeService.createId();
  }


  public getMyJsme(): JSME {
    return this.mJSME;
  }
  /**
   * Convert a size if given as a number to a string with "px" appended.
   * This method is useful to specify the width and the height of the applet
   */
  // @log
  protected sizeTopixel(value: string | number): string {
    let valueStr: string;
    if (typeof value === 'number') {
      valueStr = value + 'px';
    } else {
      valueStr = value + '';
    }
    return valueStr;
  }


  public addInitCallBack(callback: (mnJsme: MnJsmeDepict) => void) {
      if (this.mReady) {
        callback(this);
      } else {
        this.mInitCallBacks.push(callback);
      }
  }



  ngAfterViewInit() {
    const me = this;
    this.mJsmeService.createEditor(() =>  {
      me.mJSME = new JSApplet.JSME(this.mId, MnJsmeDepict.InitWidth, MnJsmeDepict.InitHeight);

      //should be removed
      me.mJSME.setCallBack('AfterStructureModified', function(jsme_event){ me.onStructureChange(jsme_event); });


      me.mReady = true;

      // run all accumulated callbacks
      for (const cb  of this.mInitCallBacks) {
        cb(this);
      }
      this.mInitCallBacks = [];

      // if ( this.storeKey !== null && this.storeKey.length > 0) {
        
      // }

    });
  }

  ngOnDestroy() {
    if (this.mJSME !== null) {delete this['mJSME']; }
    this.mJSME = null;
  }

  /**
   * One simple application is to redefine this method on the object itself
   * after it has been created:
   *  function f(evt: JSMEevent): void {console.log(evt.src.smiles();}
   * mnJsme1.onStructureChange = f;
   *
   */
  onStructureChange(jsme_event: JSMEevent): void {
    this.mSmiles = jsme_event.src.smiles();
    this.mSmilesChange.emit(this.mSmiles);
  }

  /**
   * Read a molecule in any JSME supported format
   */
  public setMol(mol: string) {
    this.readGenericMolecularInput(mol);
  }

  @postponeUntilInitialized()
  public readGenericMolecularInput(mol: string) {
    this.mJSME.readGenericMolecularInput(mol);
  }

  /**
   * Example: depict,oldlook, see http://peter-ertl.com/jsme/JSME_2017-02-26/doc.html
   */
  @postponeUntilInitialized()
  public setOptions(options: string) {
    this.mJSME.options(options);
  }

  @postponeUntilInitialized()
  public setWidth(value: string | number) {
    const valueString = this.sizeTopixel(value);
    this.mJSME.setWidth(valueString);
  }

  @postponeUntilInitialized()
  public setHeight(value: string | number) {
    const valueString = this.sizeTopixel(value);
    this.mJSME.setHeight(valueString);
  }

  @postponeUntilInitialized()
  public setAtomBackgroundColors( molIndex: number,  atomAndColorCSV: string) {
    this.mJSME.setAtomBackgroundColors(molIndex, atomAndColorCSV);
  }

  @postponeUntilInitialized()
  public resetAtomColors(molIndex: number) {
    this.mJSME.resetAtomColors(molIndex);

  }

  @postponeUntilInitialized()
  public setCallBack(callBackName: string, callBackFunction: (event:JSMEevent)=>void) {
    this.mJSME.setCallBack(callBackName, callBackFunction);
  }


  @jsmeNotNull()
  public smiles() {
    return this.mJSME.smiles();
  }

  @jsmeNotNull()
  public mol2000() {
    return this.mJSME.molFile(false);
  }
  @jsmeNotNull()
  public mol3000() {
    return this.mJSME.molFile(true);
  }

  @jsmeNotNull()
  public jme() {
    return this.mJSME.jmeFile();
  }

  @jsmeNotNull()
  public getNumberOfColorsForBackGroundPalette(): number {
    return this.mJSME.getNumberOfColorsForBackGroundPalette();
  }

}
