/**
 * Created by joerg on 7/17/17.
 */

// rxjs
import 'rxjs/Rx';
import { Observable } from 'rxjs/Observable';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Subject } from 'rxjs/Subject';
import { Subscription } from 'rxjs/Subscription';

// angular
import {Component,Input,ViewChild,HostListener,Output,EventEmitter,Injector} from '@angular/core';

//
import {Backend} from "./BackendInterfaces";
import {CtStateViewComponent,ct_state_view_components} from "./CtStateViewComponent"
import {CtStateViewService} from "./CtStateViewService";

import {GridOptions,GridApi,ColumnApi,ColDef,Column,RowNode} from 'ag-grid/main'
import {getScrollbarWidth,createTableConfig,createTableConfig2,renderFullRow} from "./CtStateViewHelpers";
import {CtTable} from "./CtTable/CtTable";
import {CtSelectionService} from "./CtSelectionService";
import {MnTextService} from "@mn/core";
import {MnWorkflowService} from "./MnWorkflowService";
import {MnWorkflowObserver} from "./MnWorkflowObserver";

let scroll_bar_width = getScrollbarWidth();

interface Tab {
    label:string;
    spec:CtStateViewComponent.Specification;
    data?: any;
    table?: CtTable;
    closable?: boolean;
    ident?:string;
}
type Tabs = Tab[];

@Component({
    selector: 'mn-state-view',
    templateUrl: './MnStateView.html',
    styleUrls: ['./MnStateView.css'],
    providers: [CtStateViewService]
})
export class MnStateView extends MnWorkflowObserver {

    @ViewChild('tabbar') mTabBar = null;

    private mTabs:Tabs = [];
    private mCurrentTab:Tab = null;

    private mSideVisible:boolean = false;
    private mSideWidth:string = "0px";
    private mTablePartialWidth:number = 200;
    private mTableWidth:string = "100%";
    private mResizing:any = undefined;
    private mPartialSeparator = 0;
    //private mTable:CtTable = null;
    private mRowHeightList:number = 32;
    private mRowHeightNormal:number = 100;
    private mRowheightFooter:number = 18;
    private mRowHeight:number = 100;

    private mListMode:boolean = false;
    private mFooterMode:boolean = false;

    // grid
    public mClasses = ['ag-workflow'];
    private mAgGridOptions:GridOptions= {
        debug: true,
        enableSorting: true,
        enableColResize: true,
        getRowHeight: (params) => {
            if (params.data.footer) {
                return this.mRowheightFooter;
            } else if (params.data.is_virtual) {
                return 32;
            } else
            {
                return this.mRowHeight;
            }
        },
        isFullWidthCell: (row_node:RowNode) => this.isFullWidthCell(row_node),
        fullWidthCellRenderer: (params) => {
            return renderFullRow(params,this.mText);
        },
        onGridReady: () => this.onGridReady(),
        onColumnResized: (data) => this.onColumnResized(data),
        /*onGridReady: function() {
            gridOptions.api.addGlobalListener(function(type, event) {
                if (type.indexOf('column') >= 0) {
                    console.log('Got column event: ', event);
                }
            });
        }*/
    };


    constructor(private mWorkflowService:MnWorkflowService, private mService:CtStateViewService, private mInjector:Injector, private mSelectionService:CtSelectionService, private mText:MnTextService) {
        super(mWorkflowService);
    }
    protected onState(state:Backend.FullState) {
        if (!this.mService) return;
        //let state:Backend.FullState = this.Workflow.State;
        let workflow:Backend.Workflow = this.Workflow.Workflow;

        let tabs:Tabs = [
            {
                label: "Data Table",
                spec: ct_state_view_components.CtStateViewComponentFullTable
            },
            /*{
                label: "Compound Details",
                spec: ct_state_view_components.CtStateViewComponentDetails
            },*/
            /*{
                label: "Summary Table",
                spec: ct_state_view_components.CtStateViewComponentSummaryTable
            },*/
            /*{
                label: "State Json (only for DEBUG)",
                spec: ct_state_view_components.CtStateViewComponentJson
            },*/
            /*{
                label: "Raw Table (only for DEBUG)",
                spec: ct_state_view_components.CtStateViewComponentRawTable
            },*/
        ];

        if (state != null && workflow != null && workflow.settings && workflow.settings != null) {
            for (var k in workflow.settings) {
                // skip loop if the property is from prototype
                if(!workflow.settings.hasOwnProperty(k)) continue;
                if (k.startsWith('create_summary_table')) {
                    let s = workflow.settings[k];
                    tabs.push({
                        label: "Summary Table - " + s.title,
                        spec: ct_state_view_components.CtStateViewComponentSummary,
                        data: s,
                        closable: true,
                        ident: k
                    })
                }
            }
        }

        if (state != null && workflow != null && state.dataset && state.dataset.table && state.dataset.table.spec) {
            let spec = state.dataset.table.spec;
            for (let i = 0, l = spec.length; i < l; i++) {
                if (spec[i].ident == "EEF_MODEL_RESULT") {
                    let key = spec[i].children[0].key;
                    tabs.push({
                        label: "ePiE Map",
                        spec: ct_state_view_components.CtStateViewComponentLeaflet,
                        data: state.dataset.table.data[0][key],
                        closable: false,
                        ident: "xxx"
                    })

                }
            }
        }

        this.mTabs = tabs;
        this.setCurrentTab(tabs[0]);
        this.mService.setState(state);
        console.log("Update Sate View");
    }

    protected onTable(table:CtTable) {
        this.syncGrid();
        this.updateVisualColumns();
    }

    private removeTab(tab:Tab) {
        this.Workflow.removeWorkflowSetting(tab.ident,()=>{});
        let index = this.mTabs.indexOf(tab);
        if (index > -1) {
            this.mTabs.splice(index, 1);
        }
        this.mTabs = this.mTabs.slice();
        this.mTabBar.selectedIndex = 0;
    }

    private onSelectedIndexChange(index) {
        console.log("CtStateView.onSelectedIndexChange",index);
        this.setCurrentTab(this.mTabs[index]);
        this.mService.Data = this.mTabs[index].data;
        this.mService.Table = this.Workflow.Table;
        this.updateVisualColumns();
        this.mService.setState(this.Workflow.State);
    }

    private isFullWidthCell(row_node:RowNode) {
        return row_node.data.is_virtual === true;
    }

    private cellClass(params) {
        if (params.data.last_in_category === true) {
            return 'last-in-category';
        }
        return '';
    }

    private onGridReady() {
        this.syncGrid();
    }

    private onColumnResized(data) {
        //console.log(data);
        if (this.mCurrentTab.spec.GridMode == CtStateViewComponent.GridMode.Partial) {

            /*if (data.column.colDef.colId == 'rootSTRUCTURE') {
                let pinned_width = data.column.getActualWidth();
                this.mTablePartialWidth = pinned_width;
                this.mTableWidth = pinned_width+scroll_bar_width + 'px';
            }*/

            let cols:Column[] = this.mAgGridOptions.columnApi.getAllColumns();
            let pinned_width = 0;
            for (let i = 0, l = cols.length; i < l; i++) {
                let c:Column = cols[i];
                if (c.isPinnedLeft()) {
                    pinned_width+= c.getActualWidth();
                }
            }
            this.mTablePartialWidth = pinned_width;
            this.mTableWidth = pinned_width+scroll_bar_width + 'px';

            //console.log(cols);


        }
        if (data.column.colDef.colId == 'rootSTRUCTUREXXXIMAGE_URL') {
            //console.log("mmmmmmmmmmmmmm",data)  ;
            //this.mRowHeightNormal = data.column.getActualWidth()-40;
            this.mRowHeightNormal = data.column.getActualWidth()-(this.Workflow.Table.maxIndent()*20);
            this.updateRowHeight();
            this.mAgGridOptions.api.refreshCells({
                columns: [data.column],
                force: true
            })
            //this.mAgGridOptions.api.resetRowHeights();
        }
        if (data.column.colDef.colId.startsWith('rootSKYLINE')) {
            this.mAgGridOptions.api.refreshCells({
                columns: [data.column],
                force: true
            })
        }
    }

    private syncGrid() {
        if (this.mAgGridOptions == undefined || this.mAgGridOptions.api == undefined) return;
        if (this.Workflow.State == null) return;
        this.updateGridFooter();
        this.mSelectionService.init(this.Workflow.Table);
        if (this.Workflow.State.dataset == null) {
            this.mAgGridOptions.api.setColumnDefs([]);
            setTimeout(() => {
                this.mAgGridOptions.api.setRowData([]);
            })
        } else {
            let table_config = createTableConfig2(this.Workflow.Table,this.mInjector);
            this.mAgGridOptions.api.setRowData([]);
            this.mAgGridOptions.api.setColumnDefs(table_config.cols);
            let fcw = this.mAgGridOptions.columnApi.getColumn('rootSTRUCTUREXXXIMAGE_URL').getActualWidth();
            this.mRowHeightNormal = fcw -(this.Workflow.Table.maxIndent()*20);
            this.updateRowHeight(false);
            setTimeout(() => {
                this.mAgGridOptions.api.setRowData(this.Workflow.Table.RowsVirtual);
            })
         }
    }

    updateGridFooter() {
        if (this.Workflow.State.dataset == null || this.mFooterMode == false) {
            this.mAgGridOptions.api.setPinnedBottomRowData([]);
        } else {
            let stat = this.Workflow.Table.Statistics;
            stat.min.footer = true;
            stat.max.footer = true;
            stat.sum.footer = true;
            stat.count.footer = true;
            stat.avg.footer = true;
            let bottom_rows = [stat.min,stat.max,stat.sum,stat.count,stat.avg];
            this.mAgGridOptions.api.setPinnedBottomRowData(bottom_rows);
        }
    }

    private updateVisualColumns() {
        if (this.mAgGridOptions == undefined || this.mAgGridOptions.api == undefined) return;
        if (this.Workflow.State == null) return;

        let mode:CtStateViewComponent.GridMode = this.mCurrentTab.spec.GridMode;
        let cols:Column[] = this.mAgGridOptions.columnApi.getAllColumns();

        let hidden:string[] = [];
        let visible:string[] = [];
        let pinned_width = 0;

        for (let i = 0, l = cols.length; i < l; i++) {
            //console.log(cols[i]);
            let c:Column = cols[i];
            if (mode == CtStateViewComponent.GridMode.Partial && (c.isPinnedLeft() == false)) {
                hidden.push(c.getColId());
            } else {
                visible.push(c.getColId());
            }
            if (c.isPinnedLeft()) {
                pinned_width+= c.getActualWidth();
            }
        }
        this.mAgGridOptions.columnApi.setColumnsVisible(hidden,false);
        this.mAgGridOptions.columnApi.setColumnsVisible(visible,true);
        if (mode == CtStateViewComponent.GridMode.Partial) {
            this.mTablePartialWidth = pinned_width;
            this.mTableWidth = pinned_width+scroll_bar_width + 'px';
        }

        //console.log(cols);

    }



    private setCurrentTab(tab:Tab) {
        this.mCurrentTab = tab;
        if (tab) {
            let spec = tab.spec;
            if (spec.GridMode == CtStateViewComponent.GridMode.Full) {
                this.mTableWidth = "100%";
                this.mSideVisible = false;
                this.mSideWidth = "0px";
                this.mPartialSeparator = 0;
            } else if (spec.GridMode == CtStateViewComponent.GridMode.Partial) {
                this.mTableWidth = this.mTablePartialWidth + scroll_bar_width + 'px';
                this.mSideVisible = true;
                this.mSideWidth = "";
                this.mPartialSeparator = 10;
            } else if (spec.GridMode == CtStateViewComponent.GridMode.Hidden) {
                this.mTableWidth = "0px";
                this.mSideVisible = true;
                this.mSideWidth = "100%";
                this.mPartialSeparator = 0;
            }

        }
    }

    updateRowHeight(reset:boolean = true) {
        if (this.mListMode == true) {
            this.mRowHeight = this.mRowHeightList;
        } else {
            this.mRowHeight = this.mRowHeightNormal
        }
        if (reset) {
            this.mAgGridOptions.api.resetRowHeights();
        }
    }

    toggleListMode() {
        this.mListMode = !this.mListMode;
        this.updateRowHeight();
        this.mAgGridOptions.api.redrawRows();
    }

    toggleFooterMode() {
        this.mFooterMode = !this.mFooterMode;
        this.updateGridFooter();
    }





    ngOnDestroy() {
        //this.mState = null;
        //this.mWorkflow = null;
    }

}


