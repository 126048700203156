import {Component, ElementRef, Input, ViewEncapsulation} from '@angular/core';

import * as d3 from 'd3';
import {CSSclasses, NavigationTreeD3Component} from '../navigation-tree-d3/navigation-tree-d3.component';
import {MnWorkflow} from '../../MnWorkflow';
import MnWorkflowNode = MnWorkflow.Node;

import {HierarchyNode, HierarchyPointLink, HierarchyPointNode} from 'd3-hierarchy'; // for type checking

@Component({
  encapsulation: ViewEncapsulation.None, // for CSS
  selector: 'app-breadcrump-d3',
  templateUrl: './breadcrump-d3.component.html',
  styleUrls: ['./breadcrump-d3.component.css']
})
export class BreadcrumpD3Component extends NavigationTreeD3Component {

  @Input() linearDisplay = true; // when true, hide the tree structure - must be true

  @Input() arrowTipAngle = 90;
  @Input() interstitialSpace = 4;
  @Input() rootNodeIsStraight = true; // root done has a distinct polygon with a straight line left

  @Input() showBranchInfo = true; // change the styling, shape, text of the node that hast a branch


  // subclasses should redefined it
  protected myType = 'BreadcrumpD3Component'; // used for debugging/logging


  // duplicated constructor is needed - same problem as Java
  constructor(public elementRef: ElementRef) {
    super(elementRef);
    this.linearDisplay = true; // override the default from super class

  }

  /**
   * Must be linear
   * @param {MnWorkflow.Node} d
   * @returns {boolean}
   */
  public checkDataValidity(d: MnWorkflow.Node): boolean {
    if (!this.linearDisplay) {
      this.log('linear display must be true');
      return false;
    }
    return super.checkDataValidity(d);
  }

  protected getTipLength(): number {
    const ry: number = this.getNodeXYradius()[1];

    const halfHeight: number = ry;
    let halfAngle: number = this.arrowTipAngle / 2;

    halfAngle *= (Math.PI / 180); // deg to radian

    const tipLength: number = halfHeight / Math.tan(halfAngle);

    return tipLength;
  }



  /**
   * Improve visual vertical centering of the text inside a node or an edge.
   * This is needed because of the polygon shape
   * return a value for the dx attribute of a svg text
   * @param {HierarchyNode<MnWorkflow.Node>} n
   * @returns {number}
   */
  protected overrideShapeCenterX(n: HierarchyNode<MnWorkflowNode>): number {
    // not succeeded yet to find a mehtod for which the centering of the text in the arrow
    // looks OK - optical effect

    if (!this.vertical ) {
      const t = this.getTipLength() ;

      if (this.drawRootAsOtherShape(n)) {
        return -t / 4;
     } else {
        return  t / 5;
      }

    }

  }

  protected isNodeShapeSymmetric(): boolean {
    return false;

  }

  protected drawRootAsOtherShape(n: any): boolean {
    return this.rootNodeIsStraight && this.isNodeRoot(n);

  }

  protected setAndStyleNodes(nodeSelectionD3: any) {

    const [rx, ry]: number[] = this.getNodeXYradius();

    const h: number = ry;
    const t = this.getTipLength();
    const polygon: number[] = [
      rx, 0, // tip on the right
      rx - t, -h, // move up (y points downward)
      -rx, -h, // move left
      -(rx - t), 0, // back arrow
      -rx, h,
      rx - t, h,
    ];
    const polygonRoot: number [] = polygon.slice(); // make a copy
    polygonRoot.splice(6, 2); // remove one x,y


    // rotate if vertical
    let groupSelection: any = nodeSelectionD3;
    if (this.vertical) {
      groupSelection = nodeSelectionD3.append('g').attr('transform', 'rotate(90)');
    }

    // draw the arrow
    const polygonSelection: any = groupSelection.append('polygon');

    polygonSelection.attr('points', (n: HierarchyNode<MnWorkflowNode>) => {
         if ( this.drawRootAsOtherShape(n)) {
           return polygonRoot.join(' ');
         } else {
           return polygon.join(' ');
         }
       });

    this.styleNodes(polygonSelection);

    if (this.showBranchInfo) {
      // raw SVG from FontAwesome icon used in the switch button in the tree navigation window
      const branchIcon = 'M384 144c0-44.2-35.8-80-80-80s-80 35.8-80 80c0 36.4 24.3 67.1 57.5 76.8-.6 16.1-4.2 28.5-11 36.9-15.4 19.2-49.3 22.4-85.2 25.7-28.2 2.6-57.4 5.4-81.3 16.9v-144c32.5-10.2 56-40.5 56-76.3 0-44.2-35.8-80-80-80S0 35.8 0 80c0 35.8 23.5 66.1 56 76.3v199.3C23.5 365.9 0 396.2 0 432c0 44.2 35.8 80 80 80s80-35.8 80-80c0-34-21.2-63.1-51.2-74.6 3.1-5.2 7.8-9.8 14.9-13.4 16.2-8.2 40.4-10.4 66.1-12.8 42.2-3.9 90-8.4 118.2-43.4 14-17.4 21.1-39.8 21.6-67.9 31.6-10.8 54.4-40.7 54.4-75.9zM80 64c8.8 0 16 7.2 16 16s-7.2 16-16 16-16-7.2-16-16 7.2-16 16-16zm0 384c-8.8 0-16-7.2-16-16s7.2-16 16-16 16 7.2 16 16-7.2 16-16 16zm224-320c8.8 0 16 7.2 16 16s-7.2 16-16 16-16-7.2-16-16 7.2-16 16-16z';
      let iconWidth = 384;
      let iconHeight = 512;
      const rotate = true;
      const sizeRatio = 1.5
      const iconMargin = Math.min(rx, ry) * 0.1 ;

      if (rotate) {
        [iconWidth, iconHeight] = [iconHeight, iconWidth];
      }

      const scaleX = (rx - this.maxStrokeWidth - iconMargin) / sizeRatio / iconWidth;
      const scaleY = (ry - this.maxStrokeWidth - iconMargin) / sizeRatio / iconHeight;

      const scale: number = Math.min(scaleX, scaleY);
      const scaleSVG: string = 'scale(' + scale + ',' + scale + ')';
      const rotateSVG: string = rotate ? 'rotate(90)' : '';

      iconHeight *= scale;
      iconWidth *= scale;


      // scale(0.025, 0.025) rotate(90)
      // add an icon inside the polygon
      /*     nodeSelectionD3.append("text")
          .attr("style","font-family:FontAwesome;")
              .attr('font-size', "20px" )
              .attr("x", 0)
              .attr("y", h)
              .text(function(d) { return '\uf0aa'; });
      */
      // css takes precedence over the SVG attributes.
      /*
      nodeSelectionD3.append("text")
        .attr("style","font-family:FontAwesome;")
        .attr('font-size', "50px" )
        .attr("x", 0)
        .attr("y", 0)
        .text(function(d) { return '\u03A9'; });
      nodeSelectionD3  // shows up in the background
        .append('foreignObject')
        //.attr("width", 50)
        //.attr("height", 50)
        //.append("xhtml:body")
        .attr('y', 0).attr('x', rx - t)
        .html('<i class="fas fa-code-branch fa-rotate-90"></i>');
        */

      groupSelection.append('g')
        .attr('transform', (n: HierarchyPointNode<MnWorkflowNode>) => {
          let tx: number;
          let ty: number;

          tx =  rx - t;
          ty = h - iconHeight  - this.maxStrokeWidth / 2 - iconMargin;
          return  'translate(' + tx + ',' + ty + ') ' + scaleSVG + ' '  + rotateSVG;
        })
        .append('path')
        .attr('d', (n: HierarchyPointNode<MnWorkflowNode>) => {
          // console.log(n.data.children);
          // need to use the data.children because data.children has been modified during tree flattening
          if (n.data.children && n.data.children.length > 1) {
            return branchIcon;
          } else {
            return '';
          }
        })
        .attr('class', CSSclasses.NodeInsideBranchSymbol);


    }


  }


  protected createEdgeGroupD3Selection(): void {
    super.createEdgeGroupD3Selection();
    const isHorizontal: boolean = !this.vertical;
    const rotation: string = isHorizontal ? '' : ' rotate(90)';

    // translate to the center of node
    this.edgeGroupD3Selection.attr('transform', function (d) {
      return 'translate(' + (d.source.x + d.target.x) / 2 + ',' + (d.source.y + d.target.y) / 2 + ')' + rotation;
    });

  }

  protected getEdgeWidthAvailableSpace(): number {
    const rx: number = this.getNodeXYradius()[0];


    const lr = this.edgeLength - 2 * (rx + this.interstitialSpace + this.maxStrokeWidth);

    return lr;

  }

  protected createEdgeShapeD3Selection(): void {
    // must return the svg group

    const [rx, ry]: number[] = this.getNodeXYradius();

    // the horizontal length  of the link as a radius
    const lr = this.getEdgeWidthAvailableSpace() / 2;

    const h: number = ry;
    const t = this.getTipLength();


    const polygon: number[] = [
      lr + t, 0, // tip on the right
      lr, -h, // move up (y points downward)
      -lr - t, -h,
      -lr, 0,
      -lr - t, h,
      lr, h,
    ];

    function createLink(d: any) {
      const p: d3.Path = d3.path();
      const target: MnWorkflowNode = d.target.data;

      if (!target.busy) {
        // make a polygon
        p.moveTo(polygon[0], polygon[1]);
        for (let i = 0; i < polygon.length; i += 2) {
          p.lineTo(polygon[i], polygon[i + 1]);
        }
        p.closePath();
        // console.log(p.toString());
      } else {
        // else make a moving arrow composed of lines
        const n = h;
        for (let i = 0; i < n; i += 1) {
          const xl = -lr - t * (1 - i / n);
          const xr = lr + t * i / n;
          const y = h * (1 - i / n);
          // [xl, xr] = [xr, xl]; // such that the arrow move from left to right
          p.moveTo(xl, y);
          p.lineTo(xr, y);
          p.moveTo(xl, -y);
          p.lineTo(xr, -y);
        }
      }
      return p.toString();
    }

    // this.edgeShapeD3Selection = linkSelectionD3.append('polygon').attr('points', polygon.join(' '));
    this.edgeShapeD3Selection = this.edgeGroupD3Selection.append('path').attr('d', createLink);
  }


  /**
   *
   */
  protected setAndStyleEdgeText(): void {

    const self: BreadcrumpD3Component = this;
    const lx: number = self.getEdgeWidthAvailableSpace() - self.getTipLength();

    // https://bl.ocks.org/mbostock/7555321

    function wrap(textSelectionD3: any, width: number): void {
        textSelectionD3.each(function () {
          const textElement = d3.select(this);
          const text = textElement.text();
         // self.log(text);
          const lines: string[] = text.split('\n');
          const nLines: number = lines.length;
          if (nLines > 0) {
            const lineHeight = 1.1; // ems
            textElement.text(null); // delete text to be replaced

            for (let lineNumber = 0 ; lineNumber < nLines ; lineNumber++) {
              let y = lineNumber - nLines / 2 + 0.5;
              y *= lineHeight;
              textElement.append('tspan')
                .text(lines[lineNumber])
                .attr('x', 0) // needed  otherwise no "carriage return"
                .attr('y', y + 'em');
            }
          }

        });

    }

    this.edgeTextD3Selection = this.edgeGroupD3Selection.append('text')
      .text(function (d: any) {
        return self.getEdgeText(d.target.data);
      }).attr('class', CSSclasses.EdgeInsideText)
      .style('pointer-events', 'none') // the mouse pointer does not change when moving over the text
      .attr('x', function (d: HierarchyNode<MnWorkflowNode>) {
        return self.overrideShapeCenterX(d); // adjust the center of for displaying the text because of shape of the edge
      });

    if ( ! this.wrapEdgeText ) {
      this.edgeTextD3Selection.style('font-size', function (d) {
        const l = this.getComputedTextLength();


        const newFontSize: string = self.getFontSizeTofit(this, lx, 0);

        return newFontSize;

      });
    } else {

       this.edgeTextD3Selection.call(wrap, lx);
    }

  }

  protected shrinkEdgeTextIfNeeded(svgText: any, d: HierarchyNode<MnWorkflowNode>) {


  }
}
