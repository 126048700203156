import {Component} from '@angular/core';
import {Backend} from "../BackendInterfaces";

//
import {CtValueView, CtValueViewRegisterDecorator} from "../CtValueView";
import {DataSummary, DataSummaryObject} from "./CtDataSummaryView";


//"[{'subgraphId': 'TA-0000-0000-0070-0310-0060-0010-006', 'atoms': [1, 2, 3]}, {'subgraphId': 'TXP-004-0000-0000-0000-0000:ring:aromatic_benzene_c1ccccc1', 'atoms': [3, 4, 5]}]"

export interface ChemotypeMatch {
    subgraphId: string;
    atoms: number[];
}


export function parseChemotypeMatch(cm: string | ChemotypeMatch[]): ChemotypeMatch[] {
    if ((typeof cm) == 'string') {
        const cms: string = (<string>cm).replace(/'/g, "\"");
        return JSON.parse(<string>cms);

    }
    return <ChemotypeMatch[]>cm;
}

@Component({
    selector: 'ct-chemotype-profile-count-view',
    template: `<div class='ct-value-view'>{{mValue.length}}</div>`

})
@CtValueViewRegisterDecorator(CtValueView.Types.ChemotypeProfile)
export class CtChemotypeProfileCountView extends CtValueView {

    mValue: ChemotypeMatch[] = [] ;


    constructor(service:CtValueView.Service) {
        super(service);
        this.mValue = [];
    }

    public update(parameters:CtValueView.Parameters) {
        if (parameters.value) { // backend send nothing if no matches - should have sent [] instead
            this.mValue = parseChemotypeMatch(parameters.value);
            //console.log(this.mValue);
        } else {
            this.mValue = [];
        }
    }

    ngOnDestroy() {
        this.destroy();
    }
}
