/**
 * Created by joerg on 7/17/17.
 */

// rxjs
import 'rxjs/Rx';
import { Observable } from 'rxjs/Observable';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Subject } from 'rxjs/Subject';
import { Subscription } from 'rxjs/Subscription';

// angular
import {Directive,Input,ElementRef,Injector} from '@angular/core';

//
import {MnValue} from "./MnValue";
import {MnValueHtml} from "./MnValueHtml";

@Directive({
    selector: 'mn-value-html-view',
})
export class MnValueHtmlView {

    private mDestroy:Subject<void> = new Subject();
    private mDestroyHtml:Subject<void> = new Subject();
    private mValueSubject:Subject<MnValue> = new Subject();
    private mHtmlSubject:Subject<MnValueHtml> = new Subject();
    private mElSubject:Subject<ElementRef> = new Subject();

    @Input()
    //get valueHtml():MnValueHtml { return this.mValueHtml; }
    set valueHtml(v:MnValueHtml) { this.mHtmlSubject.next(v); }
    //private mValueHtml:MnValueHtml;

    @Input()
    //get valueHtml():MnValueHtml { return this.mValueHtml; }
    set valueHtmlKey(v:string) {
        this.mHtmlSubject.next(MnValueHtml.Registry.get(v));
    }

    @Input()
    //get value():MnValue { return this.mValue; }
    set value(v:MnValue) { this.mValueSubject.next(v); }
    //private mValue:MnValue = new MnValue(null);

    constructor(private mElRef:ElementRef,private mInjector:Injector) {
        Observable.combineLatest([this.mElSubject,this.mHtmlSubject,this.mValueSubject]).takeUntil(this.mDestroy).subscribe((latest)=> this.update(latest));
    }

    ngAfterViewInit() {
        this.mElSubject.next(this.mElRef);
    }

    private update(latest) {
        console.log(latest);
        this.mDestroyHtml.next();
        if (!latest[1]) return;
        latest[1].get(latest[2],this.mInjector).takeUntil(this.mDestroyHtml).subscribe((html)=> this.updateHtml(html));
    }

    private updateHtml(html:string) {
        let native_element = this.mElRef.nativeElement;
        if(native_element.innerHTML !== undefined) {
            native_element.innerHTML = html;
        } else if(native_element.textContent !== undefined) {
            native_element.textContent = html;
        } else {
            native_element.data = html;
        }
    }

    ngOnDestroy() {
        this.mDestroy.next();
        this.mDestroyHtml.next();
    }
}



