// angular
import {Component} from '@angular/core';

//
import {CtValueView, CtValueViewRegisterDecorator} from "../CtValueView";

@Component({
    selector: 'ct-string-view',
    template: '{{mValue}}',
    styles: [
        `
            :host {
               overflow: auto;
            }
            `]

         })
@CtValueViewRegisterDecorator(CtValueView.Types.String)
export class CtStringView extends CtValueView {

    private mValue;

    constructor(service:CtValueView.Service) {
        super(service);
    }

    public update(parameters:CtValueView.Parameters) {
        this.mValue = parameters.value;
    }

    ngOnDestroy() {
        this.destroy();
    }
}

