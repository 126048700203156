/**
 * Created by joerg on 7/17/17.
 */

// angular
import {Component} from '@angular/core';
import {FormControl, FormGroupDirective, NgForm, Validators} from '@angular/forms';
import {ErrorStateMatcher} from '@angular/material/core';
//
import {MnBackend} from "@mn/core";

//
import {MnWorkflowComponentStatic} from "../../MnWorkflowComponent";
import {MnActionConfigurator} from "../MnActionConfigurator";
import {MnActionConfigurationService} from "../MnActionConfigurationService";
import {MnWorkflowService} from "../../MnWorkflowService";
import {MnWorkflow} from "../../MnWorkflow";

// this class could be shared by all action views that require a name and a selection of rows and cols
export class AddSummaryConfiguration {
    constructor(public name:string, public columns:string[], public rows:string[]) {}
    public valid():boolean {
        return this.name.trim().length > 0 && this.columns.length > 0 && this.rows.length > 0;
    }

    public isValidExceptName() {
        return this.columns.length > 0 && this.rows.length > 0;
    }

    public isNameValid(name: string): boolean {
        return name && name.trim().length > 0;
    }

}


// this class could be shared by all action views that require a name and a selection of rows and cols

export class MyErrorStateMatcher implements ErrorStateMatcher {
    constructor(protected summaryConf: AddSummaryConfiguration) {
        //console.log("here");
    }
    isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {

        if(this.summaryConf.isValidExceptName()) {

            return !this.summaryConf.isNameValid(this.summaryConf.name);
        }
        return false;

    }
}
export class AddSummaryRunner extends MnWorkflow.ActionConfiguration.Runner {
    constructor(private backend:MnBackend, private mWorkflowService:MnWorkflowService, private mConfiguration:AddSummaryConfiguration) {
        super();
    }
    public run() {
        console.log("Adding Summary Table",this.mConfiguration);

        let summary_s = this.mWorkflowService.getStateSetting('summary');
        let summary_w = this.mWorkflowService.getWorkflowSetting('summary');
        if (!summary_w) {
            summary_w = {
                count: 1,
                data: {
                    '1': this.mConfiguration
                }
            }
        } else {
            summary_w.count = summary_w.count+1;
            summary_w.data[summary_w.count+''] = this.mConfiguration;
        }
        if (!summary_s) {
            summary_s = ['1'];
        } else {
            summary_s.push(summary_w.count+'');
        }

        this.mWorkflowService.addWorkflowSetting('summary',summary_w,()=>{
            this.mWorkflowService.addStateSetting('summary',summary_s,()=>{
                this.done();
                this.mWorkflowService.reload();
            });
        });
    }
}

@Component({
    selector: 'mn-action-configurator-add-summary',
    template: `
        <mn-action-configurator-frame>
            <mat-form-field line1 style="width: 100%; margin-top: 8px;">
                <input matInput #nameInput placeholder="Enter summary table name" [(ngModel)]="mConfiguration.name" (ngModelChange)="checkEnabled()" [errorStateMatcher]="matcher">
                <mat-hint align="end">Name required</mat-hint>
                <mat-error align="end" >
                    Name required
                </mat-error>
            </mat-form-field>
            <mn-action-configurator-row-marks
                    line2
                    (selectedRows)="onSelectedRows($event)"
            ></mn-action-configurator-row-marks>
            <mn-action-configurator-list-controller 
                    line3
                    [label]="'Selected Columns: '+columns.count()"
                    (onSelectAll)="columns.selectAll()"
                    (onDeselectAll)="columns.deselectAll()"
            ></mn-action-configurator-list-controller>
            <mn-table-column-selection
                    scroll
                    #columns
                    style="height: 100%; width: 100%; display: block;"
                    [nodes]="mWorkflowService.Table.Nodes"
                    [(selectedNodesIds)]="mConfiguration.columns"
                    (selectedNodesIdsChange)="onSelectedNodes($event)"
            ></mn-table-column-selection>       
        </mn-action-configurator-frame>
     `,
    styles: []
})
export class AddSummary extends MnActionConfigurator {

    matcher: MyErrorStateMatcher;

    private mConfiguration:AddSummaryConfiguration = new AddSummaryConfiguration(
        '',[],[]
    );

    constructor(acs:MnActionConfigurationService, private mWorkflowService:MnWorkflowService, private mBackend:MnBackend) {
        super(acs);
        this.enable(false);
        this.matcher= new MyErrorStateMatcher(this.mConfiguration);

    }

    protected onInit(configuration:any) {
        //this.mConfiguration.properties = configuration.properties ? configuration.properties : [];
    };

    protected onStart() {
        this.finish(this.mConfiguration,new AddSummaryRunner(this.mBackend,this.mWorkflowService,this.mConfiguration));
    };

    private onSelectedNodes(v) {
        this.checkEnabled();
    }

    private onSelectedRows(v) {
        this.mConfiguration.rows = v;
        this.checkEnabled();
    }

    private checkEnabled() {
        this.enable(this.mConfiguration.valid() );
        console.log(this.mConfiguration);
    }
    
    ngOnDestroy() {
        super.destroy()
    }
}
MnActionConfigurator.Registry.register("CREATE_SUMMARY_TABLE",new MnWorkflowComponentStatic(AddSummary));
