/**
 * Created by joerg on 7/17/17.
 */

// angular
import {Component,Input,Output,EventEmitter} from '@angular/core';

//
import {MnWorkflow} from "../MnWorkflow";
import {MnWorkflowComponent} from "../MnWorkflowComponent";
import {MnActionConfigurator} from "./MnActionConfigurator";
import {MnActionConfigurationService} from "./MnActionConfigurationService";
import {MnActionConfigurationObserver} from "./MnActionConfigurationObserver";


@Component({
    selector: 'mn-action-configurator-view',
    template: `
        <mn-workflow-component-view style="height: 100%; width: 100%" [description]="mComponentDescription" ></mn-workflow-component-view>
        <div class="ct-action-apply">
            <button *ngIf="mEnabled" style="background: #ff8f00;" mat-fab (click)="onAction()">Apply</button>
            <button *ngIf="!mEnabled" mat-fab (click)="onAction()" [disabled]="true">Apply</button>
        </div>
    `,
    styles: [`
        :host {
            position: relative;
            height: 100%;
            width: 100%;
            display: block;
            font-size: 14px;
        }
        .ct-action-apply {
          position: absolute;
          right: 8px;
          bottom: 10px;
          min-width: 80px;
          min-height: 80px;
          background: white;
          border-radius: 50%;
        }
        .ct-action-apply.center {
          right: calc(50% - 40px);
          bottom: 10px;
        }
        .ct-action-apply > button {
          min-width: 80px;
          min-height: 80px;
          font-weight: bold;
          font-size: 16px;
        }

    `],
    providers: [MnActionConfigurationService]
})
export class MnActionConfiguratorView extends MnActionConfigurationObserver {

    @Input()
    get actionConfiguration():MnWorkflow.ActionConfiguration { return this.mActionConfiguration; }
    set actionConfiguration(v:MnWorkflow.ActionConfiguration) { this.mActionConfiguration = v; this.update(); }
    private mActionConfiguration:MnWorkflow.ActionConfiguration = null;

    @Output() actionApply = new EventEmitter();

    private mComponentDescription:MnWorkflowComponent.Description;
    private mEnabled:boolean = false;

    constructor(private mService:MnActionConfigurationService) {
        super(mService);
    }

    private update() {
        console.log("MnActionConfiguratorView",this.mActionConfiguration);
        if (!this.mActionConfiguration) {
            this.mComponentDescription = null;
            return;
        }
        this.init(this.mActionConfiguration);
        MnWorkflowComponent.all(MnActionConfigurator.Registry.only(this.mActionConfiguration.Action.title),(component_descriptions:MnWorkflowComponent.Description[])=>{
            console.log('MnActionConfiguratorView',component_descriptions);
            if (component_descriptions.length > 0) {
                this.mComponentDescription = component_descriptions[0];
            } else {
                MnWorkflowComponent.all(MnActionConfigurator.Registry.only("MISSING"),(component_descriptions:MnWorkflowComponent.Description[])=>{
                    console.log('MnActionConfiguratorView',component_descriptions);
                    if (component_descriptions.length > 0) {
                        this.mComponentDescription = component_descriptions[0];
                    } else {
                        this.mComponentDescription = null;
                    }
                });
            }
        });
    }

    private onAction() {
        console.log("Apply Action");
        this.start();
        //this.apply();
        //this.applyAction.next(new MnWorkflow.ActionConfiguration(this.mActionConfiguration.Action,this.configuration));
    }

    // implemented empty - used by MnActionConfigurator
    protected onInit(v:any) {};
    protected onStart() {};

    protected onFinish(v:MnWorkflow.ActionConfiguration) {
        this.actionApply.next(v);
    }

    protected onEnable(enabled:boolean) {
        this.updateEnabled();
    }

    protected onEnableRowMarks(enabled:boolean) {
        this.updateEnabled();
    }

    private updateEnabled() {
        if (this.mService) {
            if (this.mService.RowMarksActive) {
                this.mEnabled = this.mService.EnableSubject.getValue() && this.mService.EnableRowMarksSubject.getValue();
            } else {
                this.mEnabled = this.mService.EnableSubject.getValue();
            }
        } else {
            this.mEnabled = false;
        }
    }

    ngOnDestroy() {
        super.destory();
    }
}


