/**
 * Created by joerg on 7/17/17.
 */

// rxjs
import 'rxjs/Rx';
import { Observable } from 'rxjs/Observable';
import { Observer } from 'rxjs/Observer';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Subject } from 'rxjs/Subject';

// angular
import {Component,ViewChild} from '@angular/core';

// mn
import {MnBackend} from "@mn/core";

//
import {MnWorkflowComponentStatic} from "../../MnWorkflowComponent";
import {MnActionConfigurator} from "../MnActionConfigurator";
import {MnActionConfigurationService} from "../MnActionConfigurationService";
import {MnWorkflow} from "../../MnWorkflow";
import {CtTable} from "../../CtTable/CtTable";
import {MnWorkflowService} from "../../MnWorkflowService";
import {CtXlsExport} from "../../CtTable/CtXlsExport";
import {SkylineD3Component} from "../../d3/skyline-d3/skylined3.component";

export class ExportXlsRunner extends MnWorkflow.ActionConfiguration.Runner {
    constructor(private filename:string, private sheetname:string, private rowmarks:string[], private table:CtTable, private backend:MnBackend, private skplot:SkylineD3Component) {
        super();
    }
    public run() {
        console.log("Exporting",this.rowmarks,"to",this.filename, 'with sheetname', this.sheetname);

        const exporter: CtXlsExport = new CtXlsExport(
            this.backend,
            this.table,
            this.filename,
            this.sheetname,
            this.skplot,
            ()=>this.done(),
            (error)=>this.error(error)
        );
        exporter.export();
    }
}

@Component({
    selector: 'mn-action-configurator-export-structures',
    template: `
        <mn-action-configurator-frame [scroll]="false">
            
            <mat-form-field line2 style="width: 100%; margin-top: 8px;">
                <input matInput placeholder="File Name" [ngModel]="mConfiguration.filename" (ngModelChange)="onFilename($event)">
            </mat-form-field>

            <mat-form-field line3>
                <input matInput placeholder="Sheet Name" [ngModel]="mConfiguration.sheetname" (ngModelChange)="onSheetname($event)">
            </mat-form-field>
            
        </mn-action-configurator-frame>
        <app-skylined3 [margin]="10" [width]="640" [height]="400" [vertical]="true" [centerBarsMiddleHeight]="true" [preserveAspectRatioSVGpar]="'none'" [useParentSize]="false" [showHorizontalAxis]="true"></app-skylined3>

    `,
    styles: []
})
export class ExportXls extends MnActionConfigurator {

    private mConfiguration:ExportXls.Configuration = new ExportXls.Configuration("table_export" + CtXlsExport.extension,"CT workflow");

    @ViewChild(SkylineD3Component) skPlot: SkylineD3Component;

    constructor(private mAcs:MnActionConfigurationService, private mWorkflow:MnWorkflowService, private mBackend:MnBackend) {
        super(mAcs);
        this.enable(false);
    }

    protected onInit(configuration:any) {
        // not used yet, supposed to be called once when the Configurator is shonw
        this.checkEnabled();
    };

    protected onStart() {
        // do whatever needs to be done before the action can be performed
        // and then call finish, which takes the action configuration and an optional Runner instance as argument
        // the Runner instance is supposed to be used frontend only actions and provides means to report progress etc.
        this.finish(this.mConfiguration,new ExportXlsRunner(
            this.mConfiguration.filename,
            this.mConfiguration.sheetname,
            this.mAcs.RowMarksSubject.getValue(),
            this.mWorkflow.Table,
            this.mBackend,
            this.skPlot)
        );
    };

    private onFilename(filename) {
        this.mConfiguration.filename = filename;
        this.checkEnabled();
    };

    private onSheetname(sheetname) {
        this.mConfiguration.sheetname = sheetname;
        this.checkEnabled();
    };

    private checkEnabled() {
        this.enable(this.mConfiguration.filename.length > 0 && this.mConfiguration.sheetname.length > 0);
    };

    ngOnDestroy() {
        super.destroy()
    }
}
MnActionConfigurator.Registry.register("EXPORT_DATA_TABLE_XLS",new MnWorkflowComponentStatic(ExportXls));

export namespace ExportXls {
    export class Configuration {
        constructor(public filename:string, public sheetname:string) {}
    }
}