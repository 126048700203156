/**
 * Created by joerg on 7/17/17.
 */

// rxjs
import 'rxjs/Rx';
import { Observable } from 'rxjs/Observable';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Subject } from 'rxjs/Subject';
import { Subscription } from 'rxjs/Subscription';


// angular
import {Injectable} from '@angular/core';

// mn
import {MnBrandService, MnTextService} from "@mn/core";

//
import {Backend} from "../BackendInterfaces";
import {MnWorkflow} from "../MnWorkflow";


@Injectable() export class MnActionConfigurationService {
    InitSubject:BehaviorSubject<MnWorkflow.ActionConfiguration> = new BehaviorSubject(null);
    StartSubject:Subject<void> = new Subject();
    FinishSubject:Subject<MnWorkflow.ActionConfiguration> = new Subject();
    EnableSubject:BehaviorSubject<boolean> = new BehaviorSubject(false);
    EnableRowMarksSubject:BehaviorSubject<boolean> = new BehaviorSubject(false);
    RowMarksSubject:BehaviorSubject<string[]> = new BehaviorSubject([]);
    RowMarksActive:boolean = false;
}
