/**
 * Created by joerg on 5/3/17.
 */

// angular
import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';

// uirouter
import { UIRouterModule } from '@uirouter/angular';

// color picker
import { ColorPickerModule } from 'ngx-color-picker';

// mn
import { MnCoreModule } from '@mn/core';
import { MnRouteModule } from '@mn/route';
import { MnCommonModule } from '@mn/common';
import { MnFileModule } from '@mn/file';
import { MnD3Module } from '@mn/d3';
//import { MnMaterialModule } from '@mn/material';
import { MnGridModule } from '@mn/grid';
import { MnJsmeModule } from '@mn/jsme';
//import { CtSearchModule } from '@ct/search';

import {
    MatRippleModule,
    MatAutocompleteModule,
    MatListModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatSlideToggleModule,
    MatNativeDateModule,
    MatInputModule,
    MatTabsModule,
    MatSliderModule,
    MatDialogModule,
    MatMenuModule,
    MatChipsModule,
    MatPaginatorModule,
    MatIconModule,
    MatSelectModule,
    MatExpansionModule,
} from '@angular/material';

let material_modules = [
    //ScrollDispatchModule,
    MatRippleModule,
    MatAutocompleteModule,
    MatListModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatSlideToggleModule,
    MatNativeDateModule,
    MatInputModule,
    MatTabsModule,
    MatSliderModule,
    MatDialogModule,
    MatMenuModule,
    MatChipsModule,
    MatPaginatorModule,
    MatIconModule,
    MatSelectModule,
    MatExpansionModule,
];

import {CtDetailsModule} from "../details/CtDetailsModule";
import {MatTooltipModule} from "../tooltip/MnTooltipModule";

//
import {CtActionList} from "./CtActionList";
import {CtActionHeader} from "./CtActionHeader";
import {CtActionIcon} from "./CtActionIcon";
import {MnClickOutside} from "./MnClickOutside";
import {CtWorkflowNav} from "./CtWorkflowNav";
//import {CtStateViewComponentJson} from "./CtStateViewComponentJson";
import {CtStateViewComponentSummary,CtStateViewComponentSummaryInner} from "./CtStateViewComponentSummary";
//import {CtStateViewComponentDetails} from "./CtStateViewComponentDetails";
//import {CtStateViewComponentLeaflet} from "./CtStateViewComponentLeaflet";

import {ALL} from "./CtValueViews/index";
//import {ALL_ACTION_CONFIGS} from "./CtActionConfigs/index";
import {init_table,MnTableView} from "./CtTable/index";

import {CtAgGridCell} from "./CtAgGridCell"
import {AgGridGroupHeader} from "./aggrid/AgGridGroupHeader"
import {AgGridHeader} from "./aggrid/AgGridHeader"

import {NavigationTreeD3Component,BreadcrumpD3Component, ProbabilityPlotD3Component} from "./d3/index"
//import {CtActionConfigView} from "./CtActionConfigView";
import {SkylineD3Component} from "./d3/skyline-d3/skylined3.component";
import {CtHelpLink} from "./CtHelpLink";

import {MnActionView} from "./MnActionView";
import {MnStateView} from "./MnStateView";
import {MnWorkflowFrame} from "./MnWorkflowFrame";
import {MnWorkflowView} from "./MnWorkflowView";
import {MnWorkflowUrlSync} from "./MnWorkflowUrlSync";
import {MnWorkflowComponentView} from "./MnWorkflowComponent";
import {MnStateView2} from "./MnStateView/MnStateView2";
import {MnStateViewStateJson,MnStateViewTableJson,MnStateViewRowJson} from "./MnStateView/MnStateViewJson";
import {MnStateViewDetailsChemTunes} from "./MnStateView/MnStateViewDetailsChemTunes";
import {MnStateViewToxPredictions} from "./MnStateView/MnStateViewToxPredictions";
import {MnStateViewCompareRows} from "./MnStateView/MnStateViewCompareRows";
import {MnStateViewSummaryTable} from "./MnStateView/MnStateViewSummaryTable";
import {MnStateViewAssesmentTable} from "./MnStateView/MnStateViewAssesmentTable";
import {MnStateViewChemotypeProfile} from "./MnStateView/MnStateViewChemotypeProfile";
import {MnTableColumnSelection} from './CtTable/MnTableColumnSelection'


import {ChemotypeSinglematchwidgetComponent,  ChemotypeProfileMatchComponent, ChemotypematcherComponent} from './ChemotypeProfiler/chemotypematcher.component';


import {ALL_MN_VALUE_DIRECTIVES,ALL_MN_VALUE_COMPONENTS} from "./MnValue/index";

import {
    MnActionConfiguratorView,
    MnActionConfiguratorFrame,
    MnActionConfiguratorList,
    MnActionConfiguratorListController,
    MnActionConfiguratorRowMarks,
    MnActionConfiguratorPropertyList,
    MnActionConfiguratorJsme,
    AddDataBlockForbiddenColumnName,
    ALL_ACTION_CONFIGURATORS
} from "./MnActionConfigurators/index"

import {CtCompoundDetailsDB} from "./MnStateView/CtCompoundDetailsDB"
import {CtCompoundDetailsDBPAFA} from "./MnStateView/CtCompoundDetailsDBPAFA"
import {CtCompoundDetailsDBStudy} from "./MnStateView/CtCompoundDetailsDBStudy"
import {CtCompoundDetailsDBClassification} from "./MnStateView/CtCompoundDetailsDBClassification"
import {CtCompoundDetailsDBProfile} from "./MnStateView/CtCompoundDetailsDBProfile"
import {CtCompoundDetailsDBSafetyEvaluation,CtCompoundDetailsDBSafetyEvaluationReport, CtCompoundDetailsDBUnusedPODs, CtCompoundDetailsDBAdditionalData} from "./MnStateView/CtCompoundDetailsDBSafetyEvaluation"
import {MnJsmeDepict} from "./ChemotypeProfiler/MnJsmeDepict";
import {CtCompoundDetailsDBDetailProperties} from "./MnStateView/CtCompoundDetailDBProperties";
import {CtCompoundDetailToxcastEndpointsComponent} from "./MnStateView/CtCompoundToxcast";

init_table();

let directives:any[] = [MnClickOutside,AddDataBlockForbiddenColumnName,...ALL_MN_VALUE_DIRECTIVES];
let components:any[] = [CtHelpLink,
    CtActionList,CtActionHeader,CtActionIcon, /*CtActionConfigView,*/
    NavigationTreeD3Component,BreadcrumpD3Component, ProbabilityPlotD3Component, SkylineD3Component,
    CtWorkflowNav,/*CtStateViewComponentJson,*/CtStateViewComponentSummary,CtStateViewComponentSummaryInner,/*CtStateViewComponentDetails,CtStateViewComponentLeaflet,*/
    CtAgGridCell,AgGridHeader,AgGridGroupHeader,

    MnWorkflowFrame,
    MnWorkflowUrlSync,
    MnWorkflowView,
    MnActionView,
    MnStateView,
    MnTableView,
    MnWorkflowComponentView,
    MnStateView2, MnStateViewDetailsChemTunes, MnStateView2.TabHolder, MnStateViewStateJson, MnStateViewTableJson, MnStateViewRowJson, MnStateViewToxPredictions,
    CtCompoundDetailsDB,
    CtCompoundDetailsDBPAFA,
    CtCompoundDetailsDBClassification,
    CtCompoundDetailsDBProfile,
    //CtCompoundDetailsDBSafetyEvaluation,
    CtCompoundDetailsDBSafetyEvaluationReport,
    CtCompoundDetailsDBUnusedPODs,
    CtCompoundDetailsDBAdditionalData,
    CtCompoundDetailsDBStudy,
    CtCompoundDetailsDBDetailProperties,
    CtCompoundDetailToxcastEndpointsComponent,

    MnActionConfiguratorView,
    MnActionConfiguratorFrame,
    MnActionConfiguratorList,
    MnActionConfiguratorListController,
    MnActionConfiguratorRowMarks,
    MnActionConfiguratorPropertyList,
    MnActionConfiguratorJsme,
    MnStateViewCompareRows,
    MnStateViewSummaryTable,
    MnStateViewAssesmentTable,

    MnStateViewChemotypeProfile,
    ChemotypeSinglematchwidgetComponent,
    ChemotypeProfileMatchComponent,
    ChemotypematcherComponent,

    MnTableColumnSelection,
    ...ALL_ACTION_CONFIGURATORS,
    //...ALL_TABLE,
    ...ALL,
    //...ALL_ACTION_CONFIGS,
    ...ALL_MN_VALUE_COMPONENTS,

    // will be removed once stabilized - migration to mn jsme package
    MnJsmeDepict,

];

@NgModule({
    imports: [
        CommonModule,
        FormsModule,ReactiveFormsModule,
        UIRouterModule.forChild(),
        ColorPickerModule,
        MnCoreModule,
        MnRouteModule,
        MnCommonModule,
        MnFileModule,
        MnGridModule,
        MnJsmeModule,
        //CtSearchModule,
        CtDetailsModule,
        MatTooltipModule,
        ...material_modules,
        //LeafletModule,
        //MnMaterialModule,
        //MnAnimationsModule,
    ],
    exports: components.concat(directives),
    declarations: components.concat(directives),
    entryComponents: components
})
export class CtWorkflowModule {
    constructor() {
        console.log("constructor CtWorkflowModule")
    }
    static forRoot(): ModuleWithProviders {
        return {
            ngModule: CtWorkflowModule,
            providers: []
        };
    }
}

export class ct_workflow {
    static configure():any {
        return [
            CtWorkflowModule.forRoot()
        ];
    }
}