/**
 * Created by joerg on 7/17/17.
 */

// rxjs
import 'rxjs/Rx';
import { Observable } from 'rxjs/Observable';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Subject } from 'rxjs/Subject';
import { Subscription } from 'rxjs/Subscription';

// angular
import {Injectable} from '@angular/core';


@Injectable()
export abstract class MnMarker {

    static Max:number = 9007199254740991;

    public ChangeSubject:Subject<void> = new Subject();

    public abstract setup(max:number, markable:string[], marked:string[]);
    public abstract mark(mids:string[]);
    public abstract add(mid:string);
    public abstract remove(mid:string);
    public abstract toggle(mid:string):boolean;
    public abstract has(mid:string):boolean;
    public abstract markable(mid:string):boolean;
    public abstract marks():string[];
    public abstract markables():string[];
    public abstract invert();
    public abstract all();
    public abstract none();
    public abstract active():boolean;
    public abstract count():number;
}

@Injectable()
export abstract class MnRowMarker extends MnMarker {

}

@Injectable()
export abstract class MnColMarker extends MnMarker {

}
