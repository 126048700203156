// rxjs
import 'rxjs/Rx';
import { Observable } from 'rxjs/Observable';
import { Observer } from 'rxjs/Observer';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Subject } from 'rxjs/Subject';

// mn
import {MnBackend, MnTextService} from "@mn/core";

import {Backend} from "./BackendInterfaces";
import {CtTable} from "./CtTable/CtTable";

export abstract class MnWorkflow {

    protected mTransaction:MnWorkflow.Transaction = null;

    protected mWorkflowStatus: BehaviorSubject<MnWorkflow.WorkflowStatus> = new BehaviorSubject(MnWorkflow.WorkflowStatus.Invalid);
    protected mStateStatus: BehaviorSubject<MnWorkflow.StateStatus> = new BehaviorSubject(MnWorkflow.StateStatus.Invalid);
    protected mTransitionStatus: BehaviorSubject<MnWorkflow.TransitionStatus> = new BehaviorSubject(MnWorkflow.TransitionStatus.Invalid);

    protected mWorkflow: BehaviorSubject<Backend.Workflow> = new BehaviorSubject(null);
    protected mState: BehaviorSubject<Backend.FullState> = new BehaviorSubject(null);
    protected mTransition: BehaviorSubject<Backend.FullTransition> = new BehaviorSubject(null);

    protected mTable: BehaviorSubject<CtTable> = new BehaviorSubject(new CtTable({spec:[],data:[]}, {}));
    protected mTree: BehaviorSubject<MnWorkflow.Tree> = new BehaviorSubject(null);
    protected mActions: BehaviorSubject<Backend.Action[]> = new BehaviorSubject([]);
    protected mIdentifiers: BehaviorSubject<MnWorkflow.Identifiers> = new BehaviorSubject(null);
    protected mIdentifiersExternal: Subject<MnWorkflow.Identifiers> = new Subject();

    constructor(private mBackend:MnBackend, private mText:MnTextService) {}

    // public methods

    // getters for Backend objects

    protected get backend():MnBackend {
        return this.mBackend;
    }

    public get Workflow():Backend.Workflow {
        return this.mWorkflow.getValue();
    }

    public get State():Backend.FullState {
        return this.mState.getValue();
    }

    public get Transition():Backend.FullTransition {
        return this.mTransition.getValue();
    }

    // getters for MnWorkflow objects

    public get Tree():MnWorkflow.Tree {
        return this.mTree.getValue();
    }

    public get Table():CtTable {
        return this.mTable.getValue();
    }

    public get Identifiers():MnWorkflow.Identifiers {
        return this.mIdentifiers.getValue();
    }

    public get Actions():Backend.Action[] {
        return this.mActions.getValue();
    }

    public action(title:string):Backend.Action {
        let aa = this.Actions;
        for (let i = 0, l = aa.length; i < l; i++) {
            let a = aa[i];
            if (a.title == title) {
                return a;
            }
        }
        return null;
    }

    // getters for Status information

    public get WorkflowStatus():MnWorkflow.WorkflowStatus {
        return this.mWorkflowStatus.getValue();
    }

    public get StateStatus():MnWorkflow.StateStatus {
        return this.mStateStatus.getValue();
    }

    public get TransitionStatus():MnWorkflow.TransitionStatus {
        return this.mTransitionStatus.getValue();
    }

    // getters for Status Observables

    public get WorkflowStatusObservable():Observable<MnWorkflow.WorkflowStatus> {
        return this.mWorkflowStatus;
    }

    public get StateStatusObservable():Observable<MnWorkflow.StateStatus> {
        return this.mStateStatus;
    }

    public get TransitionStatusObservable():Observable<MnWorkflow.TransitionStatus> {
        return this.mTransitionStatus;
    }

    public get WorkflowObservable():Observable<Backend.Workflow> {
        return this.mWorkflow;
    }

    public get StateObservable():Observable<Backend.FullState> {
        return this.mState;
    }

    public get TransitionObservable():Observable<Backend.FullTransition> {
        return this.mTransition;
    }

    public get TreeObservable():Observable<MnWorkflow.Tree> {
        return this.mTree;
    }

    public get TableObservable():Observable<CtTable> {
        return this.mTable;
    }

    public get IdentifiersObservable():Observable<MnWorkflow.Identifiers> {
        return this.mIdentifiers;
    }
    public get IdentifiersExternalObservable():Observable<MnWorkflow.Identifiers> {
        return this.mIdentifiersExternal;
    }

    public get ActionsObservable():Observable<Backend.Action[]> {
        return this.mActions;
    }

    // getters for ids

    public get wid(): number {
        return this.Workflow == null ? undefined : this.Workflow.id;
    }

    public get sid(): number {
        return this.State == null ? undefined : this.State.id;
    }

    // update methods

    public updateWorkflow(workflow:Backend.Workflow) {
        console.log("updateWorkflow",workflow);
        this.mWorkflow.next(workflow);
    }

    public updateState(state:Backend.FullState) {
        console.log("updateState",state);
        this.mState.next(state);
    }

    public updateTransition(transition:Backend.FullTransition) {
        console.log("updateTransition",transition);
        this.mTransition.next(transition);
    }

    public updateWorkflowStatus(status:MnWorkflow.WorkflowStatus) {
        console.log("updateWorkflowStatus",status);
        this.mWorkflowStatus.next(status);
    }

    public updateStateStatus(status:MnWorkflow.StateStatus) {
        console.log("updateStateStatus",status);
        this.mStateStatus.next(status);
    }

    public updateTransitionStatus(status:MnWorkflow.TransitionStatus) {
        console.log("updateTransitionStatus",status);
        this.mTransitionStatus.next(status);
    }

    public updateWorkflowInvalid() {
        this.updateTree(null);
        this.updateStateInvalid();
        this.updateWorkflow(null);
        this.updateWorkflowStatus(MnWorkflow.WorkflowStatus.Invalid);
    }

    public updateStateValid(workflow:Backend.Workflow, state:Backend.FullState, status:MnWorkflow.StateStatus) {
        this.updateState(state);
        this.updateActions(state.actions);
        if (state.dataset && state.dataset.table) {
            this.updateTable(new CtTable(state.dataset.table, workflow.settings, this.mText));
        } else {
            this.updateTable(null);
        }
        this.updateStateStatus(status);
    }

    public updateStateInvalid() {
        this.updateState(null);
        this.updateActions(null);
        this.updateTable(null);
        this.updateStateStatus(MnWorkflow.StateStatus.Invalid);
    }

    public updateTransitionInvalid() {
        this.updateTransition(null);
        this.updateTransitionStatus(MnWorkflow.TransitionStatus.Invalid);
    }

    public updateTree(tree:MnWorkflow.Tree) {
        console.log("updateTree",tree);
        this.mTree.next(tree);
    }

    public updateTable(table:CtTable) {
        if (table == null) {
            table = new CtTable({spec:[],data:[]}, {});
        }
        console.log("updateTable",table);
        this.mTable.next(table);
    }

    public updateTableFromState(state:Backend.FullState,workflow:Backend.Workflow) {
        if (state.dataset && state.dataset.table) {
            this.updateTable(new CtTable(state.dataset.table, workflow.settings, this.mText));
        } else {
            this.updateTable(null);
        }
    }

    public updateActions(actions:Backend.Action[]) {
        console.log("updateActions",actions);
        if (actions == null) {
            actions = [];
        } else {
            actions = MnWorkflow.ActionAdapter.transform(this.mWorkflow.getValue(),this.mState.getValue(),actions);
        }
        this.mActions.next(actions);
    }

    public updateIdentifiers(identifiers:MnWorkflow.Identifiers) {
        console.log("updateIdentifiers",identifiers);
        this.mIdentifiers.next(identifiers);
    }

    public updateIdentifiersExternal(identifiers:MnWorkflow.Identifiers) {
        console.log("updateIdentifiersExternal",identifiers);
        this.mIdentifiersExternal.next(identifiers);
    }

    public updateIdentifiersInternalExternal(identifiers:MnWorkflow.Identifiers, external:boolean = false) {
        this.updateIdentifiers(identifiers);
        if (external) this.updateIdentifiersExternal(identifiers);
    }

    // activities

    public startTransaction(transaction:MnWorkflow.Transaction, identifiers:MnWorkflow.Identifiers, options:any) {
        console.log("startTransaction",transaction,identifiers,options);
        if (this.mTransaction) {
            this.mTransaction.cancel();
        }
        this.mTransaction = transaction;
        transaction.init(
            this,
            {
                source: this.Identifiers,
                target: identifiers
            },
            options
        );
        transaction.run();

    }

    public abstract loadWorkflow(wid:number):Observable<Backend.Workflow>;
    public abstract loadState(sid:number):Observable<Backend.FullState>;
    public abstract loadTransition(tid:number):Observable<Backend.FullTransition>;
    public abstract pollTransition(tid:number,
                          next:(transition:Backend.FullTransition)=>void,
                          done:(transition:Backend.FullTransition)=>void,
                          error:(any)=>void,
                          cancel:Observable<any>);
    public abstract startTransition(action_and_config:any,
                           done:(transition:Backend.FullTransition)=>void,
                           error:(any)=>void,
    )

    public getWorkflowSetting(key:string):any {
        return this.Workflow == null ? undefined : this.Workflow.settings[key];
    }
    public addWorkflowSetting(key:string, value:any, callback:Function) {
        if (this.Workflow == null) return;
        this.Workflow.settings[key]=value;
        this.patchWorkflowSettings(callback);
    }
    public removeWorkflowSetting(key:string, callback:Function) {
        if (this.Workflow == null) return;
        delete this.Workflow.settings[key];
        this.patchWorkflowSettings(callback);
    }
    private patchWorkflowSettings(callback) {
        this.mBackend.patch(`/workflows/workflow/${this.Workflow.id}/`,{
            settings: this.Workflow.settings
        })
            .map(res=>res.json())
            .subscribe(data=>{ callback(); })
    }

    public getStateSetting(key:string):any {
        return this.State == null ? undefined : this.State.settings[key];
    }
    public addStateSetting(key:string, value:any, callback:Function) {
        if (this.State == null) return;
        this.State.settings[key]=value;
        this.patchStateSettings(callback);
    }
    public removeStateSetting(key:string, callback:Function) {
        if (this.State == null) return;
        delete this.State.settings[key];
        this.patchStateSettings(callback);
    }
    private patchStateSettings(callback) {
        this.mBackend.patch(`/workflows/state/${this.State.id}/`,{
            settings: JSON.stringify(this.State.settings)
        })
            .map(res=>res.json())
            .subscribe(data=>{ callback(); })
    }



    // cleanup

    public destroy() {
        if (this.mTransaction) {
            this.mTransaction.cancel();
        }
    }

}
export namespace MnWorkflow
{

    export enum TransitionStatus
    {
        Invalid = "TransitionStatusInvalid",  // there is no active transition
        Pending = "TransitionStatusPending",  // there is an active transition running
        Ready = "TransitionStatusReady",      // there is an active transition running
    }

    export enum StateStatus
    {
        Invalid = "StateStatusInvalid",       // current state has not yet been loaded or loading resulted in error
        Pending = "StateStatusPending",       // current state is being loaded
        Transition = "StateStatusTransition", // current state has active transition
        Ready = "StateStatusReady",           // current state has been loaded successfully
    }

    export enum WorkflowStatus
    {
        Invalid = "WorkflowStatusInvalid",  // workflow has not yet been loaded or loading resulted in error
        Pending = "WorkflowStatusPending",  // workflow is being loaded
        Ready = "WorkflowStatusReady",      // workflow has been loaded successfully
        Dirty = "WorkflowStatusDirty",      // workflow has changed and need reloading
    }

    // tree representation  of all states
    export interface Node {
        state: Backend.State;
        transition?: Backend.Transition;
        highlight: boolean;
        busy: boolean;
        children?: MnWorkflow.Node[];
    }

    export interface Tree {
        root: Node;
        linear: boolean;
    }

    export interface Identifiers {
        wid: number;
        sid: number;
        tid?: number;
        aid?: string;
    }

    export abstract class Transaction {

        protected Cancel:Subject<any> = new Subject();
        protected Workflow:MnWorkflow;
        protected Parameters:Transaction.Parameters;
        protected Options:any;

        constructor() {}

        public init(workflow:MnWorkflow, parameters:MnWorkflow.Transaction.Parameters, options:any) {
            this.Workflow = workflow;
            this.Parameters = parameters;
            this.Options = options;
        }

        public abstract run();

        public cancel() {
            console.log("cancelTransaction",this);
            this.Cancel.next(true);
        }

    }

    export namespace Transaction {
        export enum Type {
            None = "TransactionStateNone",
            Internal = "TransactionInternal",
            External = "TransactionExternal",
            Action = "TransactionAction",
        }
        export interface Parameters {
            //type: Transaction.Type;
            source: MnWorkflow.Identifiers;
            target: MnWorkflow.Identifiers;
        }
    }

    export class ActionConfiguration {
        constructor(public Action:Backend.Action, public Configuration:any, public RowMarks:string[] = [], public Run:ActionConfiguration.Runner = null) {}
        static createObservableRunner(runner:ActionConfiguration.Runner)  {
            return Observable.create((observer)=>{
                runner.init(observer);
                runner.run();
            });
        }
    }
    export namespace ActionConfiguration {
        export class Progress {
            constructor(public total:number, public current:number) {}
        }
        export type ProgressObserver = Observer<ActionConfiguration.Progress>;
        export type ProgressObservable = Observable<ActionConfiguration.Progress>;
        export abstract class Runner {
            private mObserver:any;
            constructor(private has_progress:boolean = false, private can_be_canceled:boolean = false) {}
            public get hasProgress() { return this.has_progress; }
            public get canBeCanceled() { return this.can_be_canceled; }
            public init(observer:any) { this.mObserver = observer; }
            public abstract run();
            protected progress(total:number, current:number) { this.mObserver.next(new Progress(total,current)); }
            protected error(message:string) { this.mObserver.error(message); }
            protected done() { this.mObserver.complete() }
        }
    }

    export class Registry<T> {
        private mMap:Registry.Map<T> = {};

        public register(key:string,object:T) {
            if (this.mMap[key]) {
                console.warn("Warning, ActionAdapter for key already registered",key,object);
            }
            this.mMap[key] = object;
        }

        public forEach(callbackfn: (value: T, key: string, map: any) => void): void {
            let m = this.mMap;
            for (var key in m) {
                if(!m.hasOwnProperty(key)) continue;
                callbackfn(m[key], key, m);
            }
        }

        public all():Registry.Map<T> {
            return this.mMap;
        }

        public only(key:string) {
            let result:any = {};
            if (this.mMap[key]) result[key] = this.mMap[key];
            return result;
        }

        public get(key:string) {
            return this.mMap[key];
        }
    }
    export namespace Registry {
        export interface Map<T> {
            [key:string]: T;
        }
    }

    export type Action = Backend.Action;

    export abstract class ActionAdapter {
        static Registry:Registry<ActionAdapter> = new Registry<ActionAdapter>();

        static transform(workflow:Backend.Workflow, state:Backend.FullState, actions:Action[]):Action[] {
            ActionAdapter.Registry.forEach((action_acapter:ActionAdapter)=>{
                actions = action_acapter.transform(workflow, state, actions);
            });
            return actions;
        }

        protected abstract transform(workflow:Backend.Workflow, state:Backend.FullState, actions:Action[]):Action[];
    }

}

