// angular
import {Component} from '@angular/core';
import {Backend} from "../BackendInterfaces";

//
import {CtValueView, CtValueViewRegisterDecorator} from "../CtValueView";



export interface DataSummary {
    number_of_studies: number;
    names: Backend.Name[];
}

/**
 *
 */
export class DataSummaryObject {
    public numberOfStudies: string = '';
    public preferredName: string = '';
    
    
    constructor(ds: DataSummary) {
        // see also https://angular.io/api/common/I18nPluralPipe
        // https://angular.io/api/common/NgPlural
        if (ds) {
            if (ds.number_of_studies) {
                if (ds.number_of_studies == 0) {
                    this.numberOfStudies = 'No studies';
                } else if (ds.number_of_studies == 1) {
                    this.numberOfStudies = 'One study';

                } else {
                    this.numberOfStudies = ds.number_of_studies + ' studies';
                }
            }
            this.preferredName = '';
            if (ds.names) {
                if (ds.names.length > 0) {
                    this.preferredName = ds.names[0].value;
                }
                for (const each of ds.names) {
                    const name: Backend.Name = each;
                    if (name.preferred) {
                        this.preferredName = name.value;
                        break;
                    }
                }
            }
        }
    }

}
/**
 * Summary of the data displayed by CtListNameView
 */
@Component({
    selector: 'ct-data-summary-view',
    template: `<div class='ct-value-view'>{{mValue.preferredName}}<BR>{{mValue.numberOfStudies}}</div>`

})
@CtValueViewRegisterDecorator(CtValueView.Types.DataSummary)
export class CtDataSummaryView extends CtValueView {

    mValue: DataSummaryObject ;


    constructor(service:CtValueView.Service) {
        super(service);
        this.mValue = new DataSummaryObject(null);
    }

    public update(parameters:CtValueView.Parameters) {
        this.mValue = new DataSummaryObject(<DataSummary>parameters.value);
        //console.log(this.mValue);





    }

    ngOnDestroy() {
        this.destroy();
    }
}

