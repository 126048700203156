/**
 * Created by joerg on 7/17/17.
 */

// rxjs
//import 'rxjs/Rx';
//import { Subject } from 'rxjs/Subject';

// angular
import {Component,Injector} from '@angular/core';

//
import {MnWorkflowComponentStatic} from "../MnWorkflowComponent";
import {MnStateView2} from "./MnStateView2";
import {MnWorkflow} from "../MnWorkflow";
import {MnTableFocus} from "../CtTable/MnTableFocus";
import {MnStateViewFocusView} from "./MnStateViewFocusView";
import {MnCompoundDetailsDBService} from "./CtCompoundDetailsDB";

@Component({
    selector: 'mn-state-view-details-chemtunes',
    template: `
        <ct-compound-details-db style="height: 100%; width: 100%;"></ct-compound-details-db>
    `,
    providers: [MnCompoundDetailsDBService]
})
export class MnStateViewDetailsChemTunes extends MnStateViewFocusView {

    static createStateTabs(workflow:MnWorkflow):MnStateView2.Tab[] {
        if (workflow && workflow.Workflow && workflow.State && workflow.State.dataset && workflow.State.dataset.table && workflow.State.dataset.table.data.length > 0) {
            return [{
                id: "",
                label: "Data Details",
                select: 1,
                description: null,
                closable: false
            }]
        } else {
            return [];
        }
    }

    constructor(injector:Injector, private mDetailsService:MnCompoundDetailsDBService) {
        super(injector);
    }

    protected onTableFocus(cell:MnTableFocus.Cell) {
        let row_data = this.getRowData(cell);
        if (cell != {} && row_data.details) {
            this.mData = row_data.details;
        } else {
            this.mData = {};
        }
        setTimeout(() => this.mDetailsService.change(this.mData));
    }

    ngOnDestroy() {
        super.ngOnDestroy();
    }
}
MnStateView2.Registry.register("MnStateViewDetailsChemTunes",new MnWorkflowComponentStatic(MnStateViewDetailsChemTunes));
