/**
 * Created by joerg on 7/17/17.
 */

// angular
import {Component} from '@angular/core';

// mn
import {MnBackend} from "@mn/core";

//
import {Backend} from "../../BackendInterfaces";
import {MnWorkflowComponentStatic} from "../../MnWorkflowComponent";
import {MnActionConfigurator} from "../MnActionConfigurator";
import {MnActionConfiguratorList} from "../MnActionConfiguratorList";
import {MnActionConfigurationService} from "../MnActionConfigurationService";
import {MnWorkflow} from "../../MnWorkflow";

export namespace PredictToxicity {
    export interface Configuration {
        models: any
    }
}
@Component({
    selector: 'mn-action-configurator-predict-toxicity',
    template: `
        <mn-action-configurator-frame>

            <mn-action-configurator-row-marks
                    line1
            ></mn-action-configurator-row-marks>
            <mn-action-configurator-list-controller 
                    line2
                    [label]="'Selected Properties: '+props.count()"
                    (onSelectAll)="props.selectAll()"
                    (onDeselectAll)="props.deselectAll()"
            ></mn-action-configurator-list-controller>
            <mn-action-configurator-list
                    scroll
                    #props
                    line3
                    [items]="mModels"
                    (selection)="onModelSelection($event)"
            ></mn-action-configurator-list>
            
        </mn-action-configurator-frame>
     `,
    styles: []
})
export class PredictToxicity extends MnActionConfigurator {

    private mModels:MnActionConfiguratorList.Item[];
    private mConfiguration:PredictToxicity.Configuration = {
        models: {}
    };

    constructor(private mBackend:MnBackend, acs:MnActionConfigurationService) {
        super(acs);
        this.mBackend.get('/predict/ModelTree/?format=json')
            .map(data => data.json())
            .takeUntil(this.onDestroy)
            .subscribe(data => {
                console.log('CtPredictToxicityConfig',data);
                let items = [];
                for (let i = 0, l = data.length; i < l; i++) {
                    let item = data[i];
                    items.push({
                        group: true,
                        label: item.name,
                    });
                    for (let j = 0, ll = item.children.length; j < ll; j++) {
                        let group = item.children[j];
                        for (let k = 0, lll = group.children.length; k < lll; k++) {
                            let model = group.children[k];
                            items.push({
                                option: true,
                                label: model.label,
                                help: this.getHelp(item,group,model),
                                selected: false,
                                value: group,
                            })
                        }
                    }
                }
                this.mModels = items;
                console.log(items);
            });
        this.enable(false);
    }

    private getHelp(item,group,model) {
        //console.log(item,group,model);
        let i = item.name.trim().replace(/ |#/gi, "_");
        let g = group.name.trim().replace(/ |#/gi, "_");
        let m = model.label.trim().replace(/ |#/gi, "_");

        /*console.log(`calc.${i}.${g}.${m}`)
        console.log('calc.Genetic_Toxicity.In_Vivo&nbsp;Micronucleus&nbsp;.In_Vivo&nbsp;Micronucleus&nbsp;__1')
        return 'calc.Genetic_Toxicity.In_Vivo&nbsp;Micronucleus&nbsp;.In_Vivo&nbsp;Micronucleus&nbsp;__1'*/

        return `calc.${i}.${g}.${m}`;
    }

    protected onInit(configuration:any) {
        //this.mConfiguration.properties = configuration.properties ? configuration.properties : [];
    };

    protected onStart() {
        this.finish(this.mConfiguration);
    };

    onModelSelection(v) {
        console.log(v);
        this.enable(v.length > 0);

        let models = {};
        v.map(item => item.children[0])
            .forEach((model)=>{
                models[model.id] = model.logic_id;
            });
        this.mConfiguration.models = models;
        console.log(models);
    }

    ngOnDestroy() {
        super.destroy()
    }
}
MnActionConfigurator.Registry.register("PREDICT_TOXICITY",new MnWorkflowComponentStatic(PredictToxicity));
