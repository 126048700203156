export namespace Backend {

    // Timestamp
    export interface Timestamp {
        created: string;                 // when something was created
        changed: string;                 // when it was changed last
    }

    // Task
    export interface Task {
        state: Task.State;               // task sate
        message: string;                 // task message
    }
    export namespace Task {
        export enum State
        {
            Success = "SUCCESS",
            Failure = "FAILURE",
            Revoked = "REVOKED",
            Started = "STARTED",
            Received = "RECEIVED",
            Rejected = "REJECTED",
            Retry = "RETRY",
            Pending = "PENDING"
        }
    }

    // FullTask
    export interface FullTask extends Task {
        log: string;                     // task sate
    }

    // State - short version
    export interface State extends Timestamp {
        id: State.Id;                    // id
        url: string;                     // url to load the full State
        size: number;                    // number or records in state
        title: string;                   // state title token
        active: boolean;                 // wether the state is active or not
        settings: any;                   // settings
        sertial: number;                 // serial number if sorting of states is required
    }
    export namespace State {
        export type Id = number;
    }
    export interface StateMap {
        [key:number]:State
    }

    // Transition
    export interface Transition {
        id: Transition.Id;               // id
        url: string;                     // url to the full Transition
        source: State.Id;                // source state id
        target: State.Id;                // target state id
        task: Task;                      // Task that was/is performed during the transition
        title: string;                   // transition title token
    }
    export namespace Transition {
        export type Id = number;
    }
    export interface TransitionMap {
        [key:string]: Transition
    }

    // Table
    export interface Table {
        spec: Table.Node[];               // table columns specification
        data: any[];                      // table data
    }
    export namespace Table {
        export interface Node {
            ident: string;                // identifier to select getter / renderer when data type is not sufficient
            asis?: boolean;               // wether to translage ident
            type: string;                 // raw data type
            key?: string;                 // key to data
            error?: string;               // key to error information
            editable?: boolean;           // key to error information
            children?: Node[];            // children
        }
    }

    // Dataset
    export interface Dataset {
        id: Dataset.Id;                   // Dataset id
        url: string;                      // url to the Dataset
        title: string;                    // User defined title?
        description: string;              // User defined description
        comment: string;                  // User defined comments -> change to comments
        owner: string;                    // User who created to dataset
        created: string;                  // creation date
        //changed: string;                // -> maybe in the future
        temporary: boolean;               // temporary flag
        read_only: boolean;               // read only flag
        size: number;                     // number of records
        table: Table;                     // table with all the data
        context: string;                  // context ???
        annotation_uuid: string;          // for annotation purposes
    }
    export namespace Dataset {
        export type Id = number;
    }

    // Workflow
    export interface Workflow extends Timestamp {
        id: Workflow.Id;                  // Workflow id
        root: State.Id;                   // root state
        states: StateMap;                 // all states as map
        transitions: TransitionMap;       // all transitions as map
        path: any[]                       // maybe remove
        branch: any[]                     // maybe remove
        active_state: State.Id            // id of active state
        active_transition: Transition.Id; // change in backend to id
        settings: any;                    // settings
        temporary: boolean;               // temporary flag
        annotation_uuid: string;          // for annotation purposes
        title: string;                    // user defined title?
        context: string;                  // context ???
        comments: string;                 // user defined comments
        workflow_type: string;            // workflow type ???
        owner: string;                    // user who created the workflow
        grants: any[]                     // users the workflow is shared with
    }
    export namespace Workflow {
        export type Id = number;
    }

    // Action
    export interface Action {
        id: Action.Uuid;                  // unique action identifier
        config_template: any;             // ???
        settings: any;                    // settings ???
        title: string;                    // action title token
        category: string;                 // ???
        comments: string;                 // ???
        task_class: string;               // internally used by backend, does it need to be exposed here?
        needs_data: boolean;              // ???
        can_continue: boolean;            // ???
        interactive: boolean;             // ???
        needs_config: boolean;            // ???
        data?:any                         // reserved for frontend use, not provided by the backend
    }
    export namespace Action {
        export type Uuid = string;
    }

    // FullTransition = Transition with all data
    export interface FullTransition extends Transition, Timestamp { // move timestamp to Transition ???
        specification: any;               // parameters for the transition
        action_url: string;               // url to action that performed the transition
        action: Action.Uuid;              // uuid of the action that performed the transition
        comments: string;                 // comments
        task: FullTask;                   // <- needs to be reflected in the backend
    }

    // FullState = State with all data
    export interface FullState extends State {
        transition: FullTransition;       // transition that lead to this state
        finalized: boolean;               // true, when an transition into this state is still running
        dataset: Dataset;                 // dataset with all the state data
        actions: Action[];                // applicable actions from this state
        annotation_uuid: string;          // for annotation purposes
        comments: string;                 // comments
    }

    // added by BB
    // A compound name with meta data
    export interface Name {
        value: string; //the name it self
        value_type: string; // e.g. "BETA-CAROTENE"
        source: string; // e.g. ""US FDA CFSAN THESAURUS""
        serial: number; // e.g. 0
        visible: boolean;
        preferred: boolean;
        harvested_from: string;
    }
}