/**
 * Created by joerg on 7/17/17.
 */

// angular
import {Component,Input,Output,EventEmitter,HostListener} from '@angular/core';

// uirouter
import {Transition,StateService, TransitionService} from '@uirouter/angular';

// mn
import {MnBrandService, MnTextService} from "@mn/core";

//
import {Backend} from "./BackendInterfaces";

interface Action {
    lines:string[];
    fa?:string;
    svg?:string;
    action:Backend.Action;
}

interface Category {
    title:string;
    actions:Action[];
}

@Component({
    selector: 'ct-action-list',
    templateUrl: './CtActionList.html',
    styleUrls: ['./CtActionList.css']
})
export class CtActionList {

    @Input()
    get actions():Backend.Action[] {
        return this.mActions;
    }
    set actions(v:Backend.Action[]) {
        this.mActions = v;
        this.updateCategories();
    }
    private mActions:Backend.Action[] = [];
    private mCategories:Category[];


    @Output() actionSelect = new EventEmitter<Backend.Action>();

    constructor(private mBrand:MnBrandService, private mText:MnTextService) {
    }

    private updateCategories() {
        let cats:Category[] = [];
        let catsm:any = {};
        let icons:any = this.mBrand.brand().data.ct.action.icons;
        for (let i = 0, l = this.mActions.length; i < l; i++) {
            let a:Backend.Action = this.mActions[i];
            if (catsm[a.category] == undefined) {
                catsm[a.category] = { title: this.mText.text('ct.action.category.'+a.category), actions: [] };
            }
            let line:string = <string> this.mText.text('ct.action.title.'+a.title);
            let aa:Action = {
                lines: line.split('<br>'),
                action: a,
            };
            if (icons[a.title] ) {
                if (icons[a.title].fa) {
                    aa.fa = icons[a.title].fa
                }
                else if (icons[a.title].svg) {
                    aa.svg = icons[a.title].svg
                }
            }
            catsm[a.category].actions.push(aa);
        }
        for (var k in catsm) {
            if(!catsm.hasOwnProperty(k)) continue;
            let c:Category = catsm[k];
            cats.push(c);
        }
        this.mCategories = cats;
    }

    private onAction(event,action:Backend.Action) {
        console.log(action);
        this.actionSelect.emit(action);
    }

    ngOnDestroy() {
    }
}
