//BB


// angular
import {Component} from '@angular/core';

//
import {CtValueView, CtValueViewRegisterDecorator} from "../CtValueView";

import {CtComplexValueView, CtComplexValueComponentDecorator} from "./CtComplexValueView";

let div_open = "<div class='ct-value-view'>";
let div_close = "</div>";

/**
 * Display a list of string. Subclass of CtComplexValueView because it uses formated text as inner HTML
 *
 */

@CtComplexValueComponentDecorator('ct-list-string-view')
@CtValueViewRegisterDecorator(CtValueView.Types.ListString)
export class CtListStringView extends CtComplexValueView {

    //every component need to redefine the constructor from the base class
    constructor(service:CtValueView.Service) {
        super(service);
    }

    protected update(parameters:CtValueView.Parameters) {
        const mValue = parameters.value;

        if (mValue) {
            this.mvalueAsHtml = div_open+mValue.join('<br>')+div_close;
        }

        // material tooltip does not support innerHtml or multiline
        if (this.mvalueAsHtml.length > 10) {
            this.tooltip = mValue.join(', ');
        }
    }

    ngOnDestroy() {
        this.destroy();
    }

}

