/**
 * Created by joerg on 7/17/17.
 */

// rxjs
import 'rxjs/Rx';
import { Observable } from 'rxjs/Observable';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Subject } from 'rxjs/Subject';
import { Subscription } from 'rxjs/Subscription';


// angular
import {Component,Input,Output,EventEmitter,HostListener,Injectable,Optional,ChangeDetectionStrategy} from '@angular/core';

// mn
import {MnBrandService, MnTextService} from "@mn/core";

//
import {Backend} from "../BackendInterfaces";

import {CtTable} from "../CtTable/CtTable"
import {MnSelectionService} from "../MnSelectionService";
import {MnSelectionObserver} from "../MnSelectionObserver";
import {MnWorkflow} from "../MnWorkflow";
import {MnWorkflowComponent} from "../MnWorkflowComponent";
import {MnActionConfigurationService} from "./MnActionConfigurationService";
import {MnActionConfigurationObserver} from "./MnActionConfigurationObserver";

export abstract class MnActionConfigurator extends MnActionConfigurationObserver {

    static Registry:MnWorkflow.Registry<MnWorkflowComponent> = new MnWorkflow.Registry<MnWorkflowComponent>();

    constructor(mActionConfiguratorService:MnActionConfigurationService) {
        super(mActionConfiguratorService);
    }

    // implemented empty - used by MnActionConfiguratorView
    protected onFinish(v:MnWorkflow.ActionConfiguration) {}
    protected onEnable(enabled:boolean) {}
    protected onEnableRowMarks(enabled:boolean) {}

    protected destroy() {
        super.destory();
    }
}

