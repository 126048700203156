/**
 * Created by joerg on 7/17/17.
 */

// rxjs
//import 'rxjs/Rx';
//import { Subject } from 'rxjs/Subject';

// angular
import {Component,Injector} from '@angular/core';

import {ColorPickerService,Rgba} from 'ngx-color-picker';

// mn
import {MnBrandService} from "@mn/core";

//
import {MnWorkflowComponentStatic} from "../MnWorkflowComponent";
import {MnStateView2} from "./MnStateView2";
import {MnWorkflow} from "../MnWorkflow";
import {CtTable} from "../CtTable/CtTable";
import {MnStateViewRowMarkerView} from "./MnStateViewRowMarkerView";
import {CtImageView} from "../CtValueViews/CtImageView";
import {CtValueView} from "../CtValueView";
import {SimilaritiesDistances } from '../../../utils/sim_dist';

import {MnValueHtml} from "../MnValue/MnValueHtml";




let colorPicker = (key:string,position:string) => {
    return `<input 
            class="colorInput" 
            cpOutputFormat='rgba' 
            cpAlphaChannel="disabled"
            cpPosition="bottom" 
            [(colorPicker)]="m${position}Colors.${key}_hex" 
            (colorPickerChange)="onColorChange($event,m${position}Colors,'${key}')"
            [style.background]="m${position}Colors.${key}_hex"/>`
};



function round(number, precision) {
    var shift = function (number, precision) {
        var numArray = ("" + number).split("e");
        return +(numArray[0] + "e" + (numArray[1] ? (+numArray[1] + precision) : precision));
    };
    return shift(Math.round(shift(number, +precision)), -precision);
}
@Component({
    selector: 'mn-state-view-compare-rows',
    template: `
        <div class="selector">
            <mat-form-field line3>
                <mat-select [(value)]="mLower" (valueChange)="update()" placeholder="Lower Triangle">
                    <mat-option *ngFor="let lower of mOutsides" [value]="lower">{{lower.label}}</mat-option>
                </mat-select>
            </mat-form-field>
            ${colorPicker('min','Lower')}
            <i class="fas fa-caret-right"></i>
            ${colorPicker('max','Lower')}
        </div>

        <div class="selector">
            <mat-form-field line3>
                <mat-select [(value)]="mUpper" (valueChange)="update($event)" placeholder="Upper Triangle">
                    <mat-option *ngFor="let upper of mOutsides" [value]="upper">{{upper.label}}</mat-option>
                </mat-select>
            </mat-form-field>
            ${colorPicker('min','Upper')}
            <i class="fas fa-caret-right"></i>
            ${colorPicker('max','Upper')}
        </div>

        <div class="selector">
            <mat-form-field line3>
                <mat-select [value]="mDiagonal" (valueChange)="updateDiagonal($event)" placeholder="Diagonal">
                    <ng-container *ngFor="let diagonal of mDiagonalHelpers">
                        <mat-option style="font-size: 12px" [value]="diagonal.node" [innerHtml]="diagonal.header"></mat-option>
                    </ng-container>
                </mat-select>
            </mat-form-field>
        </div>

       <div class="selector"> 
            <mat-slider
                    [max]="300"
                    [min]="50"
                    [step]="1"
                    [tick-interval]="false"
                    [(ngModel)]="mSize"
                    (ngModelChange)="onSize($event)"
            ></mat-slider>
        </div>

        <div class="crTable">
            <div class="crTableRow" *ngFor="let row of Rows; let row_index = index">
                <div class="crTableCell" *ngFor="let col of Rows; let col_index = index; let col_last = last">
                    <mn-value-html-view 
                            class="crTableCellWrapper"
                            [ngStyle]="{ 'width.px': mSize, 'height.px': mSize }"
                            *ngIf="row_index == col_index && getConfig(row_index,col_index).html"
                            [valueHtml]="getConfig(row_index,col_index).html"
                        [value]="getConfig(row_index,col_index).value"
                    >
                    </mn-value-html-view>
                    <ct-state-view-component-summary-inner [ngStyle]="{ 'width.px': mSize, 'height.px': mSize }" *ngIf="row_index == col_index && getConfig(row_index,col_index).comp" [config]="getConfig(row_index,col_index)"></ct-state-view-component-summary-inner>
                    <div *ngIf="row_index != col_index" class="crTableCellOutside" 
                         [ngStyle]="getStyle(row_index,col_index)"
                    ><div class="crTableCellOutsideValue">{{getConfig(row_index,col_index).value}}</div></div>
                 </div>
           </div>
        </div>
        

    `,
    styles: [`
        :host {
            overflow: auto;
            padding: 16px;
            display: block;
            height: 100%;
            width: 100%;
        }
        .selector {
            display: inline-block;
            margin-right: 16px;
            white-space: nowrap
        }
        .colorInput {
            height: 22px;
            width: 22px;
            margin-right: 4px;
            border: 1px solid darkgrey;
        }
        .fas.fa-caret-right {
            margin-right: 2px;
        }
        .crTable {
            display: table;
            table-layout: fixed;
        }
        .crTableRow {
            display: table-row;
        }
        .crTableCell {
            display: table-cell;
            border: 1px solid white;
            position: relative;
        }
        .crTableCellLast {
            display: table-cell;
           width: 16px;
        }
        .crTableCellWrapper {
            border: 1px solid darkgrey;
            position: absolute;
            display: inline-block;
        }
        .crTableCellOutside {
            position: relative;
        }
        .crTableCellOutsideValue {
            font-weight: bold;
            position: absolute;
            left: 50%;
            top: 50%; 
            transform: translate(-50%, -50%);
        }
        .crTableBody {
            display: table-row-group;
        }
    `]
})
export class MnStateViewCompareRows extends MnStateViewRowMarkerView {

    static createStateTabs(workflow:MnWorkflow):MnStateView2.Tab[] {
        if (MnBrandService.raw().data.MnStateViewCompareRows &&
            workflow &&
            workflow.Workflow &&
            workflow.State &&
            workflow.State.dataset &&
            workflow.State.dataset.table &&
            workflow.State.dataset.table.data.length > 0
        ) {
            return [{
                id: "",
                label: "Compare Compounds",
                select: 1,
                description: null,
                closable: false
            }]
        } else {
            return [];
        }
    }

    mSize:number = 150;
    mEmpty:MnStateViewCompareRows.Comparator = new MnStateViewCompareRows.EmptyComparator();
    mDiagonal:CtTable.Node = null;
    mDiagonals:CtTable.Node[] = null;
    mDiagonalHelpers:MnStateViewCompareRows.NodeHelper[] = null;

    mOutsides:MnStateViewCompareRows.Comparator[] = [];
    mLower:MnStateViewCompareRows.Comparator = this.mEmpty;
    mUpper:MnStateViewCompareRows.Comparator = this.mEmpty;

    mLowerRange:MnStateViewCompareRows.Range = new MnStateViewCompareRows.Range();
    mUpperRange:MnStateViewCompareRows.Range = new MnStateViewCompareRows.Range();

    mLowerColors:MnStateViewCompareRows.Colors;
    mUpperColors:MnStateViewCompareRows.Colors;

    mLowerStyler = new MnStateViewCompareRows.TanimotoStyler();
    mUpperStyler = new MnStateViewCompareRows.TanimotoStyler();

    mConfigs = [];

    constructor(injector:Injector, private mCPS:ColorPickerService) {
        super(injector);
        this.mLowerColors = new MnStateViewCompareRows.Colors(new Rgba(255,128,128,1),new Rgba(128,0,0,1),mCPS);
        this.mUpperColors = new MnStateViewCompareRows.Colors(new Rgba(128,128,255,1),new Rgba(0,0,128,1),mCPS);
    }

    ngAfterContentInit() {
        let structure_col:CtTable.Node[] = this.mWorkflowService.Table.findNodes((node) => {
            if (node.type == CtTable.ColumnType.Image && node.ident == 'IMAGE_URL') {
                return true;
            }
            return false;
        });
        if (structure_col.length > 0) {
            this.mDiagonal = structure_col[0];
        }
        this.mDiagonals = this.mWorkflowService.Table.getFlatNodes();
        this.mDiagonalHelpers = this.mDiagonals.map((node)=>{
            return new MnStateViewCompareRows.NodeHelper(node);
        });


        /*this.mOutsides = this.mWorkflowService.Table.findNodes((node) => {
            if (node.type == CtTable.ColumnType.Float || node.type == CtTable.ColumnType.Int) {
                return true;
            }
            return false;
        }).map((node) => {
            return new MnStateViewCompareRows.Comparator(
                "Difference " + node.headerName,
                node,
                (v1,v2) => Math.abs(v1-v2)
            );
        });*/

        const sd:SimilaritiesDistances = new SimilaritiesDistances();
        this.mOutsides = this.mWorkflowService.Table.findNodes((node) => {
            return node.type == CtTable.ColumnType.StringFingerprint;
        }).map((node) => {
            return new MnStateViewCompareRows.Comparator(
                "Tanimoto " + node.headerName,
                node,
                (v1,v2) => (v1 && v2 && v1.length && v2.length && v1.length == v2.length ) ? sd.tanimotoBinarySimilarity(v1, v2): undefined //in the GUI, undefined is shown as NaN
            );
        });
        this.mOutsides.push( this.mEmpty );
        this.mLower = this.mOutsides[0];
        if (this.mOutsides.length > 1) {
            this.mUpper = this.mOutsides[1];
        } else {
            this.mUpper = this.mOutsides[0];
        }
    }

    private onColorChange(rgbastring:string,colors:MnStateViewCompareRows.Colors,field:string) {
        let rgbs:number[] = rgbastring
            .replace('rgb(','')
            .replace(')','')
            .split(',')
            .map(v => parseInt((v)));
        colors[field].r = rgbs[0];
        colors[field].g = rgbs[1];
        colors[field].b = rgbs[2];
        colors.updateDelta();
        this.update();
    }

    protected onRowMarker() { this.update() }

    private updateDiagonal(value) {
        this.mDiagonal = value;
        this.update();
    }

    private onSize(value) {
        this.mSize = value;
        this.update();
    }

    private update() {
        this.mLowerRange = new MnStateViewCompareRows.Range();
        this.mUpperRange = new MnStateViewCompareRows.Range();
        this.mConfigs = [];
        let diagonal_html = MnValueHtml.Registry.get(this.mDiagonal.type);
        console.log('diagonal_html',diagonal_html,MnValueHtml.Registry);
        for (let r = 0, rl = this.Rows.length; r < rl; r++) {
            let row = this.Rows[r];
            let row_config = [];
            for (let c = 0, cl = this.Rows.length; c < cl; c++) {
                let col = this.Rows[c];
                if (r == c) {
                    if (diagonal_html) {
                        row_config.push({
                            value: this.mDiagonal? {
                                    data: this.mDiagonal.getter(row),
                                    error: null
                                } : {
                                    data: null,
                                    error: null
                                },
                            size: { height: this.mSize, width: this.mSize },
                            html:diagonal_html
                        })
                    } else {
                        row_config.push({
                            value: this.mDiagonal? this.mDiagonal.getter(row) : null,
                            size: { height: this.mSize, width: this.mSize },
                            comp: CtValueView.Registry[this.mDiagonal.type]
                        })
                    }
                } else if (r > c) {
                    let v = this.mLower.compare(row,col);
                    this.mLowerRange.update(v);
                    row_config.push({
                        value: v,
                        style: {},
                    })
                } else {
                    let v = this.mUpper.compare(row,col);
                    this.mUpperRange.update(v);
                    row_config.push({
                        value: v,
                        style: {},
                    })
                }
            }
            this.mConfigs.push(row_config);
        }
        for (let r = 0, rl = this.Rows.length; r < rl; r++) {
            for (let c = 0, cl = this.Rows.length; c < cl; c++) {
                if (r > c) {
                    this.mLowerStyler.style(this.mConfigs[r][c],this.mLowerRange,this.mLowerColors,this.mSize);
                } else if (r < c) {
                    this.mUpperStyler.style(this.mConfigs[r][c],this.mUpperRange,this.mUpperColors,this.mSize);
                }
                //console.log(this.mConfigs[r][c].style );
            }
        }
    }

    getStyle(r,c) {
        return this.mConfigs[r][c].style;
    }

    getConfig(row_index:number, col_index:number) {
        return this.mConfigs[row_index][col_index];
    }

    onDiagonal($event:any) {
        this.onRowMarker();
    }

}
export namespace MnStateViewCompareRows {
    export class NodeHelper {
        public header:string;
        constructor(public node:CtTable.Node, public children:CtTable.Node[]) {
            this.header = node.headers.filter((header:string)=> {
                return header.trim().length > 0;
            }).join("&nbsp;<i class='fas fa-caret-right'></i>&nbsp;")
        }
    }
    export class Range {
        constructor(public min:number = null, public max:number = null) {}
        public update(value:number) {
            if (value == null) {
                return;
            }
            if (this.min == null || value < this.min) {
                this.min = value;
            }
            if (this.max == null || value > this.max) {
                this.max = value;
            }
        }
    }
    export class Colors {
        public min_hex:string;
        public max_hex:string;
        public delta:Rgba = new Rgba(0,0,0,1);
        constructor(public min:Rgba, public max:Rgba,private cps:ColorPickerService) {
            this.min_hex = cps.rgbaToHex(min);
            this.max_hex = cps.rgbaToHex(max);
            this.updateDelta();
        }
        public updateDelta() {
            this.delta.a = this.max.a - this.min.a;
            this.delta.g = this.max.g - this.min.g;
            this.delta.b = this.max.b - this.min.b;
        }
    }
    export interface OutsideCell {
        value: number;
        style: any;
    }
    export type Matrix = (any|OutsideCell)[][];
    export abstract class Styler {
        public abstract Scales:string[];
        public Scale:string;
        public abstract style(cell:OutsideCell,range:Range,colors:Colors,size:number):any;
    }

//                         let vv = Math.round(128+(60*(v-this.mUpperRange.min)/(this.mUpperRange.max - this.mUpperRange.min)));

    export class TanimotoStyler extends Styler {
        public Scales:string[] = ['relative','absolute'];
        public Scale:string = this.Scales[1];
        public style(cell:OutsideCell,range:Range,colors:Colors,size:number) {
            if (cell.value !== null) {
                let v = cell.value;
                if (this.Scale == this.Scales[0]) {
                    v = (v - range.min) / (range.max - range.min);
                }
                let iCol = (colors:Colors,key:string,value:number)=>{
                    return Math.floor(colors.min[key] + (colors.delta[key]*v) );
                };
                cell.style = {
                    'background-color': `rgb(${iCol(colors,'r',v)},${iCol(colors,'g',v)},${iCol(colors,'b',v)})`,
                    'width.px': size,
                    'height.px': size,
                };
                cell.value = round(v,2);
            } else {
                cell.style = {};
            }
        }
    }
    export class Comparator {
        constructor(public label:string, public node:CtTable.Node, public func:(v1:any,v2:any)=>number) {}
        public compare(row1:any,row2:any):number {
            return this.func(this.node.getter(row1),this.node.getter(row2));
        }
    }
    export class EmptyComparator extends Comparator {
        constructor() { super("None",null,null) }
        public compare(row1:any,row2:any):number { return null; }

    }
}
MnStateView2.Registry.register("MnStateViewCompareRows",new MnWorkflowComponentStatic(MnStateViewCompareRows));
