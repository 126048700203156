/**
 * Created by joerg on 7/17/17.
 */

// rxjs
import 'rxjs/Rx';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';

// angular
import {Injectable} from '@angular/core';
import {CtTable} from "./CtTable";

@Injectable()
export class MnTableFocus {

    public ChangeSubject:BehaviorSubject<MnTableFocus.Cell> = new BehaviorSubject({row:"",col:""});

    public set(cell:MnTableFocus.Cell) {
        this.ChangeSubject.next(cell);
    }
    public get():MnTableFocus.Cell {
        return this.ChangeSubject.getValue();
    }

}
export namespace MnTableFocus {
    export interface Cell {
        row:string;
        col:string;
    }
}


