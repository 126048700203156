export class Aggregate<K, V> {
  constructor( public key: K, public values: V[]) {}


}


/**
 * keys must be an object taht a Javascript dictionary can hold
 * thus K must be int or string
 */

export abstract class DataAggregator<T, V> {

  protected abstract getKeyValue( d: T): string; //string is needed because the implementation uses dictionaries

  protected abstract getOtherValue(d: T): V;

  /**
   * Used to remove duplicated values, e.g. string.
   * Default: do not remove duplicates (always return false
   * @param {V} v1
   * @param {V} v2
   * @returns {boolean}
   */
  protected valueIsEqual(v1: V, v2: V) {
    return false;
  }

  // constructor should provide sort methods for both the keys and values



  /**
   * one key can have more than one additional values, aggregation is needed.
   * Example : compounds name and the list of sources that cite the name
   * @returns {Aggregate[]}
   */
  public generateParts(data: T[], sortKeys: boolean = false, sortValues: boolean = false): Aggregate<string,V>[] {
    /* #AT python implementation

            parts = []
            if list_type == "list.name":
                all_names = {}
                for name in data:
                    all_names.setdefault(name["value"], set())
                    if name["source"]:
                        all_names.setdefault(name["value"], set()).add(name["source"])
                for name, sources in sorted(all_names.items(), key=lambda x: x[0]):
                    parts.extend([
                        normal,
                        name,
                        " (",
                        italic,
                        ", ".join(sorted(sources)),
                        normal,
                        ")\n"
                    ])
                if parts:
                    parts.append(normal)

     */

    // one could have used the Map and Set from ES6

    if (data == undefined || !data.length) {
      return [];
    }

    const keyToOtherValuesDict = {};

    const orderedKeys: string[] = [];  // to keep the keys original order
    for (let each of data) {
      const key: string = this.getKeyValue(each);

      if(! (key in keyToOtherValuesDict)) {
        keyToOtherValuesDict[key] = [];
        orderedKeys.push(key);
      }

    }


    for (let each of data) {
      const values : V[] = keyToOtherValuesDict[this.getKeyValue(each)];
      const newValue : V = this.getOtherValue(each);
      if (! values.find((v:V)=>this.valueIsEqual(v, newValue))) { // avoid duplicates
        values.push(newValue);
      }
    }

    let keys: string[] = orderedKeys;
    if( sortKeys ) {
      keys.sort();
    }

    const parts: Aggregate<string, V>[] = [];

    for (let k of keys) {
      let others: V[] = keyToOtherValuesDict[k];
      if(sortValues)
        others.sort();
      parts.push(new Aggregate(k, others));
    }

    return parts;


  }
}


/**
 * Aggregator that allows to provide key and value functions
 */
export  class MakeGroups<T, V> extends DataAggregator<T, V> {
  public getKeyValueFunction: (d:T)=>string ;
  public getOtherValueFunction: (d:T)=>V ;
  public isSameValue: (d1:V, d2:V)=>boolean;


  constructor(getKey: (d:T)=>string, getVal: (d:T)=>V, isSame: (d1:V, d2:V)=>boolean) {
    super();
    this.getKeyValueFunction = getKey;
    this.getOtherValueFunction = getVal;
    this.isSameValue = isSame;

  }
  protected  getKeyValue( d: T): string { //string is needed because the implementation uses dictionaries
    return this.getKeyValueFunction(d);
  }

  protected  getOtherValue(d: T): V {
    return this.getOtherValueFunction(d);
  }
  protected valueIsEqual(v1: V, v2: V) {
        if(this.isSameValue) {
          return this.isSameValue(v1, v2);
        }
        return super.valueIsEqual(v1, v2);
  }


}

/**
 * TODO: show an example here
 * @param {T[]} data
 * @param {(d: T) => string} getKey
 * @param {(d: T) => V} getVal
 * @param {boolean} sortKeys
 * @param {boolean} sortValues
 * @returns {Aggregate<string, V>[]}
 * @constructor
 */
export function GroupBy<T,V>(data: T[], getKey: (d:T)=>string, getVal: (d:T)=>V,  sortKeys: boolean = false, sortValues: boolean = false, isSameValue: (d1:V, d2:V)=>boolean = null): Aggregate<string,V>[]  {

  const mg : MakeGroups<T, V>= new MakeGroups<T, V>(getKey, getVal, isSameValue);

  return mg.generateParts(data, sortKeys, sortValues);

}
