// rxjs
import {Subscription} from 'rxjs/Rx';

// angular
import {Component,Input,HostListener,ViewChildren,QueryList,Output,EventEmitter} from "@angular/core"


import {MnHelpPath, MnHelpService} from '@mn/help';

@Component({
    selector: 'ct-help-link',
    template: `
             <span> ? </span>
     `,
    styles: [
        `
            :host {
                font-weight: bold !important;
                border-radius: 50% !important;
                border: 1px solid transparent !important;
                display: inline-block !important;      
                text-align: center;  
                width: 16px;
                height: 16px;
            }
            :host:hover {
                color: white !important;
                background: darkgrey !important;
            }                
         `
    ]
})
export class CtHelpLink {

    private mPath:MnHelpPath = ['test'];
    @Input()
    get path() { return this.mPath; }
    set path(v: MnHelpPath) {
        this.mPath = this.mHelp.normalizePath(v);
    }

    @HostListener('click', ['$event']) onClick(e) {
        this.mHelp.request(this.mPath);
        e.stopPropagation();
    }

    constructor(private mHelp:MnHelpService) {}

    onSelect($event) {
        console.log($event);
    }
}