export class FakeChemotypeProfiles {


    /**
     * For MnActionConfiguratorLists
     * @returns {any[]}
     */
    public getProfilesDefinitions() : any[] {
        // fake data
        const data = [
            {
                "id": 10,
                "provider": "MOSES3",
                "display_name": "DNA&Protein binders",
                "cm_logic_id": "com.mn-am.chemotype.DPB",
                "is_tested": false,
                "test_success": false,
                "test_message": "",
                "test_time": null,
                "license_info": "n/a",
                "is_enabled": false,
                "result_data_type": "int",
                "access_users": [],
                "access_groups": []
            },
            {
                "id": 20,
                "provider": "MOSES3",
                "display_name": "LJMU liver alerts",
                "cm_logic_id": "com.mn-am.chemotype.LJMULA",
                "is_tested": false,
                "test_success": false,
                "test_message": "",
                "test_time": null,
                "license_info": "n/a",
                "is_enabled": false,
                "result_data_type": "int",
                "access_users": [],
                "access_groups": []
            }
        ];

        return data;
    }

}