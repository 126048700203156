/**
 * Created by joerg on 7/17/17.
 */

// rxjs
import 'rxjs/Rx';
import { Subject } from 'rxjs/Subject';

// angular
import {Component,Input,Output,EventEmitter,ViewChild} from '@angular/core';
import {MnBackend} from "@mn/core";
import {CtTable} from "./CtTable";
import {GenericNode} from "../../../utils/generic-node";

@Component({
    selector: 'mn-table-column-selection',
    template: `
<mat-selection-list #list dense (selectionChange)="onChange($event)" style="outline: none;">
    <ng-template #nodeItem let-item>
    </ng-template>
    <ng-template #recursiveList let-list let-depth="depth">
        <ng-container *ngFor="let item of list">
            <mat-list-option
                    *ngIf="!item.children || item.children.length == 0"
                    [value]="item"
                    [selected]="mSelectedNodesIds.indexOf(item.id) >= 0"
                    style="height: 30px"
                    class="filter_grayscale">
                    <span>
                        <span [innerHtml]="item.header"></span>&nbsp;&nbsp;
                        <ct-help-link *ngIf="item.help" [path]="item.help"></ct-help-link>
                    </span>
            </mat-list-option>
            <ng-container *ngIf="item.children && item.children.length > 0">
                <h3 mat-subheader style="height: 30px" [ngStyle]="{'padding-left.px': 8 + (depth * 12)}" [innerHtml]="item.header"></h3>
                <ng-container *ngTemplateOutlet="recursiveList; context:{ $implicit: item.children, depth: depth+1 }"></ng-container>
            </ng-container>
        </ng-container>
    </ng-template>
    <ng-container *ngTemplateOutlet="recursiveList; context:{ $implicit: mNodes, depth: 0 }"></ng-container>
</mat-selection-list>
    `,
    styles: [],
})
export class MnTableColumnSelection {

    @Input()
    get nodes():CtTable.Node[] { return this.mNodes; }
    set nodes(v:CtTable.Node[]) { this.mNodes = v ? v : []; this.collectNodeIds() }
    private mNodes:CtTable.Node[] = [];
    protected mNodeIds:string[];

    @Input()
    get selectedNodesIds():string[] { return this.mSelectedNodesIds; }
    set selectedNodesIds(v:string[]) { this.mSelectedNodesIds = v ? v : []; }
    private mSelectedNodesIds:string[] = [];

    @Output() selectedNodes = new EventEmitter();
    @Output() selectedNodesIdsChange = new EventEmitter();
    @ViewChild('list') mList:any;

    constructor() {}

    private emit() {

        // this event is emitted BEFORE my selected nodes ID's have been recorded!!!!

        this.selectedNodes.emit(this.mList.selectedOptions.selected.map(v => v.value));
        //this.selectedNodes.emit(selectedNodeIdsWithOriginalOrder);
        this.mSelectedNodesIds = this.mList.selectedOptions.selected.map(v => v.value.id);
        //this.mSelectedNodesIds = selectedNodeIdsWithOriginalOrder;

        // this event is emitted AFTER my selected nodes ID's have been recorded!!!!
        this.selectedNodesIdsChange.emit(this.mSelectedNodesIds);
    }


    protected collectNodeIds() {
        this.mNodeIds = [];
        //const selectedNodeIdsWithOriginalOrder: string[] = this.mList.options._results.select(aMatListOption=>aMatListOption._selected).map(aMatListOption=>aMatListOption.value);

        /*
                const selectedNodeIdsWithOriginalOrder: string[] = [];
                for(let node of this.mNodes) {
                    const gRootNode :GenericNode<CtTable.Node>  = new GenericNode();
                    gRootNode.buildTreeFromDataWithChildren(node, (n:CtTable.Node)=>n.children?n.children:[]);

                    gRootNode.enumerate((eachNode: GenericNode<CtTable.Node>)=>{
                        const nodeId: string = eachNode.data.id;
                        this.mList.selectedOptions.selected.forEach(v => v.value == nodeId && selectedNodeIdsWithOriginalOrder.push(nodeId));
                    });


                }
        */
    }

    private onChange(e) {
        this.emit();
    }

    public selectAll() {
        this.mList.selectAll();
        this.emit();
    }

    public deselectAll() {
        this.mList.deselectAll();
        this.emit();
    }

    public count():number {
        return this.mList.selectedOptions.selected.length;
    }

}
