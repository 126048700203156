/**
 * Created by joerg on 7/17/17.
 */

// angular
import {Component,Injector} from '@angular/core';

//
import {Backend} from "../BackendInterfaces";
import {MnWorkflowComponentStatic} from "../MnWorkflowComponent";
import {MnStateView2} from "./MnStateView2";
import {MnWorkflow} from "../MnWorkflow";
import {MnWorkflowService} from "../MnWorkflowService";
import {MnTable} from "../CtTable/CtTable";
import {MnTableSelection,MnTableSelectionObserver} from "../CtTable/MnTableSelection";
import {MnStateViewFocusView} from "./MnStateViewFocusView";
import {MnTableFocus} from "../CtTable/MnTableFocus";

@Component({
    selector: 'mn-state-view-state-json',
    template: `
        <div style="height: 100%; width: 100%; overflow: auto;" [innerHtml]="mWorkflowService.State | mnjson"></div>
    `,
})
export class MnStateViewStateJson {

    static createStateTabs(workflow:MnWorkflow):MnStateView2.Tab[] {
        if (workflow && workflow.Workflow && workflow.State) {
            return [{
                id: "",
                label: "DEBUG:State",
                select: 0,
                description: null,
                closable: false
            }]
        } else {
            return [];
        }
    }
    constructor(private mService:MnStateView2.Service, private mWorkflowService:MnWorkflowService) {}
}
//MnStateView2.Registry.register("MnStateViewStateJson",new MnWorkflowComponentStatic(MnStateViewStateJson));


@Component({
    selector: 'mn-state-view-table-json',
    template: `
        <div style="height: 100%; width: 100%; overflow: auto;" [innerHtml]="mWorkflowService.State.dataset.table | mnjson"></div>
    `,
})
export class MnStateViewTableJson {

    static createStateTabs(workflow:MnWorkflow):MnStateView2.Tab[] {
        if (workflow && workflow.Workflow && workflow.State && workflow.State.dataset && workflow.State.dataset.table && workflow.State.dataset.table.data.length > 0) {
            return [{
                id: "",
                label: "DEBUG:Table",
                select: 0,
                description: null,
                closable: false
            }]
        } else {
            return [];
        }
    }
    constructor(private mService:MnStateView2.Service, private mWorkflowService:MnWorkflowService) {}
}
//MnStateView2.Registry.register("MnStateViewTableJson",new MnWorkflowComponentStatic(MnStateViewTableJson));


@Component({
    selector: 'mn-state-view-row-json',
    template: `
        <div style="height: 100%; width: 100%; overflow: auto;" [innerHtml]="mData | mnjson"></div>
    `,
})
export class MnStateViewRowJson extends MnStateViewFocusView {

    static createStateTabs(workflow:MnWorkflow):MnStateView2.Tab[] {
        if (workflow && workflow.Workflow && workflow.State && workflow.State.dataset && workflow.State.dataset.table && workflow.State.dataset.table.data.length > 0) {
            return [{
                id: "",
                label: "DEBUG:Row",
                select: 1,
                description: null,
                closable: false
            }]
        } else {
            return [];
        }
    }

    constructor(injector:Injector) {
        super(injector);
    }

    protected onTableFocus(cell:MnTableFocus.Cell) {
        this.mData = this.getRowData(cell);
    }

    ngOnDestroy() {
        super.ngOnDestroy();
    }

}
//MnStateView2.Registry.register("MnStateViewRowJson",new MnWorkflowComponentStatic(MnStateViewRowJson));
