/**
 * Created by joerg on 7/17/17.
 */

// angular
import {Component,Input,HostListener} from '@angular/core';
import {MnBackend} from "@mn/core";

// uirouter
import {StateService} from '@uirouter/angular';

//
//import {MnHelpPath, MnHelpService} from './MnHelpService';

@Component({
    selector: 'ct-data-manager',
    templateUrl: './CtDataManager.html',
    styleUrls: ['./CtDataManager.css']
})
export class CtDataManager {

    mWorkflows:any[]=[];
    constructor(private mBackend:MnBackend, private mStateService:StateService) {
        mBackend.get('/workflows/workflow/?format=json')
            .map(res => res.json())
            .subscribe(data => {
                console.log(data);
                this.mWorkflows = data;
            })
    }

    onWF(w) {
        this.mStateService.go('app.workflow',{
           wid: w.id,
           sid: w.active_state.id
        });
    }

    onDelete(e,w) {
        e.stopPropagation();
        this.mBackend.delete(`/workflows/workflow/${w.id}/?format=json`)
            .map(res => res.json())
            .subscribe(data => {
                console.log(data);
                this.mBackend.get('/workflows/workflow/?format=json')
                    .map(res => res.json())
                    .subscribe(data => {
                        console.log(data);
                        this.mWorkflows = data;
                    })
            })

    }

}


