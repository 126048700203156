// rxjs
import 'rxjs/Rx';
import { Subject } from 'rxjs/Subject';
import { Subscription } from 'rxjs/Subscription';

//
import {Backend} from "./BackendInterfaces";
import {buildWorkflowTree} from './MnWorkflowHelpers';
import {MnWorkflow} from "./MnWorkflow";


export abstract class MnWorkflowTransaction extends MnWorkflow.Transaction {

    constructor() { super(); }

    protected CancelState: Subject<any> = new Subject();
    protected CancelWorkflow: Subject<any> = new Subject();

    public loadWorkflowInternal(updateIdentifiers:()=>void) {
        this.CancelWorkflow.next(true);
        let wf = this.Workflow;
        if (this.Parameters.target.wid) {
            wf.updateWorkflowStatus(MnWorkflow.WorkflowStatus.Pending);
            wf.loadWorkflow(this.Parameters.target.wid)
                .takeUntil(this.CancelWorkflow)
                .takeUntil(this.Cancel)
                .subscribe(workflow => {
                    wf.updateWorkflow(workflow);
                    wf.updateWorkflowStatus(MnWorkflow.WorkflowStatus.Ready);
                    updateIdentifiers();
                    wf.updateTree(buildWorkflowTree(workflow,this.Parameters.target.sid));
                    if (!workflow.active_transition) {
                        this.loadStateInternal(workflow);
                    }
                    this.pollTransitionInternal(workflow);
                }, err => {
                    console.error("Workflow Loading Error",err);
                    wf.updateWorkflowInvalid();
                })
        } else {
            wf.updateWorkflowInvalid();
        }
    }

    protected loadStateInternal(workflow: Backend.Workflow) {
        this.CancelState.next(true);
        let wf = this.Workflow;
        if (this.Parameters.target.sid) {
            wf.updateStateStatus(MnWorkflow.StateStatus.Pending);
            wf.loadState(this.Parameters.target.sid)
                .takeUntil(this.CancelState)
                .takeUntil(this.Cancel)
                .subscribe(state => {
                    /*wf.updateActions(state.actions);
                    wf.updateTableFromState(state, workflow);
                    wf.updateState(state);
                    wf.updateStateStatus();*/
                    wf.updateStateValid(workflow,state,MnWorkflow.StateStatus.Ready);
                    let wfat = workflow.active_transition;
                    if (wfat && workflow.transitions[wfat].target == state.id) {
                        wf.updateStateStatus(MnWorkflow.StateStatus.Transition);
                    } else {
                        wf.updateStateStatus(MnWorkflow.StateStatus.Ready);
                    }
                }, err => {
                    console.error("State Loading Error", err);
                    wf.updateStateInvalid();
                })
        } else {
            wf.updateStateInvalid();
        }
    }

    protected pollTransitionInternal(workflow: Backend.Workflow) {
        let wf = this.Workflow;
        if (workflow.active_transition) {
            wf.pollTransition(
                workflow.active_transition,
                (transition) => {
                    wf.updateTransition(transition);
                    wf.updateTransitionStatus(MnWorkflow.TransitionStatus.Pending);
                },
                (transition) => {
                    wf.updateTransition(transition);
                    wf.updateTransitionStatus(MnWorkflow.TransitionStatus.Ready);
                    wf.updateTransitionInvalid();
                    wf.updateWorkflowStatus(MnWorkflow.WorkflowStatus.Dirty);
                    if (transition.target == this.Parameters.target.sid) {
                        //this.loadStateInternal(workflow);
                        this.loadWorkflowInternal(()=>{
                            // do nothing
                        });
                    }
                },
                (err) => {
                    wf.updateTransitionInvalid();
                },
                this.Cancel
            )
        }
    }
}

export class MnWorkflowTransactionExternal extends MnWorkflowTransaction {

    constructor() { super(); }

    public run() {
        let wf = this.Workflow;
        this.loadWorkflowInternal(()=>{
            wf.updateIdentifiers(this.Parameters.target);
        });
    }
}

export class MnWorkflowTransactionInternal extends MnWorkflowTransaction {

    constructor() { super(); }

    public run() {
        let wf = this.Workflow;
        if (this.Parameters.target.wid && this.Workflow.Workflow) {
            if (this.Workflow.WorkflowStatus == MnWorkflow.WorkflowStatus.Dirty) {
                this.loadWorkflowInternal(()=>{
                    wf.updateIdentifiersInternalExternal(this.Parameters.target,true);
                });
            } else {
                wf.updateIdentifiersInternalExternal(this.Parameters.target,true);
                wf.updateTree(buildWorkflowTree(this.Workflow.Workflow,this.Parameters.target.sid));
                this.loadStateInternal(this.Workflow.Workflow);
                this.pollTransitionInternal(this.Workflow.Workflow);
            }
        } else {
            wf.updateWorkflowInvalid();
        }
    }
}

export class MnWorkflowTransactionStartTransition extends MnWorkflowTransaction {

    constructor() { super(); }

    public run() {
        let wf = this.Workflow;
        if (wf.Workflow == null || wf.Transition != null) {
            console.log("Cannot start new transition on Workflow null or Workflow with active transition");
            //BB: better to have an alert than nothing ...
            alert("Cannot start new transition on Workflow null or Workflow with active transition");
            return;
        }
        wf.updateStateStatus(MnWorkflow.StateStatus.Pending);
        //this.Options.source = this.Parameters.source.sid;

        let start_transaction = () => {
            if (this.Options.Action.id == "frontend") {
                // we're done
                wf.updateStateStatus(MnWorkflow.StateStatus.Ready);
            } else {
                wf.startTransition(
                    {
                        source: this.Parameters.source.sid,
                        action: this.Options.Action.id,
                        specification: this.Options.Configuration,
                        selection: this.Options.RowMarks,
                    },
                    (transition) => {
                        this.Parameters.target.sid = transition.target;
                        this.loadWorkflowInternal(()=>{
                            wf.updateIdentifiersInternalExternal(this.Parameters.target,true);
                        });
                    },
                    (err) => {
                        wf.updateWorkflowStatus(MnWorkflow.WorkflowStatus.Dirty);
                        wf.updateStateStatus(MnWorkflow.StateStatus.Ready);
                        console.error("Could not create new Transition");
                    }
                );
            }
        };

        if (this.Options.Run) {
            let observable_runner = MnWorkflow.ActionConfiguration.createObservableRunner(this.Options.Run);
            observable_runner.subscribe(
                (progress) => {
                    console.log(progress);
                },
                (error) => {
                    console.log(error);
                },
                () => {
                    start_transaction();
                }
            );
        } else {
            start_transaction();
        }

    }
}
