/**
 * Probability plot implemented using the D3 library.
 *
 * One cannot change the absolute font size for the text showing
 * the probability and the error margin. To change the font size,
 * use the parameter height and relativeVerticalMargin, the font
 * size = height * relativeVerticalMargin
 *
 */

import {Component, ElementRef, Input} from '@angular/core';
import {BasePlotComponent} from '../base-plot/base-plot.component';


export interface ProbabilityPlotData {
  probability: number;
  errorMargin: number;
  plotLabel?: string;

}

interface ProbabilityPlotDataElement {
    x: number;
    length: number;
    color: string;
}
@Component({
  selector: 'app-probability-plot-d3',
  template: ``,
  styleUrls: []
})
export class ProbabilityPlotD3Component  extends BasePlotComponent<ProbabilityPlotData> {

  protected myType = 'ProbabilityPlotD3Component';


  @Input() greenColor = 'green';
  @Input() orangeColor = 'yellow';
  @Input() redColor = 'red';
  @Input() showProbabilityInfo = false;//should always be false
  @Input() showVerticalBar = false; //should always be false

  @Input() relativeVerticalMargin = 0.3;

  @Input() set probs(v) {
      this.setDataAndUpdatePlot(v);
  }



  constructor(public elementRef: ElementRef) {
    super(elementRef);
  }


  public checkDataValidity(dataObject: ProbabilityPlotData): boolean {
    const probability = dataObject.probability;
    const errorMargin = dataObject.errorMargin;

    if (probability < 0 || probability > 1) {
      this.log('Invalid probability');
      return false;
    }
    if (errorMargin < 0 || errorMargin > 1) {
      this.log('Invalid error margin');
      return false;
    }

    return true;
  }

  public plot(): ProbabilityPlotD3Component {

    // tooltip does not work where the background rectangle is covered by other SVG objects
    const svg = this.startD3Plot(null, true);

    if (!svg) {
      return;
    }

    const center = this.dataObject.probability;
    const delta = this.dataObject.errorMargin;

    // horizontal display bar with 3 segments: green, yellow, red
    const plotData: ProbabilityPlotDataElement[] = [
      {x: 0, length: center - delta, color: this.redColor},
      {x: center - delta, length: 2 * delta, color: this.orangeColor},
      {x: center + delta, length: 1 - center - delta, color: this.greenColor}
    ];

    const [pWidth, pHeight] = this.computePlotDimension();


    // display the bar
    const group = svg.append('g');
    const scaling = 'scale(' + pWidth + ',' + pHeight + ') ';
    group.attr('transform', scaling);


    // margin for the vertical line and text
    let relatiVeVerticalMargin = this.relativeVerticalMargin;

    if (!this.showProbabilityInfo) {
      relatiVeVerticalMargin = 0; // do not reserve space for the additional information
    }

    const absoluteVerticalMargin = relatiVeVerticalMargin * pHeight;

    const barTranslation = 'translate(' + 0 + ',' + relatiVeVerticalMargin + ') ';
    const barScaling = 'scale(' + 1 + ',' + (1 - 2 * relatiVeVerticalMargin) + ') ';

    const bar = group.append('g')
      .attr('transform', barTranslation + barScaling)
      .selectAll('rect')
      .data(plotData)
      .enter();


    bar.append('rect')
      .attr('x', function (d: ProbabilityPlotDataElement, i: number): number {
        return d.x;
      })
      .attr('y', function (d: ProbabilityPlotDataElement, i: number): number {
        return 0;
      })
      .attr('width', function (d: ProbabilityPlotDataElement, i: number): number {
        return d.length < 0 ? 0 : d.length;
      })
      .attr('height', function (d: ProbabilityPlotDataElement, i: number): number {
        return 1;
      })
      // .attr('fill', self.nextColor())
      .attr('fill', function (d: ProbabilityPlotDataElement, i: number): string {
        return d.color;
      });


    // additional information: show a vertical line at the probability
    // - show the probability and the error margin
    // Note cannot be used with the same scaling as the traffic light bar, otherwise the text is distorted

    if (this.showVerticalBar || this.showProbabilityInfo) {
      const infoGroup = svg.append('g');
      const x = center * pWidth;

      if (this.showVerticalBar) {
        // vertical line

        const linePar = this.relativeVerticalMargin * pHeight / 10; // adapt the line to available space for
        const strokeWidth = linePar * 2; // for the vertical line
        const strokeDashArray = 'stroke-dasharray:' + linePar + ',' + linePar;
        const h = pHeight - (absoluteVerticalMargin);
        infoGroup.append('line')
          .attr('x1', x)
          .attr('y1', absoluteVerticalMargin)
          .attr('x2', x)
          .attr('y2', h)
          .attr('style', 'stroke:black;stroke-width:' + strokeWidth + ';' + strokeDashArray);
      }


      if (this.showProbabilityInfo) {
        // text with median value
        const pText = this.formatNumber(this.dataObject.probability); // it would be better to use a sprintf %.2f

        const fontSize = absoluteVerticalMargin + 'px';
        let svgText = infoGroup.append('text')
          .attr('x', x)
          .attr('y', absoluteVerticalMargin / 2)
          .attr('font-size', fontSize)
          .attr('text-anchor', 'middle')
          .attr('alignment-baseline', 'central')
          .text(pText);

        this.correctHorizontalPositionIfNeeded(svgText, pWidth);

        // text with error margin / range
        const erText = ' ± ' + this.formatNumber(this.dataObject.errorMargin);
        svgText = infoGroup.append('text')
          .attr('x', x)
          .attr('y', pHeight - absoluteVerticalMargin / 2)
          .attr('font-size', fontSize)
          .attr('text-anchor', 'middle')
          .attr('alignment-baseline', 'central')
          .text(erText);
        this.correctHorizontalPositionIfNeeded(svgText, pWidth);


      }
    }
    this.createTooltipForTheWholePlot(svg, this.dataObject.plotLabel);

    return this;
  }

  public toggleShowHideProbabilityInfo(event: string) {
    if(this.svgPlotD3) {
      const self = this;
      this.svgPlotD3.on(event, function() {
        self.showProbabilityInfo = ! self.showProbabilityInfo;
        self.plot();
      });
    }

  }

}

