/**
 * Created by joerg on 7/17/17.
 */

// angular
import {Component,Output,EventEmitter} from '@angular/core';

@Component({
    selector: 'ct-action-header',
    templateUrl: './CtActionHeader.html',
    styleUrls: ['./CtActionHeader.css']
})
export class CtActionHeader {

    @Output() headerClick = new EventEmitter();

    constructor() {
    }

    private onHeaderClick(event) {
        this.headerClick.emit(event);
    }

    ngOnDestroy() {
    }
}
