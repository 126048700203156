/**
 * Created by joerg on 7/17/17.
 */

// rxjs
import 'rxjs/Rx';
import { Observable } from 'rxjs/Observable';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Subject } from 'rxjs/Subject';
import { Subscription } from 'rxjs/Subscription';

// angular
import {Component,Input,Injectable,Injector,Optional} from '@angular/core';
import {trigger, state, style, transition, animate, keyframes, AnimationTriggerMetadata} from '@angular/animations';

// aggrid
import {GridOptions,GridApi,ColumnApi,ColDef,Column,ColumnGroup,RowNode,CellFocusedEvent} from 'ag-grid/main'

// mn
import {MnTextService} from "@mn/core";

//
import {Backend} from "../BackendInterfaces";
import {CtTable, MnTable} from "./CtTable";
import {getScrollbarWidth,createTableConfig,createTableConfig2,renderFullRow} from "../CtStateViewHelpers";
import {MnWorkflowObserver} from "../MnWorkflowObserver";
import {MnWorkflowService} from "../MnWorkflowService";
import {MnTableSelection,MnTableSelectionObserver} from "./MnTableSelection";
import {MnTableFocus} from "./MnTableFocus";


let aSlideLeft:AnimationTriggerMetadata = trigger('aSlideLeft', [
    state('L0',
        style({ width: '100%' }),
    ),
    state('L1',
        style({ width: '{{pinned_width}}px' }), {params: { pinned_width: '0' }}
    ),
    state('L2',
        style({ width: '0' }),
    ),
    transition('* <=> *', animate('400ms cubic-bezier(0.25, 0.8, 0.25, 1)')),
]);

let aSlideRight:AnimationTriggerMetadata = trigger('aSlideRight', [
    state('L0',
        style({ width: '0' }),
    ),
    state('L1',
        style({ width: 'calc(100% - {{pinned_width}}px)' }), {params: { pinned_width: '0' }}
    ),
    state('L2',
        style({ width: '100%' }),
    ),
    transition('* <=> *', animate('400ms cubic-bezier(0.25, 0.8, 0.25, 1)')),
]);



let scroll_bar_width = getScrollbarWidth();

@Component({
    selector: 'mn-table-view',
    templateUrl: './MnTableView.html',
    animations: [ aSlideLeft, aSlideRight, ]
})
export class MnTableView extends MnTableSelectionObserver {

    private mPinnedWidth:number = 0;
    private mRowHeightList:number = 32;
    private mRowHeightNormal:number = 100;
    private mRowheightFooter:number = 18;
    private mRowHeight:number = 100;
    
    // grid
    public mClasses = ['ag-workflow'];
    private mAgGridOptions:GridOptions= {
        debug: true,
        enableSorting: true,
        enableColResize: true,
        getRowHeight: (params) => {
            if (params.data.footer) {
                return this.mRowheightFooter;
            } else if (params.data.is_virtual) {
                return 32;
            } else
            {
                return this.mRowHeight;
            }
        },
        isFullWidthCell: (row_node:RowNode) => this.isFullWidthCell(row_node),
        fullWidthCellRenderer: (params) => {
            return renderFullRow(params,this.mText);
        },
        onGridReady: () => this.onGridReady(),
        onColumnResized: (data) => this.onColumnResized(data),
        onColumnGroupOpened: (columngroupevent) => this.onColumnGroupOpened(columngroupevent),
        onRowClicked: (rowclickevent) => this.onRowClick(rowclickevent),
        onCellFocused: (event?: CellFocusedEvent) => this.onCellFocused(event),
        onCellValueChanged: (event) => this.onCellValueChanged(event),
    };

    @Input()
    get layout():MnTableView.Layout { return this.mLayout; }
    set layout(v:MnTableView.Layout) { this.mLayout = v; this.updateVisualColumns(); }
    private mLayout:MnTableView.Layout = MnTableView.Layout.L0;

    @Input()
    get footer():boolean { return this.mFooter; }
    set footer(v:boolean) { this.mFooter = v; this.updateFooter(); }
    private mFooter:boolean = false;

    @Input()
    get list():boolean { return this.mList; }
    set list(v:boolean) { this.mList = v; this.updateList(); }
    private mList:boolean = false;

    @Input()
    get table():MnTable { return this.mTable; }
    set table(v:MnTable) { this.onTable(v); }
    private mTable:MnTable = null;


    constructor(private mInjector:Injector, private mText:MnTextService, @Optional() private mSelection:MnTableSelection, private mTableFocus:MnTableFocus) {
        super(mSelection);
    }

    onCellValueChanged(event) {
        console.log(event);

    }

    protected onTable(table:MnTable) {
        if (this.mSelection) {
            this.mSelection.Table = table;
        }
        this.mTable = table;
        this.updateGrid();
        this.updateVisualColumns();
    }

    private isFullWidthCell(row_node:RowNode) {
        return row_node.data.is_virtual === true;
    }

    private cellClass(params) {
        if (params.data.last_in_category === true) {
            return 'last-in-category';
        }
        return '';
    }

    private onSlideLeftDone() {

    }

    private onGridReady() {
        this.updateGrid();
    }

    private updatePinnedWidth() {
        let cols:Column[] = this.mAgGridOptions.columnApi.getAllColumns();
        let pinned_width = scroll_bar_width;
        for (let i = 0, l = cols.length; i < l; i++) {
            let c:Column = cols[i];
            if (c.isPinnedLeft() && c.isVisible()) {
                let p:ColumnGroup = <ColumnGroup>c.getParent();
                if (p) {
                    if (p.getDisplayedChildren().indexOf(c) >= 0) {
                        pinned_width+= c.getActualWidth();
                    }
                }
                else {
                    pinned_width+= c.getActualWidth();
                }
            }
        }
        this.mPinnedWidth = pinned_width;
    }

    private onColumnResized(data) {
        if (this.mLayout == MnTableView.Layout.L1) {
            this.updatePinnedWidth();
        }
        if (data.column.colDef.colId == 'rootSTRUCTUREXXXIMAGE_URL') {
            this.mRowHeightNormal = data.column.getActualWidth()-(this.mTable.maxIndent()*20);
            this.updateRowHeight();
            this.mAgGridOptions.api.refreshCells({
                columns: [data.column],
                force: true
            })
        }
        /*if (data.column.colDef.colId.startsWith('rootSKYLINE')) {
            this.mAgGridOptions.api.refreshCells({
                columns: [data.column],
                force: true
            })
        }*/
    }

    private onColumnGroupOpened(columngroupevent) {
        this.updatePinnedWidth();
    }

    private selectionCallback(params):boolean {
        //if (this.mSelection.ModelSubject.getValue() == MnTableSelection.Model.None) return false;
        //console.log('selectionCallback',params);
        if (params.data.is_virtual) {
            return false;
        } else {
            let s = this.mSelection.RowsCurrentSubject.getValue();
            return s.has(params.data.record_index);
        }
    }

    private updateGrid() {
        if (this.mAgGridOptions == undefined || this.mAgGridOptions.api == undefined) return;
        //if (this.Workflow.State == null) return;
        //this.updateGridFooter();
        //this.mMnTableSelectionService.init(this.Workflow.Table);
        if (this.mTable == null) {
            this.mAgGridOptions.api.setColumnDefs([]);
            setTimeout(() => {
                this.mAgGridOptions.api.setRowData([]);
            })
        } else {
            let table_config = createTableConfig2(this.mTable,this.mInjector,(params) => { return this.selectionCallback(params)});
            this.mAgGridOptions.api.setRowData([]);
            this.mAgGridOptions.api.setColumnDefs(table_config.cols);
            let cr = this.mAgGridOptions.columnApi.getColumn('rootSTRUCTUREXXXIMAGE_URL');
            let fcw = 100;
            if (cr) {
                fcw = cr.getActualWidth();
            }
            //let fcw = this.mAgGridOptions.columnApi.getColumn('rootSTRUCTUREXXXIMAGE_URL').getActualWidth();
            this.mRowHeightNormal = fcw -(this.mTable.maxIndent()*20);
            this.updateRowHeight(false);
            setTimeout(() => {
                this.mAgGridOptions.api.setRowData(this.mTable.RowsVirtual);
            })
         }
    }

    /*updateGridFooter() {
        if (this.Workflow.State.dataset == null || this.mFooter == false) {
            this.mAgGridOptions.api.setPinnedBottomRowData([]);
        } else {
            let stat = this.Workflow.Table.Statistics;
            stat.min.footer = true;
            stat.max.footer = true;
            stat.sum.footer = true;
            stat.count.footer = true;
            stat.avg.footer = true;
            let bottom_rows = [stat.min,stat.max,stat.sum,stat.count,stat.avg];
            this.mAgGridOptions.api.setPinnedBottomRowData(bottom_rows);
        }
    }*/

    private updateVisualColumns() {
        if (this.mAgGridOptions == undefined || this.mAgGridOptions.api == undefined) return;
        let cols:Column[] = this.mAgGridOptions.columnApi.getAllColumns();
        if (this.mLayout == MnTableView.Layout.L1) {
            let hidden:string[] = [];
            let visible:string[] = [];
            for (let i = 0, l = cols.length; i < l; i++) {
                let c:Column = cols[i];
                if (c.isPinnedLeft() == false) {
                    hidden.push(c.getColId());
                } else {
                    visible.push(c.getColId());
                }
            }
            this.mAgGridOptions.columnApi.setColumnsVisible(hidden,false);
            this.mAgGridOptions.columnApi.setColumnsVisible(visible,true);
        } else {
            this.mAgGridOptions.columnApi.setColumnsVisible(cols,true);
        }
        this.updatePinnedWidth();
    }

    updateRowHeight(reset:boolean = true) {
        if (this.mList == true) {
            this.mRowHeight = this.mRowHeightList;
        } else {
            this.mRowHeight = this.mRowHeightNormal
        }
        if (this.mAgGridOptions == undefined || this.mAgGridOptions.api == undefined) return;
        if (reset) {
            this.mAgGridOptions.api.resetRowHeights();
        }
    }

    updateList() {
        this.updateRowHeight();
        if (this.mAgGridOptions == undefined || this.mAgGridOptions.api == undefined) return;
        this.mAgGridOptions.api.redrawRows();
    }

    updateFooter() {
        //this.updateGridFooter();
    }

    onRowClick(rowclickevent) {
        /*console.log(rowclickevent);
        if (rowclickevent && rowclickevent.data && rowclickevent.data.record_index) {
            let ri:string = rowclickevent.data.record_index;
            this.selectionRowToggle(ri);
        }*/
    }

    onCellFocused(event?: CellFocusedEvent) {
        if (this.mTable && this.mTable.RowsVirtual && this.mTable.RowsVirtual.length > event.rowIndex && event.column) {
            const clickedRow: CtTable.SortedRow = this.mTable.getRowFromSortedTable(event.rowIndex);
            if(clickedRow) {
                const tableRowIndex: string = clickedRow.record_index;
                let cell :MnTableFocus.Cell = {
                    row: tableRowIndex,
                    col: event.column.getColId()
                };
                this.mTableFocus.set(cell);
                //console.log("cell",cell);

            }
        }
    }

    protected onSelectionRows(rows:Set<string>) {
        /*if (this.mAgGridOptions == undefined || this.mAgGridOptions.api == undefined) return;
        let row_nodes:RowNode[] = this.mAgGridOptions.api.getRenderedNodes();
        console.log(row_nodes);
        this.mAgGridOptions.api.refreshCells({
            rowNodes: row_nodes,
            //force: true
        })*/
    }

    ngOnDestroy() {
        // empty
        super.destory();
    }

}
export namespace MnTableView {
    export enum Layout  {
        L0 = 'L0',
        L1 = 'L1',
        L2 = 'L2',
    }
}


