/**
 * Created by joerg on 7/17/17.
 */

// rxjs
import 'rxjs/Rx';
import { Observable } from 'rxjs/Observable';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Subject } from 'rxjs/Subject';
import { Subscription } from 'rxjs/Subscription';

// angular
import {Component,Input,HostListener,Output,EventEmitter} from '@angular/core';

import {CtStateViewComponent} from "./CtStateViewComponent"
import {CtStateViewService} from "./CtStateViewService"
import {CtTable} from "./CtTable/CtTable";
import {CtValueView} from "./CtValueView";
import {MnTextService} from "@mn/core";

@Component({
    selector: 'ct-state-view-component-summary-inner',
    template: `
<!--
        comp:{{conf.comp}}
        value:{{conf.value}}
-->
        <ng-container *ngIf="conf.comp && conf.value !== null && conf.value !== undefined" [ngComponentOutlet]="conf.comp"
        >
        </ng-container>
    `,
    styles: [``],
    providers: [CtValueView.Service]
})
export class CtStateViewComponentSummaryInner {

    conf:any = {};

    constructor(private mService:CtValueView.Service) {}
    @Input() set config(value:any) {
        this.conf = value;
        this.mService.ParameterSubject.next(value);
    }
   /* setParams(comp:any,params:CtValueView.Parameters) {
        this.mService.ParameterSubject.next(params);
    }*/
}


@Component({
    selector: 'ct-state-view-component-summary',
    templateUrl: './CtStateViewComponentSummary.html',
    styleUrls: ['./CtStateViewComponentSummary.css']
})
export class CtStateViewComponentSummary extends CtStateViewComponent{

    mRawState:any;
    mConfig:any;
    mRend:any[] = [];
    mRows:any[] = [];
    mCols:any[] = [];
    mTable:CtTable = null;
    mCache:any = {};

    constructor(service:CtStateViewService) {
        super(service);
        this.mConfig = this.mService.Data;
        if (this.mRawState == undefined) {
            this.mRawState = {};
        }
        if (this.mConfig == undefined) {
            this.mConfig = {};
        }
        this.mTable = this.mService.Table;
        this.updateConfig();
    }

    protected onStateChange(state) {
        //console.log("CtStateViewComponentJson.onStateChange",state);
        /*if (state == null) {
            this.mRawState = {};
            return;
        }*/
        this.mRawState = state;
        this.mConfig = this.mService.Data;
        if (this.mRawState == undefined) {
            this.mRawState = {};
        }
        if (this.mConfig == undefined) {
            this.mConfig = {};
        }
        this.mTable = this.mService.Table;
        this.updateConfig();
    }

    updateConfig() {
        this.mCache = {};
        this.mRows = [];
        this.mRend = [];
        this.mCols = [];
        if (this.mConfig.rows && this.mConfig.cols && this.mTable && this.mTable != null) {
            let rows:any = this.mConfig.rows;
            let cols:any = this.mConfig.cols;
            for (let i = 0, l = rows.length; i < l; i++) {
                this.mRows.push(this.mTable.findRow(rows[i]));
            }
            for (let i = 0, l = cols.length; i < l; i++) {
                let node:CtTable.Node = this.mTable.NodeMap[cols[i]];
                if (!node) {
                    this.mCols.push({
                        col: cols[i],
                        node: {},
                        rend: undefined,
                        path: []
                    });
                } else {
                    let rend:any = CtValueView.Registry[node.type];
                    this.mCols.push({
                        col: cols[i],
                        node: node,
                        rend: rend,
                        path: []
                    });
                }
            }
            for (let i = 0, l = this.mCols.length; i < l; i++) {
                let node:CtTable.Node = this.mCols[i].node;
                if (node == {}) {
                    continue;
                }
                let path:CtTable.Node[] = this.mTable.getNodeParents(node);
                let label = path.map((pnode)=>{
                    if (pnode.asis === true) {
                        return pnode.ident;
                    }
                    return this.mService.Translate.text('ct.table.header.'+pnode.ident);
                });
                this.mCols[i].path = label;
                //console.log(label);
            }
        }
    }


    sizes:any = {
        'image':     { height: 200, width: 200},
    };

    getData(row,col,ri,ci) {
        let s:string = ri+':'+ci;
        if (this.mCache[s]) {
            return this.mCache[s];
        }
        //console.log(row);
        //console.log(col);
        let res:any = {
            value: col.node? col.node.getter(row) : null,
            size: this.sizes[col.node.type]? this.sizes[col.node.type] : { height: 200, width: 200},
            comp: col.rend,
        };
        //console.log(res);
        this.mCache[s] = res;
        return res;
    }

    ngOnDestroy() {
        this.destroy();
    }

}


