/**
 * Created by joerg on 7/17/17.
 */

// rxjs
import 'rxjs/Rx';
import { Observable } from 'rxjs/Observable';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Subject } from 'rxjs/Subject';
import { Subscription } from 'rxjs/Subscription';


// angular
import {Component,Input,Output,EventEmitter,HostListener,Injectable,Optional,ChangeDetectionStrategy} from '@angular/core';

// mn
import {MnBrandService, MnTextService} from "@mn/core";

//
import {Backend} from "./BackendInterfaces";
import {CtTable} from "./CtTable/CtTable";

export abstract class CtValueView {

    // Registry of child classes
    static Registry:CtValueView.Registry = {};
    static register(key: CtValueView.Types, value_view:any) {
        CtValueView.Registry[key] = <CtValueView>value_view;
    }

    // for text formatting of the cell content - useful for XLS, SDF, ... export
    static TextRegistry:CtValueView.Registry = {};
    static registerTextOutput(key: CtTable.ColumnType, value_view:any) {
        CtValueView.TextRegistry[key] = <CtValueView>value_view;
    }

    public static GetTextFormatter(key: CtValueView.Types) : any {
        return CtValueView.TextRegistry[key];
    }

    // inputs
    @Input() size:CtValueView.Size = { height: 0, width: 0 };
    @Input() options:CtValueView.Options = { condensed: false };
    @Input()
    public get value():any { return this.mInternalValue; }
    public set value(v:any) { this.mInternalValue = v; this.update({size:this.size, options:this.options, value:this.mInternalValue}); /*console.log(this.size,this.options)*/}
    private mInternalValue:any;

    /**
     * Adapter code to reuse the component outside an ag grid.
     * data is the raw JSON data from the backend
     * @param value
     */
    @Input('data')
    set setJsonData(value: any) {
        // reformat the data into a CtValueView.Parameters that can be send to the update() method
        const par: CtValueView.Parameters = {value: value, options: null, size: null};
        this.update(par);
    }


    private mAlive:boolean = true;


    constructor(private mService?:CtValueView.Service) {
        if (this.mService) {
            setTimeout(() => {
                this.mService.ParameterSubject.takeWhile(() => this.mAlive).subscribe((parameters) => this.updateInternal(parameters));
            });
        }
    }

    private updateInternal(parameters:CtValueView.Parameters) {
        if (parameters !== null) this.update(parameters);
    }
    protected abstract update(parameters:CtValueView.Parameters) : void;

    public set tooltip(tooltip:string) {
        if (this.mService) {
            //this.mService.TooltipSubject.next(tooltip);
        }
    }

    public setNewValue(value:any) {
        if (this.mService) {
            this.mService.SetNewValueSubject.next(value);
        }
    }

    protected destroy() {
        this.mAlive = false;
    }
}



export namespace CtValueView {
    export interface Registry {
        [key:string]: CtValueView
    }
    //TODO: duplicated definitions in CtWidthHelper.ts
    // TOdo duplicated definition in column types
    export enum Types {
        Int = "int",
        Float = "float",
        Boolean = "boolean",
        String = "string",
        ListString = "list.string",
        ListStudy = "list.study",
        ListIdentifier = "list.identifier",
        ListName = "list.name",
        WoeProbabilities = "woe.probabilities",
        WoeCall = "woe.call",
        Enum = "enum",
        Skyline = "chemtunes3_skyline",
        Image = "image",
        DataSummary = "data.summary",
        ChemotypeProfile = 'chemotype_profile',
    }

    export interface Size {
        height: number;
        width: number;
    }
    export interface Options {
        condensed: boolean; // only display a single line if possible
    }
    export interface Parameters {
        value: any;
        size: Size;
        options: Options;
    }

    @Injectable() export class Service {
        ParameterSubject: BehaviorSubject<Parameters> = new BehaviorSubject(null);
        TooltipSubject: Subject<string> = new Subject();
        SetNewValueSubject: Subject<any> = new Subject();
    }

}

export function CtValueViewRegisterDecorator(key: CtValueView.Types): any {
    return function(targetClass): any  {
        CtValueView.register(key, targetClass);

        return targetClass;
    }
}


export function TextViewRegisterDecorator(key: CtTable.ColumnType): any {
    return function(targetClass): any  {
        CtValueView.registerTextOutput(key, targetClass);

        return targetClass;
    }
}
