/**
 * Created by Jongjin Park on 2017-07-27.
 */
import {Component,Optional} from '@angular/core';
import {UIRouter} from '@uirouter/core';

import {MnSizeService,MnLayoutHeight} from '@mn/core';

import {MnAuthService} from '@mn/auth'
import {MnHelpService} from "@mn/help";


@Component({
    selector: 'ct-help-header',
    template: `
 <mn-left-center-right class="mn-nav-lcr">

    <ng-container class="mn-left">
        <mn-middle-group>
            <mn-middle>Help</mn-middle>
        </mn-middle-group>
    </ng-container>

    <ng-container class="mn-right">
        <mn-middle-group>
            <mn-middle>
                                           <button class="ct-button-fab-md" mat-mini-fab color="warn"  (click)="close()">
                                    <i class="far fa-times"></i>
                                </button>

            </mn-middle>
        </mn-middle-group>
    </ng-container>

</mn-left-center-right>    
    `,
    styles: [`
       
        :host {
            border-bottom: 1px solid darkgray;
    background-color: white;
    display: block;
    height: 100%;
        }   
        button {
            border: none;
            background: transparent;
        }
    
    ` ]
})
export class CtHelpHeader {

    constructor(private mMnAuthService:MnAuthService, private uiRouter:UIRouter, @Optional() private mHeight:MnLayoutHeight, @Optional() private mHelp:MnHelpService) {
        if (mHeight) mHeight.request(50);

    }

    close() {
        if (this.mHelp) {
            this.mHelp.request(null);
        }
    }

}