// added to brand.ts
//import * as canvg from 'new-canvg';

declare var canvg;

//console.log("canvg",canvg);

export class SVGimage {

  readonly originalSVG : string;
  modifiedSVG: string;
  originalWidth: string;
  originalHeight: string;

  protected static readonly   svgTagRe = /\s*<svg\s*([^>]+)>/;
  protected static readonly withHeightRe = /(width|height)=(?:"|')(\d+(?:pt|px|%)?)(?:"|')/g;
  protected static  readonly  dimensionRe = /([+-]?\d+(?:\.\d+)?)(px|pt|%)?/;

  constructor( originalSVG: string) {
    this.originalSVG = originalSVG;
    this.findAndSetOriginalDimensions();


  }

  /**
   * return the  attributes  of a <svg ..... > as a string
   * @returns {string}
   */
  public getSVGattributesString(): string {
    let match: RegExpExecArray = SVGimage.svgTagRe.exec(this.originalSVG);
    let result: string = null;
    if( match) {
      result = match[1];
      result = result.trim();
    }

    return result;
  }
  public findAndSetOriginalDimensions(): string[] {
    let attributes = this.getSVGattributesString();
    //const re = /(originalWidth|originalHeight)=("|')(\d+)(pt|px)?("|')/;
    let match: RegExpExecArray;
    let found = false;
    while(match = SVGimage.withHeightRe.exec(attributes)) {
      //['originalWidth="390px"', 'originalWidth', '390px']
      console.assert(match.length == 3);
      if(match[1] == 'width') {
        this.originalWidth = match[2];
      } else {
        this.originalHeight = match[2];
      }
      found = true;
    }

    if( found ) {
      return [this.originalWidth, this.originalHeight];
   }

    return null;

  }

  protected getDimensionAsNumber(dim: string) : number{
    const match: RegExpExecArray = SVGimage.dimensionRe.exec(dim);
    let result: number = NaN;
    if( match ) {
      result = +match[1];
    }

    return result;
  }
  public getAspectRatio(): number {
    //
    const w : number = this.getDimensionAsNumber(this.originalWidth);
    const h : number = this.getDimensionAsNumber(this.originalHeight);

    if( h > 0 && w > 0) {
      return w / h;
    }

    return NaN;
  }

  public createPngFromSVG(): string {


    let canvas = document.createElement("canvas");
    document.body.appendChild(canvas);

    // needed to keep the aspect ratio
    // excell will stretch the image
    canvas.setAttribute("width", this.originalWidth);
    canvas.setAttribute("height", this.originalHeight);


    canvg(canvas, this.originalSVG);

    const svgPng64: string = (<any>canvas).toDataURL();

    document.body.removeChild(canvas);

    //console.log(svgPng64);


    return svgPng64;
  }
}
