/**
 * Created by joerg on 7/17/17.
 */

// angular
import {Component,Input, Output, EventEmitter} from '@angular/core';
import {trigger, state, style, transition, animate, keyframes, AnimationTriggerMetadata} from '@angular/animations';

// uirouter
import {Transition,StateService, TransitionService} from '@uirouter/angular';

//
import {Backend} from "./BackendInterfaces";
import {MnWorkflow} from "./MnWorkflow";
import {MnWorkflowService} from "./MnWorkflowService";

let aMode:AnimationTriggerMetadata = trigger('aMode', [
    state('Visible',
        style({ opacity: 1, visibility: 'visible' })
    ),
    state('Hidden',
        style({ opacity: 0, visibility: 'hidden' })
    ),
    transition('* <=> *', animate('400ms')),
]);


@Component({
    selector: 'mn-action-view',
    template: `
        <ct-action-list
                *ngIf="!mActionConfiguration"
                style="width: 100%; height: 100%;"
                [@aMode]="mActionConfiguration ? 'Hidden' : 'Visible'"
                [actions]="mActions"
                (actionSelect)="onActionSelect($event)"
        ></ct-action-list>
        <div mnCol class="mn-layout-row"
             *ngIf="mActionConfiguration"
             [@aMode]="mActionConfiguration ? 'Visible' : 'Hidden'"
        >
<!--
            <div mnRow>
                <ct-action-config-view
                        style="height: 100%; width: 100%;"
                        [action]="mActionConfiguration.Action"
                        (apply)="onActionApplyOld($event)"
                ></ct-action-config-view>
            </div>
-->
            <div mnRow>
                <mn-action-configurator-view
                        [actionConfiguration]="mActionConfiguration"
                        style="height: 100%; width: 100%;"
                        (actionApply)="onActionApply($event)"
                 ></mn-action-configurator-view>
            </div>
        </div>
    `,
    animations: [
        aMode
    ]

})
export class MnActionView {

    @Input()
    get actions():Backend.Action[] { return this.mActions; }
    set actions(v:Backend.Action[]) { this.mActions = v; }
    private mActions:Backend.Action[] = [];

    @Input()
    get actionConfiguration():MnWorkflow.ActionConfiguration { return this.mActionConfiguration; }
    set actionConfiguration(v:MnWorkflow.ActionConfiguration) { this.mActionConfiguration = v; }
    private mActionConfiguration:MnWorkflow.ActionConfiguration;

    @Output() actionConfigurationChange: EventEmitter<MnWorkflow.ActionConfiguration> = new EventEmitter();
    @Output() applyAction: EventEmitter<MnWorkflow.ActionConfiguration> = new EventEmitter();

    constructor(private mWorkflowService:MnWorkflowService) {
    }

    private onActionSelect(action) {
        console.log("onActionSelect",action);
        this.mActionConfiguration = new MnWorkflow.ActionConfiguration(action,{});
        this.actionConfigurationChange.emit(this.mActionConfiguration);
    }

    private onActionApplyOld(action_and_config) {
        console.log("onActionApplyOld",action_and_config);
        //this.apply.emit(action_and_config);
        /*this.applyAction.emit(new MnWorkflow.ActionConfiguration(
            action_and_config.action_instance,
            action_and_config.specification,
        ));*/
    }

    private onActionApply(action_config:MnWorkflow.ActionConfiguration) {
        console.log("onActionApply",action_config);
        this.applyAction.emit(action_config);
        /*this.applyAction.emit(new MnWorkflow.ActionConfiguration(
            action_and_config.action_instance,
            action_and_config.specification,
        ));*/
    }

    private onApplyActionDone(event) {
        console.log("onApplyActionDone",event);
    }

    ngOnDestroy() {}
}
