/**
 * Created by joerg on 7/17/17.
 */

// rxjs
import 'rxjs/Rx';
import { Subject } from 'rxjs/Subject';

// angular
import {Component,Input,Output,EventEmitter,ViewChild} from '@angular/core';
import {MnRowMarker} from "../MnMarker";
import {MnActionConfigurationService} from "./MnActionConfigurationService";

@Component({
    selector: 'mn-action-configurator-row-marks',
    template: `        
        <div style="display: flex; line-height: 36px; padding: 6px 0;">
            Selected Compounds: {{mRowMarker.count()}}
            <span style="flex: 1 1 auto"></span>
            <button mat-button (click)="mRowMarker.all()">Select All</button>        
            <button mat-button (click)="mRowMarker.none()">Deselect All</button>
        </div>
    `,
    styles: [`
        :host {
            display: block;
        }
    `],
})
export class MnActionConfiguratorRowMarks {

    private mDestroy:Subject<void> = new Subject();

    /*@Input()
    get label():string { return this.mLabel; }
    set label(v:string) { this.mLabel = v; }
    private mLabel:string;*/

    @Output() selectedRows = new EventEmitter();

    constructor(private mRowMarker:MnRowMarker, private mConfigurationService:MnActionConfigurationService) {
        this.mRowMarker.ChangeSubject
            .takeUntil(this.mDestroy)
            .subscribe(()=> {
                this.onRowMarksChange();
            });
        this.mRowMarker.setup(MnRowMarker.Max,[],[]);
        this.mConfigurationService.RowMarksActive = true;
        this.mConfigurationService.EnableRowMarksSubject.next(false);
    }

    private onRowMarksChange() {
        this.mConfigurationService.EnableRowMarksSubject.next(this.mRowMarker.count() > 0);
        this.mConfigurationService.RowMarksSubject.next(this.mRowMarker.marks());
        this.selectedRows.emit(this.mRowMarker.marks());
    }

    ngOnDestroy() {
        this.mConfigurationService.RowMarksActive = false;
        this.mDestroy.next();
    }

}
