/**
 *
 */
import * as ExcelJS from 'exceljs';



// file-saver is provided by the module ng.mn.file/
declare var saveAs; // no import here, done as in MnFileModule.ts


import { Observable } from "rxjs/Observable";
import { Subject } from 'rxjs/Subject';

import {MnBackend, MnTextService} from "@mn/core";

import {CtTable} from "./CtTable";

import {CtValueView} from "../CtValueView";
import {CtImageView, Image, ImageFormat} from "../CtValueViews/CtImageView";

import {SVGimage} from "../../../utils/svgimage";
import {SkylineD3Component, SkylineData, SkylineSingleData} from "../d3/skyline-d3/skylined3.component";

import {DataSummary, DataSummaryObject} from "../CtValueViews/CtDataSummaryView";
import {CtListIdentifierViewNoComponents} from "../CtValueViews/CtListIdentifierView";
import {Aggregate} from "../CtValueViews/CtComplexValueView";
import {GenericNode} from "../../../utils/generic-node";




export class CtXlsExport  {

    public static  extension: string = '.xlsx';

    // to get remote images
    protected mCancel:Subject<any> = new Subject();
    //protected mBackend:MnBackend ;

    protected workbook: ExcelJS.Workbook;
    protected sheet:  ExcelJS.Worksheet;

    protected defaultColumnWidth = 10; // 10 characters?
    protected defaultRowHeight = 10;

    protected imageColumnWidth  = 120; // px
    protected imageRowHeight = 120;  // must be the same as imageColumnWidth?


    protected  mustSortRows = true; //sort rows such that the row order of the XLS output is the same as displayed in the ag-grid

    constructor(protected mBackend:MnBackend, public table: CtTable, public filename: string, public sheetName: string, protected skPlot: SkylineD3Component, private done?:Function, private error?:Function) {
        //console.log('constructor');
    }

    public export() {
        const exportFilename = this.fixExportFilename();
        console.log('export table to ' + exportFilename);

        this.createAndFillWorkbook(() => {
            // cast to any to avoid typescript compiler error
            (<any>this.workbook.xlsx).writeBuffer({
                base64: true
            })
                .then((xls64) => {
                    const blob = new Blob([xls64], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"});
                    saveAs(blob, this.filename);
                    if (this.done) {
                        this.done();
                    }
                })
                .catch((error) => {
                    console.error(error.message);
                    if (this.error) {
                        this.error(error);
                    }
                });

        });


    }

    /**
     * ensure that the output filename has the right file extension
     * @returns {string}
     */
    protected fixExportFilename(): string {
        let newFilename: string = this.filename;
        if (! newFilename.match( new RegExp( '\\' + CtXlsExport.extension + '$' ))) {
            newFilename = newFilename.replace(/\.xls$/i, '');
            newFilename += CtXlsExport.extension;
        }

        return newFilename;
    }

    // compute cell width for a parent cell


    protected createAndFillWorkbook( callBack: Function): void {

        const useMultiRowHeader = true;

        let numberOfHeaderRows = 1;
        if(useMultiRowHeader) {
            const gRootNode : GenericNode<CtTable.Node> = this.table.buildGenericNodeFromColumnNode(null);
            numberOfHeaderRows = gRootNode.computeMaxDepth();

        }



        this.workbook = new ExcelJS.Workbook();
        this.sheet = this.workbook.addWorksheet(this.sheetName);
        let tmp = this.createColumns();
        this.sheet.columns  = tmp[1];
        const nHeaderRows = tmp[0];

        if(nHeaderRows > 1) {
            // Get the last editable row in a worksheet - here it is the header
            var row = this.sheet.lastRow;
            // Set a specific row height
            row.height = 42.5 / 3 * nHeaderRows;
        }



        let columnNodes: CtTable.NodeArray = this.getColumnNodes();
        let rows:CtTable.SortedRow[] = this.getRows();
        let rowCount = 0;

        const multipleObservable: Observable<any>  = this.generateMultipleImageObservable();
        multipleObservable.subscribe( (images: Image[]) => {
            //console.log(images);
            for (let r of rows) {
                rowCount++;
                let xlsRow: any = {};

                let columnCount = 0;
                let rowHeight = this.computeRowHeight(r);
                if(r.is_virtual) {
                    console.log(r);
                }
                for (let c of columnNodes) {
                    columnCount++;
                    const columnNode: CtTable.Node = c;
                    let cellContent: any = columnNode.getter(r); // could be a complex object
                    if( cellContent == undefined || cellContent == null) {
                        continue;
                    }
                    //console.log(cellContent);
                    let finalContent = null;
                    switch (columnNode.type) {
                        case CtTable.ColumnType.Image:
                            let url: string = cellContent.image;
                            let image: Image = images.find((each: Image) => each.url == url);
                            if (image) {
                                console.assert(image.image_format == ImageFormat.SVG);
                                const svgImage = new SVGimage(image.image_data);


                                this.addSVGImageToSheet({
                                    workbook: this.workbook,
                                    sheet: this.sheet,
                                    svgImage: svgImage,
                                    colWidth: this.computeColumnWidth(c),
                                    rowHeight: rowHeight,
                                    col: columnCount - 1,
                                    row: rowCount
                                });

                            }


                            break;
                        case CtTable.ColumnType.Skyline:
                            const plotData: SkylineData =  {plotLabel: null, data:cellContent.data};
                            //console.log(value);

                            this.skPlot.setDataAndUpdatePlot(plotData);

                            let svg: string = this.skPlot.getSVG();
                            const svgImage = new SVGimage(svg);

                            this.addSVGImageToSheet({
                                    workbook: this.workbook,
                                    sheet: this.sheet,
                                    svgImage: svgImage,
                                    colWidth: this.computeColumnWidth(c),
                                    rowHeight: rowHeight,
                                    col: columnCount - 1,
                                    row: rowCount
                                });
                            break;
                        case CtTable.ColumnType.DataSummary:
                            const ds: DataSummary = cellContent;

                            const dso :DataSummaryObject = new DataSummaryObject(ds);
                            finalContent = dso.preferredName + "\n" + dso.numberOfStudies;
                            break;


                        case CtTable.ColumnType.Fingerprint:
                        case CtTable.ColumnType.Float:
                        case CtTable.ColumnType.Int:
                        case CtTable.ColumnType.String:
                        case CtTable.ColumnType.FloatPercent:
                           finalContent = cellContent;

                           break;

                        default:
                            const formatterClass = CtValueView.GetTextFormatter(columnNode.type);
                            if (formatterClass ) {
                                const formatter = new formatterClass(null);
                                finalContent = formatter.generateMultiLineText(cellContent);

                            }

                    }

                   if (finalContent !== '' && finalContent !== '\n' && finalContent !== null && finalContent !== undefined) {
                        xlsRow[columnNode.id] = finalContent; // must use the same key as in this.sheet.columns
                    }

                }

                this.sheet.addRow(xlsRow);
                if (rowHeight > 0) {
                    // xlsRow.height = rowHeight; // has no effect
                    this.sheet.getRow(rowCount+1).height = rowHeight;
                }
            }

            if (callBack) {
                callBack();
            }

        });



    }

    protected computeColumnWidth(columnNode: CtTable.Node) {
        let result = -1;
        switch( columnNode.type) {
            case CtTable.ColumnType.Image:
            case CtTable.ColumnType.Skyline:
                result =  this.calColWidth(this.imageColumnWidth);
                break;
            default:
                result = this.defaultColumnWidth;


        }

        return result;
    }
    /**
     * The column width has to be provided in number of characters. This empirical method
     * converts the number of pixel into the column width in characters
     * @param {number} px
     * @returns {number}
     */
    public calColWidth(px: number): number {
        return 50 /8.7 * 2.6;
    }

    protected computeRowHeight(row: CtTable.SortedRow) : number {

        let max = this.defaultRowHeight;

        if (row.is_virtual) {
            return max;
        }
        // find out if there is an image
        const columnNodes: CtTable.NodeArray = this.getColumnNodes();
        for( let c of columnNodes) {
            let cellContent = c.getter(row);
            if(cellContent !== undefined) {
                switch (c.type) {
                    case CtTable.ColumnType.Image:
                    case CtTable.ColumnType.Skyline:
                        max = Math.max(max, this.imageRowHeight);
                        break;
                    default:
                        max = Math.max(max, this.defaultRowHeight);
                }
            }


        }

        return max;

    }
    //
    protected createColumns(): any[] {
        const gRootNode: GenericNode<CtTable.Node> = this.table.buildGenericNodeFromColumnNode(null);

        let cols: any[] = [];

        gRootNode.enumerateChildParents((node: GenericNode<CtTable.Node>, parents: GenericNode<CtTable.Node>[])=>{
            const colNode: CtTable.Node = node.data;
            if(node.isRoot()) {
                return;
            }
            if(node.isLeaf()) {
                let colHeader =  node.data.headerName;
                if(node.isFirstChild()) {
                    let pLabel:string = parents.filter((p:GenericNode<CtTable.Node>)=>!p.isRoot()).reverse().map((p:GenericNode<CtTable.Node>)=>p.data.headerName).join("\n");
                    colHeader = pLabel + "\n" + colHeader;

                }

                const col: any = {
                     header: colHeader,
                    key: colNode.id,
                    width: this.computeColumnWidth(colNode),
                    //outlineLevel: 1 // what is this for?

                 };
                cols.push(col);

            }
        });



/*
        let cols: any[] = [];

        for( let c of columnNodes) {
            const colNode: CtTable.Node = c;

            const col: any = {
                 header: colNode.headerName,
                key: colNode.id,
                width: this.computeColumnWidth(colNode),
                //outlineLevel: 1 // what is this for?

            };
            cols.push(col);

        }
*/
        return [gRootNode.computeMaxDepth(), cols];

    }

    protected getColumnNodes(): CtTable.NodeArray {
        return this.table.getFlatNodes(); //there is another method - which one to use?
    }


    protected getRows(): CtTable.SortedRow[] {
        let rows: CtTable.SortedRow[] = this.table.RowsVirtual;
        if (this.mustSortRows) {
            rows = rows.slice(0);  //make a shallow copy
            rows.sort( (r1: CtTable.SortedRow, r2: CtTable.SortedRow ) => r1.sort_index - r2.sort_index);
        }

        return rows;

    }
    /**
     * Used to collect all images using remote call
     * @returns {Observable<any>}
     */

    protected generateMultipleImageObservable() : Observable<any> {
        let columnNodes: CtTable.NodeArray = this.getColumnNodes();
        let rows:CtTable.SortedRow[] = this.getRows();
        const urlImageMap = {};
        const imageObservableList = [];

        // collect all remote image URLs
        for( let r of rows) {
            for( let c of columnNodes) {
                let viewerClass: any = CtValueView.Registry[c.type]; //todo: typing, is a metaclass?
                if ( viewerClass === CtImageView) {
                    let cellContent = c.getter(r);
                    if (cellContent.image && cellContent.image[0] == '/') { //how to detect it is a URL?
                        urlImageMap[cellContent.image] = null;
                    }
                }
            }
        }

        // create all observables
        for( let url in urlImageMap) {
            imageObservableList.push(
                CtImageView.getRemoteImage(this.mBackend, this.mCancel, url)
            );
        }

        // create a merged observable
        const multipleObservable: Observable<any>  = Observable.forkJoin(imageObservableList);



        return multipleObservable;

    }

    protected addSVGImageToSheet(
        {workbook, sheet, svgImage, colWidth, rowHeight, col, row}:
            { workbook: ExcelJS.Workbook, sheet: ExcelJS.Worksheet, svgImage: SVGimage, colWidth: number, rowHeight: number, col: number, row: number }): void {

            // works OK if colWidth and rowHeight are the same

        const png64: string = svgImage.createPngFromSVG();

        if ( ! png64 ) {
            console.assert(false, 'SVG to PNG failed');
            return;
        }
        let svgImageId: number = workbook.addImage({
            base64: png64,
            extension: 'png',
        });

        let dh = 0;
        let dw = 0;

        //setting the aspect ratio does not work correctly yet
        // might be a bug in the library - see https://github.com/guyonroche/exceljs/issues/467
        if (colWidth > 0 && rowHeight > 0) {

            const cellAspectRatio: number = colWidth / rowHeight;

            const imageAspectRatio: number = svgImage.getAspectRatio();

            if (!isNaN(imageAspectRatio)) {
                if (imageAspectRatio > cellAspectRatio) {
                    dh = cellAspectRatio / imageAspectRatio;
                } else {
                    dw = imageAspectRatio / cellAspectRatio;
                }


            } else {
                console.assert(false, 'incorrect image aspect ratio');
            }
        } else {
            console.assert(false, 'incorrect table cell aspect ratio');

        }
        //dw = 0; dh=0;
        //dw = 0; dh = 0.0;
        //console.log(dh, dw);
        sheet.addImage(svgImageId, {
            tl: { col: col + dw, row: row + dh / 2 }, //top left
            br: { col: col + 1, row: row + 1 }, //bottom right - substracting value messes up the plot
            /*editAs: 'oneCell'*/});
    }

}
