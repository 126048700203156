// rxjs
import 'rxjs/Rx';


// import { Request, RequestOptionsArgs, Response } from "@angular/http";
import { Observable } from "rxjs/Observable";

// import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Subject } from 'rxjs/Subject';
//import { Subscription } from 'rxjs/Subscription';

// angular
import {Component, ViewChild, ElementRef} from '@angular/core';
import { HostListener, HostBinding } from '@angular/core';
//
import {CtValueView, CtValueViewRegisterDecorator} from "../CtValueView";


import {CtTable} from "../CtTable";
import {MnTableFocus} from "../CtTable/MnTableFocus";
import {MnBackend} from "@mn/core";
import {CtSelectionService} from "../CtSelectionService";
import {MnRowMarker} from "../MnMarker";
import {MnSvgFix} from "../MnSvgFix";

export enum ImageFormat {
    SVG ='svg+xml',
}


export interface Image {
    image_format: ImageFormat;
    image_data: string;
    url : string;
    width: number;
    height: number;
    id: number;
}






/***
 * template: `<img src="{{imageData}}" alt="compound image"/.
 *
 * does not work for dynamic image -> security exception
 *
 *
 * THis version cannot display a IMG (only raw SVG) - bug to be fixed
 *
 * According to AT, if the option format=json is not used, one should receive a ready to use string
 * for the src argument of the <img>
 *    Does not work when using MnBackend, a JSON object is always returned
 *    THus one has to use the <span> object
 *
 *
 * *ngIf is used to show or hide an element
 *
 *
 * MOreover: IE dannot display inline SVG. Thus the span with innerHtml is to be kept anyway
 *
 * See https://stackoverflow.com/questions/39883142/ie11-wont-display-inline-svg-background-image
 *
 * E11 not tested
 *
 */
//         this.mRenderer.setProperty(this.image2Element.nativeElement,'src', 'data:image/svg+xml;utf8,'+imageData);


@Component({
    selector: 'ct-image-view',
    templateUrl: `./CtImageView.html`,
    styleUrls: [`./CtImageView.css`]
})

@CtValueViewRegisterDecorator(CtValueView.Types.Image)

export class CtImageView extends CtValueView {

    private static  imageCount: number = 0;

    private mSpacing:number = 3;
    private mHeight:number = 0;
    private mWidth:number = 0;

    protected mValue: CtTable.CtRowValue;
    protected previousMvalue: CtTable.CtRowValue; //used to check if the picture has changed

    protected mHeader = '';
    protected mFooter = '';

    private mCancel:Subject<any> = new Subject();
    private mDestroy:Subject<any> = new Subject();

    @ViewChild('svgImage') svgElement: ElementRef;

    // the picture is moved to the right to show the tree structure (child is indented vs his parent
    @HostBinding('style.margin-left.px') mIndent = 0;

    // change background color if the row is selected
    @HostBinding('class.x-selected') mSelected = false;
    @HostBinding('class.y-selected') mYSelected = false;
    @HostBinding('class.m-selected') mMSelected = false;

    protected isCondensed: boolean = false;

    protected hasHeader: boolean = true;
    protected hasFooter: boolean = true;



    constructor(service:CtValueView.Service, private  mBackend: MnBackend, private mSelectionService:CtSelectionService, private mRowMarker:MnRowMarker, private mTableFocus:MnTableFocus ) {
        super(service);
        this.mSelectionService.RowChangeSubject
            .takeUntil(this.mDestroy)
            .subscribe(()=> {
                this.updateSelection();
            });
        this.mRowMarker.ChangeSubject
            .takeUntil(this.mDestroy)
            .subscribe(()=> {
                this.updateYSelection();
            });
        this.mTableFocus.ChangeSubject
            .takeUntil(this.mDestroy)
            .subscribe((cell)=> {
                this.updateTableFocus(cell);
            });
    }

    public update(parameters:CtValueView.Parameters) {
        // if a second request is send before the previous one is completed,
        // the previous one must be cancelled - this is done with takeUntil

        //this.mUrl = parameters.value;
        this.mValue = parameters.value;
        this.mIndent = this.mValue.indent * 20; // for
        //console.log(this.mValue);

        if (this.mValue.headerLabel) {
            this.mHeader = this.mValue.headerLabel();
        } else {
            this.mHeader = null;
        }
        if (this.mValue.footerLabel) {
            this.mFooter = this.mValue.footerLabel();
        } else {
            this.mFooter = null;
        }

        if (parameters.size.height < 34) {
            this.isCondensed = true;
            this.mHeight = parameters.size.height - (2*this.mSpacing) -1;
            this.mWidth = parameters.size.width - (this.mIndent) -16;
            //console.log(this.mWidth);
            return;
        }

        //design wish for the table: picture width should be equal to height: always square!
        //const dim: number = Math.min(parameters.size.height, parameters.size.width);
        let dim: number = parameters.size.height - (2*this.mSpacing) -1; //currently 3margin around + 1px bottom obrder
        this.mWidth = this.mHeight = dim;

        // there is no need to download the same image again if it is the same
        // resizing the image is done by setting the SVG width and height to 100%
        if (this.previousMvalue && this.previousMvalue.image  && this.previousMvalue.image ===  this.mValue.image) {
            // console.log("CtImageView: same image - no need to download again");
            return;
        }



        this.mCancel.next();

        this.previousMvalue = this.mValue;

        //console.log(parameters);

        // console.log(parameters);
        // console.log(dim); the indigo SVG generator does not generate picture with the right size

        // good parameters for the SVG generated by RDkit (althought the SVG from RDkit is not good)
       // const [imgHeight, imgWidth] = CtImageView.defaultImageSize();

        CtImageView.getRemoteImage(this.mBackend, this.mCancel, this.mValue.image).subscribe(
                (jsonImage: Image) => this.updateImage(jsonImage),
                error => console.log("Error loading image: ", error)
            );

        // this.mBackend.get(this.mValue.image + '?format=json&height='+ imgHeight+'&width='+ imgWidth) // using format=json or not makes no difference
        //     .takeUntil(this.mCancel)
        //     .map(res => res.json())
        //     .subscribe(
        //         (jsonImage: Image) => this.updateImage(jsonImage),
        //         error => console.log("Error loading image: ", error)
        //     );
        this.updateSelection();
    }

    public static defaultImageSize(): number[] {
        // good parameters for the SVG generated by RDkit (althought the SVG from RDkit is not good)
        const imgHeight = 320;
        const imgWidth = 400;

        return [imgWidth, imgHeight];

    }

    public static getRemoteImage( backend:  MnBackend, cancel:Subject<any>, imageUrl:string) : Observable<any> {
        const [imgHeight, imgWidth] : number[] = CtImageView.defaultImageSize();

        return backend.get( imageUrl+ '?format=json&height='+ imgHeight+'&width='+ imgWidth) // using format=json or not makes no difference
            .takeUntil(cancel).map(res => {
                const img: Image = res.json();
                // add additional information
                img.url = imageUrl;
                img.width = imgWidth;
                img.height = imgHeight;
                img.id = this.imageCount ++;

                return img;

            });
    }


    protected updateImage(image: Image) {
/*
        //console.log(image);

        let imageData: string = image.image_data;
        //imageData = 'data:image/svg+xml;base64, PD94bWwgdmVyc2lvbj0nMS4wJyBlbmNvZGluZz0naXNvLTg4NTktMSc/Pgo8c3ZnOnN2ZyB2aWV3Qm94PScwIDAgODAgODAnIHZlcnNpb249JzEuMScgYmFzZVByb2ZpbGU9J2Z1bGwnIHhtbG5zOnN2Zz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHhtbG5zOnhsaW5rPSdodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rJyB4bWw6c3BhY2U9J3ByZXNlcnZlJyB3aWR0aD0nODBweCcgaGVpZ2h0PSc4MHB4JyA+Cjxzdmc6cmVjdCBzdHlsZT0nb3BhY2l0eTowLjA7ZmlsbDojMzMzMzMzO3N0cm9rZTpub25lJyB3aWR0aD0nODAnIGhlaWdodD0nODAnIHg9JzUuMCcgeT0nNS4wJz4gPC9zdmc6cmVjdD4KPHN2Zzp0ZXh0IHg9JzUwJScgeT0nNDAlJyBzdHlsZT0nZm9udC1zaXplOjE4cHg7Zm9udC1zdHlsZTpub3JtYWw7Zm9udC13ZWlnaHQ6bm9ybWFsO2ZpbGwtb3BhY2l0eToxO3N0cm9rZTpub25lO2ZvbnQtZmFtaWx5OnNhbnMtc2VyaWY7dGV4dC1hbmNob3I6bWlkZGxlO2ZpbGw6IzMzMzMzMyc+PHN2Zzp0c3Bhbj5ObyBzdHJ1Y3R1cmU8L3N2Zzp0c3Bhbj48L3N2Zzp0ZXh0Pgo8c3ZnOnRleHQgeD0nNTAlJyB5PSc1OCUnIHN0eWxlPSdmb250LXNpemU6MThweDtmb250LXN0eWxlOm5vcm1hbDtmb250LXdlaWdodDpub3JtYWw7ZmlsbC1vcGFjaXR5OjE7c3Ryb2tlOm5vbmU7Zm9udC1mYW1pbHk6c2Fucy1zZXJpZjt0ZXh0LWFuY2hvcjptaWRkbGU7ZmlsbDojMzMzMzMzJz48c3ZnOnRzcGFuPmF2YWlsYWJsZTwvc3ZnOnRzcGFuPjwvc3ZnOnRleHQ+Cjwvc3ZnOnN2Zz4K';
        //imageData =  '<svg width="100" height="100">'+
        //    '<circle cx="50" cy="50" r="40" stroke="green" stroke-width="4" fill="yellow" />'+
         //   '</svg>';

        // JSME- OCL SVG does not work well: no dynamic box size
        //imageData= this.JME_OCL_SVG;

        //imageData = this.JME_SVG; // same problem
        // the best solution might be JSME in depict mode - it rescale it self automatically
        // it has a maximal bond length such that small compound are displayed correctly

        // TODO: reuse SVGimage class

        imageData = imageData.replace(/<(\/?)svg:/g, '<$1'); // XML namespace issue with RD kit SVG - the browser messes up the SVG

        // xlink:href is deprecated
        imageData = imageData.replace('xlink:href', 'href');

        // Ingigo SVG issue - looks like the one SVG is affected by the glyphs definition in another SVG because they
        // use the same ID's - this is a browser bug (Firefox and Chrome, may be others)
        // very visible in wid 7 id = 71

        const indigoHrefPatchRe = /\b(glyph[\d-]+)\b/g;
        imageData = imageData.replace(indigoHrefPatchRe, '$1' + '_' +  image.id);

        const re = /(width|height)=("|')\d+(pt|px)?("|')/;
        imageData = imageData.replace(re, '$1="100%"')
                    .replace(re, '$1="100%"');
        this.svgElement.nativeElement.innerHTML = imageData;
*/
        this.svgElement.nativeElement.innerHTML = MnSvgFix.apply(image.image_data);

    }
    // attempt to grow the picture on mouse over - failed - zindex issue
    /*
    mZindex: any = '1000';


    @HostListener('mouseenter')
    onMouseEnter() {
        if(false && this.svgElement) {
            console.log('mouseenter');
            this.mWidth *= 2;
            this.mHeight *= 2;
            let z: any  = window.getComputedStyle(this.svgElement.nativeElement, null).getPropertyValue('z-index' );
            console.log(z);
            this.mZindex = '100000';
            let afterZ = window.getComputedStyle(this.svgElement.nativeElement, null).getPropertyValue('z-index' );
            console.log(afterZ);
            let style = this.svgElement.nativeElement.style;
            console.log(style.getPropertyValue('z-index'));
            style.setProperty('z-index', '10000');
            afterZ = window.getComputedStyle(this.svgElement.nativeElement, null).getPropertyValue('z-index' );
            console.log(afterZ);
            console.log(style.getPropertyValue('z-index'));

       }
    }
    @HostListener('mouseleave')
    onMouseLeave()
    {
        let z: any  = window.getComputedStyle(this.svgElement.nativeElement, null).getPropertyValue('z-index' );
        console.log('HERE: ' + z);
        if(false && this.svgElement) {
            console.log('mouseleave');
            this.mWidth /= 2;
            this.mHeight /= 2;
        }
    }
*/

    updateSelection() {
        if (this.mValue) {
            this.mSelected = this.mValue.isRowSelected(); // my CSS style will change accordingly thanks to the @Hosbinding directive
        }
    }

    updateYSelection() {
        if (this.mValue) {
            //console.log('updateYSelection',this.mValue,this.mRowMarker);
            this.mMSelected = this.mRowMarker.has(this.mValue.row.record_index); // my CSS style will change accordingly thanks to the @Hosbinding directive
        }
    }

    updateTableFocus(cell:MnTableFocus.Cell) {
        if (this.mValue) {
            //console.log('updateYSelection',this.mValue,this.mRowMarker);
            this.mYSelected = cell.row == this.mValue.row.record_index; // my CSS style will change accordingly thanks to the @Hosbinding directive
        }
    }

    toggleSelection() {
        //console.log("DDDDDDDDDDDDDDDDD");

        if (this.mValue && this.mRowMarker && this.mRowMarker.active()) {
            //console.log('toggleSelection',this.mValue);
            this.mRowMarker.toggle(this.mValue.row.record_index);
        }
        if (this.mSelectionService.RowMode == CtSelectionService.RowMode.None) {
            return;
        }
        this.mSelectionService.selectRow(this.mValue.row,this.mValue.isRowSelected() ? false : true);
    }

    ngOnDestroy() {
        this.mDestroy.next();
        this.mCancel.next();
        this.mCancel.unsubscribe(); //BB found web example
        this.destroy(); // better  to use super()?
        //console.log(this.mUrl + " destroyed");

    }

/*
    const JME_OCL_SVG: string = `<svg id="" xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:chemical="http://www.ch.ic.ac.uk/chemime/" width="400px" height="300px" viewBox="0 0 400 300">
	<style> # {pointer-events:none; }  # .event  { pointer-events:all;}  </style>
	<chemical:x-mdl-molfile>[C@@H]1(O)[C@@H](C(C)C)CC[C@H](C)C1\\nJME\\n\\n  0  0  0  0  0  0  0  0  0  0999 V3000\\nM  V30 BEGIN CTAB\\nM  V30 COUNTS 11 11 0 0 0\\nM  V30 BEGIN ATOM\\nM  V30 1 C 3.6373 0.7000 0.0000 0\\nM  V30 2 C 3.6373 2.1000 0.0000 0\\nM  V30 3 C 2.4249 2.8000 0.0000 0\\nM  V30 4 C 1.2124 2.1000 0.0000 0\\nM  V30 5 C 1.2124 0.7000 0.0000 0\\nM  V30 6 C 2.4249 0.0000 0.0000 0\\nM  V30 7 C 0.0000 0.0000 0.0000 0\\nM  V30 8 O 4.8497 0.0000 0.0000 0\\nM  V30 9 C 4.8497 2.8000 0.0000 0\\nM  V30 10 C 6.0622 2.1000 0.0000 0\\nM  V30 11 C 4.8497 4.2000 0.0000 0\\nM  V30 END ATOM\\nM  V30 BEGIN BOND\\nM  V30 1 1 1 2\\nM  V30 2 1 2 3\\nM  V30 3 1 3 4\\nM  V30 4 1 4 5\\nM  V30 5 1 5 6\\nM  V30 6 1 6 1\\nM  V30 7 1 5 7 CFG=1\\nM  V30 8 1 1 8 CFG=1\\nM  V30 9 1 2 9 CFG=3\\nM  V30 10 1 9 10\\nM  V30 11 1 9 11\\nM  V30 END BOND\\nM  V30 END CTAB\\nM  END\\n</chemical:x-mdl-molfile>
	<text x="199.99" text-anchor="middle" y="215.14" font-family=" Helvetica" font-size="12" fill="rgb(160,0,0)">this enantiomer</text>
	<text x="207.39" text-anchor="middle" y="181.99" font-family=" Helvetica" font-size="9" fill="rgb(160,0,0)">S</text>
	<text x="215.84" text-anchor="middle" y="173.85" font-family=" Helvetica" font-size="9" fill="rgb(160,0,0)">abs</text>
	<text x="207.14" text-anchor="middle" y="158" font-family=" Helvetica" font-size="9" fill="rgb(160,0,0)">R</text>
	<text x="210.39" text-anchor="middle" y="146.69" font-family=" Helvetica" font-size="9" fill="rgb(160,0,0)">abs</text>
	<text x="165.82" text-anchor="middle" y="181.99" font-family=" Helvetica" font-size="9" fill="rgb(160,0,0)">S</text>
	<text x="174.27" text-anchor="middle" y="173.84" font-family=" Helvetica" font-size="9" fill="rgb(160,0,0)">abs</text>
	<text x="231.17" text-anchor="middle" y="189.99" font-family=" Helvetica" font-size="14" fill="rgb(255,13,13)">O</text>
	<text x="241.17" text-anchor="middle" y="189.99" font-family=" Helvetica" font-size="14" fill="rgb(255,13,13)">H</text>
	<line x1="210.39" y1="173.99" x2="210.39" y2="150" style="stroke:rgb(0,0,0);stroke-width:1.43"/>
	<line x1="210.39" y1="150" x2="189.6" y2="138" style="stroke:rgb(0,0,0);stroke-width:1.43"/>
	<line x1="189.6" y1="138" x2="168.82" y2="150" style="stroke:rgb(0,0,0);stroke-width:1.43"/>
	<line x1="168.82" y1="150" x2="168.82" y2="173.99" style="stroke:rgb(0,0,0);stroke-width:1.43"/>
	<line x1="168.82" y1="173.99" x2="189.6" y2="185.99" style="stroke:rgb(0,0,0);stroke-width:1.43"/>
	<line x1="189.6" y1="185.99" x2="210.39" y2="173.99" style="stroke:rgb(0,0,0);stroke-width:1.43"/>
	<polygon points="168.82,173.99 157.76,178.84 159.09,181.15 " style="fill:rgb(160,0,0);stroke:rgb(160,0,0);stroke-width:1"/>
	<polygon points="159.09,181.15 157.76,178.84 146.7,183.69 149.37,188.3 " style="fill:rgb(160,0,0);stroke:rgb(160,0,0);stroke-width:1"/>
	<polygon points="210.39,173.99 217.07,178.91 217.99,177.32 " style="fill:rgb(160,0,0);stroke:rgb(160,0,0);stroke-width:1"/>
	<polygon points="217.99,177.32 217.07,178.91 223.75,183.83 225.59,180.65 " style="fill:rgb(160,0,0);stroke:rgb(160,0,0);stroke-width:1"/>
	<line x1="213.02" y1="148.91" x2="212.64" y2="148.26" style="stroke:rgb(160,0,0);stroke-width:1.43"/>
	<line x1="215.65" y1="147.82" x2="214.9" y2="146.52" style="stroke:rgb(160,0,0);stroke-width:1.43"/>
	<line x1="218.29" y1="146.73" x2="217.16" y2="144.79" style="stroke:rgb(160,0,0);stroke-width:1.43"/>
	<line x1="220.92" y1="145.65" x2="219.42" y2="143.05" style="stroke:rgb(160,0,0);stroke-width:1.43"/>
	<line x1="223.55" y1="144.56" x2="221.68" y2="141.31" style="stroke:rgb(160,0,0);stroke-width:1.43"/>
	<line x1="226.18" y1="143.47" x2="223.93" y2="139.58" style="stroke:rgb(160,0,0);stroke-width:1.43"/>
	<line x1="228.82" y1="142.39" x2="226.19" y2="137.84" style="stroke:rgb(160,0,0);stroke-width:1.43"/>
	<line x1="231.45" y1="141.3" x2="228.45" y2="136.1" style="stroke:rgb(160,0,0);stroke-width:1.43"/>
	<line x1="231.17" y1="138" x2="251.96" y2="150" style="stroke:rgb(0,0,0);stroke-width:1.43"/>
	<line x1="231.17" y1="138" x2="231.17" y2="114" style="stroke:rgb(0,0,0);stroke-width:1.43"/>
	<line id=":Bond:0-1" class="event" x1="210.39" y1="173.99" x2="210.39" y2="150" stroke-width="8" stroke-opacity="0"/>
	<line id=":Bond:1-2" class="event" x1="210.39" y1="150" x2="189.6" y2="138" stroke-width="8" stroke-opacity="0"/>
	<line id=":Bond:2-3" class="event" x1="189.6" y1="138" x2="168.82" y2="150" stroke-width="8" stroke-opacity="0"/>
	<line id=":Bond:3-4" class="event" x1="168.82" y1="150" x2="168.82" y2="173.99" stroke-width="8" stroke-opacity="0"/>
	<line id=":Bond:4-5" class="event" x1="168.82" y1="173.99" x2="189.6" y2="185.99" stroke-width="8" stroke-opacity="0"/>
	<line id=":Bond:5-0" class="event" x1="189.6" y1="185.99" x2="210.39" y2="173.99" stroke-width="8" stroke-opacity="0"/>
	<line id=":Bond:4-6" class="event" x1="168.82" y1="173.99" x2="148.03" y2="185.99" stroke-width="8" stroke-opacity="0"/>
	<line id=":Bond:0-7" class="event" x1="210.39" y1="173.99" x2="231.17" y2="185.99" stroke-width="8" stroke-opacity="0"/>
	<line id=":Bond:1-8" class="event" x1="210.39" y1="150" x2="231.17" y2="138" stroke-width="8" stroke-opacity="0"/>
	<line id=":Bond:8-9" class="event" x1="231.17" y1="138" x2="251.96" y2="150" stroke-width="8" stroke-opacity="0"/>
	<line id=":Bond:8-10" class="event" x1="231.17" y1="138" x2="231.17" y2="114" stroke-width="8" stroke-opacity="0"/>
	<circle id=":Atom:0" class="event" cx="210.39" cy="173.99" r="8" fill-opacity="0"/>
	<circle id=":Atom:1" class="event" cx="210.39" cy="150" r="8" fill-opacity="0"/>
	<circle id=":Atom:2" class="event" cx="189.6" cy="138" r="8" fill-opacity="0"/>
	<circle id=":Atom:3" class="event" cx="168.82" cy="150" r="8" fill-opacity="0"/>
	<circle id=":Atom:4" class="event" cx="168.82" cy="173.99" r="8" fill-opacity="0"/>
	<circle id=":Atom:5" class="event" cx="189.6" cy="185.99" r="8" fill-opacity="0"/>
	<circle id=":Atom:6" class="event" cx="148.03" cy="185.99" r="8" fill-opacity="0"/>
	<circle id=":Atom:7" class="event" cx="231.17" cy="185.99" r="8" fill-opacity="0"/>
	<circle id=":Atom:8" class="event" cx="231.17" cy="138" r="8" fill-opacity="0"/>
	<circle id=":Atom:9" class="event" cx="251.96" cy="150" r="8" fill-opacity="0"/>
	<circle id=":Atom:10" class="event" cx="231.17" cy="114" r="8" fill-opacity="0"/>
</svg>`;

    // SVG generated by JSME has no viewbox and without a viewbox it does not show up
    // also, the viewbox should be dynamic - one should calculate the minimal bounding box
    const JME_SVG = `<svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="380" height="340" viewBox="0 0 380 340"><style> text { cursor: default; } </style><g transform="scale(0.03333333333333333)">
<rect x="0" y="0" height="10200" width="11400" stroke="none" fill="white" />
<line x1="4988" y1="5113" x2="4988" y2="4364" stroke="black" stroke-width="30" />
<polygon points="4290,4067 4988,4364 4379,3911" stroke="none" fill="black" />
<line x1="4334" y1="3989" x2="3686" y2="4364" stroke="black" stroke-width="30" />
<line x1="3686" y1="4364" x2="3686" y2="5113" stroke="black" stroke-width="30" />
<line x1="3686" y1="5113" x2="4334" y2="5488" stroke="black" stroke-width="30" />
<polygon points="4379,5566 4988,5113 4290,5410" stroke="none" fill="black" />
<line x1="5737" y1="5113" x2="5737" y2="4364" stroke="black" stroke-width="30" />
<line x1="4988" y1="4364" x2="5737" y2="4364" stroke="black" stroke-width="30" />
<line x1="4988" y1="5113" x2="5737" y2="5113" stroke="black" stroke-width="30" />
<line x1="5695" y1="4321" x2="6225" y2="3791" stroke="black" stroke-width="30" />
<line x1="5780" y1="4406" x2="6310" y2="3876" stroke="black" stroke-width="30" />
<polygon points="6331,5580 5737,5113 6204,5707" stroke="none" fill="black" />
<line x1="6326" y1="5659" x2="6127" y2="6382" stroke="black" stroke-width="30" />
<line x1="6210" y1="5628" x2="6012" y2="6351" stroke="black" stroke-width="30" />
<line x1="6268" y1="5644" x2="6991" y2="5445" stroke="black" stroke-width="30" />
<line x1="6991" y1="5445" x2="7714" y2="5253" stroke="black" stroke-width="30" />
<line x1="6968" y1="5358" x2="7691" y2="5166" stroke="black" stroke-width="30" />
<line x1="7014" y1="5532" x2="7737" y2="5340" stroke="black" stroke-width="30" />
<rect x="6028" y="3623" height="450" width="450" stroke="none" fill="white" />
<text x="6118" y="4013"  font-size="390px" font-family="Helvetica" fill="red">O</text>
<rect x="6766" y="5235" height="450" width="420" stroke="none" fill="white" />
<text x="6856" y="5625"  font-size="390px" font-family="Helvetica" fill="rgb(64,64,64)">C</text>
<rect x="7489" y="5043" height="450" width="420" stroke="none" fill="white" />
<text x="7579" y="5433"  font-size="390px" font-family="Helvetica" fill="blue">N</text>
<polygon points="11184,9984 11400,10092 11184,10200" stroke="none" fill="blue" />
</g></svg>`;

*/
}


