// angular
import {Component,HostBinding} from '@angular/core';

//
import {CtValueView, CtValueViewRegisterDecorator} from "../CtValueView";
import {MnTextService} from "@mn/core";

interface EnumValue {
    value: any;
    enum_name: string;
    enum_object: any;
}

@Component({
    selector: 'ct-enum-view',
    template: `<div class="ct-value-view" [ngClass]="mClassInner" [innerHtml]="mTextInner"></div>`
})
@CtValueViewRegisterDecorator(CtValueView.Types.Enum)
export class CtEnumView extends CtValueView {

    /*private mValue:any = {
        value: "",
    };*/

    private mClassInner:string;
    private mTextInner:string;
    @HostBinding('class') mClass: string = "";

    constructor(service:CtValueView.Service, private mText:MnTextService) {
        super(service);
    }

    public update(parameters:CtValueView.Parameters) {
        //console.log(JSON.stringify(parameters.value));
        //this.mValue = this.mText.text(parameters.value);
        this.mClass = parameters.value.enum_name;
        this.mClassInner = parameters.value.value;
        this.mTextInner = <string>this.mText.text('ct.enum.'+this.mClass+'.'+this.mClassInner);
    }

    ngOnDestroy() {
        this.destroy();
    }
}

