/**
 * Created by joerg on 7/17/17.
 */

// rxjs
import 'rxjs/Rx';
import { Observable } from 'rxjs/Observable';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Subject } from 'rxjs/Subject';
import { Subscription } from 'rxjs/Subscription';

// angular
import {Component,Input,ViewChild,Injectable,Output,EventEmitter} from '@angular/core';

// aggrid
import {GridOptions,GridApi,ColumnApi,ColDef,Column,RowNode} from 'ag-grid/main'

//
import {Backend} from "../BackendInterfaces";

import {MnTable} from "../CtTable/CtTable";
import {MnTableView} from "../CtTable/MnTableView";

import {MnWorkflow} from "../MnWorkflow";
import {MnWorkflowService} from "../MnWorkflowService";
import {MnWorkflowObserver} from "../MnWorkflowObserver";
import {MnWorkflowComponent} from "../MnWorkflowComponent"
import {MnWorkflowComponentStatic} from "../MnWorkflowComponent";


@Component({
    selector: 'mn-state-view2',
    templateUrl: './MnStateView2.html',
})
export class MnStateView2 extends MnWorkflowObserver {

    static Registry:MnWorkflow.Registry<MnWorkflowComponent> = new MnWorkflow.Registry<MnWorkflowComponent>();

    private mTabs:MnStateView2.Tab[] = [];
    private mCurrentTab:MnStateView2.Tab = null;
    private mTableLayout:MnTableView.Layout = MnTableView.Layout.L0;
    private mTable:MnTable = null;

    private mListMode:boolean = false;
    private mFooterMode:boolean = false;

    @ViewChild('tabbar') mTabBar = null;

    constructor(private mWorkflowService:MnWorkflowService) {
        super(mWorkflowService);
    }
    protected onState(state:Backend.FullState) {
        let tabs:MnStateView2.Tab[] = [
            {
                id: "",
                label: "Data Table",
                select: -1,
                description: null,
                closable: false,
            }
        ];
        MnWorkflowComponent.all(MnStateView2.Registry.all(),(component_descriptions:MnWorkflowComponent.Description[])=>{
            console.log(component_descriptions);
            for (let i = 0, l = component_descriptions.length; i < l; i++) {
                let cd:MnWorkflowComponent.Description = component_descriptions[i];
                let ctabs:MnStateView2.Tab[] = cd.component.createStateTabs(this.Workflow).map(t=>{
                    t.description = cd;
                    return t;
                });
                tabs = tabs.concat(ctabs);
            }
            this.mTabs = tabs;
            this.setCurrentTab(tabs[0]);
        });
    }

    protected onTable(table:MnTable) {
        this.mTable = table;
    }

    private removeTab(tab:MnStateView2.Tab) {
        let index = this.mTabs.indexOf(tab);
        if (index > -1) {
            this.mTabs.splice(index, 1);
        }
        this.mTabs = this.mTabs.slice();
        //this.mTabBar.selectedIndex = 0;
        this.setCurrentTab(this.mTabs[0]);
    }

    private onTab(index) {
        this.setCurrentTab(this.mTabs[index]);
    }

    private setCurrentTab(tab:MnStateView2.Tab) {
        this.mCurrentTab = tab;
        if (tab) {
            if (tab.description) {
                if (tab.select == 0) {
                    this.mTableLayout = MnTableView.Layout.L2;
                } else if (tab.select > 0) {
                    this.mTableLayout = MnTableView.Layout.L1;
                }
            } else {
                this.mTableLayout = MnTableView.Layout.L0;
            }
        }
    }

    private onRemoveTab(tab:MnStateView2.Tab) {
        var index = this.mTabs.indexOf(tab);
        if (index > -1) {
            this.mTabs.splice(index, 1);
            console.log("Removed Tab");
            this.onTab(0);
        }
    }

    toggleListMode() {
        this.mListMode = !this.mListMode;
    }

    toggleFooterMode() {
        this.mFooterMode = !this.mFooterMode;
    }

    ngOnDestroy() {
        this.destory();
    }

}
export namespace MnStateView2 {

    export interface Component {
        createStateTabs: (workflow:MnWorkflow)=>MnStateView2.Tab[];
    }

    export interface Tab {
        id:string;
        label:string;
        select:number;
        description:MnWorkflowComponent.Description;
        closable:boolean;
        data?:any;
    }

    @Injectable() export class Service {
        CloseSubject:Subject<void> = new Subject();
        IdSubject:BehaviorSubject<string> = new BehaviorSubject("");
        TabSubject:BehaviorSubject<Tab> = new BehaviorSubject(null);
    }

    @Component({
        selector: 'mn-state-view-tab-holder',
        template: `
            <mn-workflow-component-view [description]="mTab.description" ></mn-workflow-component-view>
    
    `,
        providers: [MnStateView2.Service]
    })
    export class TabHolder {
        private mDestroy:Subject<void> = new Subject();
        @Input()
        get tab():MnStateView2.Tab { return this.mTab; }
        set tab(v:MnStateView2.Tab) { this.mTab = v; this.mService.TabSubject.next(v); }
        private mTab:MnStateView2.Tab = {
            id: "",
            label: "",
            select: 0,
            description: null,
            closable: false
        };
        @Output() remove = new EventEmitter();
        constructor(private mService:MnStateView2.Service) {
            this.mService.CloseSubject.takeUntil(this.mDestroy).subscribe(()=>{
                this.remove.emit(this.mTab);
            })
        }
        ngOnDestroy() {
            this.mDestroy.next();
        }
    }

}


