/**
 * Created by joerg on 5/3/17.
 */

// angular
import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

// uirouter
import { UIRouterModule } from '@uirouter/angular';

// mn
import { MnCoreModule } from '@mn/core';
import { MnCommonModule } from '@mn/common';
import { MnRouteModule } from '@mn/route';
import { MnHelpModule } from '@mn/help';

// ct
import { CtWorkflowModule } from "./ct/workflow/CtWorkflowModule";
import { CtDetailsModule } from "./ct/details/CtDetailsModule";

//
import { CtApp } from './CtApp';
import { CtHome } from './CtHome';
import { CtDataManager } from './CtDataManager';
import { CtHelpHeader } from './CtHelpHeader';

//Note for BB: new materail design modules should be added to CtWorkflowModule.ts and not here
import {
    /*MatRippleModule,
    MatListModule,*/
    MatButtonModule,
    /*MatButtonToggleModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatSlideToggleModule,
    MatNativeDateModule,
    MatInputModule,
    MatTabsModule,
    MatSliderModule,
    MatDialogModule,
    MatMenuModule,
    MatChipsModule,
    MatPaginatorModule,
    MatIconModule,
    MatSelectModule,*/
} from '@angular/material';

let material_modules = [
    //ScrollDispatchModule,
    //MatRippleModule,
    //MatListModule,
    MatButtonModule,
    //MatButtonToggleModule,
    //MatCheckboxModule,
    //MatDatepickerModule,
    //MatSlideToggleModule,
    //MatNativeDateModule,
    //MatInputModule,
    //MatTabsModule,
    //MatSliderModule,
    //MatDialogModule,
    //MatMenuModule,
    //MatChipsModule,
    //MatPaginatorModule,
    //MatIconModule,
    //MatSelectModule,
];

let directives = [];
let components = [CtApp,CtHome,CtHelpHeader,CtDataManager];

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        UIRouterModule.forChild(),
        MnCoreModule,
        MnCommonModule,
        MnRouteModule,
        CtDetailsModule,
        CtWorkflowModule,
        MnHelpModule,
        ...material_modules,
    ],
    exports: components.concat(directives),
    declarations: components.concat(directives),
    entryComponents: components
})
export class MnAppModule {
    constructor() {
        console.log("constructor MnAppModule")
    }
    static forRoot(): ModuleWithProviders {
        return {
            ngModule: MnAppModule,
            providers: []
        };
    }
}

export class ct_dev {
    static configure():any {
        return [
            MnAppModule.forRoot()
        ];
    }
}