import {Component, Input} from "@angular/core"

@Component({
    selector:'ct-compound-details-db-profile',
    templateUrl:'./CtCompoundDetailsDBProfile.html',
    styleUrls: ['./CtCompoundDetailsDBStudy.css']
})
export class CtCompoundDetailsDBProfile {

    // list of property names/values
    private map = [
        //["serial", "Serial"],
        ["value_type", "Compound Profile"],
        //["harvested_from", "Harvested from"],
        ["data", "Chemical Group"],
        //["attribute", "Attribute"]
        //["visible","Visible"]
    ];

    public profileDataTitles = [];
    public profileDataRecords = [];

    @Input('compoundProfileDetails') set compoundProfileDetails(value) {

        this.profileDataTitles = [];
        this.profileDataRecords = [];

        let unsortedTitleList = [];
        let sortedTitleList = [];
        let cleanSortedTitleList = [];

        if (value == null) {
            return;
        }
        var me = this;
        if (value) {
             // loop over list of objects - skip columns where all cells are empty
             value.forEach(function(row, i) {
                for (var j in row["value"]) {
                    if (row["value"][j] != "" && row["value"][j] != null) {
                        if (unsortedTitleList.indexOf(j) == -1) {
                             unsortedTitleList.push(j);
                        }
                    }
                };
            });
            // sort list of titles
            this.map.forEach(function(key) {
                if (unsortedTitleList.indexOf(key[0]) > -1) {
                    sortedTitleList.push(key[0]);
                }
            });

            value.forEach(function(row, i) {
                let ret = [];
                sortedTitleList.forEach(function(key, i) {
                    if (row["value"][key] !== null && typeof row["value"][key] === 'object') {
                        let text = "";
                        for (var obj in row["value"][key]) {
                            if (obj == "is_public" || obj == "visible") continue;
                            if (row["value"][key][obj] != "" && row["value"][key][obj] != null) {
                                text += obj + ": " + row["value"][key][obj] + "; ";  
                            }
                        }
                        ret.push(text.replace(/;\s*$/, ""));
                    } else {
                        ret.push(row["value"][key]);
                    }
                });
                me.profileDataRecords.push(ret);
            });

            // replace names of titles by defined map
            this.map.forEach(function(key) {
                if (sortedTitleList.indexOf(key[0]) > -1) {
                    cleanSortedTitleList.push(key[1]);
                }
            }); 
            me.profileDataTitles = cleanSortedTitleList;

        }

    }
    

    constructor(){

    }

}