// angular
import {Component, ViewChild} from '@angular/core';

//
import {CtValueView, CtValueViewRegisterDecorator} from "../CtValueView";
import {CtTable} from "../CtTable/CtTable";

import {SkylineSingleData, SkylineData, SkylineD3Component, } from "../d3/skyline-d3/skylined3.component";

export interface SkylineSingleDataFromServer {
    width: number;
    value: number;
    label: string;
    color: string;

}
export interface SkylineDataFromServer {
    //chart: SkylineSingleDataFromServer[];
    chart: SkylineSingleData[];
    enabled: boolean;
}


/*  {{mValue | json}}
        http://localhost:4200/#/workflow?wid=1&sid=7
      */
@Component({
    selector: 'ct-skyline-view',
    template: `
      <app-skylined3 [margin]="4" [vertical]="true" [centerBarsMiddleHeight]="true" [preserveAspectRatioSVGpar]="'none'" [useParentSize]="true" [showHorizontalAxis]="true"></app-skylined3>
       
      
    `
    // preserveAspectRatioSVGpar is not working - don't know why

    // BB removed the checkbox - not clear how to use it, a better solution might be https://codepen.io/jaredpearce/pen/JEKWxd
    //         <mat-checkbox *ngIf="mValue" [ngModel]="mValue.enabled" (ngModelChange)="enabledChange($event)">{{mValue.enabled}}</mat-checkbox>
    // line used to show the horozontal axis
    //             <div style="position: absolute; left: 0; right: 0; top: 50%; height: 1px; background: darkgrey"></div>


})
@CtValueViewRegisterDecorator(CtValueView.Types.Skyline)
export class CtSkylineView extends CtValueView {



    @ViewChild(SkylineD3Component)
    skPlot: SkylineD3Component;

    constructor(service:CtValueView.Service) {
        super(service);
    }

    protected previousValue: any;

    static firstWidth:  number;
    static firstHeight: number;

    public update(parameters:CtValueView.Parameters) {
        if (!parameters.value) {
            return;
        }
        if (parameters.value != this.previousValue) {
            const mValue: CtTable.Skyline = parameters.value;
            //console.log( mValue);
            if (mValue.enabled) {
                let w, h: number;

                if ( CtSkylineView.firstHeight) {
                    [w,h] = [CtSkylineView.firstWidth, CtSkylineView.firstHeight]; // for consistency
                } else {
                    CtSkylineView.firstWidth = w = parameters.size.width ;
                    CtSkylineView.firstHeight = h = parameters.size.height - 1;
                }

                //const skylineD3Data: SkylineSingleData[] = this.fakeFMinMax(mValue.chart);
                const plotData: SkylineData = <any>({plotLabel: null, data: mValue.data});
                this.updatePlot(plotData, w , h);
            }
            this.previousValue = parameters.value;
        }



    }


    public updatePlot(plotData: SkylineData, width: number, height: number) {


        this.skPlot.width = width ;
        this.skPlot.height = height;

        // alternative
        // this.skPlot.width = 10000;
        // this.skPlot.height = 13900;

        // compute default display values for the mouse over
        this.skPlot.generateDisplayValues(plotData.data);
       // console.log(plotData);
        this.skPlot.setDataAndUpdatePlot(plotData);




    }

    // TODO ?
    private enabledChange(value) {
       // if (!this.mValue) return;
       // this.mValue.enabled = value;
       // this.setNewValue(this.mValue);
    }




    ngOnDestroy() {
        this.destroy();
    }
}

export function createTemporarySkylinePlot(data: CtTable.Skyline ) : HTMLElement {
    //there must be a way to get the tag from the component
    let comp: HTMLElement = document.createElement("ct-skyline-view");

    document.body.appendChild(comp);



    return comp;
}

