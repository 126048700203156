// angular
import {Component, ViewChild, Input} from '@angular/core';

//
import {CtValueView, CtValueViewRegisterDecorator} from "../CtValueView";
import {ProbabilityPlotData, ProbabilityPlotD3Component} from "../d3/probability-plot-d3/probability-plot-d3.component";


interface ExtendedProbabilityPlotData  extends ProbabilityPlotData {
   applicable: boolean ;

}

@Component({
    selector: 'ct-woe-probabilities-view',
    template: '<app-probability-plot-d3 class="plot" style="display: block;" [margin]="7.5"></app-probability-plot-d3>',
    styles: [
        `
            :host {
                overflow: auto;
            }
            .plot {
                display: block;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%,-50%);
            }
        `
    ]

})
@CtValueViewRegisterDecorator(CtValueView.Types.WoeProbabilities)
export class CtWoeProbabilitiesView extends CtValueView {

    @ViewChild(ProbabilityPlotD3Component) plot: ProbabilityPlotD3Component;

    @Input() set probs(v) {
        this.update(v);
    }

    constructor(service:CtValueView.Service) {
        super(service);
    }

    //protected previousValue: ExtendedProbabilityPlotData;

    public update(parameters:CtValueView.Parameters) {
        const plotData : ExtendedProbabilityPlotData = <ExtendedProbabilityPlotData> parameters.value;
        console.log("plotData",plotData);

        const {width, height} : {width: number, height: number} = parameters.size;
        const maxHeight = 30; // the probability plot does not look good if too big - label font issue - they grow
        const maxWidth = 120;
        this.plot.height = Math.min(height, maxHeight);
        this.plot.width = Math.min(width, maxWidth);

        if ( ! plotData.applicable) { // display grey scale if the model is out of application domain
            this.plot.greenColor = "grey";
            this.plot.redColor = "grey";
            this.plot.orangeColor = "lightgrey";
            this.tooltip = "Out of model domain";
         } else {
            if ( plotData.plotLabel ) {
                this.tooltip = plotData.plotLabel;
            }
        }

        this.plot.setDataAndUpdatePlot(plotData);

    }

    ngOnDestroy() {
        this.destroy();
    }
}

