/**
 * Hack for
 * 1) mean and standard deviations
 * 2) favorite properties
 * @type {string}
 */



// values from Jim's spreadsheet
const chemtunesValues = `	Atoms	Bonds	BondsRot	HAcc	HAccN	HAccO	HDon	HDonN	HDonO	Ro5Viol	Ro5ViolExt	Stereo	Weight	Complex	ComplexRing	ASA	McGowan	TPSA	Dipole:Cor3D:ori1	Polariz	LogS	XlogP	Aspheric:Cor3D:ori1	Diameter:Cor3D:ori1	Eccentric:Cor3D:ori1	InertiaX:Cor3D:ori1	InertiaY:Cor3D:ori1	InertiaZ:Cor3D:ori1	Rgyr:Cor3D:ori1	Span:Cor3D:ori1	HoF:AM1:Cor3D:ori1	Homo:AM1:Cor3D:ori1	HomoLumoGap:AM1:Cor3D:ori1	Lumo:AM1:Cor3D:ori1
mean	40.7836663771	41.7468288445	5.5616854909	4.4772371851	1.3633362294	3.1139009557	1.7244135534	0.7777584709	0.9466550825	0.351607298	0.4775847089	1.45803649	296.7109409209	387.3001384014	0.8448400235	403.5939457081	225.01707298	65.9324787142	3.8513287781	31.5276225022	-3.298364766	2.5854804705	0.2844876063	12.6853967385	0.953778192	1630.4771824739	11785.3743778379	12731.3853228101	3.8541140785	6.9309226197	-69.799975666	-9.6115305393	-9.4704529201	-0.141077823
std dev	32.0224410425	32.8208832855	8.5219210686	5.1514008544	2.4719914734	3.6116511896	3.1752179208	2.4686320604	1.5745512353	0.7153861389	0.9210618475	3.1373298821	217.565813872	456.0561001898	0.5039529305	299.66547277	164.6530461333	80.8484476501	3.9023580948	22.7093237891	2.688272243	3.1187255456	0.1106869491	7.5443224442	0.0666041475	11891.0676153745	136691.377784992	139805.190474882	2.1755474178	4.0267112972	170.8920613492	1.3361491703	1.6672553997	1.5716026853
name	Number of atoms		Number of rotatable bonds	Number of H-bond acceptors			Number of H-bond donors			Number of Lipinski’s rule of 5 violations		Number of tetrahedral stereo centers	Molecular weight	Molecular complexity				Topological polar surface area				Octanol/water partition coefficient												
favorite	1		1	1			1			1		1	1	1				1				1												
`;
export class CustomStandardization {

  protected meanDict ;
  protected stdevsDict;
  protected favoriteDict;
  protected niceNameDict;

  /**
   * make a dict
   */
  public parseTableData() {
    const lines: string[] = chemtunesValues.split("\n");
    console.assert(lines.length >= 5);

    let d = {};
    for(let l of lines) {
      if(! l )
        continue;
      let values = l.split("\t");
      let key = values.shift();
      if(! key) {
        key = 'header';
      }
      d[key] = values;

    }

    let i = 0;
    this.meanDict = {};
    this.stdevsDict = {};
    this.niceNameDict = {};
    this.favoriteDict = {};


    for(let pn of d['header']) {
      // adapt some of the labels provided by Jim to the ones used in the FE
        // ex: Eccentric
      const pn2  = pn.replace(':Cor3D:ori1', '');

      this.meanDict [pn2] = this.meanDict [pn] = parseFloat(d['mean'][i]);
      this.stdevsDict[pn2] = this.stdevsDict[pn] = parseFloat(d['std dev'][i]);
      const name = d['name'][i];
      if(name.length > 0) {
        this.niceNameDict[pn2] = this.niceNameDict[pn] = name;
      }
      const favorite = d['favorite'][i] == '1';

      this.favoriteDict[pn2] = this.favoriteDict[pn]  = favorite;

      i++;
    }

  }

  protected parseIfNeeded() : void {
    if(!this.meanDict) {
      this.parseTableData();
    }
  }
  public getMean(p: string): number {
    this.parseIfNeeded();
    return this.meanDict[p];
  }

  public getStdev(p: string): number {
    this.parseIfNeeded();
    return this.stdevsDict[p];
  }

  public isFavorite(p: string): boolean {
    this.parseIfNeeded();

    return this.favoriteDict[p];

  }

   public getNiceName(p: string): string {
    this.parseIfNeeded();

    return this.niceNameDict[p];

  }
}

export const CustomStandardizationInstance = new CustomStandardization();
