/**
 * Created by joerg on 7/17/17.
 */

// rxjs
import 'rxjs/Rx';
import { Observable } from 'rxjs/Observable';

// angular
import {Injector} from '@angular/core';

//
import {MnValue} from "./MnValue";
import {MnValueHtml} from "./MnValueHtml";
import {MnBackend} from "@mn/core";
import {MnSvgFix} from "../MnSvgFix";

export enum ImageFormat {
    SVG ='svg+xml',
}
export interface Image {
    image_format: ImageFormat;
    image_data: string;
    url : string;
    width: number;
    height: number;
    id: number;
}

let count = 0;

// fixme cleanup
// image
export class MnValueHtmlImage extends MnValueHtml {
    public get(mnv:MnValue,injector?:Injector):Observable<string> {
        //return this.generateDefault(mnv,(data) => data, 'string');
        if (this.hasError(mnv)) {
            return this.generateDefault(mnv,(v)=> "","image");
        }
        let v:any = mnv.data;
        let backend:MnBackend = injector.get(MnBackend);

        return backend.get( v.image+ '?format=json&height='+400+'&width='+ 400) // using format=json or not makes no difference
            .map(res => {
                const img: Image = res.json();
                // add additional information
                img.url = v.image;
                img.width = 400;
                img.height = 400;
                img.id = count++;
                return img;
            }).map(image => {
                return this.generateDivs(MnSvgFix.apply(image.image_data),null,'image');
            })
    }
}
MnValueHtml.Registry.register('image',new MnValueHtmlImage());
