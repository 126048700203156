/**
 * Created by Joerg on 08.08.2017.
 */

//
import {Backend} from "./BackendInterfaces";
import {MnWorkflow} from './MnWorkflow'


interface Nodes {
    [key: string]: MnWorkflow.Node;
}

export function buildWorkflowTree(workflow:Backend.Workflow,current_state_id?:number):MnWorkflow.Tree {

    let nodes:Nodes = {};
    let transitions = workflow.transitions;
    let states = workflow.states;

    for (let sid in states) {
        if(!states.hasOwnProperty(sid)) continue;
        let state:Backend.State = states[sid];
        nodes[sid] =  {
            state: state,
            transition: null,
            highlight: sid == current_state_id+'',
            busy: false,
            children: []
        };
    }

    for (let tid in transitions) {
        if(!transitions.hasOwnProperty(tid)) continue;
        let transition:Backend.Transition = transitions[tid];

        let target:MnWorkflow.Node = nodes[transition.target];
        let source:MnWorkflow.Node = nodes[transition.source];
        target.transition = transition;
        source.children.push(target);
    }

    for (let nid in nodes) {
        if(!nodes.hasOwnProperty(nid)) continue;
        if (nodes[nid].transition == null) {
            //console.log("workflow state tree",nodes[nid]);
            // check if the tree is linear
            let linear = function(node:MnWorkflow.Node):boolean {
                if (node.children.length > 1) {
                    return false;
                } else if (node.children.length == 1) {
                    return linear(node.children[0]);
                }
                return true;
            };
            return {root: nodes[nid], linear: linear(nodes[nid])};
        }
    }

    // we should never end up here
    //console.error("workflow state tree build error",nodes);
    return {root: null, linear: true};
}



