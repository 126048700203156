// mn
import {MnBrandService} from "@mn/core";

//
import {MnWorkflow} from "../MnWorkflow";
import {Backend} from "../BackendInterfaces";

export class MnActionAdapterAddSummary extends MnWorkflow.ActionAdapter{
    protected transform(workflow:Backend.Workflow, state:Backend.FullState, actions:MnWorkflow.Action[]):MnWorkflow.Action[] {
        if (MnBrandService.raw().data.CREATE_SUMMARY_TABLE && state && state.dataset && state.dataset.table && state.dataset.table.data && state.dataset.table.data.length > 0) {
            actions.push({
                id: "frontend",
                config_template: {},
                settings: "",
                title: "CREATE_SUMMARY_TABLE",
                category: "EXPORT",
                comments: "",
                task_class: "",
                needs_data: false,
                can_continue: false,
                interactive: false,
                needs_config: false,
            });
        }
        return actions;
    }
}
MnWorkflow.ActionAdapter.Registry.register('CREATE_SUMMARY_TABLE',new MnActionAdapterAddSummary());

export class MnActionAdapterAddAssessment extends MnWorkflow.ActionAdapter{
    protected transform(workflow:Backend.Workflow, state:Backend.FullState, actions:MnWorkflow.Action[]):MnWorkflow.Action[] {
        if (MnBrandService.raw().data.CREATE_ASSESSMENT_TABLE && state && state.dataset && state.dataset.table && state.dataset.table.data && state.dataset.table.data.length > 0 &&
            state.settings.summary && state.settings.summary.length > 0) {
            actions.push({
                id: "frontend",
                config_template: {},
                settings: "",
                title: "CREATE_ASSESSMENT_TABLE",
                category: "EXPORT",
                comments: "",
                task_class: "",
                needs_data: false,
                can_continue: false,
                interactive: false,
                needs_config: false,
            });
        }
        return actions;
    }
}
MnWorkflow.ActionAdapter.Registry.register('CREATE_ASSESSMENT_TABLE',new MnActionAdapterAddAssessment());
