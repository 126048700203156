/**
 * Created by joerg on 7/17/17.
 */

// rxjs
import 'rxjs/Rx';
import { Observable } from 'rxjs/Observable';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Subject } from 'rxjs/Subject';
import { Subscription } from 'rxjs/Subscription';

// angular
import {Component} from '@angular/core';

// mn
import {MnBrandService} from "@mn/core";
import {CtSearchService} from "@ct/search";

//
import {MnWorkflowComponentStatic} from "../../MnWorkflowComponent";
import {MnActionConfigurator} from "../MnActionConfigurator";
import {MnActionConfigurationService} from "../MnActionConfigurationService";

@Component({
    selector: 'mn-action-configurator-search-database',
    template: `
        <div style="width: 100%; height: 100%; background: white">
            <mn-lazy [comp]="comp" [inputs]="inputs" style="height: 100%; width: 100%;"></mn-lazy>
        </div>
     `,
    styles: [],
    providers: [CtSearchService]
})
export class SearchDatabase extends MnActionConfigurator {

    private mConfiguration:SearchDatabase.Configuration;

    private comp:any = { lib: '@ct/search', mod: 'CtSearchModule', comp: 'CtSearch' };
    private inputs:any= { button: false };
    private mTimer:Observable<any> = Observable.timer(1000,200);
    private mActive:boolean = false;

    constructor(private mBrand:MnBrandService, private mSearchService:CtSearchService, acs:MnActionConfigurationService) {
        super(acs);
        this.comp = this.mBrand.brand().data.workflowsearch;
        this.mTimer.takeUntil(this.onDestroy).subscribe(()=> this.onCheck());
        this.enable(false);
    }

    protected onInit(configuration:any) {
        //this.mConfiguration.properties = configuration.properties ? configuration.properties : [];
    };

    protected onStart() {
        if (this.comp.lib == "@ct/search") {
            this.mConfiguration = this.mSearchService.getQuery();
        }
        this.finish(this.mConfiguration);
    };

    onCheck() {
        if (this.comp.lib == "@ct/search") {
            // changes.prop contains the old and the new value...
            let active:boolean = !this.mSearchService.isSearchActive();
            if (this.mActive != active) {
                this.mActive = active;
                this.enable(this.mActive);
            }
        } /*else {
            let active:boolean = !this.mSearchServiceIp.isSearchActive();
            if (this.mActive != active) {
                this.mActive = active;
                this.enable(this.mActive);
            }
        }*/
    }

    ngOnDestroy() {
        super.destroy()
    }
}
MnActionConfigurator.Registry.register("SEARCH_DATABASE",new MnWorkflowComponentStatic(SearchDatabase));

export namespace SearchDatabase {
    export interface ChemTunesQuery {
        databases: string[],
        chem: ChemTunesQuery.Chemistry,
        reg: ChemTunesQuery.Regulatory,
        tox: ChemTunesQuery.Toxicology
    }
    export namespace ChemTunesQuery {
        export interface Active {
            active:boolean;
        }
        export interface Chemistry extends Active {
            mode: Chemistry.Mode;
            terms: string[];
            term: Chemistry.Term;
            structure: Chemistry.Structure;
            datasetid: string;
        }
        export namespace Chemistry {
            export enum Mode {
                Term = "term",
                Structure = "structure",
            }
            export interface Term {
                Key: Term.Key
            }
            export namespace Term {
                export enum Key {
                    Exact = 'exact',
                    Partial = 'partial',
                }
            }
            export interface Structure {
                Key: Structure.Key
            }
            export namespace Structure {
                export enum Key {
                    Exact = 'exact',
                    Partial = 'partial',
                    Similar = 'similar',
                }
            }
        }
        export interface Toxicology extends Active {
            study: any[];
        }
        export interface Regulatory extends Active {

        }
    }
    export type Configuration = ChemTunesQuery;
}