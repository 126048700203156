/**
 * Non gui parts
 */



export class ChemotypeSingleMatch {

    public isSelected = false;
    public subgraphId: string;
    public title: string;
    public label: string;
    public atoms: number[];

    constructor({subgraphId='', title='', label='',  atoms=[]}) {
        this.subgraphId = subgraphId;
        this.title=title;
        this.label=label;
        this.atoms = atoms;
    }


}

/////////////////////////////////////////////////////////////////////////////

export class ChemotypeProfileMatch {

    constructor(public name: string, public matches: ChemotypeSingleMatch[]) {
    }
}


/////////////////////////////////////////////////////////////////////////////

/**
 * Array of ChemotypeProfileMatch
 */
export class ChemotypeProfileMatches {
    matches: ChemotypeProfileMatch[];

    constructor(data: any) { // see testdata
        this.matches = [];

        for (let profileResult of data) {
            if (!profileResult) {
                continue;
            }
            for (let profileLabel in profileResult) { // actuallly we have only one
                if (!profileLabel) {
                    continue;
                }
                let matches: any[] = profileResult[profileLabel];
                if (!matches) {
                    matches = [];
                }

                const cpm: ChemotypeProfileMatch = new ChemotypeProfileMatch(
                    profileLabel,
                    matches.map((each: any) => new ChemotypeSingleMatch(each)));

                this.matches.push(cpm);

            }
        }
    }

}

/////////////////////////////////////////////////////////////////////////////


// wrong format
export const testdata = {
    "toxprints": [
        {
            "subgraphId": 'TA-0000-0000-0070-0310-0060-0010-006',
            "atoms": [1, 2, 3]
        },
        {
            "subgraphId": 'TXP-004-0000-0000-0000-0000:ring:aromatic_benzene_c1ccccc1',
            "atoms": [3, 4, 5]
        },
    ],
    "livertox_alerts": [
        {
            "subgraphId": 'TA-0000-0000-0070-0310-0060-0010-006',
            "atoms": [1, 2, 3]
        },
        {
            "subgraphId": 'TXP-004-0000-0000-0000-0000:ring:aromatic_benzene_c1ccccc1',
            "atoms": [3, 4, 5]
        },
    ]
}

export const testmol = `O=C(O)c1ccccc1
JME 2014-06-28 Sun May 20 17:11:19 GMT+200 2018

  9  9  0  0  0  0  0  0  0  0999 V2000
    2.4249    0.7000    0.0000 C   0  0  0  0  0  0  0  0  0  0  0  0
    2.4249    2.1000    0.0000 C   0  0  0  0  0  0  0  0  0  0  0  0
    1.2124    2.8000    0.0000 C   0  0  0  0  0  0  0  0  0  0  0  0
    0.0000    2.1000    0.0000 C   0  0  0  0  0  0  0  0  0  0  0  0
    0.0000    0.7000    0.0000 C   0  0  0  0  0  0  0  0  0  0  0  0
    1.2124    0.0000    0.0000 C   0  0  0  0  0  0  0  0  0  0  0  0
    3.6373    2.8000    0.0000 C   0  0  0  0  0  0  0  0  0  0  0  0
    4.8497    2.1000    0.0000 O   0  0  0  0  0  0  0  0  0  0  0  0
    3.6373    4.2000    0.0000 O   0  0  0  0  0  0  0  0  0  0  0  0
  1  2  1  0  0  0  0
  2  3  2  0  0  0  0
  3  4  1  0  0  0  0
  4  5  2  0  0  0  0
  5  6  1  0  0  0  0
  6  1  2  0  0  0  0
  2  7  1  0  0  0  0
  7  8  1  0  0  0  0
  7  9  2  0  0  0  0
M  END
`;
