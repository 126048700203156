// rxjs
import 'rxjs/Rx';
import { Observable } from 'rxjs/Observable';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Subject } from 'rxjs/Subject';
import { Subscription } from 'rxjs/Subscription';

import {Component,Input,Injector,NgModuleFactory} from '@angular/core'


export abstract class MnWorkflowComponent {
    static all(container:any,callback:(component_descriptions:MnWorkflowComponent.Description[])=>void) {
        let list:any[] = [];
        console.log(container);
        for (let key in container) {
            if(!container.hasOwnProperty(key)) continue;
            let component_description:MnWorkflowComponent.Description = container[key].get();
            component_description.key = key;
            list.push(component_description);
        }
        Observable.forkJoin(list).subscribe(res=> {
            callback(<MnWorkflowComponent.Description[]>res);
        });
    }
    public abstract get():Observable<MnWorkflowComponent.Description>;
}
export namespace MnWorkflowComponent {
    export interface Description {
        key:string;
        component:any;
        injector?:Injector;
        factory?:NgModuleFactory<any>;
    }
}

export class MnWorkflowComponentStatic extends MnWorkflowComponent {
    constructor(private mComponent:any, private mInjector?:Injector) { super(); }
    public get():Observable<MnWorkflowComponent.Description> {
        return Observable.of({
            key: "",
            component: this.mComponent,
            injector: this.mInjector,
        })
    }
}


@Component({
    selector: 'mn-workflow-component-view',
    template: `
        <ng-container *ngComponentOutlet="mComponent; injector: mInjector; ngModuleFactory: mFactory"></ng-container>
    `,
})
export class MnWorkflowComponentView {
    private mComponent:any;
    private mInjector:Injector;
    private mFactory:NgModuleFactory<any>;
    @Input()
    get description():MnWorkflowComponent.Description { return this.mDescription; }
    set description(v:MnWorkflowComponent.Description) { this.mDescription = v; this.update(); }
    private mDescription:MnWorkflowComponent.Description = { key: "", component: null };
    private update() {
        if (this.mDescription ) {
            this.mComponent = this.mDescription.component;
            this.mInjector = this.mDescription.injector;
            this.mFactory = this.mDescription.factory;
        } else {
            this.mComponent = undefined;
            this.mInjector = undefined;
            this.mFactory = undefined;
        }
    }
    constructor() {}
}