/**
 * Created by joerg on 7/17/17.
 */

// rxjs
//import 'rxjs/Rx';
//import { Subject } from 'rxjs/Subject';

// angular
import {Component,Injector} from '@angular/core';

import {ColorPickerService,Rgba} from 'ngx-color-picker';

//
import {MnWorkflowComponentStatic} from "../MnWorkflowComponent";
import {MnStateView2} from "./MnStateView2";
import {MnWorkflow} from "../MnWorkflow";
import {CtTable} from "../CtTable/CtTable";
import {MnStateViewRowMarkerView} from "./MnStateViewRowMarkerView";
import {CtValueView} from "../CtValueView";
import {MnValueHtml} from "../MnValue/MnValueHtml";
import {MnWorkflowService} from "../MnWorkflowService";

@Component({
    selector: 'mn-state-view-summary-table',
    templateUrl: './MnStateViewSummaryTable.html',
    styles: [`
        :host {
            overflow: auto;
            padding: 16px;
            display: block;
            height: 100%;
            width: 100%;
            
        }
        
        .nameInput /deep/ .mat-input-underline {
            background-color: red;
        }
        .headerTop {
            background: #eaeaea;
        }
        .headerTop td {
            font-weight: bold; 
            font-size: 16px;
            color: #222;
        }
        .header0 {
            color: #444;
            font-weight: bold; 
            font-size: 16px;
            white-space: nowrap;
        }
        .header1 {
            color: #444;
            font-size: 14px;
            font-weight: bold;
            white-space: nowrap;
             text-align: right;
       }
        .header2 {
            height: 32px;
            line-height: 32px;
            white-space: nowrap;
            text-align: right;
        }
        .tdheader2 {
            width: 200px;
        }
        td {
            font-size: 12px;
            padding: 0 4px;
            /*border: 1px solid lightgrey;*/
        }
        td /deep/ .mat-input-underline {
            background-color: transparent;
            bottom: 0;
        }

        td /deep/ .mat-input-wrapper {
            padding-bottom: 0;
        }

    `]
})
export class MnStateViewSummaryTable extends MnStateViewRowMarkerView {

    static createStateTabs(workflow:MnWorkflow):MnStateView2.Tab[] {

        //FIXME this  is called two times
        let result = [];
        if (workflow &&
            workflow.Workflow &&
            workflow.State &&
            workflow.State.settings &&
            workflow.State.settings.summary
        ) {
            for (let i = 0, l = workflow.State.settings.summary.length; i < l; i++) {
                let sid = workflow.State.settings.summary[i];
                if (workflow.Workflow.settings &&
                    workflow.Workflow.settings.summary &&
                    workflow.Workflow.settings.summary.count &&
                    workflow.Workflow.settings.summary.data &&
                    workflow.Workflow.settings.summary.data[sid]
                ) {
                    result.push({
                        id: "",
                        label: "Summary: " + workflow.Workflow.settings.summary.data[sid].name,
                        select: 1,
                        description: null,
                        closable: false,
                        data: {
                            id: sid,
                            summary: workflow.Workflow.settings.summary.data[sid]
                        }
                    })
                }
            }
        }
        return result;
    }

    mSizeStyle = {
        width: 140,
        'min-width': 140,
        'max-width': 140,
    };

    mNodes:CtTable.Node[] = [];
    mCells:MnStateViewSummaryTable.Matrix = [];
    mConfiguration = {
        name: "",
        columns: [], // nodes
        rows: []
    };
    mHeaders:string[][] = [];
    mTab:any = {};
    mSize:number = 150;
    mBlock = true;
    
    constructor(injector:Injector, private mCPS:ColorPickerService, private mWorkflow:MnWorkflowService, private mStateViewService:MnStateView2.Service) {
        super(injector);
        this.mStateViewService.TabSubject.takeUntil(this.mDestroy).subscribe((tab)=>this.onTab(tab));
        this.mWorkflowService.StateStatusObservable.takeUntil(this.mDestroy).subscribe((ss)=>{
            if (ss == MnWorkflow.StateStatus.Pending) {
                this.mBlock = true;
            } else {
                this.mBlock = false;
            }
        })
    }

    onTab(tab:any) {
        this.mTab = this.mStateViewService.TabSubject.getValue();

        // Bug reported by AT June 4th 2018: the tab is not visible anymore
        // reason: the validity test was not correct
        // fixed by BB
        if ( !this.mTab || !this.mTab.data || !this.mTab.data.summary) {
            return;
        }


        this.mConfiguration = this.mTab.data.summary;
        this.mRowMarker.setup(20000000000,[],this.mConfiguration.rows);
        this.mNodes = this.mWorkflow.Table.findNodes((node)=>{
            return this.mConfiguration.columns.indexOf(node.id) >= 0
        });
        this.update();
        console.log('nodes',this.mNodes);
        console.log("SSD",this.mTab);
    }

    ngAfterViewInit() {
        this.mBlock = false;
    }

    private save() {
        console.log("Save", this.mConfiguration);
        let summary_w = this.mWorkflowService.getWorkflowSetting('summary');
        summary_w.data[this.mTab.data.id+''] = this.mConfiguration;
        this.mWorkflowService.addWorkflowSetting('summary',summary_w,()=>{
            console.log("Saved Summary");
        });
    }

    private onSize(width) {
        console.log(width);
        this.mSizeStyle = {
            width: width,
            'min-width': width,
            'max-width': width,
        };
    }

    private onRemove($event) {
        console.log("Remove", this.mConfiguration);
        let summary_w = this.mWorkflowService.getWorkflowSetting('summary');
        delete summary_w.data[this.mTab.data.id+''];
        this.mWorkflowService.addWorkflowSetting('summary',summary_w,()=>{
            console.log("Removed Summary");
        });
        this.mStateViewService.CloseSubject.next();
    }

    private onNamerChange(name:string) {
        if (this.mBlock) return;
        this.mConfiguration.name = name;
        this.save();
    }

    private onCellHeaderChange(header,node_index) {
        console.log(header,node_index);
    }

    private onNodesIds(nodes_ids) {
        if (this.mBlock) return;
        console.log(nodes_ids);
        this.mConfiguration.columns = nodes_ids;
        this.save();
    }
    private onNodes(nodes:CtTable.Node[]) {
        // always have the nodes sorted
        this.mNodes = this.mWorkflow.Table.findNodes((node) => { return nodes.indexOf(node) >= 0 });
        this.mConfiguration.columns = this.mNodes.map((node)=> node.id);
        this.update();
    }

    protected onRowMarker() {
        console.log('onRowMarker');
        if (this.mBlock) return;
        this.mConfiguration.rows = this.mRowMarker.marks();
        this.save();
        this.update()
    }
    private update() {
        this.mCells = [];
        this.mHeaders = [];
        let current_headers = [];
        for (let ni = 0, nl = this.mNodes.length; ni < nl; ni++) {
            let node = this.mNodes[ni];
            let nodes_row = [];
            let cell_html = MnValueHtml.Registry.get(node.type);
            let cell_comp =  CtValueView.Registry[node.type];

            if (ni == 0) {
                this.mHeaders.push(node.headers);
                current_headers = node.headers;
            } else {
                let node_headers = [];
                for (let hi = 0, hl = node.headers.length; hi < hl; hi++) {
                    if (hi >= current_headers.length) {
                        break;
                    } else if (node.headers[hi] != current_headers[hi] || hi > 1) {
                        node_headers.push(node.headers[hi]);
                    } else if (node.headers[hi] == current_headers[hi] && hi < 2) {
                        node_headers.push(null);
                    }
                }
                if (node_headers.length == 2) {
                    node_headers.push(node_headers[1]);
                    node_headers[1] = '';
                }
                this.mHeaders.push(node_headers);
                current_headers = node.headers;
            }

            for (let ci = 0, cl = this.Rows.length; ci < cl; ci++) {
                let comp = this.Rows[ci];

                if (cell_html) {
                    nodes_row.push({
                        system_id: 'CMS-'+comp.system_id,
                        value: {
                            data: node.getter(comp),
                            error: null
                        },
                        size: { height: this.mSize, width: this.mSize },
                        html: cell_html
                    })
                } else {
                    nodes_row.push({
                        system_id: 'CMS-'+comp.system_id,
                        value: node.getter(comp),
                        size: { height: this.mSize, width: this.mSize },
                        comp: cell_comp
                    })
                }
            }
            this.mCells.push(nodes_row);
        }

    }

    ngOnDestroy() {
        console.log('ngOnDestroy');
        super.ngOnDestroy()
    }
}
export namespace MnStateViewSummaryTable {
    export interface Cell {
        value: any;
        comp: any;
        html: any;
        system_id: string;
        user_id: string;
    }
    export type Matrix = Cell[][];
}
MnStateView2.Registry.register("MnStateViewSummaryTable",new MnWorkflowComponentStatic(MnStateViewSummaryTable));
