/**
 * Created by joerg on 7/17/17.
 */

// rxjs
import 'rxjs/Rx';
import { Observable } from 'rxjs/Observable';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Subject } from 'rxjs/Subject';
import { Subscription } from 'rxjs/Subscription';

// angular
import {Injectable} from '@angular/core';

//
import {CtTable} from "./CtTable";
import {MnMarker,MnRowMarker,MnColMarker} from "../MnMarker";

@Injectable()
export class MnTableRowMarker extends MnMarker {

    private mTable:CtTable;
    private mMax:number = 0;
    private mMarkable:Set<string> = new Set();
    private mMarked:Set<string> = new Set();

    constructor() {
        super()
    }

    public init(table:CtTable) {
        this.mTable = table;
        this.mMarkable = new Set();
        this.mMarked = new Set();
        this.mMax = 0;
    }

    public setup(max:number, markable:string[] | Set<string>, marked:string[] | Set<string>) {
        this.mMax = max;
        if (max == 0) {
            this.mMarkable = new Set();
            this.mMarked = new Set();
            this.ChangeSubject.next();
            return;
        }
        this.mMarkable = markable? new Set(markable) : new Set();
        this.mMarked = marked? new Set(marked) : new Set();
        this.ChangeSubject.next();
    }
    public mark(marked:string[] | Set<string>) {
        if (this.mMax == 0) return;
        if (marked.constructor === Array) {
            if ((<Array<string>>marked).length > this.mMax) return;
        } else {
            if ((<Set<string>>marked).size > this.mMax) return;
        }
        this.mMarked = new Set(marked);
        this.ChangeSubject.next();
    }
    public add(mid:string) {
        if (this.mMax == 0) return;
        if (this.mMax == 1) {
            this.mMarked.clear();
            if ( this.mMarkable.size == 0 || this.mMarkable.has(mid)) {
                this.mMarked.add(mid);
                this.ChangeSubject.next();
            }
        } else {
            if (this.mMarked.size < this.mMax && ( this.mMarkable.size == 0 || this.mMarkable.has(mid) )) {
                this.mMarked.add(mid);
                this.ChangeSubject.next();
            }
        }
        this.ChangeSubject.next();
    }
    public toggle(mid:string):boolean {
        if (this.mMarked.has(mid)) {
            this.mMarked.delete(mid);
            this.ChangeSubject.next();
            return false;
        } else {
            this.add(mid);
            return false;
        }

    }
    public remove(mid:string) {
        if (this.mMarked.has(mid)) {
            this.mMarked.delete(mid);
            this.ChangeSubject.next();
        }
    }
    public has(mid:string):boolean {
        return this.mMarked.has(mid);
    }
    public markable(mid:string):boolean {
        return this.mMarkable.size == 0 ? true : this.mMarkable.has(mid);
    }
    public marks():string[] {
        let result = [];
        this.mMarked.forEach(v => result.push(v));
        return result;
    }
    public markables():string[] {
        let result = [];
        this.mMarkable.forEach(v => result.push(v));
        return result;
    }
    public invert() {
        if (this.mMarkable.size == 0) {
            if (this.mTable) {
                let new_marked:Set<string> = new Set();
                let rows:any[] = this.mTable.Rows;
                for (let i = 0, l = rows.length; i < l; i++) {
                    let row:any = rows[i];
                    if (!this.mMarked.has(row.record_index)) new_marked.add(row.record_index);
                }
                this.mMarked = new_marked;
                this.ChangeSubject.next();
            }
        } else {
            this.mMarked = new Set(this.markables().filter(v => !this.mMarked.has(v)));
            this.ChangeSubject.next();
        }
    }
    public all() {
        if (this.mMarkable.size == 0) {
            if (this.mTable) {
                this.mMarked.clear();
                let rows:any[] = this.mTable.Rows;
                for (let i = 0, l = rows.length; i < l; i++) {
                    this.mMarked.add(rows[i].record_index);
                }
                this.ChangeSubject.next();
            }
        } else {
            this.mMarked = new Set(this.mMarkable);
            this.ChangeSubject.next();
        }
    }
    public none() {
        this.mMarked.clear();
        this.ChangeSubject.next();
    }
    public active():boolean {
        return this.mTable && (this.mMax > 0);
    }
    public count():number {
        return this.mMarked.size;
    }

}

/*@Injectable()
export abstract class MnTableColMarker extends MnColMarker {

    constructor() {
        super()
    }

}*/
