/**
 * Created by joerg on 7/17/17.
 */

// angular
import {Component,Input,Output,EventEmitter,HostListener} from '@angular/core';

// uirouter
import {Transition,StateService, TransitionService} from '@uirouter/angular';

// mn
import {MnBrandService, MnTextService} from "@mn/core";

//
import {Backend} from "./BackendInterfaces";

interface Action {
    lines:string[];
    fa?:string;
    svg?:string;
}

interface Category {
    title:string;
    actions:Action[];
}

@Component({
    selector: 'ct-action-icon',
    templateUrl: './CtActionIcon.html',
    styleUrls: ['./CtActionIcon.css']
})
export class CtActionIcon {

    @Input()
    get action():Backend.Action { return this.mAction; }
    set action(v:Backend.Action) { this.mAction = v; this.updateIcon(); }
    private mAction:Backend.Action;

    @Input() position:string = "absolute";

    private mFa:string;
    private mSvg:string;

    constructor(private mBrand:MnBrandService, private mText:MnTextService) {}

    private updateIcon() {
        this.mSvg = undefined;
        this.mFa = undefined;
        if (this.mAction == null) return;
        let icons = this.mBrand.brand().data.ct.action.icons;
        let token = this.mAction.title;
        if (icons[token] ) {
            if (icons[token].fa) {
                this.mFa = icons[token].fa
            }
            else if (icons[token].svg) {
                this.mSvg = icons[token].svg
            }
        }
    }

    ngOnDestroy() {
    }
}

