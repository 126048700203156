// rxjs
import {Subscription} from 'rxjs/Rx';

// angular
import {Component} from '@angular/core';

// uirouter
//import { TransitionService } from '@uirouter/angular';

// mn
import {MnUnsubscribe} from "@mn/core";

//
import {MnHelpService,MnHelpPath} from '@mn/help';

let ipie_header_left = [
    {
        anchor: "app.home",
        text: "chemtunes2",

/*group: [
    {image: "chemtunes_icon"},
    {text: "chemtunes"},
    {image: "toxgps_icon"},
    ]*/
    },
];
let ipie_header_right = [
    /*{ anchor: "app.about", text: "app.about",},
    { anchor: "app.contact", text: "app.contact",},
    { anchor: "app.feedback", text: "app.feedback",},*/
    { anchor: "user.authenticated?", group: [ { text: "icon.username.authenticated?" },{ text: "auth.username.authenticated?", theme: "shiftleft" } ]},
];
let ipie_footer_mnam = [
    { text: "d&d", },
    { anchor: "mnam", image: "mnam", theme: "mnamlink" },
];



@Component({
    selector: 'ct-app',
    templateUrl: './CtApp.html'
})
@MnUnsubscribe()
export class CtApp {

    //onStateChangeUnsub:Function;

    mHelpTopicRequest:Subscription;
    mHelpPath:MnHelpPath = null;


    header = {
        left: ipie_header_left,
        center: [],
        right: ipie_header_right,
        height: 50,
        theme: "ct-header"
    };
    footer= {
        left: [],
        center: ipie_footer_mnam,
        right: [],
        height: 50,
        theme: "ip-sum-footer"
    };


    constructor(private mHelp:MnHelpService) {
        this.mHelpTopicRequest = this.mHelp.onRequest(path => this.onHelpTopicRequest(path));

        /*this.onStateChangeUnsub = this.mTrans.onSuccess({}, transition => {
            const to = transition.to(); // The state that was just activated
            console.log("fffffffffffffffffffffff2",to);
            if (to.data) {
                console.log("fffffffffffffffffffffff",to.data);
                // do something
            }
        });*/


    }

    private onHelpTopicRequest(path) {
        this.mHelpPath = path;
    }

    ngOnDestroy() {
        //this.onStateChangeUnsub();
    }
}
