import {MnWorkflow} from "../MnWorkflow";
import {Backend} from "../BackendInterfaces";

export class MnActionAdapterExportXls extends MnWorkflow.ActionAdapter{
    protected transform(workflow:Backend.Workflow, state:Backend.FullState, actions:MnWorkflow.Action[]):MnWorkflow.Action[] {
        if (state && state.dataset && state.dataset.table && state.dataset.table.data && state.dataset.table.data.length > 0) {
            actions.push({
                id: "frontend",
                config_template: {},
                settings: "",
                title: "EXPORT_DATA_TABLE_XLS",
                category: "EXPORT",
                comments: "",
                task_class: "",
                needs_data: false,
                can_continue: false,
                interactive: false,
                needs_config: false,
            });
        }
        return actions;
    }
}
MnWorkflow.ActionAdapter.Registry.register('EXPORT_DATA_TABLE_XLS',new MnActionAdapterExportXls());
