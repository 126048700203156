/**
 * Created by joerg on 7/17/17.
 */


export class MnValue {
    constructor(public data:any,public error:string = null) {}
}


