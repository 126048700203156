
/**
 * BUG: zoomAndSpan does not work
 */
import { Component, Input, Output, ElementRef} from '@angular/core';
import {BasePlotComponent} from '../base-plot/base-plot.component';

import * as d3 from 'd3';

const DEBUG = false;

export declare class SkylineSingleData {
  // example: {'label': 'miLogP', 'max': 10.0, 'min':0, 'value': 1.68}
  label: string;
  max:   number;
  min:   number;
  value: number;
  unit?: string;
  displayValue?: string; // additional for mouse over display,  use the displayValue instead  of the value

  valueFormat?: string; // d3 number format for formating the value (not used if displayValue is defined), e.g.".4f"
  // see  http://bl.ocks.org/zanarmstrong/05c1e95bf7aa16c4768e

    // computed values
  isValid?: boolean;
  y?: number; // y coordinate of the displayed bar
  h?: number; // height of the displayed bar

  // it is not possible to add a method => not compatible with JSON objects
  // printString(): string;
}


// useful for showing error messages
function string_SkylineSingleData(ssd: SkylineSingleData): string {
    return 'label: ' + ssd.label + ' max: ' + ssd.max + ' min: ' + ssd.min + ' value: ' + ssd.value;
}
// cannot be added to the interface




export interface SkylineData {
  plotLabel: string; // e.g compound name
  data: SkylineSingleData[];
}

function hasAtleastOneValidSkylineData(ssd: SkylineData): boolean {
   for (const each of ssd.data) {
     if (each.isValid) {
       return true;
     }
  }

  return false;
}

let template = '';
if (DEBUG) {
  template = `<p matTooltip="Tooltip!">skyline W:{{width}} H:{{height}} V:{{vertical}} M:{{centerBarsMiddleHeight}}
 showPlotTooltip:{{showPlotTooltip}} showBarTooltip: {{showBarTooltip}}</p>`;
}



@Component({
  selector: 'app-skylined3',
  // template used only for debugging
  template: template,
  // styleUrls: ['./skyline-d3.component.css'] // not used
})
export class SkylineD3Component extends BasePlotComponent<SkylineData> {

  // override
  protected myType = 'SkylineD3Component';

  @Input() vertical = true;
  @Input() centerBarsMiddleHeight = true;
  @Input() showPlotTooltip = true;
  @Input() showBarTooltip = true;
  @Input() showHorizontalAxis = true;
  @Input() horizontalAxisRelativeStrokeWidth = 0.01;




  // SVG color names, see http://www.december.com/html/spec/colorsvg.html
  // previous colors, JM did not want black and grey
 // public colors: string[] = ['black', 'goldenrod', 'darkgray', 'darkolivegreen', 'indigo', 'coral', 'darkred', 'mediumblue',
 //   'gray', 'darkorange', 'darkseagreen', 'lightblue'];


  public bbColors: string[] = `
  darkred
  goldenrod
  darkolivegreen
  burlywood
  indigo
  coral
  darkkhaki
  darkorange
  darkseagreen
  lightblue
  indianred
  saddlebrown
  slateblue
  palevioletred
  mediumseagreen
  steelblue
  thistle
  gold
  yellowgreen
  wheat
  darkcyan
  
`.split(/\s+/).map((c) => c.trim()).filter((c) => c.length > 0);

    /* Michael's color definitions

    There are 16 colors in total. What I have tried to do is arrange a palette that achieves three things.



      No color overpowers another (attracts the eye more). This was probably the hardest part and I am still not sure if truly got it right. A lot playing around with the saturation and intensity was necessary.
      The colors are all slightly desaturated as I do not want them brighter / more attractive than the system colors. This places them as a minor, yet still important, detail on the page.
      Enough variance in hue so that there is minimal or no repetition.
      Obviously if there are more than 16 properties, you will reuse some colors. However, I’ve followed a hue pattern across the first two sets (8 in each), so that if a color does need to be repeated, it will look okay next to its neighbors.
     */

  public mmColors: string[] = `255, 119, 065

                060, 138, 196

                255, 174, 065

                052, 172, 081

                179, 051, 066

                134, 119, 223

                089, 201, 182

                200, 200, 200

                223, 144, 027

                078, 168, 236

                227, 223, 075

                015, 135, 050

                232, 048, 048

                219, 121, 191

                014, 164, 139

                130, 130, 130`.split(/\n/)
      .map((c) => c.trim()).filter((c) => c.length > 0)
      .map( each => 'rgb(' + each.replace(/,\s+/g, ',') + ')');

  protected colorIndex = -1;

  protected selectedColors = this.mmColors;

  constructor(public elementRef: ElementRef) {
    super(elementRef);
    if (DEBUG) {
      this.log(elementRef.nativeElement);
    }
 }



   public checkDataValidity(d: SkylineData): boolean {
    if (!d) {
      return false;
    }
    this.dataObject = d;
    this.checkAndComputeYvalues();

    return hasAtleastOneValidSkylineData(d);

   }

   /**
   * select the next color for the color stack
   * restart at the beginning of the stack if overflow
   * @returns {string}
   */
  protected nextColor(): string {
    this.colorIndex ++;
    if (this.colorIndex >= this.selectedColors.length) {
      this.colorIndex = 0;
    }
    return this.selectedColors[this.colorIndex];
  }

  public plot(): void {

    this.colorIndex = 0;

    this.checkAndComputeYvalues();
    let [pWidth, pHeight] = this.computePlotDimension();

    // make a copy because of possible rotation
    const svgWidth: number = pWidth;
    const svgHeight: number = pHeight;



    // graphics transformation for SVG:
    // - put the origin at the bottom left
    // - scale for 0-1
    // the order of the transformations is important

    // TODO debug margin for the orientation
    const margin = this.getMargin();
    let translation = 'translate(' + margin.top + ',' + (this.height - margin.left ) + ') ';
    let rotation = ' ';

    if (  !  this.vertical) {
      // rotate to 90 around the center of the svg
      rotation = 'rotate(90, ' + this.width / 2 + ',' + this.height / 2 + ') ';

      // if the box is not a square, the rotation needs an additional translation
      rotation = 'translate(' + (this.width - this.height) / 2 + ',' + -(this.height - this.width) / 2 + ') ' + rotation;
      translation = 'translate(' + margin.left + ',' + (this.width - margin.left ) + ') ';
      [pWidth, pHeight] = [pHeight, pWidth];
    }
    const scaling = 'scale(' + pWidth + ',' +  - pHeight + ') '; // there is a minus sign before pHeigth !!

   // this.log('plot(): '  + rotation + translation + scaling);

    // provide a tooltip for the whole plot if a name is provided
    const plotToolTipLabel = this.dataObject.plotLabel;

    const svg = this.startD3Plot(plotToolTipLabel, false);

    if (!svg) {
      return;
    }
    svg.attr('transform', rotation + translation + scaling);

    if ( this.showHorizontalAxis ) {
      const x1 = 0;
      const x2 = 1;
      const y = this.centerBarsMiddleHeight ? 0.5 : 0 ;
      svg.append('line').attr('x1', x1)
        .attr('y1', y)
        .attr('x2', x2)
        .attr('y2', y)
        .attr('style', 'stroke:black;stroke-width:' + this.horizontalAxisRelativeStrokeWidth);

    }


// TODO: IE svg compatibility issue: search contenBox attribute
    // compute the actual y values and check their validity

    const self: SkylineD3Component = this;
     const group = svg.append('g');
    const bar = group.selectAll('g')
    .data(this.dataObject.data)
    .enter().append('g'); // extra group is used for the tooltip

    if (this.showBarTooltip) {
      bar.append('title').html(function (d: SkylineSingleData, i: number): string {
        let tooltip = '';

        if (self.isNonEmptyString(d.label)) {
            if (d.label && d.label.length > 0) {
                tooltip += d.label;
                tooltip += ' : ';
            }
            if (d.displayValue !== undefined && d.displayValue !== null) {
                tooltip += d.displayValue;
            } else {

              tooltip += d.value;
              if (d.unit && d.unit.length > 0) {
                  tooltip += ' ' + d.unit;
              }
        }
        }
        return tooltip;

      });
    }
    bar.append('rect')
        .attr('x', function(d: SkylineSingleData, i: number): number {return i / self.dataObject.data.length; })
        .attr('y', function(d: SkylineSingleData, i: number): number {return d.y; })
      .attr('width', (1 / self.dataObject.data.length))
      .attr('height', function(d: SkylineSingleData, i: number): number {
        return d.h;
      })
      .attr('fill', function(d: SkylineSingleData, i: number): string {return self.nextColor(); })
    ;



  }

  public generateDisplayValues(ssdList: SkylineSingleData[] ) {
    for (const each of ssdList) {
        this.generateDisplayValue(each);
    }
  }

  public generateDisplayValue(ssd: SkylineSingleData ) {
    function defined(obj) {
      return obj !== null && obj !== undefined;
    }

    if(defined(ssd.displayValue)) {
      return;
    }
    if(defined(ssd.valueFormat)) {
      ssd.displayValue = d3.format(ssd.valueFormat)(ssd.value);
    }
    const outOfRange: number = ssd.value > ssd.max ? ssd.max : (ssd.value < ssd.min ? ssd.min : 0);
    if(outOfRange !== 0) {
      const outSymbol = outOfRange == ssd.max ? '>' : '<';
      ssd.displayValue = ssd.displayValue + '(' + outSymbol + outOfRange + ')';
    }

  }
/**
 * transform each value, do some checking
 * y values are computed and scaled:
 * y range is 0-1 if the bars are not centered in the middle,
 * -0.5 0.5 , otherwise
 */
  protected checkAndComputeYvalues(): void {

    // TODO use the d3.max()?
   // d3.extent() compute the min and max => array [min, max]
  //[min, max] = d3.extent(data, function(d) { return d.date; }))

    for (const each of this.dataObject.data) {
      const eachData: SkylineSingleData = each;
      eachData.isValid = true;

      if (eachData.min >= eachData.max) {
        this.log('min >= max for data point ' + string_SkylineSingleData(eachData));
        eachData.isValid = false;
        eachData.y = eachData.h = 0;

        continue;
      }


      let h = eachData.value;
      let y = 0;

      if (h < eachData.min) {
        this.log('y < min for data point ' + string_SkylineSingleData(eachData));
        h = eachData.min;
        //eachData.isValid = false;
      }
      if (h > eachData.max) {
        this.log('y > max for data point ' + string_SkylineSingleData(eachData));
        h = eachData.max;
        //eachData.isValid = false;

      }

      // the range cannot be 0
      eachData.isValid = eachData.isValid && (eachData.max > eachData.min);

      if (eachData.isValid) {
        const range =  (eachData.max - eachData.min);
        h -= eachData.min;
        h /= range; // from now on h is [0.1]

        if (this.centerBarsMiddleHeight) {
          h -= 0.5;
          y = 0;
          if ( h >= 0) {
            y = 0.5;
          } else {
            y = 0.5 + h;
            h = - h;
          }

        }
        eachData.y = y;
        eachData.h = h;
      }
    }
  }
}
