
/**
 * Created by Joerg on 15.08.2017.
 */

import {Subject,Subscription} from 'rxjs/Rx';

import {Component,Injectable,OnDestroy,Injector,ChangeDetectorRef,NgZone} from "@angular/core";

import {CtValueView} from "./CtValueView";

@Component({
    selector: 'ct-aggrid-cell',
    template: `
        <div [matTooltip]="mTooltip" class="wrapper" [ngClass]="{error: mError}">
            <ng-container *ngIf="mParams.value !== null"
                    [ngComponentOutlet]="mComponentClass"
                    [ngComponentOutletInjector]="mComponentInjector"
            >
            </ng-container>
            <div *ngIf="mParams.value === null && mError !== null" 
            class="message ct-value-view"
            [innerHtml]="mErrorText"
            >
            </div> 
        </div>
`,
    styles: [`
        :host {
            display: block;
            height: 100%;
            width: 100%;
        }
        .wrapper {
            display: block;
            height: 100%;
            width: 100%;
        }
        .wrapper.error {
            position: relative;
            background: #fbfbfb;
            color: darkgrey;
        }
        .wrapper.error .message {
              position: absolute;
              top: 50%;
              left: 0;
              right: 0;
              white-space: pre-wrap;
              transform: translate(0,-50%);
        }
        :host > *:not(.error) > /deep/ * {
            display: block;
            height: 100%;
            width: 100%;
        }        
    `],
    providers: [CtValueView.Service]
})
export class CtAgGridCell {

    private mParams:any;
    private mComponentClass:any;
    private mComponentInjector:Injector;
    private mError:any = null;
    private mTooltipSubscription: Subscription;
    private mSetNewValueSubscription: Subscription;
    private mTooltip:string;
    private mErrorText:string = "";

    constructor(private mService:CtValueView.Service, private mInjector:Injector, private mZone:NgZone) {
        this.mTooltipSubscription = this.mService.TooltipSubject.subscribe((tooltip) => this.updateTooltip(tooltip) );
        this.mSetNewValueSubscription = this.mService.SetNewValueSubject.subscribe((new_value) => this.setNewValue(new_value) );
    }

    agInit(params:any): void {
        this.mComponentInjector = Injector.create({
            providers: [
                {
                    provide: CtValueView.Service,
                    useValue: this.mService,
                }
            ],
            parent: params.colDef.angularComponent.injector
        });
        this.mComponentClass = params.colDef.angularComponent.class;
        this.updateCell(params);
    }

    refresh(params: any): boolean {
        this.updateCell(params);
        return true;
    }

    updateCell(params:any) {
        //console.log(params);
        this.mParams = params;

        if (params.colDef.errorGetter) {
            if (params.data.footer) {
                this.mError = null;
                this.mErrorText = "";
            } else if (params.value == null) {
                this.mError = params.colDef.errorGetter(params.data);
                if (this.mError && this.mError.text) {
                    this.mErrorText = this.mError.text;
                } else {
                    this.mErrorText = "";
                }
            } else {
                this.mError = null;
                this.mErrorText = "";
            }
        } else {
            this.mError = null;
            this.mErrorText = "";
        }

        this.mService.ParameterSubject.next({
            size: { height: params.node.rowHeight, width: params.column.actualWidth },
            options: { condensed: false },
            value: params.value,
        });
    }

    updateTooltip(tooltip:string) {
        //console.log(tooltip);
        this.mTooltip = tooltip;
    }

    setNewValue(new_value:any) {
        let params = this.mParams;
        if (params.colDef.valueSetterX) {
            params.colDef.valueSetterX(params.data,new_value,params.colDef.colId);
        }
    }

    ngOnDestroy() {
        this.mTooltipSubscription.unsubscribe();
        this.mSetNewValueSubscription.unsubscribe();
        //console.log(`Destroying CtAgGridCell component`);
    }

}


