// rxjs
import 'rxjs/Rx';
import { Observable } from 'rxjs/Observable';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Subject } from 'rxjs/Subject';
//import { Subscription } from 'rxjs/Subscription';

// mn
//import {MnBackend} from "@mn/core";

import {SimilaritiesDistances } from '../../../utils/sim_dist';

import {anyPairToNumberFunction, GenericNode} from "../../../utils/generic-node";


import {Backend} from "../BackendInterfaces";
import ErrorMessage = CtTable.ErrorMessage;
//import Tree = CtWorkflow.Tree;

import {IHeaderParams} from "ag-grid/main";
import {CtSelectionService} from "../CtSelectionService";
import {GridOptions,GridApi,ColumnApi,ColDef,Column,RowNode} from 'ag-grid/main'
import SortedRow = CtTable.SortedRow;
import {ProbabilityPlotData} from "../d3/probability-plot-d3/probability-plot-d3.component";
import {MnBackend, MnTextService} from "@mn/core";


export class CtTable {

    static ColumnHandlers:CtTable.ColumnHandlerMap = {

    };

    static registerColumnHandler(type: CtTable.ColumnType,handler:CtTable.ColumnHandler) {
        CtTable.ColumnHandlers[type] = handler;
    }


    private mNodeRoot:CtTable.Node;
    private mNodeMap:CtTable.NodeMap = {};
    private mNodeArray:CtTable.NodeArray = [];
    private mStatistics:CtTable.Statistics = {
        min: {},
        max: {},
        sum: {},
        count: {},
        avg: {},
    };

    protected originalRowTree: GenericNode<CtTable.SortedRow>; // original row order

    private mMaxIndent:number = 0;
    private mRowsVirtual:CtTable.SortedRow[] = []; //rows that are used to separate parent from child nodes

    constructor(private mTable:Backend.Table, private mWorkflowSettings:any, private mTextService:MnTextService = null) {
        //console.log(this.mWorkflowSettings);
        this.setupTable();
    }

    private setupTable() {
        this.setupNodes();
        this.setupFlatNodes();
        this.setupStatistics();
        this.setupCalculatedNodes();
        this.setUpTreeFromIndentedRows();
        this.setupTableWithVirtualRows();
    }


    public maxIndent() {
        return this.mMaxIndent;
    }

    // now come methos to handle the nodes related to the columns
    private setupNodes() {

        let root_handler = CtTable.ColumnHandlers['group'];
        let root_node:Backend.Table.Node = {
            type: "group",
            ident:"root",
            children: this.mTable.spec
        };
        this.mNodeRoot = root_handler(this,root_node)
    }

    private translate(node:CtTable.Node):string {
        if (this.mTextService == null || node.asis == true) {
            return node.ident;
        } else {
            return <string>this.mTextService.text('ct.table.header.'+node.ident);
        }
    }

    private setupFlatNodes() {
        let rec = (node:CtTable.Node, parent_id:string, headers:string[]) => {
            node.id = parent_id + node.ident;
            node.header = this.translate(node);
            node.headers = headers;
            if (node.children && node.children.length > 0) {
                for (let i = 0, l = node.children.length; i < l; i++) {
                    rec(node.children[i],node.id,headers.concat([this.translate(node.children[i])]));
                }
            } else {
                //console.log(me,this);
                this.mNodeMap[node.id] = node;
                this.mNodeArray.push(node);
            }
        };
        rec(this.mNodeRoot,'',[]);
        //console.log(this.mNodeMap);
        //console.log(this.mNodeArray);
    }


    public setupCalculatedNodes() {
        let result:CtTable.NodeArray = [];
        for (let i = 0, l = this.mNodeArray.length; i < l; i++) {
            let n:CtTable.Node = this.mNodeArray[i];
            if (n.calculator) {
                this.recalculateNode(n);
            }
        }
        return result;
    }


    public getFlatNodesOfType(type: CtTable.ColumnType):CtTable.NodeArray {
        let result:CtTable.NodeArray = [];
        for (let i = 0, l = this.mNodeArray.length; i < l; i++) {
            let n:CtTable.Node = this.mNodeArray[i];
            if (n.type == type){
                result.push(n);
            }
        }
        return result;
    }

    public getFlatNodes():CtTable.NodeArray {
        return this.mNodeArray;
    }

    private setupStatistics() {
        /*let stat = (t:string,k:string) => {

        };*/
        let s:CtTable.Statistics = this.mStatistics;
        let s_max = s.max;
        let s_min = s.min;
        let s_count = s.count;
        let s_sum = s.sum;
        let s_avg = s.avg;
        let data:any[] = this.mTable.data;
        for (let id = 0, ld = data.length; id < ld; id++) {
            let row:any = data[id];
            for (let ic = 0, lc = this.mNodeArray.length; ic < lc; ic++) {
                let col:CtTable.Node = this.mNodeArray[ic];
                if (col.type == CtTable.ColumnType.Float || col.type == CtTable.ColumnType.Int || col.type == CtTable.ColumnType.FloatPercent)  {
                    let v:number = col.getter(row);
                    if (v == null || v == undefined) continue;
                    s_max[col.id] = s_max[col.id] ? (s_max[col.id] > v ? v : s_max[col.id]) : v;
                    s_min[col.id] = s_min[col.id] ? (s_min[col.id] < v ? v : s_min[col.id]) : v;
                    s_sum[col.id] = s_sum[col.id] ? (s_min[col.id] + v) : v;
                    s_count[col.id] = s_count[col.id] ? (s_count[col.id] + 1) : 1;
                }
            }
        }
        for (let ic = 0, lc = this.mNodeArray.length; ic < lc; ic++) {
            let col:CtTable.Node = this.mNodeArray[ic];
            if (col.type == CtTable.ColumnType.Float || col.type == CtTable.ColumnType.Int || CtTable.ColumnType.FloatPercent) {
                if (s_sum[col.id] != undefined && s_count[col.id] != undefined)
                s_avg[col.id] = s_sum[col.id] / s_count[col.id];
            }
        }

        //console.log(s);
    }

    /**
     * Find the row in my table data that has the given record index
     * @param {string} record_index
     * @returns {any}
     */
    findRow(record_index:string):any {
        for (let i = 0, l = this.mTable.data.length; i < l; i++) {

            let row: any = this.mTable.data[i];
            if (row.record_index == record_index) {
                return row;
            }
        }
        return {};
    }

    public recalculate(nid:string) {
        let node = this.mNodeMap[nid];
        if (!node) return;
        if (!node.calculator) return;
        this.recalculateNode(node);
    }

    public recalculateNode(node:CtTable.Node) {
        //console.log("XXX",node);
        node.calculator(this,node,this.mTable.data,this.mWorkflowSettings[node.key]);
    }

    public get Nodes():CtTable.Node[] {
        return this.mNodeRoot.children;
    }

    public getNodeParents(search_node:CtTable.Node):CtTable.Node[] {
        let result:CtTable.Node[] = [];
        let rec = (node:CtTable.Node, parents:CtTable.Node[]) => {
            if (result.length > 0) {
                return;
            }
            let new_parents:CtTable.Node[] = parents.slice();
            new_parents.push(node);
            if (node.id == search_node.id) {
                new_parents.shift();
                result = new_parents;
                return;
            }
            if (node.children && node.children.length > 0) {
                for (let i = 0, l = node.children.length; i < l; i++) {
                    rec(node.children[i],new_parents);
                }
            }
        };
        rec(this.mNodeRoot,[]);
        return result;
    }

    public findNodes(matcher:(node:CtTable.Node,level?:number)=>boolean):CtTable.Node[] {
        let result:CtTable.Node[] = [];
        this.forNodes((node:CtTable.Node,level:number) => {
            if (matcher(node,level)) result.push(node);
        });
        return result;
    }

    public findNodesStartFrom(start_from:CtTable.Node, matcher:(node:CtTable.Node,level?:number)=>boolean):CtTable.Node[] {
        let result:CtTable.Node[] = [];
        this.forNodesStartFrom(start_from,(child:CtTable.Node,level:number) => {
            if (matcher(child,level)) result.push(child);
        });
        return result;
    }

    public forNodes(callback:(node:CtTable.Node,level?:number)=>void) {
        let rec = (node:CtTable.Node,level:number) => {
            callback(node,level);
            if (node.children && node.children.length > 0) {
                for (let i = 0, l = node.children.length; i < l; i++) {
                    rec(node.children[i],level+1);
                }
            }
        };
        rec(this.mNodeRoot,0);
    }

    public forNodesStartFrom(start_from:CtTable.Node, callback:(node:CtTable.Node,level?:number)=>void) {
        let rec = (node:CtTable.Node,level:number) => {
            callback(node,level);
            if (node.children && node.children.length > 0) {
                for (let i = 0, l = node.children.length; i < l; i++) {
                    rec(node.children[i],level+1);
                }
            }
        };
        rec(start_from,0);
    }


    /**
     * Create a GenericNode from a CtTable.Node. If the argument is null, then use my root node.
     * @param {CtTable.Node} start_from
     * @returns {GenericNode<CtTable.Node>}
     */
    public buildGenericNodeFromColumnNode(start_from:CtTable.Node) {
        const result: GenericNode<CtTable.Node> = new GenericNode();

        if( ! start_from) {
            start_from = this.mNodeRoot;
        }
        result.buildTreeFromDataWithChildren(start_from, (n:CtTable.Node)=>n.children?n.children:[]);



        return result;

    }




    public get Statistics():CtTable.Statistics {
        return this.mStatistics;
    }

    public get Hierarchy() {
        return null;
    }

    public get Columns():any[] {
        return [];
    }

    public get NodeMap():CtTable.NodeMap {
        return this.mNodeMap;
    }

    public get Rows():any[] {
        return this.mTable.data;
    }

    public get RowsVirtual():CtTable.SortedRow[] {
        return this.mRowsVirtual;
    }

    /**
     * return the row that matched the given index. this method can be used to retrieve the row that
     * has received a click event
     * @param {number} rowIndex
     * @returns {CtTable.SortedRow}
     */
    public getRowFromSortedTable(eventRowIndex: number) : CtTable.SortedRow {
        for( let row of this.RowsVirtual) {
            if(row.sort_index == eventRowIndex) {
                return row;
            }
        }

        return null;
    }
    // not used any more ?
    /*
    public get RowsTree():RowsTree[] {
        if (this.mTable.data.length == 0) {
            return null;
        }

        console.log('build RowsTree!!!!!!!!!!!');
        console.assert(false, 'glglgl');

        let root:CtTable.RowsTree = {
            row: {},
            children: [],
        };

        let stack:RowsTree[] = [root];
        let last:RowsTree = root;
        for (let i = 0, l = this.mTable.data.length; i < l; i++) {
            let row:any = this.mTable.data[i];
            let indent = stack.length - 1;
            if (row.indent == indent) {
                last = {
                    row: row,
                    children: []
                };
                stack[indent].children.push(last);
            } else if (row.indent < indent) {
                stack.length = row.indent + 1;
                last = {
                    row: row,
                    children: []
                };
                stack[stack.length -1].children.push(last);
            } else if (row.indent > indent) {
                let new_last:RowsTree = {
                    row: row,
                    children: []
                };
                last.children.push(new_last);
                last = new_last;
                indent++;
                stack.push(last);
            }
        }

        //console.log(root);
        return root.children
    }

*/


    protected setUpTreeFromIndentedRows(): void {
        const rowTree: GenericNode<any> = new GenericNode();
        rowTree.buildTreeFromDataWithDepth(this.mTable.data, (row:any) => row.indent);
        //console.log(rowTree);
        this.originalRowTree = rowTree;
    }


    protected setupTableWithVirtualRows(): void {
    if( this.originalRowTree.children.length > 0) {
        this.mRowsVirtual = this.createOrSetTableWithAdditionalVirtualRows(this.originalRowTree, null);

        this.mMaxIndent = this.mRowsVirtual.map((r) => r.indent).reduce(
            (max, indent )=> Math.max(max, indent));

        //console.log(this.mMaxIndent);

    } else {
        this.mRowsVirtual = [];
        this.mMaxIndent = 0;
    }


}


    /**
     * Add so called virtual rows that contains labels that are used to label for the hierarchy levels.
     * If the virtual rows already exist then reuse them (as found in the second argument).
     * Add / change the sort_index to each row
     * @param {GenericNode<{}>} rowTree
     * @param {any[]} mixedVirtualRows
     * @returns {any[]}
     */
    protected createOrSetTableWithAdditionalVirtualRows(rowTree: GenericNode<CtTable.SortedRow>, mixedVirtualRows: CtTable.SortedRow[] ): CtTable.SortedRow[] {

        // if mixedVirtualRows already exist, then the virtual rows will not be created but reused
        // this is needed for sorting

        const rows: CtTable.SortedRow[] = [];
        let previous: GenericNode<CtTable.SortedRow> = rowTree;

        // function to create a key for the virtual rows
        function makeKey( r: CtTable.SortedRow): string {return r.category + '::' + r.indent;}

        let sortIndex = -1;

        const virtualRowDict :{ [key: string] : CtTable.SortedRow[] } = {};
        if (mixedVirtualRows) { //
            for (const vr of  mixedVirtualRows.filter((r) => r.is_virtual)) {
                const key: string = makeKey(vr);
                virtualRowDict[key] =  virtualRowDict[key] ?  virtualRowDict[key] : [];
                virtualRowDict[key].push(vr);
            }
        }

        // rowTree might have the original row order or might be sorted (then mixedVirtualRows is defined)
        // rowTree is the root, it does not contain any data - must be skipped during enumeration
        rowTree.collectNodeWithData().forEach((gNode: GenericNode<CtTable.SortedRow>)=> {
            const row: CtTable.SortedRow = gNode.data;;
            let category: string = row.category;


            // add more info the label - does not work because of the translator
            // should have a format string e.g. 'ANALOGS of %s'
            // if (gNode.parent &&  gNode.parent.data) {
            //     const parentRow: any = gNode.parent.data;
            //     const parentLabel = new CtTable.CtRowValue(parentRow).headerLabel();
            //     category += ' of ' + parentLabel;
            // }

            // create or reuse a virtual row if there is an increase of indentation
            if (category &&  previous.treeDepth < gNode.treeDepth) {

                sortIndex ++;
                let vRow: CtTable.SortedRow = null;
                if (!mixedVirtualRows) {
                    // create new virtual row
                    vRow = <CtTable.SortedRow>{
                        is_virtual: true,
                        indent: row.indent,
                        label: category, // used for getting the translated row label
                        category: category, // same name convention as row - needed for makeKey() function
                    }
                } else {
                    //reuse one of the virtual rows with the same key
                    vRow = virtualRowDict[makeKey(row)].pop();
                }
                console.assert(vRow != null);
                vRow.sort_index = sortIndex;
                rows.push(vRow);
            }
            sortIndex ++;
            row.sort_index = sortIndex;
            rows.push(row);
            previous = gNode;

        });

        // if (true) { // debugging tool
        //     for (let row of rows) {
        //         console.log('\t'.repeat(row.indent), row.system_id, ' sort index:'row.sort_index,  ' indent:', row.indent);
        //     }
        // }

        return rows;

    }



    protected localeCompareStrings(s1: string, s2: string): number {
        s1 = s1 ? s1 : '';
        s2 = s2 ? s2 : '';
        let result = s1.localeCompare(s2);

        return result;
    }
    // BB
    /**
     * Sort a tree of rows, children of one level at a time
     * THe eventShiftKey is not supported
     * @param {IHeaderParams} headerParam
     * @param {string} sortDirection
     * @param {boolean} eventShiftKey
     */
    public sortMultiLevels(headerParam: IHeaderParams, sortDirection: string, eventShiftKey: boolean) {
       const fieldId = headerParam.column.getColId();
       const tableNode: CtTable.Node = this.mNodeMap[fieldId]; // is needed to extract the value from the row
       //console.log('sorting by ', fieldId);

        //Note: if the sortDirection is empty, the ag-grid will restore the original order by it self
        if (sortDirection != '') { //TODO: use enum

            // make a copy before sorting
            const sortedRowTree: GenericNode<CtTable.SortedRow> = this.originalRowTree.shallowCopy();
            const reverse: boolean = sortDirection == 'asc';
            const type: CtTable.ColumnType = tableNode.type;
            let comparator: anyPairToNumberFunction<any>;

            switch(type) {
                case CtTable.ColumnType.String:
                    comparator = (r1: SortedRow, r2: SortedRow) => {
                        let v1: string = tableNode.getter(r1);
                        let v2: string = tableNode.getter(r2);

                        return this.localeCompareStrings(v1, v2);

                    };
                    break;

                case  CtTable.ColumnType.Int:
                case  CtTable.ColumnType.Float:
                case  CtTable.ColumnType.Boolean:
                case  CtTable.ColumnType.FloatPercent:

                    comparator = (r1: SortedRow, r2: SortedRow) => {
                        const v1: number = + tableNode.getter(r1);
                        const v2: number = + tableNode.getter(r2);
                        const result: number = v1 - v2;
                        return result;
                    };
                    break;

                    // BB: sort the compounds by CMS-ID
                case CtTable.ColumnType.Image:
                    comparator = (r1: SortedRow, r2: SortedRow) => {
                        let s1: number = r1.system_id;
                        let s2: number = r2.system_id;

                        return s1 - s2;
                    };
                    break;

                default:
                    console.assert( ! (type in CtTable.sortableTypes), 'Sorting of ' + type + ' must be implemented');

            }

            if ( comparator ) {
                sortedRowTree.sort(comparator, reverse);
             }

             // will set the sort_index in each row
            this.createOrSetTableWithAdditionalVirtualRows(sortedRowTree, this.mRowsVirtual);

        }

     }

    public get Tree():any[] {
        return [];
    }

    public destroy() {

    }

}





export namespace CtTable
{
    /*export enum Status
    {
        Invalid = "INVALID",
        Pending = "PENDING",
        Transition = "TRANSITION",
        Ready = "READY",
    }*/

    import Chart = CtTable.Skyline.Chart;

    // coming / defined in the backend?

    //TODO: merge with CtValueView.Types?
    export enum ColumnType {
        Boolean = 'boolean',
        Int = 'int',
        Float = 'float',
        FloatPercent = 'float.percent',
        Float01 = 'float.01',
        String = 'string',
        StringFingerprint = 'string.fingerprint',
        Group = 'group',
        GroupFingerprints = 'group.fingerprints',
        Image = 'image',
        ChemotypeProfile = 'chemotype_profile',
        ListName = 'list.name',
        ListIdentifier = 'list.identifier',
        ListString = 'list.string',
        ListStudy = 'list.study',
        GroupCompound = 'group.compound',
        GroupTox = 'group.tox',
        GroupFingerprint = 'group.fingerprint', // Should be removed??
        GroupChemotypeProfile = 'group.chemotype_profiles',

        // Node specific?
        DataSummary = 'data.summary',
        Enum = 'enum',
        WoeProbabilitis = 'woe.probabilities',

        Skyline = 'chemtunes3_skyline',
        SkylineGroup = 'group.skyline',
        Fingerprint = 'fingerprint',
        BitString = 'bitstring',

    }

    export const sortableTypes: CtTable.ColumnType[] = [
        CtTable.ColumnType.Boolean,
        CtTable.ColumnType.String,
        CtTable.ColumnType.Int,
        CtTable.ColumnType.Float,
        CtTable.ColumnType.FloatPercent, //does not work


        ColumnType.Image, // to be sorted by CMS id


    ];


    // BB: column node info - can span multiple columns
    export interface Node {
        id?: string;
        ident: string;                // identifier to select getter / renderer when data type is not sufficient
        asis?: boolean;
        type: ColumnType;                 // raw data type
        key?: string;                 // key to data
        help?: string;                 // help to data
        getter?: ValueGetter;
        error?: ValueError;
        setter?: ValueSetter;
        calculator?: NodeCalculator;
        children?: Node[];            // children
        group_expansion?: number;  // show or not  a column when the group is expanded
        editable?:boolean;
        headerName?: string; // same as ident unless translated (see CtStateViewHelper.createTableConfig2)
        headers?: string[];
        header?: string;
    }

    // BB: needed - TBC
    function nodeShallowCopy(src: Node) {
        const newNode = {};
    }


    export enum ErrorStatus {
        None = "None",
        Failure = "Failure"
    }
    export interface ErrorMessage {
        status: ErrorStatus;
        text:string;
    }
    export type ValueGetter = (row:any) => any;
    export type ValueError = (row:any) => ErrorMessage;
    export type ValueSetter = (row:any,data:any,nid:string,backend?:MnBackend) => any;
    export type NodeCalculator = (table:CtTable,node:Node,rows:any[],settings:any) => void;

    export type ColumnHandler = (table:CtTable, node:Backend.Table.Node, object?:string, error?:ValueError) => Node;

    export interface ColumnHandlerMap {
        [key:string]: ColumnHandler
    }

    export interface NodeMap {
        [key:string]: Node
    }
    export type NodeArray = Node[];
    export interface Statistics {
        min: any;
        max: any;
        sum: any;
        count: any;
        avg: any;
    }

    export interface Skyline {
        enabled: boolean;
        data: Chart[];
    }
    export namespace Skyline {
        export interface Chart {
            label: string;
            width: number;
            color: string;
            value: number;
        }
    }

    /**
     * ag-grid rows that can be sorted
     */
    export interface SortedRow {
        label?: string;
        indent?: number;
        category: string;
        sort_index?: number;
        record_index? : string; //to avoid an error message in CtImageView.ts
        is_virtual: boolean;

        image? : string;
        selected? : boolean;
        system_id? :  number;
        system_prefix?: string;
        x_selected? : boolean;

        id? : string;

        data?: any; // the data row coming from the backend
    }


    // BB:  the data structure to be sent to the cell viewer or more
    // BB: what is the class / interface for the row object?
    export class CtRowValue {
        public  row: SortedRow;
        public image: string; //url to get the image (SVG)
        public selected: boolean; //
        public indent: number;

        constructor( row: SortedRow) {
            this.row = row;
            this.image = row.image;
            this.selected = row.selected;
            this.indent = row.indent;

        }

        /**
         * Return a label that one can use to display a compound id above the compound image
         * @returns {string}
         */
        public headerLabel(): string {
            let result = '';

            if (this.row.system_id) {
                if (this.row.system_prefix) { //e.g CMS
                    result = this.row.system_prefix + '-';
                }
                result += this.row.system_id;
            }
            return result;
        }

        /**
         * Return a label that one can display at the bottom of the compound picture
         * @returns {string}
         */
        public footerLabel(): string {
            return null; //TO be implemented - default null will not be displayed
        }

        /**
         * not used&tested yet, idea is to get the SDF from the server to generate a SVG display on the fly
         * or to use JSME in depict mode to display the structure, which is probably the best solution
         */
        public molUrl() : string {
            //return this.row.details.chem; // this is not a url for MOL - but details-meta information
            //console.log(this.row);
            let image = this.image;
            let mol = image.replace('image', 'mol');

            return mol;

        }

        /**
         * not used&tested yet
         * @returns {string}
         */
        public smilesUrl(): string {
            let image = this.image;
            let mol = image.replace('image', 'smiles');

            return mol;
        }

        /**
         * Returns true if this row has been selected by the user - used to change the cell style
         * @returns {boolean}
         */
        public  isRowSelected(): boolean {
            return this.row.x_selected;
        }
    }

}


CtTable.registerColumnHandler(CtTable.ColumnType.Group, (table:CtTable, node:Backend.Table.Node, object?:string, error?:CtTable.ValueError) => {
    let result:any = {
        ident: node.ident,
        type: node.type,
        children: []
    };
    if (node.asis) {
        result.asis = node.asis;
    }
    if (node.ident == "SUMMARY") {
        result.group_expansion = 1;
        result.children.push(
            <CtTable.Node>{
                ident: "SUMMARY_SUMMARY",
                type: CtTable.ColumnType.DataSummary,
                getter: (row: any) => {
                    return {
                        number_of_studies: row.sum_tot_std,
                        names: row.sum_names,
                    }
                },
             }
        )
    }

    for (let i = 0, l = node.children.length; i < l; i++) {
        let child_node:Backend.Table.Node = node.children[i];
        if (child_node.ident == "USER_ID") {
            //console.log('USER_ID',child_node);
        } else {
            let child_handler = CtTable.ColumnHandlers[child_node.type];
            if (child_handler) {
                result.children.push(child_handler(table,child_node, object, error))
            }
        }
    }
    // this is a hack, order should be reversed in the backend
    if (node.ident == "STUDIES") {
        result.children = result.children.reverse();
        result.group_expansion = 2;
    }

    //fixme
    if (node.ident == "PHARMACOLOGY") {
        result.children.forEach((value,index) => {
            console.log("PHFIX",value);
            value.getter = (row:any) => {
                let n = node.children[index];
                if (row.sum_pharm) {
                    return row.sum_pharm[n.key];
                } return "";
            }
        })
    }
    return result;
});

// hack: duplicated of CtTable.ColumnType.Group
CtTable.registerColumnHandler(CtTable.ColumnType.GroupChemotypeProfile, (table:CtTable, node:Backend.Table.Node, object?:string, error?:CtTable.ValueError) => {
    let result:any = {
        ident: node.ident,
        type: node.type,
        children: []
    };
    if (node.asis) {
        result.asis = node.asis;
    }
    if (node.ident == "SUMMARY") {
        result.group_expansion = 1;
        result.children.push(
            <CtTable.Node>{
                ident: "SUMMARY_SUMMARY",
                type: CtTable.ColumnType.DataSummary,
                getter: (row: any) => {
                    return {
                        number_of_studies: row.sum_tot_std,
                        names: row.sum_names,
                    }
                },
             }
        )
    }

    for (let i = 0, l = node.children.length; i < l; i++) {
        let child_node:Backend.Table.Node = node.children[i];
        if (child_node.ident == "USER_ID") {
            //console.log('USER_ID',child_node);
        } else {
            let child_handler = CtTable.ColumnHandlers[child_node.type];
            if (child_handler) {
                result.children.push(child_handler(table,child_node, object, error))
            }
        }
    }
    // this is a hack, order should be reversed in the backend
    if (node.ident == "STUDIES") {
        result.children = result.children.reverse();
        result.group_expansion = 2;
    }

    //fixme
    if (node.ident == "PHARMACOLOGY") {
        result.children.forEach((value,index) => {
            console.log("PHFIX",value);
            value.getter = (row:any) => {
                let n = node.children[index];
                if (row.sum_pharm) {
                    return row.sum_pharm[n.key];
                } return "";
            }
        })
    }
    return result;
});


CtTable.registerColumnHandler(CtTable.ColumnType.GroupFingerprints, (table:CtTable, node:Backend.Table.Node, object?:string, error?:CtTable.ValueError) => {
    let result:any = {
        ident: node.ident,
        type: node.type,
        children: []
    };
    if (node.asis) {
        result.asis = node.asis;
    }
    for (let i = 0, l = node.children.length; i < l; i++) {
        let child_node:Backend.Table.Node = node.children[i];
        let child_handler = CtTable.ColumnHandlers[child_node.type];
        if (child_handler) {
            result.children.push(child_handler(table,child_node, object, error))
        }
    }
    return result;
});


/*let create_value_handler = function(table:CtTable, node:Backend.Table.Node, object?:string) {
    return {
        ident: node.ident,
        type: node.type,
        getter: object === undefined ?
            (row:any) => { return row[node.key]; } :
            (row:any) => { return row[object][node.key]; }
    };
};*/

let create_default_value_getter = function(node:Backend.Table.Node) {
    return (row:any) => { /*console.log(node.key,row[node.key]);*/return row[node.key]; }
};

let create_object_value_getter = function(node:Backend.Table.Node, object) {
    return (row:any) => { return row[object][node.key]; }
};

let create_default_value_error = function(node:Backend.Table.Node):CtTable.ValueError {
    if (node.error) {
        return (row:any) => {
            return {
                status: CtTable.ErrorStatus.Failure,
                text: JSON.stringify(row[node.error])
            }
        }
    } else {
        return (row:any) => {
            return {
                status: CtTable.ErrorStatus.Failure,
                text: "",
                //text: JSON.stringify(row[node.error])
            }
        }
    }
 };

let create_value_handler_func = function() {
    return (table:CtTable, node:Backend.Table.Node, object?:string, error?:CtTable.ValueError) => {
        let result:CtTable.Node = {
            ident: node.ident,
            type: <CtTable.ColumnType>node.type,
            getter: object === undefined ?
                create_default_value_getter(node) :
                create_object_value_getter(node,object)
        };
        if (node.editable) {
            result.editable = node.editable;
            result.setter = (row:any,data:any,nid:string,backend?:MnBackend) => {
                console.log("Setter",data,nid);

                let nn = table.findNodes((node)=>{
                    return node.id == nid;
                });
                if (nn.length == 1) {
                    let mynode = nn[0];
                    console.log(mynode,data);
                    row[mynode.key] = data;
                    let aux = row[mynode.key+'_aux'];
                    if (backend && aux) {
                        console.log("saving to backend");
                        let body:any = {
                            property: aux.property,
                            value: data,
                        };
                        console.log('saving', aux.change_url, body);
                        backend.patch( aux.change_url,body).subscribe((done)=>{
                            console.log(done);
                        })
                    }
                }
            };
            result.key = node.key;
        }
        if (node.asis == true || node.key.indexOf('com.mnam.corinasymphony.') > -1) {
            // fixme this comes wrong from the backend: com_mnam_corinasymphony_
            //result.asis = node.asis;
            result.asis = true;
        }
        if (error) {
            result.error = error;
        } else {
            result.error = create_default_value_error(node);
        }
        return result;
    };
};


CtTable.registerColumnHandler(CtTable.ColumnType.Float, create_value_handler_func());
CtTable.registerColumnHandler(CtTable.ColumnType.Int, create_value_handler_func());
CtTable.registerColumnHandler(CtTable.ColumnType.Boolean, create_value_handler_func());
CtTable.registerColumnHandler(CtTable.ColumnType.String, create_value_handler_func());
CtTable.registerColumnHandler(CtTable.ColumnType.ChemotypeProfile, create_value_handler_func());
//CtTable.registerColumnHandler(CtTable.ColumnType.BitString, create_value_handler_func());

// BB: this method shows how one can add custom columns - can be useful for debugging
CtTable.registerColumnHandler(CtTable.ColumnType.Image, (table:CtTable, node:Backend.Table.Node, object?:string, error?:CtTable.ValueError) => {
    return {
        ident: 'XXX',
        type: CtTable.ColumnType.GroupCompound,
        group_expansion: 2,
        children: [
            {
                ident: 'IMAGE_URL', //TODO: this will be translated to CMS ID - not good - should use the backend system_prefix
                type: CtTable.ColumnType.Image,
                getter: (row:CtTable.SortedRow) => {
                    //return row.image;
                    return new CtTable.CtRowValue(row);

                }
            },
            /*{
                ident: 'SYSTEM_ID',
                type: CtTable.ColumnType.String,
                getter: (row:CtTable.SortedRow) => {
                    return row.system_id;
                }
            },*/

            {
                ident: 'USER_ID',
                type: CtTable.ColumnType.String,
                getter: (row:CtTable.SortedRow) => {
                    return "";
                }
            },
        ],
    };
});



///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/// tanimoto and fingerprint

/* input: fingerprint
'0001010101 ... '

output : overwrite the data in the row with an object
{
    tanimoto : 0.89
    fingerprint: '0001010101 ... '
}


Assuming a table with hierarchy, the tanimoto coefficient is computed between the parent and the child

*/

// the fields in the new data object
export const tanimotoKey = 'tanimoto';
export const fingerPrintKey = 'fingerprint';





function tanimotoIsAlreadySet(row: CtTable.SortedRow, node:CtTable.Node) :boolean {
    return  row && row[node.key] &&  row[node.key].hasOwnProperty(tanimotoKey);
}

// assume that the tanimoto coefficient have not been already set
function getRowFingerPrintPrime(row: CtTable.SortedRow, node:CtTable.Node) : string {
    if(row && row[node.key]) {
        return row[node.key];
    }

    return null;
}

function getRowFingerPrintAlreadySet(row: CtTable.SortedRow, node:CtTable.Node) : string {
    if( tanimotoIsAlreadySet(row, node)) {
        return row[node.key][fingerPrintKey];
    }

    return null;
}


function setRowFingerPrintAndTanimoto(row: CtTable.SortedRow, node:CtTable.Node, tanimoto: number, fingerprint: string) : void {
    row[node.key] = {};
    row[node.key][tanimotoKey] = tanimoto;
    row[node.key][fingerPrintKey] = fingerprint;
}


CtTable.registerColumnHandler(CtTable.ColumnType.Fingerprint, (table:CtTable, node:Backend.Table.Node, object?:string, error?:CtTable.ValueError) => {
    return {
        ident: node.ident,
        asis: true,
        type: CtTable.ColumnType.Group,
        group_expansion: 2,
        children: [
            {
                ident: 'Tanimoto',
                asis: true,
                key: node.key,
                //type: CtTable.ColumnType.Float01, // not sortable
                type: CtTable.ColumnType.Float, //sortable and displayed with 2 decimals - perfect
                //type: CtTable.ColumnType.FloatPercent, // does not work yet - no  display, just number and not sortable
                calculator: (table:CtTable,node:CtTable.Node, rows:any[], settings: any) => {
                    //Note: this code is called twice when the page showing the grid is reloaded


                    // a bit of duplicated code with setUpTreeFromIndentedRows()
                     const rowTree: GenericNode<any> = new GenericNode();
                     rowTree.buildTreeFromDataWithDepth(rows, (row:any) => row.indent);
                     // takes no time even with 2581 rows

                    // for tanimoto computation
                    const sd:SimilaritiesDistances = new SimilaritiesDistances();


                    rowTree.enumerateDepthFirst((eachNode: GenericNode<CtTable.SortedRow>): void => {
                        if(eachNode.data) {
                            console.assert(!tanimotoIsAlreadySet(eachNode.data, node));
                            let tSim: number = null;
                            const childFingerPrint: string = getRowFingerPrintPrime(eachNode.data, node);
                            if(eachNode.parent && eachNode.parent.data) {
                                const parentFingerPrint = getRowFingerPrintPrime(eachNode.parent.data, node);

                                if (parentFingerPrint && childFingerPrint && parentFingerPrint.length == childFingerPrint.length) {
                                    // compute tanimoto coefficient
                                    tSim = sd.tanimotoBinarySimilarity(parentFingerPrint, childFingerPrint);
                                } else {
                                    //console.error('Different fingerprint length', node.key, parent.data['id'], row['id'], parentFingerPrint.length, childFingerPrint.length);
                                }

                            }
                            setRowFingerPrintAndTanimoto(eachNode.data, node, tSim, childFingerPrint);


                        }
                    });

                },

                getter: (row:any) => {if(row[node.key] && row[node.key].hasOwnProperty(tanimotoKey)) {return row[node.key][tanimotoKey]} else {return row[node.key];}},
                error: create_default_value_error(node)
            },
            {
                ident: 'Fingerprint',
                asis: true,
                key: node.key,
                type: CtTable.ColumnType.StringFingerprint,
                getter: (row:any) => {if(row[node.key] && row[node.key].hasOwnProperty(fingerPrintKey)) {return row[node.key][fingerPrintKey]}else {return row[node.key];}},
                error: create_default_value_error(node)
            },
        ],
    };
});



let create_list_group = function(table:CtTable, node:Backend.Table.Node, group_type:string): any {
    return {
        ident: node.ident,
        //type: group_type,
        type: "group",
        group_expansion: 2,
        children: [
            {
                ident: 'values',
                type: node.type,
                getter: (row:any) => {
                    //return JSON.stringify(row[node.key],null,2);
                    return row[node.key];
                }
            },
            {
                ident: 'count',
                type: 'int',
                getter: (row:any) => {
                    let list:any[] = row[node.key];
                    if (list == undefined) {
                        return undefined;
                    }
                    return row[node.key].length;
                }
            }
        ],
    };

};

CtTable.registerColumnHandler(CtTable.ColumnType.ListName, (table:CtTable, node:Backend.Table.Node) => {
    return create_list_group(table,node,'group.list');
});

CtTable.registerColumnHandler(CtTable.ColumnType.ListIdentifier, (table:CtTable, node:Backend.Table.Node) => {
    return create_list_group(table,node,'group.list');
});

CtTable.registerColumnHandler(CtTable.ColumnType.ListString, (table:CtTable, node:Backend.Table.Node) => {
    return create_list_group(table,node,'group.list');
});

CtTable.registerColumnHandler(CtTable.ColumnType.ListString, (table:CtTable, node:Backend.Table.Node) => {
    return  <CtTable.Node> {
        ident: node.ident,
        type: node.type,
        getter: (row:any) => {
            return row[node.key];
        }
    };
});

let create_tox_default_error = function(node:Backend.Table.Node):CtTable.ValueError {
    return (row:any) => {
        let tox = row[node.key];
        if (tox && tox.toxgps_woe_success) {
            return null;
        } else {
            return <CtTable.ErrorMessage> {
                status: CtTable.ErrorStatus.Failure,
                text: ""
            };
        }
    }
};

let create_tox_message_error = function(node:Backend.Table.Node):CtTable.ValueError {
    return (row:any) => {
        let tox = row[node.key];
        if (tox && tox.toxgps_woe_success) {
            return null;
        } else {
            return <CtTable.ErrorMessage> {
                status: CtTable.ErrorStatus.Failure,
                //text: tox ? tox.service_status : 'Not automatically calculated'
                text: tox ? tox.service_status : ''
            };
        }
    }
};

/* fixme */
let toxhelp = {
    mnamwoetummouse201501: "calc.Carcinogenicity.Mouse_Tumorigenicity.Mouse_Tumorigenicity__1",
    mnamwoeames201501: "calc.Genetic_Toxicity.Bacterial_Reverse_Mutagenesis.Bacterial_Reverse_Mutagenesis__1",
    mnamwoetumrat201501: "calc.Carcinogenicity.Rat_Tumorigenicity.Rat_Tumorigenicity__1",
    mnamwoesshazard201501: "calc.Skin_Toxicity.Skin_Sensitization_(LLNA)_Hazard.Skin_Sensitization_(LLNA)_Hazard__1",
    mnamwoeivtca201501: "calc.Genetic_Toxicity.In_Vitro&nbsp;Chromosome_Aberration.In_Vitro&nbsp;Chromosome_Aberration__1",
    mnamwoesspotency201501: "calc.Skin_Toxicity.Skin_Sensitization_(LLNA)_Potency.Skin_Sensitization_(LLNA)_Potency__1",
    mnamwoecleftpalate201501: "calc.DART.Cleft_Palate.Cleft_Palate__1",
    mnamwoeivvmn201501: "calc.Genetic_Toxicity.In_Vivo&nbsp;Micronucleus&nbsp;.In_Vivo&nbsp;Micronucleus&nbsp;__1",
};


CtTable.registerColumnHandler(CtTable.ColumnType.GroupTox, (table: CtTable, node: Backend.Table.Node) => {
    let result:any = {
        ident: node.ident.replace('&nbsp;',' ').replace('ToxGPS - ',''),
        type: node.type,
        key: node.key,
        group_expansion: 2,
        children: [
            <CtTable.Node>{
                ident: "WOE_CALL",
                type: CtTable.ColumnType.Enum,
                getter: (row: any) => {
                    let tox = row[node.key];
                    if (tox && tox.toxgps_woe_success) {
                        return {
                            value: tox.toxgps_woe_call,
                            enum_name: tox.toxgps_woe_ad_success ? 'woe_call' : 'woe_call aod',
                            enum_object: {},
                        }
                    } else {
                        return null;
                    }
                },
                error: create_tox_message_error(node),
            },
            <CtTable.Node>{
                ident: "WOE_PROBABILITIES",
                type: CtTable.ColumnType.WoeProbabilitis,
                getter: (row: any) => {
                    let tox = row[node.key];
                    //console.log(tox,tox.toxgps_woe_ppos);
                    if (tox && tox.toxgps_woe_success) {
                        let p = tox.toxgps_woe_ppos;
                        let u2 = 0.5 * tox.toxgps_woe_punc;
                        //let n = tox[2];
                        return <any>{ // missing type: ProbabilityPlotData
                            probability: p + u2,
                            errorMargin: u2,
                            applicable: tox.toxgps_woe_ad_success,
                        }
                    } else {
                        return null;
                    }
                },
                error: create_tox_default_error(node),
            },
            <CtTable.Node>{
                ident: "WOE_PPOS",
                type: CtTable.ColumnType.Float,
                getter: (row: any) => {
                    let tox = row[node.key];
                    if (tox && tox.toxgps_woe_success) {
                        //return tox.toxgps_woe_ppos + (0.5 * tox.toxgps_woe_punc);
                        // so called lower limit
                        return tox.toxgps_woe_ppos;
                    } else {
                        return null;
                    }
                },
                error: create_tox_default_error(node),
            },
            <CtTable.Node>{
                ident: "WOE_PNEG",
                type: CtTable.ColumnType.Float,
                getter: (row: any) => {
                    let tox = row[node.key];
                    if (tox && tox.toxgps_woe_success) {
                        //return tox.toxgps_woe_pneg + (0.5 * tox.toxgps_woe_punc);

                        //so called upper limit
                        return tox.toxgps_woe_ppos + tox.toxgps_woe_punc;

                    } else {
                        return null;
                    }
                },
                error: create_tox_default_error(node),
            },
            <CtTable.Node>{
                ident: "WOE_PUNC",
                type: CtTable.ColumnType.Float,
                getter: (row: any) => {
                    let tox = row[node.key];
                    if (tox && tox.toxgps_woe_success) {
                        //return 0.5 * tox.toxgps_woe_punc;

                        // full uncertainty
                        return  tox.toxgps_woe_punc;

                    } else {
                        return null;
                    }
                },
                error: create_tox_default_error(node),
            },
            /*<CtTable.Node>{
                ident: "WOE_APPLICABLE",
                type: 'boolean',
                getter: (row: any) => {
                    let tox = row[node.key];
                    if (tox.toxgps_woe_success) {
                        return tox.toxgps_woe_ad_success;
                    } else {
                        return null;
                    }
                },
                error: create_tox_default_error(node),
            },*/
            <CtTable.Node>{
                ident: "WOE_APPLICABLE",
                type: CtTable.ColumnType.Enum,
                getter: (row: any) => {
                    let tox = row[node.key];
                    if (tox && tox.toxgps_woe_success) {
                        return {
                            value: tox.toxgps_woe_ad_success ? 'true' : 'false',
                            enum_name: 'woe_applies',
                            enum_object: {},
                        }
                    } else {
                        return null;
                    }
                },
                error: create_tox_default_error(node),
            },
            <CtTable.Node>{
                ident: "WOE_MESSAGE",
                type:  CtTable.ColumnType.String,
                getter: (row: any) => {
                    let tox = row[node.key];
                    if (tox && tox.toxgps_woe_success) {
                        return tox.toxgps_woe_message;
                    } else {
                        return null;
                    }
                },
                error: create_tox_default_error(node),
            },

        ]
    };
    if (node.asis) {
        result.asis = node.asis;
    }
    if (toxhelp[node.key]) {
        result.help = toxhelp[node.key];
    }

    /*for (let i = 0, l = node.children.length; i < l; i++) {
        let child_node: Backend.Table.Node = node.children[i];
        let child_handler = CtTable.ColumnHandlers[child_node.type];
        if (child_handler) {
            result.children.push(child_handler(table, child_node, node.key))
        }
    }*/
    return result;
});

export type MnTable = CtTable;


