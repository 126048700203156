/**
 * Created by joerg on 7/17/17.
 */

// rxjs
import 'rxjs/Rx';
import { Observable } from 'rxjs/Observable';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Subject } from 'rxjs/Subject';
import { Subscription } from 'rxjs/Subscription';


// angular
import {Component,Input,Output,EventEmitter,HostListener,Injectable,Optional,ChangeDetectionStrategy} from '@angular/core';

// mn
import {MnBrandService, MnTextService} from "@mn/core";
import {CtTable} from "./CtTable/CtTable";

//

@Injectable() export class CtSelectionService {
    /*EnableSubject: Subject<boolean> = new Subject();
    ConfigGetSubject: Subject<Function> = new Subject();
    ConfigSetSubject: Subject<any> = new Subject();
    TableSubject: BehaviorSubject<CtTable> = new BehaviorSubject(null);*/

    public RowChangeSubject: Subject<any> = new Subject();
    public ColChangeSubject: Subject<any> = new Subject();

    public Data:any[];
    public ctTable: CtTable; // fixme clarify with BB where used
    public Table:CtTable = null;
    public RowMode:CtSelectionService.RowMode = CtSelectionService.RowMode.None;
    public ColMode:CtSelectionService.ColMode = CtSelectionService.ColMode.None;
    public SelectedRowCount:number = 0;

    public SelectedCols:string[] = [];
    public SelectableCols:string[] = [];

    public init(ctTable: CtTable) {
        this.Table = ctTable;
        if( ctTable && ctTable.Rows) {
            this.Data = ctTable.Rows;
            this.ctTable = ctTable; // BB: to be used for custom sorting
        } else {
            this.Data = [];
        }
         this.SelectedRowCount = 0;
    }

    public configure(row_mode:CtSelectionService.RowMode,col_mode:CtSelectionService.ColMode | string[]) {
        this.RowMode = row_mode;
        if (row_mode == CtSelectionService.RowMode.None) {
            this.selectAllRows(false);
        }
        if (col_mode == undefined) {
            this.ColMode = CtSelectionService.ColMode.None;
            this.SelectableCols = [];
            this.SelectedCols = [];
        } else if (col_mode.constructor === Array) {
            this.ColMode = CtSelectionService.ColMode.Active;
            this.SelectableCols = <string[]>col_mode;
            this.SelectedCols = [];
        } else {
            this.ColMode = CtSelectionService.ColMode.None;
            this.SelectableCols = [];
            this.SelectedCols = [];
        }
        this.ColChangeSubject.next();
        this.RowChangeSubject.next();
    }

    public selectRow(row:any,value:boolean) {
        console.log(value);
        if (!row.x_selected) {
            row.x_selected = false;
        }
        if (value == true && row.x_selected == false) {
            this.SelectedRowCount++;
        }
        else if (value == false && row.x_selected == true) {
            this.SelectedRowCount--;
        }
        row.x_selected = value;
        this.RowChangeSubject.next()
    }

    public selectRowByIndex(row_index:number,value:boolean) {
        this.selectRow(this.Data[row_index],value);
    }

    selectAllRows(value:boolean) {
        this.Data.forEach((row)=>{ row.x_selected = value});
        if (value == true) {
            this.SelectedRowCount = this.Data.length;
        }
        else if (value == false) {
            this.SelectedRowCount = 0;
        }
        this.RowChangeSubject.next()
    }

    selectAllCols(value:boolean) {
        if (value == true) {
            this.SelectedCols = this.SelectableCols.slice();
        } else {
            this.SelectedCols = [];
        }
        this.ColChangeSubject.next()
    }

    selectCol(id:string,value:boolean) {
        let index = this.SelectedCols.indexOf(id);
        if (value) {
            if (index == -1) {
                this.SelectedCols.push(id);
            }
        } else {
            if (index > -1) {
                this.SelectedCols.splice(index, 1);
            }
        }
        this.ColChangeSubject.next()
    }

    isColSelected(id:string):boolean {
        return this.SelectedCols.indexOf(id) > -1;
    }

    isColSelectable(id:string):boolean {
        return this.SelectableCols.indexOf(id) > -1;
    }

}
export namespace CtSelectionService {
    export enum RowMode {
        None = "None",
        Single = "Single",
        Multiple = "Multiple"
    }
    export enum ColMode {
        None = "None",
        Active = "Active",
    }
}
