/**
 * Created by joerg on 7/17/17.
 */

// rxjs
import 'rxjs/Rx';
import { Subject } from 'rxjs/Subject';

// angular
import {Injector} from '@angular/core';

//
import {MnStateView2} from "./MnStateView2";
import {MnWorkflow} from "../MnWorkflow";
import {MnWorkflowService} from "../MnWorkflowService";
import {MnTable} from "../CtTable/CtTable";
import {MnTableFocus} from "../CtTable/MnTableFocus";



export abstract class MnStateViewFocusView {

    protected mData:any = {};
    protected mDestroy:Subject<void> = new Subject();
    protected mService:MnStateView2.Service;
    protected mWorkflowService:MnWorkflowService;
    protected mTableFocus:MnTableFocus;

    constructor(private mInjector:Injector) {
        this.mService = this.mInjector.get(MnStateView2.Service);
        this.mWorkflowService = this.mInjector.get(MnWorkflowService);
        this.mTableFocus = this.mInjector.get(MnTableFocus);
        this.mTableFocus.ChangeSubject
            .takeUntil(this.mDestroy)
            .subscribe((cell)=> {
                this.onTableFocus(cell);
            });
    }

    protected getRowData(cell:MnTableFocus.Cell):any {
        let t:MnTable = this.mWorkflowService.Table;
        if (t) {
            return t.findRow(cell.row);
        }
        return {};
    }

    protected getTable(): MnTable {
        return this.mWorkflowService.Table;
    }

    protected abstract onTableFocus(cell:MnTableFocus.Cell);

    ngOnDestroy() {
        this.mDestroy.next();
    }
}
