/**
 * Created by joerg on 7/17/17.
 */

// angular
import {Component} from '@angular/core';

//
import {MnBackend} from "@mn/core";

//
import {MnWorkflowComponentStatic} from "../../MnWorkflowComponent";
import {MnActionConfigurator} from "../MnActionConfigurator";
import {MnActionConfigurationService} from "../MnActionConfigurationService";
import {MnWorkflowService} from "../../MnWorkflowService";
import {MnWorkflow} from "../../MnWorkflow";
import {CtTable} from "../../CtTable/CtTable";
import {GenericNode} from "../../../../utils/generic-node";
import {CustomStandardization, CustomStandardizationInstance} from "../../../../utils/custom_standardization";

export class AddSkylineColumnConfiguration {
    constructor(public title:string, public initial:boolean, public properties:string[]) {}
    public valid():boolean {
        return this.title.trim().length > 0 && this.properties.length > 0;
    }
}

@Component({
    selector: 'mn-action-configurator-add-skyline-column',
    template: `
        <mn-action-configurator-frame>
            <mat-form-field line1 style="width: 100%; margin-top: 8px;">
                <input matInput placeholder="Skyline Column Name" [(ngModel)]="mConfiguration.title" (ngModelChange)="checkEnabled()">
            </mat-form-field>
            <mn-action-configurator-row-marks
                    line2
            ></mn-action-configurator-row-marks>
            <mn-action-configurator-list-controller 
                    line3
                    [label]="mLabel"
                    (onSelectAll)="columns.selectAll()"
                    (onDeselectAll)="columns.deselectAll()"
            ></mn-action-configurator-list-controller>
            <mn-table-column-selection
                    scroll
                    #columns
                    style="height: 100%; width: 100%; display: block;"
                    [nodes]="mNodes"
                    [(selectedNodesIds)]="mConfiguration.properties"
                    (selectedNodesIdsChange)="onSelectedNodes($event)"
                    >
            </mn-table-column-selection>
        </mn-action-configurator-frame>
     `,
    styles: []
})
export class AddSkylineColumn extends MnActionConfigurator {

    mLabel:string = "";

    private mConfiguration:AddSkylineColumnConfiguration = new AddSkylineColumnConfiguration(
        '',true,[]
    );

    private mNodes = [];
    protected mNodeIds: string[] = [];

    constructor(acs:MnActionConfigurationService, private mWorkflowService:MnWorkflowService, private mBackend:MnBackend) {
        super(acs);
        this.enable(false);

        // create a GenericNode from the Table column root node
        const gRootNode : GenericNode<CtTable.Node> = this.mWorkflowService.Table.buildGenericNodeFromColumnNode(null);


        // remove all leaf nodes that are not numeric until no more nodes can be removed
        gRootNode.deleteNodesDepthFirst((n:GenericNode<CtTable.Node>) => n.isLeaf() && !(n.data.type == 'float' || n.data.type == 'int'));

        const cstd: CustomStandardization = CustomStandardizationInstance; // preferrred properties selection by Jim
        const self:AddSkylineColumn = this;

        // reconstruct the CtTable.Node using a deep copy method for the children
        // note that not all fields of CtTable.Node are copied
        const preselectedProperties: string[] = [];
        function recursiveBuildNodeTree(gn: GenericNode<CtTable.Node>): CtTable.Node {
            const n1: CtTable.Node = gn.data;
            const newNode: CtTable.Node = {
                id: n1.id,
                ident: n1.ident,
                asis: n1.asis,
                type: n1.type,
                header: n1.header,
                headers: n1.headers,
                headerName: n1.headerName,
                key: n1.key,
                children: [],
            };

            if( cstd.isFavorite(newNode.ident)) {
                preselectedProperties.push(newNode.id); // when mConfiguration.properties changes, the display changes because it is bidirectional: [(selectedNodesIds)]="mConfiguration.properties"
            }
            self.mNodeIds.push(newNode.id);

            for(let gnChild of gn.children) {
                newNode.children.push(recursiveBuildNodeTree(gnChild));
            }

            return newNode;
        }

        const newRootNode : CtTable.Node = recursiveBuildNodeTree(gRootNode);


        //  this.mNodes is used in the template
        this.mNodes = newRootNode.children;

        /*
        ExpressionChangedAfterItHasBeenCheckedError: Expression has changed after it was checked. Previous value: 'label: Selected Columns: 0'. Current value: 'label: Selected Columns: 10'
         */
        this.mConfiguration.properties = preselectedProperties;
        this.mLabel = 'Selected Properties: '+this.mConfiguration.properties.length;

        //console.log(this.mConfiguration.properties);



    }


    protected onInit(configuration:any) {
        //this.mConfiguration.properties = configuration.properties ? configuration.properties : [];
    };

    protected onStart() {
        // rebuild mConfiguration.properties such that the order of the property id's
        // does not vary with the clicking sequence on the check boxes
        const properties: string[] = [];

        for(let id of this.mNodeIds) {
            if(this.mConfiguration.properties.indexOf(id) != -1) {
               properties.push(id);
            }
        }
        this.mConfiguration.properties = properties;
        this.finish(this.mConfiguration);
    };

    onSelectedNodes(nodes) {
        //console.log(nodes);
        this.mLabel = 'Selected Properties: '+this.mConfiguration.properties.length;
        this.checkEnabled(); // added by BB
    }

    //BB  why is this not used here?
    /*
    private onSelectedRows(v) {
        this.mConfiguration.rows = v;
        this.checkEnabled();
    }
    */
    private checkEnabled() {
        this.enable(this.mConfiguration.valid() );
        //console.log(this.mConfiguration);
    }
    
    ngOnDestroy() {
        super.destroy()
    }
}
MnActionConfigurator.Registry.register("ADD_SKYLINE_COLUMN",new MnWorkflowComponentStatic(AddSkylineColumn));
