/**
 * Created by joerg on 7/17/17.
 */

// rxjs
import 'rxjs/Rx';
import { Observable } from 'rxjs/Observable';

// angular
import {Injector} from '@angular/core';

//
import {MnWorkflow} from "../MnWorkflow";
import {MnValue} from "./MnValue";

export abstract class MnValueHtml {

    static Registry:MnWorkflow.Registry<MnValueHtml> = new MnWorkflow.Registry<MnValueHtml>();

    public abstract get(mnv:MnValue,injector?:Injector):Observable<string>;

    protected hasValue(mnv:MnValue) {
        return mnv && mnv.data !== undefined && mnv.data !== null;
    }

    protected hasError(mnv:MnValue) {
        return mnv && mnv.error !== undefined && mnv.error !== null;
    }

    generateDefault(mnv:MnValue,dataconverter:(v:any)=>string,k:string):Observable<string> {
        let v:string = '';
        let e:string = null;
        if (this.hasValue(mnv)) v = dataconverter(mnv.data);
        else if (this.hasError(mnv)) v = mnv.error;
        return this.generateObservable(v,e,k);
    }

    protected generateObservable(v:string,e:string,k:string):Observable<string> {
        return Observable.of(this.generateDivs(v,e,k));
    }

    protected generateDivs(v:string,e:string,k:string):string {
        if (e === null) {
            return `            
                <div class="mn-value">
                    <div class="mn-value-${k}">${v}</div>
                </div>
            `
        } else {
            return `            
                <div class="mn-value">
                    <div class="mn-value-error">${e}</div>
                </div>
            `
        }
    }
}


/*export enum ColumnType {
    Boolean = 'boolean',
    Int = 'int',
    Float = 'float',
    String = 'string',
    Group = 'group',
    GroupFingerprints = 'group.fingerprints',
    Image = 'image',

    ListName = 'list.name',
    ListIdentifier = 'list.identifier',
    ListString = 'list.string',
    ListStudy = 'list.study',
    GroupCompound = 'group.compound',
    GroupTox = 'group.tox',

    // Node specific?
    DataSummary = 'data.summary',
    Enum = 'enum',
    WoeProbabilitis = 'woe.probabilities',

    Skyline = 'chemtunes3_skyline',
    SkylineGroup = 'group.skyline',
    Fingerprint = 'fingerprint',
}*/

// string
export class MnValueHtmlString extends MnValueHtml {
    public get(mnv:MnValue):Observable<string> {
        return this.generateDefault(mnv,(data) => data, 'string');
    }
}
MnValueHtml.Registry.register('string',new MnValueHtmlString());

// int
export class MnValueHtmlInt extends MnValueHtml {
    public get(mnv:MnValue):Observable<string> {
        return this.generateDefault(mnv,(data) => data, 'int');
    }
}
MnValueHtml.Registry.register('int',new MnValueHtmlInt());

// float
export class MnValueHtmlFloat extends MnValueHtml {

    public round(number, precision) {
        let shift = function (number, precision) {
            let numArray = ("" + number).split("e");
            return +(numArray[0] + "e" + (numArray[1] ? (+numArray[1] + precision) : precision));
        };
        return shift(Math.round(shift(number, +precision)), -precision);
    }

    public get(mnv:MnValue):Observable<string> {
        return this.generateDefault(mnv,(data) => this.round(data,2)+'', 'float');
    }
}
MnValueHtml.Registry.register('float',new MnValueHtmlFloat());

export class MnValueHtmlFloat01 extends MnValueHtml {

    public round(number, precision) {
        let shift = function (number, precision) {
            let numArray = ("" + number).split("e");
            return +(numArray[0] + "e" + (numArray[1] ? (+numArray[1] + precision) : precision));
        };
        return shift(Math.round(shift(number, +precision)), -precision);
    }

    public get(mnv:MnValue):Observable<string> {
        return this.generateDefault(mnv,(data) => this.round(data*100,0)+'%', 'float');
    }
}
MnValueHtml.Registry.register('float.01',new MnValueHtmlFloat01());


// enum

/*

    public update(parameters:CtValueView.Parameters) {
        //console.log(JSON.stringify(parameters.value));
        //this.mValue = this.mText.text(parameters.value);
        this.mClass = parameters.value.enum_name;
        this.mClassInner = parameters.value.value;
        this.mTextInner = <string>this.mText.text('ct.enum.'+this.mClass+'.'+this.mClassInner);
    }

 */

export class MnValueHtmlEnum extends MnValueHtml {

    public get(mnv:MnValue):Observable<string> {
        let v:string = '';
        let en:string = '';
        let e:string = null;
        if (this.hasValue(mnv)) {
            v = mnv.data.value;
            en = mnv.data.enum_name;
        }
        else if (this.hasError(mnv)) v = mnv.error;
        return this.generateObservable(v,e,'enum '+v+' '+en);
    }
}
MnValueHtml.Registry.register('enum',new MnValueHtmlEnum());


