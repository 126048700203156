/**
 * Created by joerg on 7/17/17.
 */

// rxjs
//import 'rxjs/Rx';
//import { Subject } from 'rxjs/Subject';

// angular
import {Component,Injector} from '@angular/core';

//
import {MnWorkflowComponentStatic} from "../MnWorkflowComponent";
import {MnStateView2} from "./MnStateView2";
import {MnWorkflow} from "../MnWorkflow";
import {CtTable} from "../CtTable/CtTable";
import {MnTableFocus} from "../CtTable/MnTableFocus";
import {MnStateViewFocusView} from "./MnStateViewFocusView";


@Component({
    selector: 'mn-state-view-tox-predictions',
    template: `
        Data Details:
        <div style="height: 100%; width: 100%; overflow: auto;" [innerHtml]="mData | mnjson"></div>
    `,
})
export class MnStateViewToxPredictions extends MnStateViewFocusView {

    static createStateTabs(workflow:MnWorkflow):MnStateView2.Tab[] {
        if (workflow &&
            workflow.Workflow &&
            workflow.State &&
            workflow.State.dataset &&
            workflow.State.dataset.table &&
            workflow.State.dataset.table.data.length > 0 &&
            workflow.Table && workflow.Table.findNodes((node) => (node.type == CtTable.ColumnType.GroupTox) ).length > 0
        ) {
            return [{
                id: "",
                label: "Tox Details",
                select: 1,
                description: null,
                closable: false
            }]
        } else {
            return [];
        }
    }

    constructor(injector:Injector) {
        super(injector);
    }

    protected onTableFocus(cell:MnTableFocus.Cell) {
        let row_data = this.getRowData(cell);
        if (cell != {} && this.mWorkflowService && this.mWorkflowService.Table) {
            let nodes:CtTable.Node[] = this.mWorkflowService.Table.findNodes((node) => (node.type == CtTable.ColumnType.GroupTox) );
            this.mData = nodes.map((node) => {
               return {
                   node: node,
                   data: row_data[node.key]
               }
            }).filter((value) => value.data);
        } else {
            this.mData = [];
        }
    }

}
MnStateView2.Registry.register("MnStateViewToxPredictions",new MnWorkflowComponentStatic(MnStateViewToxPredictions));
