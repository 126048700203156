/**
 * Created by joerg on 7/17/17.
 */

// rxjs
import 'rxjs/Rx';
import { Subject } from 'rxjs/Subject';

// angular
import {Injector} from '@angular/core';

//
import {MnStateView2} from "./MnStateView2";
import {MnWorkflowService} from "../MnWorkflowService";
import {MnTable} from "../CtTable/CtTable";
import {MnRowMarker} from "../MnMarker";



export abstract class MnStateViewRowMarkerView {

    protected mRows:any[] = [];
    protected mDestroy:Subject<void> = new Subject();
    protected mService:MnStateView2.Service;
    protected mWorkflowService:MnWorkflowService;
    protected mRowMarker:MnRowMarker;

    constructor(private mInjector:Injector) {
        this.mService = this.mInjector.get(MnStateView2.Service);
        this.mWorkflowService = this.mInjector.get(MnWorkflowService);
        this.mRowMarker = this.mInjector.get(MnRowMarker);
        this.mRowMarker.setup(20,[],[]);
        this.mRowMarker.ChangeSubject
            .takeUntil(this.mDestroy)
            .subscribe(()=> {
                let t:MnTable = this.mWorkflowService.Table;
                if (t) {
                    let marks:string[] = this.mRowMarker.marks();
                    this.mRows = marks.map((marked_row) => {
                        return t.findRow(marked_row);
                    });
                } else {
                    this.mRows = [];
                }
                this.onRowMarker();
            });
    }

    protected get Marker() {
        return this.mRowMarker;
    }

    protected get Rows() {
        return this.mRows;
    }

    protected abstract onRowMarker();

    ngOnDestroy() {
        this.mDestroy.next();
    }
}
