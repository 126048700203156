import {Component, Input} from "@angular/core"
import {Aggregate} from "../../../utils/data-aggregator";



/*
{
  "label": "Properties",
  "data": [
    {
      "key": "Aqueous solubility",
      "values": [
        {
          "attribute": {
            "name": "Aqueous solubility",
            "domain": "Compound",
            "context": "Annotation",
            "is_public": true,
            "visible": true,
            "comments": "Initially imported"
          },
          "value": {
            "id": 5,
            "data_type": "float",
            "serializer": null,
            "min_prefix": "",
            "min_value": null,
            "max_prefix": "",
            "max_value": null,
            "std_dev": null,
            "modality": "Not given",
            "value_note": "",
            "value": "207.0",
            "exact_value": 207,
            "unit": 67
          },
          "value_type": {
            "id": 49,
            "name": "Experimental",
            "source": "QSAR Toolbox",
            "version": "",
            "comments": ""
          },
          "source": null,
          "serial": 6,
          "visible": true,
          "harvested_from": "CE ToxGPS case study"
        },
        {
          "attribute": {
            "name": "Aqueous solubility",
            "domain": "Compound",
            "context": "Annotation",
            "is_public": true,
            "visible": true,
            "comments": "Initially imported"
          },
          "value": {
            "id": 15,
            "data_type": "float",
            "serializer": null,
            "min_prefix": "",
            "min_value": null,
            "max_prefix": "",
            "max_value": null,
            "std_dev": null,
            "modality": "not given",
            "value_note": "",
            "value": "159.0",
            "exact_value": 159,
            "unit": 67
          },
          "value_type": {
            "id": 50,
            "name": "Calculated",
            "source": "ACD/labs",
            "version": "",
            "comments": ""
          },
          "source": null,
          "serial": 18,
          "visible": true,
          "harvested_from": "CE ToxGPS case study"
        }
      ]
    },
    {
 */

export namespace CpdDbProp {

    export interface Attribute {
        name: string;
        domain: string;
        context: string;
        is_public: boolean;
        visible: boolean;
        comments: string;
    }

    export interface Unit {
        name: string;
        symbol: string;
        comments: string;

    }
    export interface Value {
        id: number;
        data_type: string;
        serializer?: any;
        min_prefix: string;
        min_value?: any;
        max_prefix: string;
        max_value?: any;
        std_dev?: any;
        modality: string;
        value_note: string;
        value: string;
        exact_value: number;
        unit: Unit;
    }

    export interface ValueType {
        id: number;
        name: string;
        source: string;
        version: string;
        comments: string;
    }

    export interface RootObject {
        attribute: Attribute;
        value: Value;
        value_type: ValueType;
        source?: any;
        serial: number;
        visible: boolean;
        harvested_from: string;
    }

}

export interface Cell {
    value: any;
    rowSpan: number;
    isLastGroup: boolean;

}



const template = `

<div class="tox-body" style="margin: 10px;">
<table class="tox-endpoints-table">
    <thead>
        <th *ngFor="let label of colNames"
        >{{label}}</th>
    </thead> 
    
    <tr *ngFor="let row of rows" 
            [style.border-top]="row[0].rowSpan>0 ? '1px solid grey' : 'none'"
           
               >
        <td *ngFor="let cell of row"
            style="text-align:center; padding: 5px;"
             [attr.rowspan]="cell.rowSpan>0? cell.rowSpan: 'none'">
         {{cell.value}}
         </td>        
    </tr>
    
</table>
</div>
`;

//TODO: add units when available from BE
//Could not find experimental conditions
// value note is always emmpty
@Component({
    selector:'ct-compound-details-db-properties',
    template: template,
    styleUrls: ['./CtCompoundDetailsDBStudy.css']
})
export class CtCompoundDetailsDBDetailProperties {
    protected colNames: string[];
    protected rows: Cell[][];

    @Input('properties')
    set properties(groups: Aggregate<string,CpdDbProp.RootObject>[]) {
        this.colNames = ["Name", "Value", "Unit", "Condition", "Note", "Origin", "Source", "Version"];
        this.rows = [];

        // row grouping is used

        for(let group of groups) {
            const rowSpan = group.values.length;
            let first = true;

            let isLastGroup = groups.indexOf(group) == groups.length-1;
            const propertyNameCell: Cell = {value: group.key, rowSpan: rowSpan, isLastGroup: isLastGroup};

            for(let v  of group.values) {
                const value: Cell = {value: undefined, rowSpan: 0, isLastGroup: isLastGroup};
                const note: Cell = {value: undefined, rowSpan: 0, isLastGroup: isLastGroup};

                if(v.value) {
                    value.value = v.value.exact_value;
                    note.value = v.value.value_note; //always empty?
                 }
                const type: Cell = {value: v.value_type?v.value_type.name:undefined, rowSpan: 0, isLastGroup: isLastGroup};
                const source: Cell = {value: v.value_type?v.value_type.source:undefined, rowSpan: 0, isLastGroup: isLastGroup};
                const version: Cell = {value: v.value_type?v.value_type.version:undefined, rowSpan: 0, isLastGroup: isLastGroup};
               // const harvestedFrom: Cell = {value: v.value_type?v.harvested_from:undefined, rowSpan: 0, , isLastGroup: isLastGroup};

                const unit: Cell = {value: v.value? v.value.unit? v.value.unit.name?v.value.unit.name:undefined:undefined:undefined,
                                        rowSpan: 0, isLastGroup: isLastGroup};

                const condition: Cell = {value: v.value? v.value.modality: undefined, rowSpan: 0, isLastGroup: isLastGroup};

                const row: Cell[] = [value, unit, condition, note,  type, source, version];
                if(first) {
                    first = false;
                    row.unshift(propertyNameCell);
                }
                this.rows.push(row);

            }

        }

    }

    borderTopRow(row: Cell[]) : any {
        if(row[0].rowSpan > 0) {
            return {bordertop: "1px solid #000"};
        }
        return null;
    }
}
