// rxjs
import 'rxjs/Rx';

// import { Request, RequestOptionsArgs, Response } from "@angular/http";
// import { Observable } from "rxjs/Observable";

// import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Subject } from 'rxjs/Subject';

import {Component} from "@angular/core";
import {IHeaderGroupParams} from "ag-grid/main";
import {IHeaderGroupAngularComp} from "ag-grid-angular/main";

@Component({
    templateUrl: './AgGridGroupHeader.html',
    styleUrls: ['./AgGridGroupHeader.css']
})
export class AgGridGroupHeader implements IHeaderGroupAngularComp {
    public params: IHeaderGroupParams;
    public expanded: boolean;
    public expandable: boolean;
    private help:string;

    private mDestroy:Subject<any> = new Subject();

    constructor() {
    }

    agInit(params: IHeaderGroupParams): void {
        //console.log(params);
        this.params = params;
        this.params.columnGroup.getOriginalColumnGroup().addEventListener('expandedChanged', this.onExpandChanged.bind(this));
        this.expandable = this.params.columnGroup.isExpandable();
        this.expanded = this.params.columnGroup.getOriginalColumnGroup().isExpanded();
        if ((<any>params.columnGroup.getColGroupDef()).help) {
            this.help = (<any>params.columnGroup.getColGroupDef()).help
        }

    }

    ngOnDestroy() {
        //console.log(`Destroying HeaderComponent`);
     }

    expandOrCollapse() {
        //console.log("this.expanded",this.params);
        this.params.setExpanded(!this.params.columnGroup.getOriginalColumnGroup().isExpanded());
    }

    onExpandChanged() {
        this.expanded = this.params.columnGroup.getOriginalColumnGroup().isExpanded()
    }
}