import {ColumnType, CtTable} from "./CtTable";
import {Backend} from "../BackendInterfaces";
import {SkylineSingleData} from "../d3/skyline-d3/skylined3.component"
import * as d3 from 'd3';

import {GenericNode} from "../../../utils/generic-node";
import {SimilaritiesDistances} from "../../../utils/sim_dist";
import {CustomStandardization, CustomStandardizationInstance} from "../../../utils/custom_standardization";


/**
 * Settings needed to compute a skyline column
 */

// TODO: cannot be used yet
export class SkylineNodeSettings {

    constructor(public readonly selectedCols: string[], public readonly addPearsonCorrelation: boolean) {}
}

interface SkylineStat {
    min: number;
    max: number;
    sum: number;
    count: number;
}

interface KeyToNumberMap {
    [key:string]: number;
}

// these names must match the translation in brand.ts
// TODO: must be defined in the back end?
const PearsonCorrCoeff_KEY = 'PEARSON_CORR_COEFF';
const EuclideanDistance_KEY = 'EUCLIDEAN_DISTANCE';

const SkylineChart_KEY = 'SKYLINE_CHART';
const SkylineGroup_KEY = 'SKYLINE_GROUP';
const SkylineSubGroup_KEY = 'SKYLINE_SUBGROUP';


class SkylineNode implements CtTable.Node {
        ident: string;                // identifier to select getter / renderer when data type is not sufficient
        asis: boolean;
        type: CtTable.ColumnType;                 // raw data type
        key: string;                 // key to data
        help: string;                 // help to data
        getter: CtTable.ValueGetter;
        error: CtTable.ValueError;
        setter: CtTable.ValueSetter;
        //calculator: CtTable.NodeCalculator;
        children: CtTable.Node[];            // children
        group_expansion: number  // show or not  a column when the group is expanded

    constructor(table:CtTable, node:Backend.Table.Node, columnName: string, asIs: boolean) {
        this.ident = columnName;
        this.asis = asIs; // when true,  the naming of the plot is provided by the end user, otherwise the translation dictionary will be used

        this.key =  node.key;
        this.type=  <CtTable.ColumnType>node.type;

        this.getter = (row:any) => {
                //console.log("dddddsssss",row,node,row[node.key]);
                return row[node.key];
            };

        this.setter = (row:any, value:any, nid:string) => {
                //console.log("dddddsssss",row,node,row[node.key]);
                row[node.key] = value;
                table.recalculate(nid);
                //console.log("SETTER",value,nid);
            };



    }

    //
    public calculator(table:CtTable,node:CtTable.Node, rows:any[], settings: any) {
        //console.log("recalc Skyline caltulator",this,node,settings);
        let useCustomStandardization = true;

        // if we don't have the settings we don't know which properties to use
        if (!settings) { return; }
        const selectedCols: string[] =  settings.properties;
        if (!selectedCols || selectedCols.length == 0) { return; }

        console.log("Skyline calculator # of rows=", rows.length, ' columns:', settings);

        //console.log(table.NodeMap);

        // resolve all property nodes (= user selected columns) that we depend on to calculate the skyline plot
        let selectedColumnNodes: CtTable.Node[] = selectedCols
            .map((node_id) => {
                //console.log(node_id,table.NodeMap[node_id]);
                return table.NodeMap[node_id]}
            )
            .filter( (node) => (node != undefined));
        //console.log('selectedColumnNodes',selectedColumnNodes);

        // statistics data
        let property_stats:SkylineStat[] = selectedColumnNodes.map((n) => {
            return {
                min: Number.POSITIVE_INFINITY,
                max: Number.NEGATIVE_INFINITY,
                sum: 0,
                count: 0,
            }
        });

        //iterate over all rows
        for (let rr = 0, rl = rows.length; rr < rl; rr++) {
            let row = rows[rr];
            selectedColumnNodes
                .forEach((property_node,index) => {
                    let value:number = property_node.getter(row);
                    if (value == null || value == undefined) { return; }
                    let property_stat = property_stats[index];
                    property_stat.count++;
                    property_stat.sum+= value;
                    if (value < property_stat.min) {
                        property_stat.min = value;
                    }
                    if (value > property_stat.max) {
                        property_stat.max = value;
                    }
                })
        }

        if(useCustomStandardization) {
            this.computeSimilarityAndDistanceMetricsWithCustomStandardization(rows, selectedColumnNodes);
        } else {
            this.computeSimilarityAndDistanceMetrics(rows, selectedColumnNodes);
        }

        // do whatever it takes to calculate the Skyline data
        for (let row of rows) {
            row[node.key] = {
                enabled: true,
                data: selectedColumnNodes.map((property_node,index) => {
                    const result :SkylineSingleData = <SkylineSingleData> {
                        label: property_node.ident,
                        min: property_stats[index].min,
                        max: property_stats[index].max,
                        value: property_node.getter(row),

                    };

                    if(property_node.type == CtTable.ColumnType.Float) {
                        result.valueFormat = '.4n';
                    } else {
                        result.valueFormat = '';
                    }

                     if( useCustomStandardization ) {
                         const ms: number[] = this.getCustomMeanAndStandardDeviation(property_node);
                         const mean = ms[0];
                         const stdev = ms[1];

                         if (mean != NaN && stdev != NaN && stdev > 0 && result.value !== null && result.value !== undefined) {
                             result.min = -2; // value from Jim
                             result.max = 2; // value from Jim
                             const nonScaledValue = result.value;
                             result.value = (result.value - mean) / stdev;


                             let displayValue: string = d3.format(result.valueFormat)(nonScaledValue);


                            if(result.value - result.max > 0.001) {
                                displayValue += '(>' + result.max + ')';
                            } else if(result.value - result.min < 0.001) {
                                displayValue += '(<' + result.min +')';
                            } else {
                                displayValue += '(' + d3.format('.2n')(result.value) + ')'; // show additionally the scaled value
                            }
                            result.displayValue = displayValue;

                         } else {
                             result.isValid = false;

                         }
                     }
                    return result;

                })
            };
            //console.log('skylines',row[node.key]);
        }


    }

    protected computeSimilarityAndDistanceMetrics(rows: CtTable.SortedRow[], selectedColumnNodes: CtTable.Node[]) : void {


        //find out if there is a hierarchy: need to compute the tree of the rows
        const rowTree: GenericNode<CtTable.SortedRow> = new GenericNode();
        rowTree.buildTreeFromDataWithDepth(rows, (row:CtTable.SortedRow) => row.indent);

        const statsHelper: SimilaritiesDistances = new SimilaritiesDistances();

        rowTree.enumerateParentChildren((parent: GenericNode<CtTable.SortedRow>, children: GenericNode<CtTable.SortedRow>[]) => {
            let matrix: number[][] = [];

            for(let each of [parent].concat(children)) {
                matrix.push(selectedColumnNodes.map(property_node => property_node.getter(each.data)));
            }

            const pccs: number[] = statsHelper.computePearsonCorrelationCoefficientMatrix(matrix);
            const eds: number[] = statsHelper.computeEuclidianDistanceMatrix(matrix);
            console.assert(pccs.length == eds.length);
            console.assert(pccs.length == children.length);

            children.forEach( (child, index) => {
                child.data[PearsonCorrCoeff_KEY] = pccs[index];
                child.data[EuclideanDistance_KEY] = eds[index];

            });

        });


    }
    protected computeSimilarityAndDistanceMetricsWithCustomStandardization(rows: CtTable.SortedRow[], selectedColumnNodes: CtTable.Node[]) : void {


        //find out if there is a hierarchy: need to compute the tree of the rows
        const rowTree: GenericNode<CtTable.SortedRow> = new GenericNode();
        rowTree.buildTreeFromDataWithDepth(rows, (row:CtTable.SortedRow) => row.indent);

        const statsHelper: SimilaritiesDistances = new SimilaritiesDistances();

        rowTree.enumerateParentChildren((parent: GenericNode<CtTable.SortedRow>, children: GenericNode<CtTable.SortedRow>[]) => {
            let matrix: number[][] = [];
            let means: number[] = [];
            let stdevs: number[] = []

            for(let each of [parent].concat(children)) {
                let values = selectedColumnNodes.map(property_node => property_node.getter(each.data));
                matrix.push(values);
            }

            // compute the non custom means and stdevs
            let meansAndStdevs: number[][] = statsHelper.computeAveragesAndStandardDeviations(matrix);
            means = meansAndStdevs[0];
            stdevs = meansAndStdevs[1];

            //replace if custom available and valid
            let i = 0;
            for(let property_node of selectedColumnNodes) {
                //console.log(property_node);
                const ms: number[] = this.getCustomMeanAndStandardDeviation(property_node);
                if(ms[0] != NaN) {
                    means[i] = ms[0];
                }

                if(ms[1] != NaN) {
                    stdevs[i] = ms[1];
                }


                i++;
            }

            // cleanup: remove variables for which the standard deviation is 0 or NaN
            const matrixTransposed: number[][]= statsHelper.geTranspose(matrix);
            let validMatrixTransposed: number[][] = [];
            let validMeans = [];
            let validStdevs = [];

            for(let i =0; i< stdevs.length; i++) {
                if(stdevs[i] != NaN && stdevs[i] > 0 && means[i] != NaN) {
                    validStdevs.push(stdevs[i]);
                    validMeans.push(means[i]);
                    validMatrixTransposed.push(matrixTransposed[i]);
                }
            }

            matrix = statsHelper.geTranspose(validMatrixTransposed);


            // TODO: handle NaN and null values in the matrix
            const pccs: number[] = statsHelper.computePearsonCorrelationCoefficientMatrixWithCustomStandardization(matrix, validMeans, validStdevs);
            const eds: number[] = statsHelper.computePcomputeEuclidianDistanceMatrixWithCustomStandardization(matrix, validMeans, validStdevs);
            console.assert(pccs.length == eds.length);

            children.forEach( (child, index) => {
                child.data[PearsonCorrCoeff_KEY] = pccs[index];
                child.data[EuclideanDistance_KEY] = eds[index];
                //console.log(pccs[index]);

            });

        });


    }

    /**
     * return [mean, standard deviation] or [NaN,NaN] if not found
     * @param {CtTable.Node} property_node
     * @returns {number[]}
     */
    protected getCustomMeanAndStandardDeviation(property_node: CtTable.Node) : number[] {
        const cstd: CustomStandardization = CustomStandardizationInstance; // ChemTUnes meas and stdevs provided by Jim

        let results = [NaN, NaN];
        let found = false;

        for(let colNodeVariableLabel of  ["header", "ident", "id"]) {
            const pNameCandidate: string = property_node[colNodeVariableLabel];
            if(pNameCandidate) {
                const mean: number = cstd.getMean(pNameCandidate);
                if(mean !== undefined) {
                    results[0] = mean;
                }
                const stdev: number = cstd.getStdev(pNameCandidate);
                if(stdev !== undefined) {
                    results[1] = stdev;
                }

                if(mean !== undefined && stdev !== undefined) {
                    found = true;
                    break;
                }
            }
        }
        if(!found) {
            console.log('Property could not be  standardized using Chemtunes:', property_node.ident);
        }

        return results;

    }

}
export function init_skyline() {

    // CtTable.registerColumnHandler(CtTable.ColumnType.SkylinePlot, (table:CtTable, node:Backend.Table.Node) => {
    //     return new SkylineNode(table, node);
    // });


    CtTable.registerColumnHandler(CtTable.ColumnType.SkylineGroup, (table: CtTable, node: Backend.Table.Node) => {
        let result:any = {
            //ident: node.ident, // is empty when creating a new plot?
            ident: SkylineGroup_KEY,
            type: node.type,
            children: []
        };

        for (let i = 0, l = node.children.length; i < l; i++) {
            let child_node: Backend.Table.Node = node.children[i];
            let child_handler = CtTable.ColumnHandlers[child_node.type];
            if (child_handler) {
                result.children.push(child_handler(table, child_node))
            }
        }
        //console.log("skyline",result);
        return result;
    });

    // this is actually a Skyline sub group
    CtTable.registerColumnHandler(CtTable.ColumnType.Skyline, (table: CtTable, node: Backend.Table.Node) => {
        //console.log('node.ident=', node.ident);
        let result:any = {
            //ident: SkylineSubGroup_KEY, // use translation,
            ident: node.ident, asis: node.asis, //the name of the column is dynamic - provided by the end user
            //type: group_type,
            type: "group",
            group_expansion: 2,
            children: [
                new SkylineNode(table, node, 'Skyline', true), //BB:  leave column name blank ... JM sorry Bruno, I like it better with ;-)
                {
                    ident: PearsonCorrCoeff_KEY,
                    type: 'float',
                    getter: (row:any) => row[PearsonCorrCoeff_KEY]

                },
                {
                    ident: EuclideanDistance_KEY,
                    type: 'float',
                    getter: (row:any) => row[EuclideanDistance_KEY]

                }
           ],
        };
        //console.log("skyline",result);
        return result;
    });


}