import {Injector} from "@angular/core"
import {Backend} from "./BackendInterfaces";
import {CtTable} from "./CtTable/CtTable";

import {CtValueView} from "./CtValueView";
//import {CtStringView,CtIntView} from "./CtValueViews/index";
import {CtAgGridCell} from "./CtAgGridCell"
import {MnBackend, MnTextService} from "@mn/core";
import {AgGridGroupHeader} from "./aggrid/AgGridGroupHeader";
import {AgGridHeader} from "./aggrid/AgGridHeader";
import {RowNode} from 'ag-grid/main';


export function getScrollbarWidth() {
    var outer = document.createElement("div");
    outer.style.visibility = "hidden";
    outer.style.width = "100px";
    outer.style.msOverflowStyle = "scrollbar"; // needed for WinJS apps

    document.body.appendChild(outer);

    var widthNoScroll = outer.offsetWidth;
    // force scrollbars
    outer.style.overflow = "scroll";

    // add innerdiv
    var inner = document.createElement("div");
    inner.style.width = "100%";
    outer.appendChild(inner);

    var widthWithScroll = inner.offsetWidth;

    // remove divs
    outer.parentNode.removeChild(outer);

    return widthNoScroll - widthWithScroll;
}

export function createTableConfig(state:Backend.FullState) {
    let rows:any[] = [];
    let cols:any[] = [];
    let dataset:Backend.Dataset = state.dataset;
    let table:Backend.Table = dataset.table;
    let spec:Backend.Table.Node[] = table.spec;

    let rec = function(ss:Backend.Table.Node[],first?:boolean,root?:boolean) {
        let cc:any[] = [];
        for (let i = 0, l = ss.length; i < l; i++) {
            let s:Backend.Table.Node = ss[i];
            let mefirst = false;
            if (root === true && i == 0) {
                mefirst = true;
            } else {
                mefirst = first;
            }

            if (s.type.startsWith('group')) {
                cc.push({
                    headerName: s.ident,
                    children: rec(s.children,mefirst)
                })
            } else {
                cc.push({
                    headerName: s.ident,
                    field: s.key,
                    pinned: (mefirst? 'left' : null)
                })
            }
        }
        return cc;
    };



    rows = table.data;
    cols = rec(spec,undefined,true);
    return {
        rows:rows,
        cols:cols,
    }

}


// Todo use CtView.Types
let widths = {
    'image': 160,
    'boolean': 70,
    'int': 70,
    'float': 70,
    'string': 100,
    'list.name': 200,
    'list.study': 200,
    'list.identifier': 200,
    'list.string': 200,
    'woe.probabilities': 120,
    'data.summary': 160,
};








let cellClass = function(params) {
    if (params.data.last_in_category === true) {
        return 'last-in-category';
    }
    return '';
};

let cellError = function(params) {
    if (params.value == null) {
        return 'ct-value-view-error'
    }
    return '';
};


export function createTableConfig2(table:CtTable, injector:Injector, selectionCallback?:Function) {
    let rows:any[] = [];
    let cols:any[] = [];
    let spec:CtTable.Node[] = table.Nodes;
    let text_service:MnTextService = injector.get(MnTextService); //used for translation of column headers
    let backend:MnBackend = injector.get(MnBackend);


    // use the precomputed multi level  sort
    function comparatorRow(valueA, valueB, nodeA: RowNode, nodeB: RowNode, isInverted) {

        const indexA: number = (<CtTable.SortedRow>(nodeA.data)).sort_index;
        const indexB: number = (<CtTable.SortedRow>(nodeB.data)).sort_index;

        console.assert(indexA >=0);
        console.assert(indexB >=0);
        console.assert(indexA != indexB);


        return (indexA - indexB) * (isInverted? -1 : 1);
    };

    let rec = function(ss:CtTable.Node[],first?:boolean,root?:boolean,group_expansion?:number) {
        let cc:any[] = [];
        for (let i = 0, l = ss.length; i < l; i++) {
            let s:CtTable.Node = ss[i];
            let mefirst = false;
            if (root === true && i == 0) {
                mefirst = true;
            } else {
                mefirst = first;
            }

            let gcs = () => {
                if (group_expansion === 1) {
                    if (i == 0) return 'closed';
                    else return 'open';
                } else if (group_expansion === 2) {
                    if (i == 0) return '';
                    else return 'open';
                }
                return ''
            };

            let text: string = s.asis ? s.ident : text_service.text('ct.table.header.'+s.ident);
            s.headerName = text;

            if (s.type.startsWith('group')) {
                let ggg:any = {
                    colId: s.id,
                    headerName: text,
                    columnGroupShow: gcs(),
                    headerGroupComponentFramework: AgGridGroupHeader,
                    //columnGroupShow: i > 0 ? 'open' : '',
                    children: rec(s.children,mefirst,false,s.group_expansion)
                };
                if (s.help) {
                    ggg.help = s.help
                }
                cc.push(ggg);
            } else {
                let renderer:CtValueView = CtValueView.Registry[s.type];
                let width = widths[s.type] || 100;
                if (renderer) {
                    //console.log("renderer",renderer.options);
                    let col:any = {
                        //headerName: s.ident,
                        colId: s.id,
                        columnGroupShow: gcs(),
                        headerComponentFramework: AgGridHeader,
                        headerName: text,
                        //field: s.key,
                        valueGetter: (params) => {
                            //console.log(params.data);
                            if (params.data.footer) {
                                if (params.data[s.id]) {
                                    return params.data[s.id];
                                } else {
                                    return null;
                                }
                            } else {
                                return s.getter(params.data);
                            }
                        },
                        width: width,
                        pinned: (mefirst? 'left' : null),
                        cellClass: cellClass,
                        comparator: comparatorRow, //used for sorting
                        suppressSorting: CtTable.sortableTypes.indexOf(s.type) < 0,
                        editable: s.editable ? true : false,
                    };

                    if (s.editable) {
                        col.valueSetter = (params) => {
                           console.log(params);
                           s.setter(params.data,params.newValue,s.id,backend);
                           return true;
                            //console.log(params.data);
                        };
                    }

                    if (selectionCallback) {
                        col.cellClassRules = {
                            'sel-row-on': (params) => { return selectionCallback(params) },
                            //'sel-col-on': function(params) { return params.data.sel_row == false},
                        };
                    }

                    if ((<any>renderer).render) {
                        //col.cellRenderer = (<any>renderer).render
                        col.cellRenderer = (params) => {
                            if (params.value == null) {
                                return "";
                            }
                            return (<any>renderer).render(params);
                        };
                        col.cellClass = (params) => [(<any>renderer).classes(params),cellClass(params),cellError(params)];
                    } else {
                        col.cellRendererFramework= CtAgGridCell;
                        col.angularComponent = {class: renderer, injector: injector};
                    }

                    if (s.error) {
                        col.errorGetter = s.error;
                    }
                    if (s.setter) {
                        col.valueSetterX = s.setter;
                        //
                    }
                    if (s.id == 'rootSTRUCTUREXXXIMAGE_URL') {
                        col.minWidth = 100;
                        col.maxWidth =  table.maxIndent() * 20 + 300;
                    }
                    cc.push(col)
                } else {
                    let col:any = {
                        colId: s.id,
                        headerName: text,
                        columnGroupShow: gcs(),
                        //field: s.key,
                        valueGetter: (params) => { return s.getter(params.data) },
                        width: width,
                        pinned: (mefirst? 'left' : null)
                    };
                    cc.push(col);
                    if (s.id == 'rootSTRUCTUREXXXIMAGE_URL') {
                        col.minWidth = 100;
                        col.maxWidth = 300;
                    }
                }
            }
        }
        return cc;
    };

    rows = table.Rows;
    cols = rec(spec,undefined,true);
    return {
        rows:rows,
        cols:cols,
    }

}

/*
             <div class="centered">${params.data.label} ${params.data.category_id} ${params.data.category_parent_index}</div>

  */

export function renderFullRow(params,text_service:MnTextService):string {
    let tr = text_service.text('ct.virtualrow.'+params.data.label);
    let result:string = `
        <div class="virtual-row" style="margin-left: ${(params.data.indent * 20)+3}px">
            <div class="centered">${tr}</div>
        </div>
    `;
    return result;
}
