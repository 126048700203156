/**
 * Created by joerg on 7/17/17.
 */

// rxjs
import 'rxjs/Rx';
import { Subject } from 'rxjs/Subject';

// angular
import {Component,Injector} from '@angular/core';

import {ColorPickerService,Rgba} from 'ngx-color-picker';

//
import {MnWorkflowComponentStatic} from "../MnWorkflowComponent";
import {MnStateView2} from "./MnStateView2";
import {MnWorkflow} from "../MnWorkflow";
import {CtTable} from "../CtTable/CtTable";
import {MnStateViewRowMarkerView} from "./MnStateViewRowMarkerView";
import {CtValueView} from "../CtValueView";
import {MnValueHtml} from "../MnValue/MnValueHtml";
import {MnWorkflowService} from "../MnWorkflowService";
import {InagakiCombinationRule, ProbabilityMass} from "../../../utils/woe";

@Component({
    selector: 'mn-state-view-assesment-table',
    templateUrl: './MnStateViewAssesmentTable.html',
    styles: [`
        :host {
            overflow: auto;
            padding: 16px;
            display: block;
            height: 100%;
            width: 100%;
            
        }

        .ass-cell {
            width: 150px; 
            border-right: 1px solid lightgrey; 
        }
        .ass-cell.selected {
            background: rgba(92, 163, 234, 0.27);
        }
        .ass-group {
            /* overflow: hidden; */
            position: relative;
            height: 30px;
        }
        
        .ass-slide {
            /*transform: scale(0.8);*/
            filter: grayscale(100%);
            position: absolute;
            left: -1px;
            top: -12px;
            height: 32px;
        }
        
        .ass-num {
            display: inline-block;
            position: absolute;
            right: -5px;
            top: 3px;
        }
        
        .ass-text {
            position: absolute;
            top: calc(50% - 3px);
            left: calc(50% - 8px);
            transform: translate(-50%,-50%);
            font-weight: bold;
            /* color: white; */
        }
        
        .ass-rem {
            display: inline-block;
            font-weight: bold;
            padding-left: 8px;
            vertical-align: super;
        }
        .ass-probs {
            padding-left: 4px;
        }
        
        .hovervable:hover {
            outline: 0;
            -webkit-box-shadow: 0 0 2px 1px rgba(97, 145, 255, .93);
            -moz-box-shadow: 0 0 2px 1px rgba(97, 145, 255, .93);
            box-shadow: 0 0 2px 1px rgba(97, 145, 255, .93);
        }

        .hoverable:hover {
            outline: 0;
            -webkit-box-shadow: 0 0 2px 1px rgba(97, 145, 255, .93);
            -moz-box-shadow: 0 0 2px 1px rgba(97, 145, 255, .93);
            box-shadow: 0 0 2px 1px rgba(97, 145, 255, .93);
        }

        .selected {
            background: rgba(25, 118, 210, 0.27);
        }


        .nameInput /deep/ .mat-input-underline {
            background-color: red;
        }
        .headerTop {
            background: #eaeaea;
        }
        .headerTop td {
            font-weight: bold; 
            font-size: 16px;
            color: #222;
        }
        .header0 {
            color: #444;
            font-weight: bold; 
            font-size: 16px;
            white-space: nowrap;
        }
        .header1 {
            color: #444;
            font-size: 14px;
            font-weight: bold;
            white-space: nowrap;
             text-align: right;
       }
        .header2 {
            height: 32px;
            line-height: 32px;
            white-space: nowrap;
            text-align: right;
        }
        .tdheader2 {
            width: 200px;
        }
        td {
            font-size: 12px;
            padding: 0 4px;
            /*border: 1px solid lightgrey;*/
        }
        td /deep/ .mat-input-underline {
            background-color: transparent;
            bottom: 0;
        }

        td /deep/ .mat-input-wrapper {
            padding-bottom: 0;
        }

    `]
})
export class MnStateViewAssesmentTable /*extends MnStateViewRowMarkerView*/ {

    static createStateTabs(workflow:MnWorkflow):MnStateView2.Tab[] {
        let result = [];
        if (workflow &&
            workflow.Workflow &&
            workflow.State &&
            workflow.State.settings &&
            workflow.State.settings.assessment
        ) {
            for (let i = 0, l = workflow.State.settings.assessment.length; i < l; i++) {
                let sid = workflow.State.settings.assessment[i];
                if (workflow.Workflow.settings &&
                    workflow.Workflow.settings.assessment &&
                    workflow.Workflow.settings.assessment.count &&
                    workflow.Workflow.settings.assessment.data &&
                    workflow.Workflow.settings.assessment.data[sid]
                ) {
                    result.push({
                        id: "",
                        label: "Assessment: " + workflow.Workflow.settings.assessment.data[sid].name,
                        select: 0,
                        description: null,
                        closable: false,
                        data: {
                            id: sid,
                            assessment: workflow.Workflow.settings.assessment.data[sid]
                        }
                    })
                }
            }
        }
        return result;
    }

    mSizeStyle = {
        width: 140,
        'min-width': 140,
        'max-width': 140,
    };

    mNodes:CtTable.Node[] = [];
    mRows:any[] = [];
    mCells:MnStateViewAssesmentTable.Matrix = [];
    mConfiguration:MnStateViewAssesmentTable.Configuration = new MnStateViewAssesmentTable.Configuration("",[],[],{},[],[],[]);
    mHeaders:string[][] = [];
    mTab:any = {};
    mSize:number = 150;
    mBlock = true;
    mHoverablePearsonRows:boolean[];
    mHoverableTanimotoRows:boolean[];
    mTanimotoRows:boolean[];
    mPearsonRows:boolean[];
    mAnalogQualities:number[] = [];
    mResultT1:any = null;
    mResultT2:any = null;
    private mDestroy:Subject<void> = new Subject();
    //private mScores:MnStateViewAssesmentTable.Scores = {};
    
    constructor(injector:Injector, private mCPS:ColorPickerService, private mWorkflow:MnWorkflowService, private mStateViewService:MnStateView2.Service) {
        //super(injector);
        this.mStateViewService.TabSubject.takeUntil(this.mDestroy).subscribe((tab)=>this.onTab(tab));
        this.mWorkflow.StateStatusObservable.takeUntil(this.mDestroy).subscribe((ss)=>{
            if (ss == MnWorkflow.StateStatus.Pending) {
                this.mBlock = true;
            } else {
                this.mBlock = false;
            }
        })
    }

    onTab(tab:any) {
        this.mTab = this.mStateViewService.TabSubject.getValue();
        if (!this.mTab.data || !this.mTab.data.assessment) {
            return;
        }
        this.mConfiguration = new MnStateViewAssesmentTable.Configuration(
            this.mTab.data.assessment.name,
            this.mTab.data.assessment.columns,
            this.mTab.data.assessment.rows,
            this.mTab.data.assessment.scores ? this.mTab.data.assessment.scores : {},
            this.mTab.data.assessment.toxpreds ? this.mTab.data.assessment.toxpreds : [],
            this.mTab.data.assessment.tanimotos ? this.mTab.data.assessment.tanimotos : [],
            this.mTab.data.assessment.pearsons ? this.mTab.data.assessment.pearsons : [],
        );
        //this.mRowMarker.setup(20000000000,[],this.mConfiguration.rows);
        this.mNodes = this.mWorkflow.Table.findNodes((node)=>{
            return this.mConfiguration.columns.indexOf(node.id) >= 0
        });
        this.mRows = this.mWorkflow.Table.Rows.filter((row)=>{
           return this.mConfiguration.rows.indexOf(row.record_index) >= 0
        });
        this.update();
        this.updateResults();
        console.log('nodes',this.mNodes);
        console.log("SSD",this.mTab);
    }

    ngAfterViewInit() {
        this.mBlock = false;
    }

    public round(number, precision) {
        let shift = function (number, precision) {
            let numArray = ("" + number).split("e");
            return +(numArray[0] + "e" + (numArray[1] ? (+numArray[1] + precision) : precision));
        };
        return shift(Math.round(shift(number, +precision)), -precision);
    }

    getProbs(score,cell_index) {
        let rel = this.mAnalogQualities[cell_index];
        if (!rel) {
            let pos = 1.0;
            score.on(()=>{},()=>{ pos = 0.0; }, ()=>{ pos = 0.5; });
            return {probability: pos, errorMargin: 0.0, applicable: false};
        }
        let prod = rel * score.value;
        let err = (1.0 - prod) * 0.5;
        let val = prod + err;
        if (score.outcome == MnStateViewAssesmentTable.Reliability.Outcome.Negative) {
            val = 1.0 - val;
        } else if (score.outcome == MnStateViewAssesmentTable.Reliability.Outcome.Equivocal) {
            val = (prod * 0.5) + err;
        }
        return {probability: val, errorMargin: err, applicable: true};
    }

    updateSlider() {
        this.updateResults();
        this.save();
    }

    updateResults() {
        console.log("updateResults()");

        let probs = [];
        let probsT2 = [];
        for (let i = 0, l = this.mCells.length; i < l; i++) {
            for (let j = 0, k = this.mCells[i].length; j < k; j++) {
                let rel = this.mAnalogQualities[j];
                //if (!rel) { continue; }
                let woe = this.mCells[i][j].woe_sel;
                let score = this.mCells[i][j].score;
                if (score && rel) {
                    let prod = rel * score.value;
                    let unc = (1.0 - prod);
                    let pos = 0.0;
                    let neg = 0.0;
                    score.on(()=>{ pos = prod; },()=>{ neg = prod; }, ()=>{ pos = neg = prod * 0.5; });
                    let pm = new ProbabilityMass(pos,unc,neg);
                    probs.push(pm);
                    probsT2.push(pm);
                } else if (woe) {
                    let v = this.mCells[i][j].value;
                    let p = v.probability - v.errorMargin;
                    let u = v.errorMargin*2.0;
                    let n = 1.0 - p - u;
                    probsT2.push(new ProbabilityMass(p,u,n));
                }
            }
        }

        if (probs.length == 0) {
            this.mResultT1 = null;
        } else if (probs.length == 1) {
            this.mResultT1 = {
                bar: {probability: probs[0].mP + (probs[0].mU * 0.5), errorMargin: probs[0].mU * 0.5, applicable: true},
                p0: this.round(probs[0].mP,2),
                p1: this.round(probs[0].mP + probs[0].mU,2),
                u: this.round(probs[0].mU,2),
            }
        } else {
            console.log(probs);
            let combiner = new InagakiCombinationRule(probs);
            combiner.compute();
            console.log(combiner);
            this.mResultT1 = {
                bar: {probability: combiner.mP + (combiner.mU * 0.5), errorMargin: combiner.mU * 0.5, applicable: true},
                p0: this.round(combiner.mP,2),
                p1: this.round(combiner.mP + combiner.mU,2),
                u: this.round(combiner.mU,2),
            }
        }

        if (probsT2.length == 0) {
            this.mResultT2 = null;
        } else if (probsT2.length == 1) {
            this.mResultT2 = {
                bar: {probability: probsT2[0].mP + (probsT2[0].mU * 0.5), errorMargin: probsT2[0].mU * 0.5, applicable: true},
                p0: this.round(probsT2[0].mP,2),
                p1: this.round(probsT2[0].mP + probsT2[0].mU,2),
                u: this.round(probsT2[0].mU,2),
            }
        } else {
            console.log(probsT2);
            let combiner = new InagakiCombinationRule(probsT2);
            combiner.compute();
            console.log(combiner);
            this.mResultT2 = {
                bar: {probability: combiner.mP + (combiner.mU * 0.5), errorMargin: combiner.mU * 0.5, applicable: true},
                p0: this.round(combiner.mP,2),
                p1: this.round(combiner.mP + combiner.mU,2),
                u: this.round(combiner.mU,2),
            }
        }

    }

    private save() {
        console.log("Save", this.mConfiguration);
        let assessment_w = this.mWorkflow.getWorkflowSetting('assessment');
        this.mTab.data.assessment = this.mConfiguration.json();
        assessment_w.data[this.mTab.data.id+''] = this.mTab.data.assessment;
        this.mWorkflow.addWorkflowSetting('assessment',assessment_w,()=>{
            console.log("Saved Summary");
        });
    }

    private onSize(width) {
        console.log(width);
        this.mSizeStyle = {
            width: width,
            'min-width': width,
            'max-width': width,
        };
    }

    private onRemove($event) {
        console.log("Remove", this.mConfiguration);
        let assessment_w = this.mWorkflow.getWorkflowSetting('assessment');
        delete assessment_w.data[this.mTab.data.id+''];
        this.mWorkflow.addWorkflowSetting('assessment',assessment_w,()=>{
            console.log("Removed Assessment");
        });
        this.mStateViewService.CloseSubject.next();
    }

    private onNamerChange(name:string) {
        if (this.mBlock) return;
        this.mConfiguration.name = name;
        this.save();
    }

    private onCellHeaderChange(header,node_index) {
        console.log(header,node_index);
    }

    onRowClick(node_index, pearson, tanimoto) {
        console.log(node_index, pearson, tanimoto);
        if (pearson) {
            this.mPearsonRows[node_index] = !this.mPearsonRows[node_index];
            this.mConfiguration.pearsons = this.mPearsonRows.map((v,i) => {
                if (!v) return null;
                return this.mNodes[i].id;
            }).filter((v) => {
                return v !== null;
            });
        } else if (tanimoto) {
            this.mTanimotoRows[node_index] = !this.mTanimotoRows[node_index];
            this.mConfiguration.tanimotos = this.mTanimotoRows.map((v,i) => {
                if (!v) return null;
                return this.mNodes[i].id;
            }).filter((v) => {
                return v !== null;
            });
        }
        this.updateAnalogQuality();
        this.updateResults();
        this.save();
    }

    cellKey(cell):string {
        return cell.node_id+'|'+cell.record_index;
    }

    onCellClick(cell,node_index,cell_index) {
        //console.log(cell);
        if (cell.hoverable && !this.mConfiguration.scores[this.cellKey(cell)]) {
            let score:MnStateViewAssesmentTable.Score = new MnStateViewAssesmentTable.Score(0.5,true,MnStateViewAssesmentTable.Reliability.Outcome.Positive);
            this.mConfiguration.scores[cell.node_id+'|'+cell.record_index] = score;
            this.mCells[node_index][cell_index].score = score;
            console.log("added reliabilities");
            this.updateResults();
        }
        if (cell.woe_is) {
            cell.woe_sel = !cell.woe_sel;
            var toxpreds_index = this.mConfiguration.toxpreds.indexOf(this.cellKey(cell));
            if (cell.woe_sel == true && toxpreds_index == -1) {
                this.mConfiguration.toxpreds.push(this.cellKey(cell));
            } else if (cell.woe_sel == false && toxpreds_index > -1) {
                this.mConfiguration.toxpreds.splice(toxpreds_index, 1);
            }
            this.updateResults();
        }
        this.save();
    }

    onBarClick(cell) {
        cell.score.next();
        this.updateResults();
        this.save();
    }

    onCellClickRemove(cell,node_index,cell_index) {
        delete this.mConfiguration.scores[this.cellKey(cell)];
        this.mCells[node_index][cell_index].score = null;
        this.updateResults();
        this.save();
    }

    nodesHaveReliability(node_index:number) {
        //console.log(this.mConfiguration.scores,node_index,this.mNodes[node_index].id);
        return Object.keys(this.mConfiguration.scores).filter((key)=>key.startsWith(this.mNodes[node_index].id+'|')).length > 0;
    }

    updateAnalogQuality() {
        this.mAnalogQualities = [];
        for (let i = 0, l = this.mRows.length; i < l; i++) {
            let row = this.mRows[i];
            let product = 1.0;
            let count = 0.0;
            for (let j = 0, k = this.mNodes.length; j < k; j++) {
                if (this.mTanimotoRows[j]) {
                    let value = this.mNodes[j].getter(row);
                    if (value) {
                        product = product * value;
                        count = count + 1.0;
                    }
                } else if (this.mPearsonRows[j]) {
                    let value = this.mNodes[j].getter(row);
                    if (value) {
                        product = product * (1.0+value) * 0.5;
                        count = count + 1.0;
                    }
                }
            }
            if (count > 0) {
                this.mAnalogQualities.push(Math.pow(product,(1.0/count)))
            } else {
                this.mAnalogQualities.push(null)
            }
        }
    }

    private update() {
        this.mCells = [];
        this.mHeaders = [];
        this.mHoverableTanimotoRows = [];
        this.mHoverablePearsonRows = [];
        this.mTanimotoRows = [];
        this.mPearsonRows = [];

        let current_headers = [];
        for (let ni = 0, nl = this.mNodes.length; ni < nl; ni++) {
            let node = this.mNodes[ni];
            let nodes_row = [];
            let cell_html = MnValueHtml.Registry.get(node.type);
            let cell_comp =  CtValueView.Registry[node.type];
            console.log(node.ident);
            if (node.ident == 'Tanimoto') {
                this.mHoverableTanimotoRows.push(true);
                this.mHoverablePearsonRows.push(false);
                this.mPearsonRows.push(false);
                this.mTanimotoRows.push(this.mConfiguration.tanimotos.indexOf(node.id) >= 0);
            } else if (node.ident == 'PEARSON_CORR_COEFF') {
                this.mHoverableTanimotoRows.push(false);
                this.mHoverablePearsonRows.push(true);
                this.mPearsonRows.push(this.mConfiguration.pearsons.indexOf(node.id) >= 0);
                this.mTanimotoRows.push(false);
            } else {
                this.mHoverableTanimotoRows.push(false);
                this.mHoverablePearsonRows.push(false);
                this.mPearsonRows.push(false);
                this.mTanimotoRows.push(false);
            }

            if (ni == 0) {
                this.mHeaders.push(node.headers);
                current_headers = node.headers;
            } else {
                let node_headers = [];
                for (let hi = 0, hl = node.headers.length; hi < hl; hi++) {
                    if (hi >= current_headers.length) {
                        break;
                    } else if (node.headers[hi] != current_headers[hi] || hi > 1) {
                        node_headers.push(node.headers[hi]);
                    } else if (node.headers[hi] == current_headers[hi] && hi < 2) {
                        node_headers.push(null);
                    }
                }
                if (node_headers.length == 2) {
                    node_headers.push(node_headers[1]);
                    node_headers[1] = '';
                }
                this.mHeaders.push(node_headers);
                current_headers = node.headers;
            }

            for (let ci = 0, cl = this.mRows.length; ci < cl; ci++) {
                let comp = this.mRows[ci];
                let woe_is = false;
                let woe_sel = false;
                if (node.type == 'woe.probabilities' && node.getter(comp) !== null) {
                    woe_is = true;
                    woe_sel = this.mConfiguration.toxpreds.indexOf(node.id+'|'+comp.record_index) >= 0;
                }
                let score = this.mConfiguration.scores[node.id+'|'+comp.record_index];
                if (score == undefined) {
                    score = null;
                }
                if (cell_html) {
                    nodes_row.push({
                        system_id: 'CMS-'+comp.system_id,
                        value: {
                            data: node.getter(comp),
                            error: null
                        },
                        size: { height: this.mSize, width: this.mSize },
                        html: cell_html,
                        hoverable: node.editable == true ? true : false,
                        selected: false,
                        record_index: comp.record_index,
                        node_id: node.id,
                        score: score,
                        woe_is: woe_is,
                        woe_sel: woe_sel,
                    })
                } else {
                    nodes_row.push({
                        system_id: 'CMS-'+comp.system_id,
                        value: node.getter(comp),
                        size: { height: this.mSize, width: this.mSize },
                        comp: cell_comp,
                        hoverable: node.editable == true ? true : false,
                        selected: false,
                        record_index: comp.record_index,
                        node_id: node.id,
                        score: score,
                        woe_is: woe_is,
                        woe_sel: woe_sel,
                    })
                }
            }
            this.mCells.push(nodes_row);
        }
        this.updateAnalogQuality();
        //console.log('mHoverableRows',this.mHoverableRows);
    }

    ngOnDestroy() {
        console.log('ngOnDestroy');
        //super.ngOnDestroy()
        this.mDestroy.next();
    }
}
export namespace MnStateViewAssesmentTable {
    export class Score {
        constructor(public value: number, public include: boolean, public outcome: Reliability.Outcome) {}
        public next() {
            this.on(()=>{
                this.outcome = Reliability.Outcome.Negative;
            },()=>{
                this.outcome = Reliability.Outcome.Equivocal;
            }, ()=>{
                this.outcome = Reliability.Outcome.Positive;
            });
        }
        public probabilities() {

        }
        public on(positive:()=>void,negative:()=>void,equivocal:()=>void) {
            if (this.outcome == Reliability.Outcome.Positive) {
                positive();
            } else if (this.outcome == Reliability.Outcome.Negative) {
                negative();
            } else if (this.outcome == Reliability.Outcome.Equivocal) {
                equivocal();
            }
        }
        public json() {
            return {
                value: this.value,
                include: this.include,
                outcome: this.outcome,
            }
        }

    }
    export namespace Reliability {
        export enum Outcome {
            Positive = 'positive',
            Negative = 'negative',
            Equivocal = 'equivocal'
        }
    }

    export interface Cell {
        value: any;
        comp: any;
        html: any;
        system_id: string;
        user_id: string;
        hoverable: boolean;
        selected: boolean;
        record_index: string;
        node_id: string;
        score: any;
        woe_is: boolean;
        woe_sel: boolean;
    }
    export type Matrix = Cell[][];
    export interface Scores {
        [key:string]:Score
    }
    export class Configuration {
        public scores:Scores = {};
        constructor(public name:string, public columns:string[], public rows:string[], scores:Scores, public toxpreds:string[], public tanimotos:string[], public pearsons:string[]) {
            for (var key in scores) {
                if(!scores.hasOwnProperty(key)) continue;
                let score:any = scores[key];
                this.scores[key] = new Score(
                    score.value,
                    score.include,
                    score.outcome,
                );
            }
        }
        public json() {
            let scores = {};
            for (var key in this.scores) {
                if(!this.scores.hasOwnProperty(key)) continue;
                let score:Score = this.scores[key];
                scores[key] = score.json();
            }
            return {
                name: this.name,
                columns: this.columns,
                rows: this.rows,
                scores: scores,
                toxpreds: this.toxpreds,
                tanimotos: this.tanimotos,
                pearsons: this.pearsons,
            }
        }
    }

}
MnStateView2.Registry.register("MnStateViewAssesmentTable",new MnWorkflowComponentStatic(MnStateViewAssesmentTable));


