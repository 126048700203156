import {Component} from '@angular/core';

import {CtValueView, CtValueViewRegisterDecorator, TextViewRegisterDecorator} from "../CtValueView";
import {CtAggregatedValueView, CtComplexValueView, CtComplexValueComponentDecorator} from "./CtComplexValueView";
import {CtTable} from "../CtTable/CtTable";

/*

AT table:  Available Studies

[
            {
              "count": 2,
              "endpoint": "Acute Toxicity"
            }]

*/

//namespace Backend {
    export interface ListStudy {
        count: number;
        endpoint: string;
    }
//}


/**
 * Implementation issue: the list study does not require an aggregation
 * It might be simpler to have implemented the update() method
 */

@TextViewRegisterDecorator(CtTable.ColumnType.ListStudy)

export class CtListStudyViewNoComponent extends CtAggregatedValueView<ListStudy> {
    protected getKeyValue( d: ListStudy): string {
        return d.endpoint;
    }

    protected getOtherValue(d: ListStudy): string {
        return '' + d.count;
    }


    protected formatOtherValueHTML(count: string): string {
        return '<b>' + count + '</b>';
    }

    protected formatOtherValues(othersAstring: string) {
        return ': ' + othersAstring ;
    }
}


@CtComplexValueComponentDecorator('ct-list-study-view')
@CtValueViewRegisterDecorator(CtValueView.Types.ListStudy)
export class CtListStudyView extends CtListStudyViewNoComponent {

    constructor(service:CtValueView.Service) {
        super(service);
    }



}