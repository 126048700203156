/**
 * Created by joerg on 7/17/17.
 */

// rxjs
import 'rxjs/Rx';
import { Subject } from 'rxjs/Subject';

// angular
import {Component,Input,Output,EventEmitter,ViewChild,ViewEncapsulation} from '@angular/core';


// temporary change: use <mn-jsme-depict> which is an improved local copy inside
@Component({
    selector: 'mn-action-configurator-jsme',
    template: `
        <mn-jsme-depict [height]="320" [width]="368" [options]="polarnitro" #jsme (smilesChange)="onSmilesChange($event)"> </mn-jsme-depict>
    `,
    styles: [`
        .jsa-resetDiv {
            filter: brightness(107%);
        }
    
    `],
    encapsulation: ViewEncapsulation.None
})
export class MnActionConfiguratorJsme {

    @Input()
    get smiles():string { return this.mSmiles; }
    set smiles(v:string) { this.mSmiles = v;  }
    private mSmiles:string;

    @Input()
    get mol():string { return this.mMol; }
    set mol(v:string) {
        this.mMol = v;
        //fixme
        setTimeout(()=>{
            setTimeout(()=>{
                this.mJsme.setMol(v)
            });
        });
    }
    private mMol:string;

    @Output() smilesChange = new EventEmitter();
    @Output() molChange = new EventEmitter();
    @ViewChild('jsme') mJsme:any;

    constructor() {}

    private onSmilesChange(smiles:string) {
        this.smilesChange.emit(smiles);
        this.molChange.emit(this.mJsme.mol2000());
    }
}


