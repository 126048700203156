/**
 *
 *
 *
 * The Angular CSS encapsulation of the D3 styles does not work in Angular because
 * Angular does not know about the dynamically generated SVG nodes.
 * Solution:
 *  1) use ViewEncapsulation
 *  2) prefix the styles with app-navigation-tree-d3 (or something else)
 *
 *
 *
 */



import {Component, ElementRef, EventEmitter, Input, Output, ViewChild, ViewEncapsulation} from '@angular/core'; // for css
import * as d3 from 'd3';
import {HierarchyNode, HierarchyPointLink, HierarchyPointNode} from 'd3-hierarchy'; // for type checking
import {BasePlotComponent, Margin} from '../base-plot/base-plot.component';
import {MnWorkflow} from '../../MnWorkflow';

import MnWorkflowNode = MnWorkflow.Node;



// declare a function pointer
// type NodeEvent = (param: MnWorkflowNode) => void;


export type getNodeTextFunction = (param: MnWorkflowNode) => string;

export type sizeFunction = (param: NavigationTreeD3Component, width: number, height: number) => number;
export type nodeWithDefaultFunction = (param: MnWorkflowNode, defaultWidth: number) => number;


export type nodeFunction = (node: HierarchyPointNode<MnWorkflowNode>) => void;
export type nodeListFunction = (nodeList: HierarchyPointNode<MnWorkflowNode>[]) => void;

/**
 * A class to represent an event associated with the navigation tree.
 */
export class NavigationTreeEvent {
  component: NavigationTreeD3Component;
  graphNode: HierarchyNode<MnWorkflowNode>;

  sourceData: MnWorkflowNode;
  targetData: MnWorkflowNode; // null if node


  isEdge: boolean;
  typeName: string; // event standard name such as 'click', 'mouseleave', ...

  // coordinates of the graph element - for the edge the mid point between the two nodes
  x: number;
  y: number;

  // event provided by d3
  d3Event: any;  // application: d3Event.pageX

  id: string; // html id of the SVG object? needed?
  // TODO

  plotWidth: number;
  plotHeight: number;

}

// CSS classes
export enum CSSclasses {
  // must match the definition in the CSS file !!!!
  NodeDefault = 'node--default',
  NodeBusy = 'node--greyed',
  NodeSelected = 'node--highlight',
  NodeDimmed = 'node--dimmed',
  TopGroupDebug = 'top-group-debug',

  NodeInsideText = 'node--inside-text',
  EdgeInsideText = 'edge--inside-text',

  RootNode = 'node-root--default',
  RootNodeSelected = 'node-root--highlight',

  EdgeBusy = 'edge--busy',
  EdgeDefault = 'edge--default',
  EdgeDimmed = 'edge--dimmed',

  NodeInsideBranchSymbol = 'node--inside-branch-symbol',

}

export enum TooltipType {
  CustomDiv,
  Title,
  MDtooltip
}

@Component({
  encapsulation: ViewEncapsulation.None, // for CSS
  selector: 'app-navigation-tree-d3',  // the prefixes in the CSS must be changed as well
  templateUrl: './navigation-tree-d3.component.html',
  styleUrls: ['./navigation-tree-d3.component.css'],
})
export class NavigationTreeD3Component extends BasePlotComponent<MnWorkflowNode> {


  // @Input() nodeRadius = 20; // for the circles - used to compute extra margin for the tree layout

  @Input() nodeWidth = 40; // for the nodes - used to compute extra margin for the tree layout
  @Input() nodeHeight = 40; // not used in the current implementration be caude we use circles for the nodes
  @Input() maxStrokeWidth = 6;

  sizeIsDynamic: true;

  // assuming that the tree is horizontal, this is the  distance between two nodes
  // projected on the X axis
  // This number should be at least two times larger than the radius + stroke width
// - used for dynamic sized  plot
  @Input() edgeLength = 100;

  // assuming that the tree is horizontal, this is the vertical distance between the two closest nodes
  // projected on the Y axis.
  // This number should be at least two times larger than the radius + stroke width
  // - used for dynamic sized  plot
 @Input() nodeDist = 100;


  @Input() vertical = false;

  // to register events
  @Input() nodeEvents: string[] = []; // e.g ['click']
  @Input() edgeEvents: string[] = [];


  // @Input() zoomAndSpan = true; // override the default from my super class

  @Input() duration = 750; // for animation not used yet - see css file

  @Input() displayNodeAndEdgeToolTip = true;

  @Input() linearDisplay = false; // when true, hide the tree structure
  @Input() dimUnselectedPath = false; // when true dimm the node and edges that are not the linear path that contains a selected node

  @Input() getNodeText: getNodeTextFunction; // function that provides the text shown inside each node - should be short
  @Input() getNodeTitle: getNodeTextFunction; // function that provides the tooltip text for each node
  @Input() getEdgeTitle: getNodeTextFunction; // function that provides the tooltip text for each edge
  @Input() getEdgeText: getNodeTextFunction;  // function  provides the text shown inside/above each edge

  @Input() wrapEdgeText = false; // display the edge text as multi lines if it has new lines


  @Input() getCustomNodeWidth: nodeWithDefaultFunction; // allows to customize the node width for e.g. root node


  // problem: how to specify in a template because template eval is JS, not typescript
  @Input() tooltipType = TooltipType.Title;




  @Output()
  events: EventEmitter<NavigationTreeEvent> = new EventEmitter<NavigationTreeEvent>();

  @Output()
  plotSizeIsKnownEvent: EventEmitter<NavigationTreeEvent> = new EventEmitter<NavigationTreeEvent>();

  @ViewChild('tooltipDiv') tooltipDiv: ElementRef;

  // subclasses should redefined it
  protected myType = 'NavigationTreeD3Component'; // used for debugging/logging

  protected svgTopGroup: any; // store the object returned by startD3Plot()

  protected previousSelectedNode: MnWorkflowNode;


  protected nodesWithTreeLayout: HierarchyNode<MnWorkflow.Node> | HierarchyPointNode<MnWorkflow.Node>;
  protected edgeGroupD3Selection: any; // SVG group that contains the shape and the text
  protected edgeShapeD3Selection: any;
  protected edgeTextD3Selection: any;

  // duplicated constructor is needed - same problem as Java
  constructor(public elementRef: ElementRef) {
    super(elementRef);
    if (this.debug) {
      this.log(elementRef.nativeElement);
    }

  }



  // @override
  public checkDataValidity(d: MnWorkflowNode): boolean {
    if (!d) {
      return false;
    }

    return true;

  }

  /**
   * Let d3 compute the hierarchy tree - add a parent field
   * @returns {HierarchyNode<MnWorkflow.MnWorkflowNode>}
   */
  protected rootWithParent(): HierarchyNode<MnWorkflowNode> {
    let rootWithParent: HierarchyNode<MnWorkflowNode> = this.computeRootWithParent();

    if (this.linearDisplay) {
      rootWithParent = this.computeLinearTree(rootWithParent);
    }
    return rootWithParent;

  }

  /**
   * compute the tree - add parent to each node
   * @returns {HierarchyNode<MnWorkflow.MnWorkflowNode>}
   */
  protected computeRootWithParent(): HierarchyNode<MnWorkflowNode> {
    return d3.hierarchy(this.dataObject, function (d: MnWorkflowNode) {
      return d.children;
    });
  }


  /**
   * select a subset of the tree to make a linear path that contains the selected node - omit some branches
   * @returns {HierarchyNode<MnWorkflow.MnWorkflowNode>}
   */
  protected computeLinearTree(rootWithParent: HierarchyNode<MnWorkflowNode>): HierarchyNode<MnWorkflowNode> {



    // select a subset of the tree to make a linear path
    const linearPath: HierarchyNode<MnWorkflowNode>[] = this.selectLinearPathNodeContainingSelectedNode(rootWithParent);



    // modify the hierarchy: only one child allowed
    // for (const i in linearPath) { // does not work: i is a string
    for (let i = 0; i < linearPath.length; i++) {
      const dataNode: any = linearPath[i].data;
      if (dataNode.children && dataNode.children.length > 0) {
        dataNode.children_0 = dataNode.children; // make a backup
        // this.log('i=' + i + ' ch='+ linearPath[i + 1]);
        dataNode.children = [linearPath[i + 1].data];
      }
    }

    // recompute the hierarchy using the modified (linear) tree
    rootWithParent = this.computeRootWithParent();

    // restore the hierarchy of the input data to avoid side effects

    for (const node of  linearPath) {
      const dataNode: any = node.data;
      if (dataNode.children_0) {
        dataNode.children = dataNode.children_0; // restore backup
        delete dataNode.children_0;
      }
    }


    return rootWithParent;
  }

  /**
   * return the non branched path (from root to a leaf) containing the selected node, and if possible, the previously selected node.
   * If there is no selected node, then returns the linear path starting from root to the first leaf found.
   * @param {MnWorkflow.MnWorkflowNode} root
   * @returns {HierarchyNode<MnWorkflow.MnWorkflowNode>[]}
   */
  protected selectLinearPathNodeContainingSelectedNode(rootWithParent: HierarchyNode<MnWorkflowNode>): HierarchyNode<MnWorkflowNode>[] {

    const self = this;


    let selected: HierarchyNode<MnWorkflowNode> = this.findSelectedGraphNode(rootWithParent);
    const previousSelectedNode: HierarchyNode<MnWorkflowNode> =
      rootWithParent.descendants().find(function (n) {
        return n.data === self.previousSelectedNode;
      });

    // if no node is selected, then build the linear path from previous selection - should not happen
    if (!selected) {
      selected = previousSelectedNode;
    }

    // if no node is selected, then build the linear path from the root
    if (!selected) {
      selected = rootWithParent;
    }

    // previousSelectedNode might be null - that's OK
    const results: HierarchyNode<MnWorkflowNode>[] = this.findLinearPathway(selected, previousSelectedNode);


    return results;

  }

  /**
   * return the non branched path (from root to a leaf) containing the mustNode (=selected node) and the shouldNode (= previously
   * selected node) if the latter is defined and there a path between the two nodes, otherwise ignore shouldNode
   * Returns the linear path starting from root to the first leaf found that includes mustNode and may be shouldNode.
   * Goal: representation of a tree in a less complex linear form that can be used for a bread crum navigation
   *
   * @param {HierarchyNode<MnWorkflow.MnWorkflowNode>} mustNode
   * @param {HierarchyNode<MnWorkflow.MnWorkflowNode>} shouldNode
   * @returns {HierarchyNode<MnWorkflow.MnWorkflowNode>[]}
   */
  protected findLinearPathway(mustNode: HierarchyNode<MnWorkflowNode>,
                              shouldNode: HierarchyNode<MnWorkflowNode>): HierarchyNode<MnWorkflowNode>[] {

    let results: HierarchyNode<MnWorkflowNode>[];

    // assume that the node that is the deeper in the tree is mustNode
    let deepNode: HierarchyNode<MnWorkflowNode> = mustNode;
    let shallowNode: HierarchyNode<MnWorkflowNode> = shouldNode;

    // deepNode will be used to build the pathway because this allows the
    // use of the ancestors() method

    // ignore  shouldNode if shouldNode and deepNode cannot be placed on the same path because of branching
    if (shouldNode && mustNode.depth !== shouldNode.depth) { // if same depth they cannot be connected
      if (deepNode.depth < shallowNode.depth) { //
        [deepNode, shallowNode] = [shallowNode, deepNode];
      }
      // if they are not connected, reset deepNode to must node -we don't care about shallow node
      if (!shallowNode.descendants().find(function (n: HierarchyNode<MnWorkflowNode>) {
          return n === deepNode;
        })) {
        [deepNode, shallowNode] = [shallowNode, deepNode];
      }

    }

    // build the top part of the path using ancestors() starting from deepNode - if shallowNode is OK, then it is
    // one of the ancestors
    results = deepNode.ancestors();
    results.reverse();
    // results.push(deepNode); not needed deepNode is already in the ancestors

    // now complete the tree down to a leaf - branching will lead to an arbitrary
    // branch selection

    let child: HierarchyNode<MnWorkflowNode> = deepNode;

    // select one child - arbitrarily the first one
    while (child = (child.children && child.children.length > 0) ? child.children[0] : null) {
      results.push(child);
    }

    return results;


  }


  /**
   * find the node that is selected if any - does not check if there is more than one node
   * @param {HierarchyNode<MnWorkflow.MnWorkflowNode>} root
   * @returns {HierarchyNode<MnWorkflow.MnWorkflowNode>}
   */
  protected findSelectedGraphNode(root: HierarchyNode<MnWorkflowNode>): HierarchyNode<MnWorkflowNode> {

    let selected: HierarchyNode<MnWorkflowNode>;
    for (const each of root.descendants()) {

      const eachNodeWithParent: HierarchyNode<MnWorkflowNode> = each;
      if (eachNodeWithParent.data.highlight) {
        selected = eachNodeWithParent;
        break;
      }

    }

    return selected;
  }

  public getSelectedNode(): MnWorkflowNode {

    const selected: HierarchyNode<MnWorkflowNode> = this.findSelectedGraphNode(this.rootWithParent());

    if (selected) {
      return selected.data;
    }
    return null;
  }

  /**
   * TODO: this implemetation must know too much of the logic of MnWorkflowNode
   * @param {MnWorkflow.MnWorkflowNode} newSelectedNode
   */
  public toggleNodeSelection(newSelectedNode: MnWorkflowNode) {
    const selected: MnWorkflowNode = this.getSelectedNode(); // could be null
    if (selected !== newSelectedNode && !newSelectedNode.busy) {
      if (selected) {
        selected.highlight = false;
      }
      newSelectedNode.highlight = true;
      this.previousSelectedNode = selected;
      this.plot();
    }
  }

  public toggleLinearDisplay() {
    this.linearDisplay = !this.linearDisplay;
    this.plot(); // how to add animation?
  }

  /**
   * Register/bind events to the parent component
   * @param {string[]} events : the list of browser event type: 'click', ....
   * @param receiver : a d3 object selection
   * @param {boolean} isEdge : false if it is a node
   */
  protected registerEvents(receiver: any, events: string[], isEdge: boolean): void {
    const self = this;
    for (const typename of events) {
      // the typing is not clear here
      receiver.on(typename, function (d: HierarchyNode<MnWorkflowNode>, i: number, a3: any[]) {
        let x: number;
        let y: number;
        const dd: any = d;
        let sourceData: MnWorkflowNode;
        let targetData: MnWorkflowNode;


        if (isEdge) { // compute the average position of the source and target
          x = (dd.source.x + dd.target.x) / 2;
          y = (dd.source.y + dd.target.y) / 2;

          sourceData = dd.source.data;
          targetData = dd.target.data;
        } else {
          x = dd.x;
          y = dd.y;
          sourceData = dd.data;

        }

        const event: NavigationTreeEvent = {
          component: self,
          isEdge: isEdge, id: d.id,
          graphNode: d, typeName: typename,
          x: x, y: y,
          d3Event: d3.event,
          sourceData: sourceData,
          targetData: targetData,
          plotWidth: null,
          plotHeight: null,
        };
        self.events.emit(event);
      });
    }
  }

  protected sendPlotSizeIsknownEvent(plotWidth: number, plotHeight: number): void {

    const event: NavigationTreeEvent = <NavigationTreeEvent>{};

    event.component = this;
    event.plotWidth = plotWidth;
    event.plotHeight = plotHeight;
    event.typeName = 'plotsize';

    this.plotSizeIsKnownEvent.emit(event);

  }

  protected update(): void {



    const isHorizontal: boolean = !this.vertical;
    let pWidth: number;
    let pHeight: number;



    // dimension from HTML parameters
    [pWidth, pHeight] = this.computePlotDimension();



    // TODO - the stroke width should not be defined in CSS such that we can predict the margin
    //  var borderRadius = document.getElementById('RoundedDiv').style.borderRadius; ???
    // let plotMargin = Math.max(this.nodeWidth, this.nodeHeight);
    // plotMargin += this.maxStrokeWidth ; //do not divide by 2 because the stroke is centered on the line thus
    // only half of the stroke requires more space

    /*   if (pWidth - plotMargin  <= 0 || pHeight - plotMargin  <= 0) {
         this.log('Plot dimension is too small for the node radius');
         return ;
       }
   */
    // assigns the data to a hierarchy using parent-child relationships
    // let nodes: any = d3.hierarchy(this.dataObject);
    // assigns the data to a hierarchy using parent-child relationships
    const nodesWithTreeLayout: HierarchyNode<MnWorkflowNode>| HierarchyPointNode<MnWorkflowNode> = this.rootWithParent();
    this.nodesWithTreeLayout = nodesWithTreeLayout;


    [pWidth, pHeight] = this.computeLayout(pWidth, pHeight);


    // from now on nodesWithTreeLayout contains nodes with x,y coordinates (HierarchyPointNode<MnWorkflowNode>)
    // the tree is vertical, from top to bottom


    // horizontal -> rotation 90 deg
    if (isHorizontal) {
      [pHeight, pWidth] = [pWidth, pHeight];
      this.swapXY(<HierarchyPointNode<MnWorkflowNode>>nodesWithTreeLayout);
    }


    const m: Margin = this.getMargin();
    const svgWidth = pWidth + m.left + m.right;
    const svgHeight = pHeight + m.top + m.bottom;


    if (this.sendPlotSizeIsknownEvent) { // do  we need to test this?
      this.sendPlotSizeIsknownEvent(svgWidth, svgHeight);
    }

    // resize my svg element  - should we keep a minimum size?
    this.svgPlotD3.attr('width', svgWidth)
      .attr('height', svgHeight);


    if (this.debug) {
      // show a background rectangle
      this.svgTopGroup.append('rect').attr('width', pWidth)
        .attr('height', pHeight)
        .attr('class', CSSclasses.TopGroupDebug);
    }

    this.createEdgeGroupD3Selection();
    this.createEdgeShapeD3Selection();
    this.styleEdges();

    if (this.getEdgeText) {
      this.setAndStyleEdgeText();
    }

    this.registerEvents(this.edgeShapeD3Selection, this.edgeEvents, true);

    this.setTooltip(this.edgeShapeD3Selection, this.getEdgeTitle);
    // bug: will set the tooltip for all the SVG element in the node: circle + text



    // adds each node as a SVG group
    const nodeSelectionD3 = this.svgTopGroup.selectAll('.node')
      .data(nodesWithTreeLayout.descendants())
      .enter().append('g')
      .attr('transform', function (d) {
        return 'translate(' + d.x + ',' + d.y + ')';
      });


    this.setTooltip(nodeSelectionD3, this.getNodeTitle); // bug: will set the tooltip for all the SVG element in the node: circle + text


    this.registerEvents(nodeSelectionD3, this.nodeEvents, false);



    // adds the circle to the node selection
    this.setAndStyleNodes(nodeSelectionD3);

    // adds the text to the node selection
    this.setAndStyleNodesText(nodeSelectionD3.append('text'));

  }


  /**
   * return a value for the dx atribute of a svg text
   * @param {HierarchyNode<MnWorkflow.Node>} n
   * @returns {number}
   */
  protected overrideShapeCenterX(n: HierarchyNode<MnWorkflowNode>): number {
    return 0; // shape is a circle - symmetric - no changes needed
  }

  /**
   * without the stroke width
   * @returns {number[]}
   */
  protected getNodeDefaultDimensions(): number[] {

    return [
      this.nodeWidth,
      this.isNodeShapeSymmetric() ? this.nodeWidth : this.nodeHeight];
  }

  protected getNodeXYradius(): number[] {
    return [this.getNodeDefaultDimensions()[0] / 2, this.getNodeDefaultDimensions()[1] / 2];

  }

  protected createEdgeGroupD3Selection(): void {
    this.edgeGroupD3Selection = this.svgTopGroup.selectAll('.link')
      .data(this.nodesWithTreeLayout.links())
      .enter().append('g');

  }

  protected createEdgeShapeD3Selection(): void {
    const isHorizontal: boolean = !this.vertical;

    this.edgeShapeD3Selection = this.edgeGroupD3Selection.append('path');

    // link based on Bezier curve
    const linkBuilder: any = isHorizontal ? d3.linkHorizontal() : d3.linkVertical();

    this.edgeShapeD3Selection.attr('d', linkBuilder.x(function (d: HierarchyPointNode<MnWorkflowNode>) {
      return d.x;
    }).y(function (d) {
      return d.y;
    }));

  }


  protected createEdgeText() : void {
      if (this.getEdgeText) {
          // this.noGrou.
      }

  }

  /**
   * Compute the layout of the tree, returns the dimension box of the tree
   * Does not adapt to vertical/horizontal dimension
   *
   * @param pWidth - used only if there is only one node
   * @param pHeight - used only if there is only one node
   */
  protected computeLayout(pWidth: number, pHeight: number): number[] {
    // compute the tree layout for a fixed size

    const dd = 1;
    const [nodeWidth, nodeHeight]: number[] = this.getNodeDefaultDimensions();

    let marginX =  nodeWidth / 2 + this.maxStrokeWidth / 2;
    let marginY =  nodeHeight / 2 + this.maxStrokeWidth / 2;

    // d3.tree function will add .x and .y
    const root:  HierarchyPointNode<MnWorkflowNode> =  <HierarchyPointNode<MnWorkflowNode>> this.nodesWithTreeLayout;

    // console.log(root);
    const firstChild:  HierarchyPointNode<MnWorkflowNode> = root.children ? root.children[0] : null;

    // TODO: THIS  HAS NOT BEEN TESTED
    if (!firstChild) {
      // let d3 compute the layout for the single node
        d3.tree().size([pWidth - 2 * marginX, pHeight - nodeHeight - 2 * marginY])(root);
      return [pWidth, pHeight];
    } else {
      d3.tree().size([dd, dd])(root);
    }
    // from now on the nodes contains the x, y values for the layout
    // the d3.tree() function computes a vertical tree
    // the node width and height are, convention,  for an horizontal layout
    // therefore swap is needed
    [marginX, marginY] = [marginY, marginX];



    // try to fix the tree layout in case of a customized node with or height
    this.sameDepthEnumerate(root, (children: HierarchyNode<MnWorkflowNode>[]) => {
      // remember : the tree is vertical
      for (const eachNode of children) {
        // TBC
      }
    });

    // scale the node positions

    const layoutEdgeDist: number = firstChild.y - root.y;

    const layoutNodeDist: number = this.computeMinimalNodeDist(root);

    if (layoutEdgeDist <= 0) {
      this.log('Error: invalid  distance between the two first nodes: ' + layoutEdgeDist);
      return;
    }
     if (layoutNodeDist <= 0) {
      this.log('Error: invalid  distance between the two closest nodes: ' + layoutNodeDist);
      return;
    }
   const scaleY: number = this.edgeLength / layoutEdgeDist;
    const scaleX: number = this.nodeDist / layoutNodeDist;
    let maxX: number = root.x;
    let maxY: number = root.y;
    let minX: number = root.x;
    let minY: number = root.y;

    for (const eachNode of root.descendants()) {
      maxX = Math.max(eachNode.x, maxX);
      maxY = Math.max(eachNode.y, maxY);
      minX = Math.min(eachNode.x, minX);
      minY = Math.min(eachNode.y, minY);
    }

    // shrink further the size because of the margin, descendants() yields root as well
    for (const eachNode of root.descendants()) {
      eachNode.x = (eachNode.x - minX) * scaleX + marginX;
      eachNode.y = (eachNode.y - minY) * scaleY + marginY;
    }

    return [(maxX - minX) * scaleX + 2 * marginX, (maxY - minY) * scaleY + 2 * marginY];
  }

  /**
   * Used to rotate the tree from vertical to horizontal
   * @param {HierarchyPointNode<MnWorkflow.Node>} root
   */
  protected swapXY(root: HierarchyPointNode<MnWorkflowNode>): void {
    for (const eachNode of root.descendants()) {
      [eachNode.x, eachNode.y] = [eachNode.y, eachNode.x];
    }

  }

  protected computeMinimalNodeDist(root: HierarchyPointNode<MnWorkflowNode>): number {
    let minDist = 1; // the layout was computed for [0,1]

    this.sameDepthEnumerate(root, function(childrenWithSameDepth: HierarchyPointNode<MnWorkflowNode>[]) {
      if (childrenWithSameDepth.length > 1) {
        for ( let i = 0; i < childrenWithSameDepth.length - 1; i++) {
          const x1 = childrenWithSameDepth[i].x;
          const x2 = childrenWithSameDepth[i + 1].x;
          const dist = x2 - x1;
          console.assert( dist > 0);

          minDist = Math.min(minDist, dist);
          // console.log(minDist);


        }
      }

    });

    return minDist;
  }

  protected breadFirstEnumerate(root: HierarchyPointNode<MnWorkflowNode>, f: nodeListFunction): void {
      const children: HierarchyPointNode<MnWorkflowNode>[] = root.children;
      if (children) {
        f(children);
        children.forEach( (each: HierarchyPointNode<MnWorkflowNode>) => {this.breadFirstEnumerate(each, f); });
      }
  }

  protected sameDepthEnumerate(root: HierarchyPointNode<MnWorkflowNode>, f: nodeListFunction): void {
    const allChildren: HierarchyNode<MnWorkflowNode>[] = root.descendants();

    if (! allChildren) {return ; }
     // sort the children array by depth
    allChildren.sort(function(n1: HierarchyPointNode<MnWorkflowNode>, n2: HierarchyNode<MnWorkflowNode>) {
      return n1.depth - n2.depth; });

    let d = allChildren[0].depth;
    let childrenWithSameDepth = [];

    for ( const eachChild of allChildren ) {
      if (eachChild.depth === d) {
        childrenWithSameDepth.push(eachChild);
      } else {
        // sort the children by height
        childrenWithSameDepth.sort( function(n1: HierarchyPointNode<MnWorkflowNode>, n2: HierarchyPointNode<MnWorkflowNode>) {
          return n1.x - n2.x;
        });
        // call the function argument
        f(childrenWithSameDepth);

        console.assert(d < eachChild.depth);
        // increase depth level
        d = eachChild.depth;

        childrenWithSameDepth = [eachChild];
      }

    }

  }

  protected styleEdges(): void {
    let linearPath: HierarchyNode<MnWorkflowNode>[] = [];

    const dimUnselectedPath = this.dimUnselectedPath && ! this.linearDisplay;

    // this.log('dimUnselectedPath: ' + dimUnselectedPath);

    if (dimUnselectedPath) {
      // select a subset of the tree to make a linear path
      linearPath = this.selectLinearPathNodeContainingSelectedNode(this.nodesWithTreeLayout);

    }
    this.edgeShapeD3Selection.attr('class', function (d: HierarchyPointLink<MnWorkflowNode>) {
      const target: MnWorkflowNode = d.target.data;
      // self.log('' + target.busy);
      if (target.busy ) {
        return CSSclasses.EdgeBusy;
      } else {
        // if the node is not one of the main selected linear path
        if (dimUnselectedPath && linearPath.indexOf(d.target) === -1) {
          return CSSclasses.EdgeDimmed;
        } else {
          return CSSclasses.EdgeDefault;

        }
      }
    });
  }

  // this.animateLink(linkSelectionD3);

  /**
   * circle is symmetric, meaning that the node width must equal the node height
   * @returns {boolean}
   */
  protected isNodeShapeSymmetric(): boolean {
      return true;
  }

  protected setAndStyleNodes(nodeSelectionD3: any) {
    const self = this;
    const circleSelection: any = nodeSelectionD3.append('circle');
    this.styleNodes(circleSelection);

    // does this work?
    if (this.nodeWidth > 0) {
      circleSelection.attr('r', self.nodeWidth / 2);
    }
    if (this.nodeWidth > 0 || this.getCustomNodeWidth) {
      circleSelection.attr('r', (n: HierarchyNode<MnWorkflowNode>): number => {
        if ( this.getCustomNodeWidth) {
          return this.getCustomNodeWidth(n.data, this.nodeWidth) / 2;
        } else {
          return self.nodeWidth / 2;
        }
      });
    }


  }

  /**
   * Set the CSS class to the nodes
   *  The style defined in the CSS overrides

   * @param nodeSelectionD3
   */
  protected styleNodes(nodeSelectionD3: any) {

      // TODO duplicated code
      let linearPath: HierarchyNode<MnWorkflowNode>[] = [];

      const dimUnselectedPath = this.dimUnselectedPath && ! this.linearDisplay;

      // this.log('dimUnselectedPath: ' + dimUnselectedPath);

      if (dimUnselectedPath) {
          // select a subset of the tree to make a linear path
          linearPath = this.selectLinearPathNodeContainingSelectedNode(this.nodesWithTreeLayout);

      }

    nodeSelectionD3.attr('class',  (d: HierarchyPointNode<MnWorkflowNode>) => {
        let cl = CSSclasses.NodeDefault;
        if (dimUnselectedPath && linearPath.indexOf(d) === -1) {
            cl = CSSclasses.NodeDimmed;
        }
        if (d.data.busy) {
          cl = CSSclasses.NodeBusy;
        }
        if (d.data.highlight) {
          cl = CSSclasses.NodeSelected;
        }
        if (this.isNodeRoot(d)) {
          cl = CSSclasses.RootNode;
          if (d.data.highlight) {
            cl = CSSclasses.RootNodeSelected;
          }
        }
        return cl;
      });

  }

  protected setAndStyleNodesText(nodeSelectionD3: any): void {
    const self: NavigationTreeD3Component = this;

    if (this.getNodeText) {
      nodeSelectionD3
        .text(function (d: HierarchyNode<MnWorkflowNode>) {
          return self.getNodeText(d.data);
        })
        .attr('class', CSSclasses.NodeInsideText)
        .style('pointer-events', 'none') // the mouse pointer does not change when moving over the text
        .attr('x', function (d: HierarchyNode<MnWorkflowNode>) {
          return self.overrideShapeCenterX(d);
        });

    }
  }

  /**
   * Not implemented yet : the text should follow the Besier path - possible in SVG
   * @param linkSelectionD3
   */
  protected setAndStyleEdgeText(): void {
     const self: NavigationTreeD3Component = this;

     this.edgeShapeD3Selection.each(function(d) {
       // self.log('' + d);
       // this is the path, instance of SVGPathElement

     });



  }

  /**
   * does not work
   * @param nodeOrLinkSelectionD3
   */

  /*
  protected setTooltip(nodeOrLinkSelectionD3: any, getTextFunction: getNodeTextFunction): void {
    const self: NavigationTreeD3Component = this;

    nodeOrLinkSelectionD3.append('title').html(function(graphNode: any) {
      const data: MnWorkflowNode = graphNode.data ? graphNode.data : graphNode.target.data;
      let title: string;
      if (data) {
        title = graphNode.data ? data.nodeTitle : data.edgeTitle ;
        if ( self.isNonEmptyString(title)) {
          return title;
        }

      }
      return title;

    });

  }
*/
  protected setTooltip(nodeOrLinkSelectionD3: any, getTextFunction: getNodeTextFunction): void {
    if ( !this.displayNodeAndEdgeToolTip || !getTextFunction) {
      return;
    }


    switch (this.tooltipType) {
      case TooltipType.CustomDiv: {
        this.setTooltipUsingDiv(nodeOrLinkSelectionD3, getTextFunction);
        break;
      }
      case TooltipType.Title: {
        this.setTooltipUsingTitle(nodeOrLinkSelectionD3, getTextFunction);
        break;
      }
      case TooltipType.MDtooltip: {
        this.setTooltipUsingMaterialDesignTooltip(nodeOrLinkSelectionD3, getTextFunction);
        break;

      }

      default: {
        console.error('Tooltip method not implemented');
      }

    }

  }

  protected getValidTextForNodeOrEdge(graphNode: any,  getTextFunction: getNodeTextFunction): string {
    const data: MnWorkflowNode = graphNode.data ? graphNode.data : graphNode.target.data;
        let title: string;
        if (data) {
          title = getTextFunction(data);
          if (!this.isNonEmptyString(title)) {
            return null;
          }
          return title;
        }


  }
    protected setTooltipUsingMaterialDesignTooltip(nodeOrLinkSelectionD3: any, getTextFunction: getNodeTextFunction): void {
       console.error('MD tooltip on SVG is not implemented');
    }


  /**
   * insert a <title> in the SVG group
   * @param nodeOrLinkSelectionD3
   * @param {getNodeTextFunction} getTextFunction
   */
  protected setTooltipUsingTitle(nodeOrLinkSelectionD3: any, getTextFunction: getNodeTextFunction): void {

    nodeOrLinkSelectionD3.append('title').html(
      (graphNode: any)  => this.getValidTextForNodeOrEdge(graphNode, getTextFunction)
    );
  }


  /**
   * Does not work nicely - based on a d3 example
   * @param nodeOrLinkSelectionD3
   * @param {getNodeTextFunction} getTextFunction
   */
  protected setTooltipUsingDiv(nodeOrLinkSelectionD3: any, getTextFunction: getNodeTextFunction): void {

    if (! this.tooltipDiv) {
      return;
    }

    const self: NavigationTreeD3Component = this;

      const tooltipDivD3: any = d3.select(this.tooltipDiv.nativeElement);
      tooltipDivD3.style('display', 'inline');

      nodeOrLinkSelectionD3.on('mouseover', function (graphNode: any) {
        const data: MnWorkflowNode = graphNode.data ? graphNode.data : graphNode.target.data;
        const title: string = self.getValidTextForNodeOrEdge(graphNode, getTextFunction);


        tooltipDivD3.transition()
          .duration(200)
          .style('opacity', .9);

        tooltipDivD3.html(title)
          .style('position', 'absolute')
          .style('left', (d3.event.pageX) + 'px')
          .style('top', (d3.event.pageY - 45) + 'px');
      });

      nodeOrLinkSelectionD3.on('mouseout', function (graphNode: any) {
        tooltipDivD3.transition().duration(500)
          .style('opacity', 0);
      });


   }



  public plot(): void {

    // create the D3 svg/group object
    this.svgTopGroup = this.startD3Plot(null, true);

    if (!this.svgTopGroup) {
      return;
    }
    this.update();

  }

  public addNode(parent: MnWorkflowNode, child: MnWorkflowNode): void {

    // TODO: add the new node to its parent

    // if nothing has been plotted before
    if (!this.svgTopGroup) {
      this.plot();
    } else { // otherwise just do an update
      this.update();
    }

  }

  protected isNodeRoot(n: any): boolean {
    // first check if the n is actually a node
    if (n.source || n.target) { // edge?
      return false;
    }

    if (!n.parent) {
      return true;
    }
    return false;
  }
}
