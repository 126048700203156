/**
 * Created by joerg on 7/17/17.
 */

// rxjs
import 'rxjs/Rx';
import { Observable } from 'rxjs/Observable';
import { Observer } from 'rxjs/Observer';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Subject } from 'rxjs/Subject';

// angular
import {Component,ViewChild} from '@angular/core';

//
import {MnWorkflowComponentStatic} from "../../MnWorkflowComponent";
import {MnActionConfigurator} from "../MnActionConfigurator";
import {MnActionConfigurationService} from "../MnActionConfigurationService";
import {MnWorkflow} from "../../MnWorkflow";
import {CtTable} from "../../CtTable/CtTable";
import {MnWorkflowService} from "../../MnWorkflowService";
import {CtXlsExport} from "../../CtTable/CtXlsExport";
import {SkylineD3Component} from "../../d3/skyline-d3/skylined3.component";


@Component({
    selector: 'mn-action-configurator-remove-records',
    template: `
        <mn-action-configurator-frame>

            <mn-action-configurator-row-marks
                    line1
            ></mn-action-configurator-row-marks>

            <mat-form-field line3>
                <mat-select [(value)]="mKeepOrErase">
                    <mat-option [value]="mErase">Remove selected Rows</mat-option>
                    <mat-option [value]="mKeep">Keep only selected Rows</mat-option>
                </mat-select>
            </mat-form-field>

        </mn-action-configurator-frame>

    `,
    styles: []
})
export class RemoveRecords extends MnActionConfigurator {

    private mKeep:any;
    private mErase:any;
    private mKeepOrErase:any;

    constructor(private mAcs:MnActionConfigurationService) {
        super(mAcs);
        this.mKeep = this.mAcs.InitSubject.getValue().Action.data.keep;
        this.mErase = this.mAcs.InitSubject.getValue().Action.data.erase;
        this.mKeepOrErase = this.mErase;
        this.enable(true);
    }

    protected onInit(configuration:any) {
        // not used yet, supposed to be called once when the Configurator is shonw
    };

    protected onStart() {
        this.mAcs.InitSubject.getValue().Action = this.mKeepOrErase;
        this.finish({})
    };

    ngOnDestroy() {
        super.destroy()
    }
}
MnActionConfigurator.Registry.register("REMOVE_RECORDS",new MnWorkflowComponentStatic(RemoveRecords));
//MnActionConfigurator.Registry.register("DELETE_SELECTED_RECORDS",new MnWorkflowComponentStatic(RemoveRecords));
//MnActionConfigurator.Registry.register("REMOVE_RECORDS",new MnWorkflowComponentStatic(RemoveRecords));


