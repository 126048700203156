import {
    Component,
    OnInit,
    Input,
    Output,
    EventEmitter,
    ViewChild,
    ViewChildren,
    QueryList,
    ElementRef,
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
} from '@angular/core';

import {GridOptions,GridApi,ColumnApi,ColDef, ColGroupDef,Column,RowNode} from 'ag-grid/main'

import {renderFullRow} from "../CtStateViewHelpers";


///////////////////////////////////////////////
// interface created with json2ts
export namespace ToxCastEndpoint {

    export interface Citation {
        author: string;
        citation: string;
        citation_id: number;
        doi: string;
        other_id: string;
        other_source?: any;
        pmid: number;
        title: string;
        url: string;
    }

    export interface ReagentCondition {
        culture_or_assay: string;
        name_value: string;
        name_value_type: string;
    }

    export interface AssayInfo {
        analysis_direction: string;
        assay_component_desc: string;
        assay_component_endpoint_desc: string;
        assay_component_endpoint_name: string;
        assay_component_name: string;
        assay_component_target_desc: string;
        assay_desc: string;
        assay_design_type: string;
        assay_design_type_sub: string;
        assay_footprint: string;
        assay_format_type: string;
        assay_format_type_sub: string;
        assay_function_type: string;
        assay_name: string;
        assay_source_desc: string;
        assay_source_long_name: string;
        assay_source_name: string;
        biological_process_target: string;
        burst_assay: string;
        cell_format: string;
        cell_free_component_source?: any;
        cell_growth_mode: string;
        cell_short_name: string;
        citation: Citation[];
        content_readout_type: string;
        detection_technology: string;
        detection_technology_type: string;
        detection_technology_type_sub: string;
        dilution_solvent: string;
        dilution_solvent_percent_max: number;
        export_ready: string;
        fit_all: string;
        gene: any[];
        intended_target_family: string;
        intended_target_family_sub: string;
        intended_target_type: string;
        intended_target_type_sub: string;
        internal_ready: string;
        key_assay_reagent: string;
        key_assay_reagent_type: string;
        key_positive_control: string;
        normalized_data_type: string;
        organism: string;
        organism_id: number;
        parameter_readout_type: string;
        reagent_condition: ReagentCondition[];
        signal_direction: string;
        signal_direction_type: string;
        technological_target_type: string;
        technological_target_type_sub: string;
        technology: any[];
        timepoint_hr: string;
        tissue: string;
    }

    export interface DataSource {
        data_src: string;
        db_name: string;
        db_version: string;
        url: string;
    }

    export interface RootObject {
        assay_component_endpoint_name: string;
        assay_info: AssayInfo;
        data_source: DataSource[];
        hit_call: string;
        hit_call_binary: string;
        mod_ga: string;
        mod_tp: string;
        nept_type: string;
    }

}




enum FIELDS {
    ASSAY_NAME = 'Assay Component Endpoint Name',
    ASSAY_DESCRIPTION = 'Assay Description',
    TARGET_FAMILY = "Intended Target Family",
    ENDPOINT_TYPE = "Endpoint Type",

    NUMERIC_ENDPOINT_GROUP ="Numeric Endpoint",

    MOLDGA = "modlGa",
    MODLTP = "modlTp",

    HIT_CALL = "Hit Call",
    HIT_CALL_BINARY = "Hit Call (binary)",

    DATA_SOURCE = "Data Source",
    DATABASE = "Database",
    URL = "URL"


}

// ag grid settings based on the master table in MnStateView
@Component({
    selector: 'ct-compound-toxcast-endpoints',
    template: `<ag-grid-angular 
    style="width: 100%; height: 100%;" 
    [ngClass]="'ag-workflow'"
     [gridOptions]="mAgGridOptions"
     [rowData]="rowData" 
    [columnDefs]="columnDefs"
    >
</ag-grid-angular>`,
    styles:[]
})

export class CtCompoundDetailToxcastEndpointsComponent {
    tcEndpointData: ToxCastEndpoint.RootObject[];
    columnDefs: ColDef[]|ColGroupDef[];
    rowData: any[];


    private mAgGridOptions:GridOptions= {
        debug: true,
        enableSorting: true,
        enableFilter: true,
        enableColResize: true,
        /*
        getRowHeight: (params) => {
            if (params.data.footer) {
                return this.mRowheightFooter;
            } else if (params.data.is_virtual) {
                return 32;
            } else
            {
                return this.mRowHeight;
            }
        },
        */
        //isFullWidthCell: (row_node:RowNode) => this.isFullWidthCell(row_node),
        // fullWidthCellRenderer: (params) => {
        //     return renderFullRow(params,this.mText);
        // },
        // onGridReady: () => this.onGridReady(),
        //onColumnResized: (data) => this.onColumnResized(data),
        /*onGridReady: function() {
            gridOptions.api.addGlobalListener(function(type, event) {
                if (type.indexOf('column') >= 0) {
                    console.log('Got column event: ', event);
                }
            });
        }*/
    };

    @Input('tcEndpointData')
    set setEndpointData(data: ToxCastEndpoint.RootObject[]) {
        this.tcEndpointData = data;
        this.buildAgGrid();
    }

    protected buildAgGrid() {

        this.columnDefs = [
            {headerName: FIELDS.ASSAY_NAME, field: FIELDS.ASSAY_NAME},



            {headerName: FIELDS.ASSAY_DESCRIPTION, field: FIELDS.ASSAY_DESCRIPTION,
                // found on the toxcast web site
            headerTooltip:`US Environmental Protection Agency Sequence Alignment to Predict Across Species Susceptibility (SeqAPASS) output corresponding to the assay protein target. Data provides initial predictions of chemical susceptibility across species based on default settings in evaluating protein sequence/structural similarity compared to the  assay’s protein target and model organism. Corresponding NCBI protein accession links to folders for Level 1  (primary amino acid sequence comparisons) and Level 2 (functional domain comparisons) data. Each level of the SeqAPASS evaluation provides an additional line of evidence for predicting cross species susceptibility. Data is presented using default SeqAPASS settings and may require further evaluation within the SeqAPASS tool.`,
                tooltipField : FIELDS.ASSAY_DESCRIPTION

            },


            {headerName: FIELDS.TARGET_FAMILY, field: FIELDS.TARGET_FAMILY,
                headerTooltip: `Biological grouping of assays (e.g., GPCR, Nuclear Receptor)`  // found on the toxcast web site

            },

            {
                headerName: FIELDS.NUMERIC_ENDPOINT_GROUP, children: [
                    {headerName: FIELDS.ENDPOINT_TYPE, field: FIELDS.ENDPOINT_TYPE},

                    {headerName: FIELDS.MOLDGA, field: FIELDS.MOLDGA, type: 'number', headerTooltip: 'winning AC50 model'},
                    {headerName: FIELDS.MODLTP, field: FIELDS.MODLTP, type: 'number', headerTooltip: 'modeled top of the curve'},
                ]
            },




            {headerName: FIELDS.HIT_CALL, field: FIELDS.HIT_CALL},
            {headerName: FIELDS.HIT_CALL_BINARY, field: FIELDS.HIT_CALL_BINARY,
                headerTooltip: "Binary hit call (INACTIVE = not hit, ACTIVE = hit)"// found on the toxcast web site

            },

            {headerName: FIELDS.DATA_SOURCE, field: FIELDS.DATA_SOURCE},

            {headerName: FIELDS.DATABASE, field: FIELDS.DATABASE},

            {headerName: FIELDS.URL, field: FIELDS.URL},

        ];

        const rowData = [];

        for( let eachEndpointData of  this.tcEndpointData) {
            let row = {};
            row[FIELDS.ASSAY_NAME] =  eachEndpointData.assay_component_endpoint_name;

            if(eachEndpointData.assay_info) {
                row[FIELDS.ASSAY_DESCRIPTION] = eachEndpointData.assay_info.assay_desc;
                row[FIELDS.TARGET_FAMILY] = eachEndpointData.assay_info.intended_target_family;
            }

            row[FIELDS.ENDPOINT_TYPE] = eachEndpointData.nept_type;

            // BE data is string
            row[FIELDS.MODLTP] = Number(eachEndpointData.mod_tp);
            row[FIELDS.MOLDGA] = Number(eachEndpointData.mod_ga);

            row[FIELDS.HIT_CALL] = Number(eachEndpointData.hit_call);
            row[FIELDS.HIT_CALL_BINARY] = eachEndpointData.hit_call_binary;

            if(eachEndpointData.data_source && eachEndpointData.data_source[0]) {
                row[FIELDS.DATABASE] = eachEndpointData.data_source[0].db_name;
                row[FIELDS.DATA_SOURCE] = eachEndpointData.data_source[0].data_src;
                row[FIELDS.URL] = eachEndpointData.data_source[0].url;

            }

            rowData.push(row)

        }

        this.rowData = rowData;

    }

}

