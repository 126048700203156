/**
 * Created by joerg on 7/17/17.
 */

// angular
import {Component,Directive,Input} from '@angular/core';
import { AbstractControl,Validator,ValidatorFn,NG_VALIDATORS } from '@angular/forms';

//
import {MnWorkflowComponentStatic} from "../../MnWorkflowComponent";
import {MnActionConfigurator} from "../MnActionConfigurator";
import {MnActionConfigurationService} from "../MnActionConfigurationService";
import {MnWorkflowService} from "../../MnWorkflowService";
import {CtTable} from "../../CtTable/CtTable";

export function forbiddenNameValidator(names:string[],exclude:string): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} => {
        if (control.value == exclude) return null;
        const forbidden = names.indexOf(control.value) >= 0;
        return forbidden ? {'forbiddenNames': {value: control.value}} : null;
    };
}

@Directive({
    selector: '[mn-action-configurator-add-data-block-forbidden-column-name]',
    providers: [{provide: NG_VALIDATORS, useExisting: AddDataBlockForbiddenColumnName, multi: true}]
})
export class AddDataBlockForbiddenColumnName implements Validator {
    @Input('mn-action-configurator-add-data-block-forbidden-column-name') forbiddenNames: string[];
    @Input('my-title') exclude: string = "";

    validate(control: AbstractControl): {[key: string]: any} {
        //console.log(control);
        return this.forbiddenNames ? forbiddenNameValidator(this.forbiddenNames, this.exclude)(control)
            : null;

    }
}

@Component({
    selector: 'mn-action-configurator-add-data-block',
    template: `
        <mn-action-configurator-frame>

            <div line1>
                <mat-form-field line1 style="width: 100%; margin-top: 8px;">
                    <input matInput placeholder="Column Group Name" [(ngModel)]="test" (ngModelChange)="onGroup($event)" [matAutocomplete]="auto_group">
                </mat-form-field>

                <mat-autocomplete #auto_group="matAutocomplete" [displayWith]="displayFn">
                    <mat-option *ngFor="let group of mUserGroups" [value]="group">
                        {{ group.ident }}
                    </mat-option>
                </mat-autocomplete>
            </div>
            
            <div line2 style="padding: 6px 0;">
                <button mat-button (click)="onAddColumn()">Add Column</button>
            </div>
            <div scroll>
                <div style="height: 8px;"></div>
                <div *ngFor="let column of mConfiguration.columns; let i = index">
                    <mat-form-field>
                        <input 
                                matInput 
                                placeholder="Column Name" 
                                [(ngModel)]="column.title" 
                                [disabled]="!column.editable" 
                                required 
                                #title_model="ngModel" 
                                [mn-action-configurator-add-data-block-forbidden-column-name]="mForbiddenColumnNames"
                                [my-title]="column.title"
                        >
                        <mat-error *ngIf="title_model.errors && title_model.errors.required">
                            Column Name is <strong>required</strong>
                        </mat-error>
                        <mat-error *ngIf="title_model.errors && title_model.errors.forbiddenNames">
                            Column Name <strong>already exists</strong>
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field style="width: 140px;">
                        <mat-select placeholder="Data Type" [(value)]="column.value_type" [disabled]="!column.editable">
                            <mat-option value="string">text</mat-option>
                            <mat-option value="int">integral number</mat-option>
                            <mat-option value="float">floating point number</mat-option>
                            <mat-option value="boolean">binary</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <button mat-icon-button *ngIf="column.editable" (click)="onRemoveColumn(i)"><i class="far fa-times"></i></button>
                </div>
            </div>
            
        </mn-action-configurator-frame>
     `,
    styles: [],
})
export class AddDataBlock extends MnActionConfigurator {

    private test;
    private mUserGroups:CtTable.Node[] = []
    private mUserColumns:any[] = [];
    private mConfiguration:AddDataBlock.Configuration = new AddDataBlock.Configuration("Group 1",[]);
    private mForbiddenColumnNames:string[] = [];

    constructor(acs:MnActionConfigurationService, private mWorkflowService:MnWorkflowService) {
        super(acs);
        this.enable(false);
    }

    protected onInit(configuration:any) {
        //this.mConfiguration.properties = configuration.properties ? configuration.properties : [];

        this.mUserGroups = this.mWorkflowService.Table.findNodes((node:CtTable.Node,level?:number) => {
            if (node.asis && node.type == 'group' && level == 1) {
                let children:CtTable.Node[] = this.mWorkflowService.Table.findNodesStartFrom(node,(child:CtTable.Node,level?:number) => {
                    //console.log(child);
                    return child.editable;
                });
                /*let children = this.mWorkflowService.Table.findNodes((node:CtTable.Node,level?:number) => {
                    return node.editable && node.key && node.key.startsWith('p_');
                });*/
                console.log(children);
                if (children.length > 0) return true;
            };
            return false;
        });

        this.mUserColumns = this.mWorkflowService.Table.findNodes((node:CtTable.Node,level?:number) => {
            return node.editable && node.key && node.key.startsWith('p_');
        })
    };

    protected onStart() {
        this.finish(this.mConfiguration);
    };

    private updateEnabled() {
        if (this.mConfiguration.group.length == 0) {
            this.enable(false);
            return;
        }
        this.enable(this.mConfiguration.columns.filter((column)=>{
            return column.editable && column.title.trim() != '';
        }).length > 0);
    }

    private updateForbidden() {
        this.mForbiddenColumnNames = this.mConfiguration.columns.map((column)=>{
            return column.title;
        });
    }

    private onGroup(group) {
        console.log(group);
        if (group.asis) {
            let children:CtTable.Node[] = this.mWorkflowService.Table.findNodesStartFrom(group,(node:CtTable.Node,level?:number) => {
                return node.editable;
            });
            this.mConfiguration.group = group.ident;
            this.mConfiguration.columns = children.map((column) => {
                console.log(column.type);
                return new AddDataBlock.Configuration.Column(
                    column.ident,
                    AddDataBlock.Configuration.Column.lookupValueType(column.type),
                    AddDataBlock.Configuration.Column.StorageClass.Volatile,
                    false)
            });
            this.updateForbidden();
            console.log(this.mConfiguration);
            this.updateEnabled();
        } else {
            this.mConfiguration.group = group;
        }
    }

    private onAddColumn() {
        this.mConfiguration.columns.push(new AddDataBlock.Configuration.Column(
            "Column "+(this.mConfiguration.columns.length+1),
            AddDataBlock.Configuration.Column.ValueType.String,
            AddDataBlock.Configuration.Column.StorageClass.Volatile,
            true
        ));
        this.updateForbidden();
        this.updateEnabled();
    }

    private onRemoveColumn(index:number) {
        this.mConfiguration.columns.splice(index,1);
    }

    displayFn(group): string | undefined {
        console.log(group);
        if (group && group.asis) {
            return group.ident;
        }
        return group;
    }
    ngOnDestroy() {
        super.destroy()
    }
}
export namespace AddDataBlock {
    export class Configuration {
        constructor(public group:string, public columns:Configuration.Column[]){}
    }
    export namespace Configuration {
        export class Column {
            constructor(public title:string, public value_type:Column.ValueType, public storage_class:Column.StorageClass, public editable:boolean) {}
        }
        export namespace Column {
            export enum ValueType {
                String = 'string',
                Integer = 'integer',
                Float = 'float',
                Boolean = 'boolean'
            }
            export enum StorageClass {
                Public = 'public',
                Private = 'private',
                Volatile = 'volatile',
            }
            export function lookupValueType(value_type:string):ValueType {
                if (value_type == ValueType.String) return ValueType.String;
                else if (value_type == ValueType.Integer) return ValueType.Integer;
                else if (value_type == ValueType.Float) return ValueType.Float;
                else if (value_type == ValueType.Boolean) return ValueType.Boolean;
                return null;
            }
        }
    }
}
MnActionConfigurator.Registry.register("ADD_DATA_BLOCK",new MnWorkflowComponentStatic(AddDataBlock));
