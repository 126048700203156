/**
 * Created by joerg on 7/17/17.
 */

// rxjs
import 'rxjs/Rx';
import { Observable } from 'rxjs/Observable';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Subject } from 'rxjs/Subject';
import { Subscription } from 'rxjs/Subscription';

// angular
import {Component,Input,HostListener,Output,EventEmitter} from '@angular/core';

//
import {Backend} from "./BackendInterfaces";
import {CtStateViewService} from "./CtStateViewService";

export abstract class CtStateViewComponent {

    private mChangeSubscription:Subscription;

    constructor(protected mService:CtStateViewService) {

        this.mChangeSubscription = this.mService.StateObservable.subscribe(data => this.onStateChange(data))

    }

    protected abstract onStateChange(state:Backend.FullState);

    protected destroy() {
        this.mChangeSubscription.unsubscribe();
    }

}
export namespace CtStateViewComponent {
    export enum GridMode {
        Full = "Full",
        Partial = "Partial",
        Hidden = "Hidden"
    }
    export type GridSelect = number;
    export interface Specification {
        Name:string;
        GridMode:GridMode;
        GridSelect?:GridSelect;
        Component?:any;
    }
}
export interface CtStateViewComponentSpecificationMap {
    [key:string]:CtStateViewComponent.Specification;
}

export let ct_state_view_components:CtStateViewComponentSpecificationMap = {
    /*'CtStateViewComponentJson': {
        Name: "CtStateViewComponentJson",
        GridMode: CtStateViewComponent.GridMode.Hidden,
        Component: {lib: '@ct/dev', comp: 'CtStateViewComponentJson'}
    },
    'CtStateViewComponentRawTable': {
        Name: "CtStateViewComponentTable",
        GridMode: CtStateViewComponent.GridMode.Hidden,
    },*/
    'CtStateViewComponentFullTable': {
        Name: "CtStateViewComponentTable",
        GridMode: CtStateViewComponent.GridMode.Full,
    },
    'CtStateViewComponentSummary': {
        Name: "CtStateViewComponentSummary",
        GridMode: CtStateViewComponent.GridMode.Hidden,
        Component: {lib: '@ct/dev', comp: 'CtStateViewComponentSummary'}
    },
    /*'CtStateViewComponentDetails': {
        Name: "CtStateViewComponentDetails",
        GridMode: CtStateViewComponent.GridMode.Partial,
        Component: {lib: '@ct/dev', comp: 'CtStateViewComponentDetails'}
    },
    'CtStateViewComponentLeaflet': {
        Name: "CtStateViewComponentLeaflet",
        GridMode: CtStateViewComponent.GridMode.Partial,
        Component: {lib: '@ct/dev', comp: 'CtStateViewComponentLeaflet'}
    },*/

};