/**
 * Created by joerg on 7/17/17.
 */

// rxjs
import 'rxjs/Rx';
import { Observable } from 'rxjs/Observable';
import { Observer } from 'rxjs/Observer';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Subject } from 'rxjs/Subject';

// angular
import {Component,ViewChild} from '@angular/core';

//
import {MnWorkflowComponentStatic} from "../../MnWorkflowComponent";
import {MnActionConfigurator} from "../MnActionConfigurator";
import {MnActionConfigurationService} from "../MnActionConfigurationService";
import {MnWorkflow} from "../../MnWorkflow";
import {CtTable} from "../../CtTable/CtTable";
import {MnWorkflowService} from "../../MnWorkflowService";
import {CtXlsExport} from "../../CtTable/CtXlsExport";
import {SkylineD3Component} from "../../d3/skyline-d3/skylined3.component";

export class AddAssesmentConfiguration {
    constructor(public name:string, public columns:string[], public rows:string[]) {}
    public valid():boolean {
        return this.name.trim().length > 0 && this.columns.length > 0 && this.rows.length > 0;
    }
}

export class AddAssessmentRunner extends MnWorkflow.ActionConfiguration.Runner {
    constructor(private mWorkflowService:MnWorkflowService, private mConfiguration:AddAssesmentConfiguration) {
        super();
    }
    public run() {
        console.log("Adding Assessment Table",this.mConfiguration);

        let assessment_s = this.mWorkflowService.getStateSetting('assessment');
        let assessment_w = this.mWorkflowService.getWorkflowSetting('assessment');
        if (!assessment_w) {
            assessment_w = {
                count: 1,
                data: {
                    '1': this.mConfiguration
                }
            }
        } else {
            assessment_w.count = assessment_w.count+1;
            assessment_w.data[assessment_w.count+''] = this.mConfiguration;
        }
        if (!assessment_s) {
            assessment_s = ['1'];
        } else {
            assessment_s.push(assessment_w.count+'');
        }

        this.mWorkflowService.addWorkflowSetting('assessment',assessment_w,()=>{
            this.mWorkflowService.addStateSetting('assessment',assessment_s,()=>{
                this.done();
                this.mWorkflowService.reload();
            });
        });
    }
}

@Component({
    selector: 'mn-action-configurator-add-assessment',
    template: `
        <mn-action-configurator-frame [scroll]="false">
            <div line2>
                Select Summary Table:
            </div>
            <mat-form-field line3>
                <mat-select [(value)]="mSummaryIndex" (valueChange)="enable($event  >= 0)">
                    <mat-option [value]="-1">None Selected</mat-option>
                    <mat-option *ngFor="let summary_id of mSummariesS" [value]="summary_id">{{mSummariesW.data[summary_id].name}}</mat-option>
                </mat-select>
            </mat-form-field>

        </mn-action-configurator-frame>

    `,
    styles: []
})
export class AddAssessment extends MnActionConfigurator {

    private mSummariesS:any[] = [];
    private mSummariesW:any = {};
    private mSummaryIndex:number = -1;
    constructor(private mAcs:MnActionConfigurationService, private mWorkflowService:MnWorkflowService) {
        super(mAcs);
        this.enable(false);
        this.mSummariesW = this.mWorkflowService.getWorkflowSetting('summary');
        if (!this.mSummariesW.data) { return }
        this.mSummariesS = this.mWorkflowService.getStateSetting('summary').filter((s)=>{
            return this.mSummariesW.data[s];
        });
        console.log("this.mSummariesW",this.mSummariesW);
        console.log("this.mSummariesS",this.mSummariesS);
    }

    protected onInit(configuration:any) {
        // not used yet, supposed to be called once when the Configurator is shonw
    };

    protected onStart() {
        let configuration = new AddAssesmentConfiguration(this.mSummariesW.data[this.mSummaryIndex].name, this.mSummariesW.data[this.mSummaryIndex].columns,  this.mSummariesW.data[this.mSummaryIndex].rows);
        this.finish(configuration,new AddAssessmentRunner(this.mWorkflowService,configuration));
    };

    ngOnDestroy() {
        super.destroy()
    }
}
MnActionConfigurator.Registry.register("CREATE_ASSESSMENT_TABLE",new MnWorkflowComponentStatic(AddAssessment));


