import {CtTable} from "./CtTable"
import {init_skyline} from "./CtTableSkyline"
import {CtXlsExport} from "./CtXlsExport";
import {MnTableView} from "./MnTableView";

export const ALL_TABLE = [
    CtTable,
    CtXlsExport,
    MnTableView
];

export * from "./CtTable";
export * from "./CtXlsExport";
export * from "./MnTableView";
export function init_table() {
    init_skyline();
}