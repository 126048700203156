import {BitArray} from "./BitArray";

export function tanimotoSimilarity(A, B) {
    let AandB = BitArray.and(A, B);
    let M11 = BitArray.count(AandB);
    let M01 = BitArray.count(BitArray.xor(AandB, B));
    let M10 = BitArray.count(BitArray.xor(AandB, A));
    return M11 / (M01 + M10 + M11);
}

export function tanimotoDistance(A, B) {
    let AandB = BitArray.and(A, B);
    let M11 = BitArray.count(AandB);
    let M01 = BitArray.count(BitArray.xor(AandB, B));
    let M10 = BitArray.count(BitArray.xor(AandB, A));
    return (M01 + M10) / (M01 + M10 + M11);
}

export function tanimotoBinarySimilarity(A, B) {
    return tanimotoSimilarity(BitArray.parseBinaryString(A), BitArray.parseBinaryString(B));
}

export function tanimotoBinaryDistance(A, B) {
    return tanimotoDistance(BitArray.parseBinaryString(A), BitArray.parseBinaryString(B));
}

export function valueSimilarity(A, B) {
    const union = new Set(A.concat(B));
    A = new Set(A);
    B = new Set(B);
    let M11 = 0;
    let M01 = 0;
    let M10 = 0;

    // try to remove an error message
    // BB: not sure this works
    union.forEach( key => {
      if(A.has(key)) {
            if(B.has(key)) {
                M11++;
            } else {
                M10++;
            }
        } else if(B.has(key)) {
            M01++;
        }
  });


   /*
    for(let key of <any[]>union) {
        if(A.has(key)) {
            if(B.has(key)) {
                M11++;
            } else {
                M10++;
            }
        } else if(B.has(key)) {
            M01++;
        }
    }
    */
    return M11 / (M01 + M10 + M11);
}

export function valueDistance(A, B) {
    return 1 - valueSimilarity(A, B);
}
