/**
 * Created by joerg on 7/17/17.
 */

// rxjs
import 'rxjs/Rx';
import { Subject } from 'rxjs/Subject';

// angular
import {Component,Input,Output,EventEmitter,ViewChild} from '@angular/core';
import {MnBackend} from "@mn/core";
import {CustomStandardization, CustomStandardizationInstance} from "../../../utils/custom_standardization";
import {FakeChemotypeProfiles} from "../../../fake/fake_chemotype_profiles";

@Component({
    selector: 'mn-action-configurator-list',
    template: `
        <mat-selection-list #list  dense (selectionChange)="onChange($event)">
            <ng-container *ngFor="let item of mItems">
                <h3 *ngIf="item.group" mat-subheader [innerHtml]="item.label"></h3>
                <mat-list-option 
                        *ngIf="item.option" 
                        [value]="item.value"
                        [selected]="item.selected"
                        class="filter_grayscale">
                    <span>
                        <span [innerHtml]="item.label"></span>&nbsp;&nbsp;
                        <ct-help-link *ngIf="item.help" [path]="item.help"></ct-help-link>
                    </span>
                </mat-list-option>
            </ng-container>
        </mat-selection-list>
    `,
    styles: [],
})
export class MnActionConfiguratorList {

    @Input()
    get items():MnActionConfiguratorList.Item[] { return this.mItems; }
    set items(v:MnActionConfiguratorList.Item[]) { this.mItems = v; }
    private mItems:MnActionConfiguratorList.Item[];

    @Output() selection = new EventEmitter();
    @ViewChild('list') mList:any;

    constructor() {}

    private onChange(e) {
        this.selection.emit(this.mList.selectedOptions.selected.map(v => v.value));
    }

    public selectAll() {
        this.mList.selectAll();
        this.selection.emit(this.mList.selectedOptions.selected.map(v => v.value));
    }

    public deselectAll() {
        this.mList.deselectAll();
        this.selection.emit(this.mList.selectedOptions.selected.map(v => v.value));
    }

    public count():number {
        return this.mList.selectedOptions.selected.length;
    }

}
export namespace MnActionConfiguratorList {
    export interface Item {
        label:string;
        group?:boolean;
        option?:boolean;
        selected?:boolean;
        value?:any;
    }
}

@Component({
    selector: 'mn-action-configurator-list-controller',
    template: `
        <div style="display: flex; line-height: 36px; padding: 6px 0;">
            <span [innerHtml]="mLabel"></span>
            <span style="flex: 1 1 auto"></span>
            <button mat-button (click)="onSelectAll.next()">Select All</button>        
            <button mat-button (click)="onDeselectAll.next()">Deselect All</button>
        </div>
    `,
    styles: [`
        :host {
            display: block;
        }
    `],
})
export class MnActionConfiguratorListController {

    @Input()
    get label():string { return this.mLabel; }
    set label(v:string) { this.mLabel = v; }
    private mLabel:string;

    @Output() onSelectAll = new EventEmitter();
    @Output() onDeselectAll = new EventEmitter();

    constructor() {}
}
export namespace MnActionConfiguratorList {
    export interface Item {
        label:string;
        group?:boolean;
        option?:boolean;
        selected?:boolean;
        value?:any;
    }
}

@Component({
    selector: 'mn-action-configurator-property-list',
    template: `
        <mn-action-configurator-list
                scroll
                #props
                line3
                [items]="mProps"
                (selection)="onPropSelection($event)"
        ></mn-action-configurator-list>
    `,
    styles: [`
        :host {
            display: block;
        }
    `],
})
export class MnActionConfiguratorPropertyList {

    protected onDestroy:Subject<void> = new Subject();
    private mProps:MnActionConfiguratorList.Item[];

    @Input()
    get selection():string[] { return this.mSelection; }
    set selection(v:string[]) { this.mSelection = v; }
    private mSelection:string[];


    // presently there are two kinds defined:
    /// - molecular property computation
    /// - chemotype profiles
    @Input()
    get kind():string { return this.mKind; }
    set kind(v:string) { this.mKind = v; }
    private mKind:string = "";

    @ViewChild('props') mList:any;
    @Output() selectionChange = new EventEmitter();

    constructor(private mBackend: MnBackend) {


    }

    ngAfterViewInit() {
        //future: use a configuration object


        /*
        To get a list of available computation methods:
        /computations/comp-profile/
        one has to find the correct alias, e.g. chemotype_profiles

          {
    "id": 5,
    "methods": [
      {
        "url": "/computations/comp-method/36/",
        "cm_logic_id": "com.mn-am.ChemotypeProfiler.toxprints",
        "display_name": "Toxprint",
        "provider": "ChemotypeProfiler"
      },
      {
        "url": "/computations/comp-method/37/",
        "cm_logic_id": "com.mn-am.ChemotypeProfiler.livertox_alerts",
        "display_name": "LJMU Livertox Alerts",
        "provider": "ChemotypeProfiler"
      }
    ],
    "alias": "chemotype_profiles",
    "title": "Builtin ChemoType Profiles",
    "description": "",
    "owner": "",
    "created": "2018-05-18T20:03:24.738579+02:00",
    "is_builtin": true
  }

         */


        const cstd: CustomStandardization = CustomStandardizationInstance; // preferrred properties selection by Jim


        const molPropertiesUrl = '/computations/comp-method/?cmprofile__alias=corina_symphony_properties&format=json' ; //URL for computing molecular properties
        const chemotypeProfilesUrl = '/computations/comp-method/?cmprofile__alias=chemotype_profiles&format=json';

        // choose the right URL according th the kind
        const url = this.mKind.length > 0 ? chemotypeProfilesUrl : molPropertiesUrl;

        if( true || this.mKind.length == 0) {
            this.mBackend.get(url)
                .map(data => data.json())
                .takeUntil(this.onDestroy)
                .subscribe(data => {
                    this.mProps = data.map(v => {
                        return {
                            option: true,
                            label: v.display_name,
                            selected: cstd.isFavorite(v.display_name), // e.g ASA
                            value: v.cm_logic_id
                        }
                    });
                    //console.log(data);
                    // let the event subscribers know abouit the initial selection
                    this.onPropSelection(this.mProps.filter(propOject => propOject.selected).map(propOject => propOject.value));
                });

        } else
            {

                const data = new FakeChemotypeProfiles().getProfilesDefinitions();

                this.mProps = data.map(v => {
                    return {
                        option: true,
                        label: v.display_name,
                        selected: false,
                        value: v.cm_logic_id
                    }
                });

            }
    }

    onPropSelection(propertyIds:string[]) {
        //fix for one issue: the order of the properties follows the user clicking order
        const propertyIdsWithOriginalOrder: string[] = this.mProps.filter(propOject=>propertyIds.indexOf(propOject.value) != -1 ).map(propOject=>propOject.value);
        this.selectionChange.emit(propertyIdsWithOriginalOrder);
    }

    public selectAll() {
        if (!this.mList) return;
        this.mList.selectAll();
    }

    public deselectAll() {
        if (!this.mList) return;
        this.mList.deselectAll();
    }

    public count():number {
        if (!this.mList) return;
        return this.mList.count();
    }
}
