import {MnWorkflow} from "../MnWorkflow";
import {Backend} from "../BackendInterfaces";

export class MnActionAddDataColumn extends MnWorkflow.ActionAdapter{

    protected transform(workflow:Backend.Workflow, state:Backend.FullState, actions:MnWorkflow.Action[]):MnWorkflow.Action[] {
        return actions.filter((action)=>{
            if (action.title == "ADD_DATA_COLUMN") {
                return false;
            }
            return true;
        });
    }
}
MnWorkflow.ActionAdapter.Registry.register('ADD_DATA_COLUMN',new MnActionAddDataColumn());
