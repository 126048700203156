import {Component, Input} from "@angular/core"
import {CtComplexValueView} from "../CtValueViews/CtComplexValueView";

import {DataAggregator} from "../../../utils/data-aggregator";

// JSON definition generated with the help of json2ts - http://json2ts.com/
namespace CompoundDBClassificationDetailJSON {

    export interface Attribute {
        name: string;
        domain: string;
        context: string;
        is_public: boolean;
        visible: boolean;
        comments: string;
    }

    export interface Classifier {
        Title: string;
        Version?: any;
    }

    export interface Data {
        comments?: any;
        is_public: boolean;
        classifier: Classifier;
        classification_value: string;
        classification_type: string; // key for aggregation here
    }

    export interface Value {
        attribute: Attribute;
        data: Data;
        value_type: string;
        source?: any;
        serial: number;
        visible: boolean;
        harvested_from: string;
        registrar?: any;
    }

    export interface RootObject {
        db: string;
        value: Value;
    }

}


class ClassificationTypeAgregator extends DataAggregator<CompoundDBClassificationDetailJSON.RootObject, string> {
    protected getKeyValue(d: CompoundDBClassificationDetailJSON.RootObject): string {
        return d.value.data.classifier.Title + ' ' +d.value.data.classification_type;
    };

    protected getOtherValue(d: CompoundDBClassificationDetailJSON.RootObject): string {
        return d.value.data.classification_value;
    }

    protected valueIsEqual(v1: string, v2: string) {
        return v1 == v2;
    }


}

@Component({
    selector:'ct-compound-details-db-classification',
    templateUrl:'./CtCompoundDetailsDBClassification.html',
    styleUrls: ['./CtCompoundDetailsDBClassification.css'],
})
export class CtCompoundDetailsDBClassification {

    mClassifications:any[] = null; // input
    mSelectedClassifications: any = null;

    @Input('classifications') set classifications(value: any) {
        this.mClassifications = value;
       // console.log(value);

        const cta = new ClassificationTypeAgregator();
        const parts = cta.generateParts(value);

        if(parts && parts.length > 0) {
           // console.log(parts);
            this.mSelectedClassifications = parts;
        } else {
            this.mSelectedClassifications = [];
        }


    }

    constructor(){
       // console.log('constructor');
    }


    /** aggregation by classification_type*/
    protected aggregateData() {
        //let c = new CtComplexValueView();
    }

}

