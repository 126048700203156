/**
 * Created by Jongjin on 24/8/17.
 */
import {Component, Input} from "@angular/core"

@Component({
    selector:'ct-compound-details-db-pafa-safety-evaluation',
    templateUrl:'./CtCompoundDetailsDBSafetyEvaluation.html',
    styleUrls: ['./CtCompoundDetailsDBSafetyEvaluation.css']
})
export class CtCompoundDetailsDBSafetyEvaluation {

    mRecords = [];
    mPafaLength = 0;
    //mClassificationLength = 0;

    activeSubTabIdx = 1;

    mCompoundPafaDetails:any = null;
    mUnusedPODsRecords:any = null;

    mLeftTabs = [
        {
            label:'By ChemTunes',
            chip:'0',
            active:true
        },
        {
            label:'PAFA Safety DB',
            chip:'0',
            active:false
        },{
            label:'Additional PODs',
            chip:'0',
            active:false
        }
    ];

    @Input('compoundPafaDetails') set compoundPafaDetails(value:any) {
        this.mCompoundPafaDetails = value;

        if (this.mCompoundPafaDetails) {
            this.mPafaLength = this.mCompoundPafaDetails.data.filter(r => r.attribute.name==='PAFA Chemical Information').length;
            //this.mClassificationLength = this.mCompoundPafaDetails.data.filter(r => r.attribute.name==='Compound Classification').length;
            this.mLeftTabs[1].chip = this.mPafaLength+'';
        }
    }


    @Input('safetyEvaluation') set safetyEvaluation(value:any) {
        this.setSE(value);
    }

    @Input('compoundUnusedPODsDetails') set compoundUnusedPODsDetails(value:any) {
        //console.log("UUUUU unused PODs UUUUU");
        //console.log(value);
        this.mUnusedPODsRecords = [];
        if (value == null) {
            return;
        }
        var me = this;
        if (value) {
            this.mLeftTabs[2].chip = value.length+'';
            value.forEach(function(row, i) {
                let ret = {
                    'title':'Additional POD Record # '+(i+1) + ' of ' + value.length,
                    'id':row.id,
                    'pod':row.pod
                };
                me.mUnusedPODsRecords.push(ret);
            });
        }
    }

    tabs = [];

    constructor(){

        console.log("SE created");

    }

    onSubTabClick($event){
        this.activeSubTabIdx = $event.index;
    }

    public setSE(value) {
        console.log(value);
        this.mRecords = [];
        if (value == null) {
            return;
        }
        var me = this;
        if(value){
            this.mLeftTabs[0].chip = value.length+'';
            value.forEach(function(row, i){
                let ret = {
                    'title':'Safety Evaluation Record # '+(i+1) + ' of ' + value.length,
                    'riskType' : row.safety_metric_type,
                    'riskValue' : row.safety_metric_value,
                    'productUse' : row.product_use_type,
                    'seOwner' : row.owners[0],
                    'seOwners' : row.owners,
                    'podValue' : row.pod['pod_value'],
                    'podOwner' : row.pod['pod_owner'],
                    'podMethod' : row.pod['pod_method'],
                    'criticalStudy' : row.study.details.label,
                    'noloOwner' : row.study.lo_effect.owner,
                    'originalNoel' : row.study.no_effect.value_with_units,
                    'originalLoael' : row.study.lo_effect.value_with_units,
                    'criticalSites' : row.study.lo_effect.sites,
                    'criticalEffects' : row.study.lo_effect.effects,
                    'comments' : row.comments,
                    'references' : row.references.join(','),
                    'uncertaintyFactors' : row.uncertainty_modifying_factors,
                    'adjustmentFactors' : row.pod.adjustment_factors
                };

                if(ret.uncertaintyFactors.length<1){
                    ret.uncertaintyFactors = null;
                }
                if(ret.adjustmentFactors.length<1){
                    ret.adjustmentFactors = null;
                }

                me.mRecords.push(ret);
            });
        }

    }

}

@Component({
    selector:'ct-compound-details-db-pafa-safety-evaluation-report',
    templateUrl:'./CtCompoundDetailsDBSafetyEvaluationReport.html',
    styleUrls: ['./CtCompoundDetailsDBSafetyEvaluation.css']
})
export class CtCompoundDetailsDBSafetyEvaluationReport{

    constructor(){}

    mRecords = [];
    //mData:any;
    @Input('seData') set seData(value:any){
        //this.mData = value;
        this.setSE(value);
    }

    public setSE(value) {
        console.log(value);
        this.mRecords = [];
        if (value == null) {
            return;
        }
        var me = this;
        if(value){
            value.forEach(function(row, i){
                let ret = {
                    'title':'Safety Evaluation Record # '+(i+1) + ' of ' + value.length,
                    'riskType' : row.safety_metric_type,
                    'riskValue' : row.safety_metric_value,
                    'productUse' : row.product_use_type,
                    'seOwner' : row.owners[0],
                    'seOwners' : row.owners,
                    'podValue' : row.pod['pod_value'],
                    'podOwner' : row.pod['pod_owner'],
                    'podMethod' : row.pod['pod_method'],
                    'criticalStudy' : row.study.details.label,
                    'noloOwner' : row.study.lo_effect.owner,
                    'originalNoel' : row.study.no_effect.value_with_units,
                    'originalLoael' : row.study.lo_effect.value_with_units,
                    'criticalSites' : row.study.lo_effect.sites,
                    'criticalEffects' : row.study.lo_effect.effects,
                    'comments' : row.comments,
                    'references' : row.references.join(','),
                    'uncertaintyFactors' : row.uncertainty_modifying_factors,
                    'adjustmentFactors' : row.pod.adjustment_factors
                };

                if(ret.uncertaintyFactors.length<1){
                    ret.uncertaintyFactors = null;
                }
                if(ret.adjustmentFactors.length<1){
                    ret.adjustmentFactors = null;
                }

                me.mRecords.push(ret);
            });
        }

    }

}


@Component({
    selector:'ct-compound-details-db-unused-pods',
    template:`
        <div *ngFor="let row of unusedPODsRecords; let i = index;">

            <div class="evaluation-report-block" style="padding: 20px 20px 0px;">
                <h3 style="margin: 0; padding: 0;">{{row.title}}&nbsp;&nbsp;-&nbsp;&nbsp;{{row.pod.pod_owner}}</h3>
            </div>

            <div class="evaluation-report-block" style="padding: 20px;">
                <table>
                    <tr class="titles">
                        <td mn-text="ct.details.se.POD_Method">@POD Method@</td>
                        <td mn-text="ct.details.se.POD_Value">@POD Value@</td>
                        <td mn-text="ct.details.se.POD_Owner">@POD Owner@</td>
                    </tr>
                    <tr class="values">
                        <td>{{row.pod.pod_method}}</td>
                        <td>{{row.pod.pod_value}}</td>
                        <td>{{row.pod.pod_owner}}</td>
                    </tr>

                    <!-- Lo effect data -->
                    <tr *ngIf="row.pod.lo_effect">
                        <td colspan="7" style="font-weight: bold; padding-left: 20px; padding-top: 20px;">Lowest-observed effect</td>
                    </tr>
                    <tr><td colspan="7" style="padding-left: 20px; padding-top: 5px;">
                        <table>
                            <tr class="titles">
                                <td mn-text="ct.details.se.LO_Owner">@Owner@</td>
                                <td mn-text="ct.details.se.LO_Type">@Type@</td>
                                <td mn-text="ct.details.se.LO_Value">@Value@</td>
                                <td mn-text="ct.details.se.LO_Sites">@Sites@</td>
                                <td mn-text="ct.details.se.LO_Effects">@Effects@</td>
                            </tr>
                            <tr class="values">
                                <td>{{row.pod.lo_effect.owner}}</td>
                                <td>{{row.pod.lo_effect.type}}</td>
                                <td>{{row.pod.lo_effect.value_with_units}}</td>
                                <td style="text-align:left;"><ul><li *ngFor="let site of row.pod.lo_effect.effects;">{{site}}</li></ul></td>
                                <td style="text-align:left;"><ul><li *ngFor="let effect of row.pod.lo_effect.effects;">{{effect}}</li></ul></td>
                            </tr>
                        </table>
                    </td></tr>

                    <!-- No effect data -->
                    <tr *ngIf="row.pod.no_effect" style="">
                        <td colspan="7" style="font-weight: bold; padding-left: 20px; padding-top: 20px;">No-observed effect</td>
                    </tr>
                    <tr *ngIf="row.pod.no_effect" style="">
                        <td colspan="7" style="padding-left: 20px; padding-top: 5px;">
                            {{row.pod.no_effect.owner}}: {{row.pod.no_effect.type}}: {{row.pod.no_effect.value_with_units}}
                        </td>
                    </tr>

                    <!-- Adjustment factors -->
                    <tr *ngIf="row.pod.adjustment_factors" style="">
                        <td colspan="7" style="font-weight: bold; padding-left: 20px; padding-top: 20px;">Adjustment factors</td>
                    </tr>
                    <tr *ngIf="row.pod.adjustment_factors" style="">
                        <td colspan="7" style="padding-left: 20px; padding-top: 5px;">
                            <div *ngFor="let adfactor of row.pod.adjustment_factors">
                                Domain: {{adfactor.domain}} - Type: {{adfactor.type}} - Subtype: {{adfactor.subtype}} - Value: {{adfactor.value}} - Comments: {{adfactor.comments}}
                            </div>
                        </td>
                    </tr>

                </table>
            </div>

            <div class="evaluation-report-block">
                <div style="margin-bottom: 10px; padding-left: 25px;">
                    <span style="font-weight: bold; font-size:14px;">>Critical study:</span>&nbsp;
                    {{row.pod.critical_study.label}} -
                    ID: <a href="{{row.pod.critical_study.details.url}}">{{row.pod.critical_study.details.study_id}}</a>
                </div>
            </div>

        </div>

    `,
    styleUrls: ['./CtCompoundDetailsDBSafetyEvaluation.css']
})
/* CtDetailsUnusedPODs */
export class CtCompoundDetailsDBUnusedPODs {

    unusedPODsRecords:any = "";

    @Input('compoundUnusedPODsDetails') set compoundUnusedPODsDetails(value) {
        this.unusedPODsRecords = [];
        if (value == null) {
            return;
        }
        var me = this;
        if (value) {
            value.forEach(function(row, i) {
                let ret = {
                    'title':'Additional POD Record # '+(i+1) + ' of ' + value.length,
                    'id':row.id,
                    'pod':row.pod
                };
                me.unusedPODsRecords.push(ret);
            });
        }
    }

    constructor() {
    }


}

/* CtCompoundDetailsAdditionalData */
@Component({
    selector:'ct-compound-details-db-additional-data',
    template:`
    <div class="tox-body" style="margin: 10px;">
        <table class="tox-endpoints-table">
            <thead>
            <th *ngFor="let row of addDataTitles" mn-text="{{row}}" style="padding: 5px;">@{{row}}@</th>
            </thead>
            <tbody>
            <tr *ngFor="let row of addDataRecords" style="border-bottom: 1px solid #ccc;">
                <td style="text-align:left; padding: 5px;" *ngFor="let value of row">{{value}}</td>
            </tr>
            </tbody>
        </table>
    </div>
    `,
    styleUrls: ['./CtCompoundDetailsDBStudy.css']
})

export class CtCompoundDetailsDBAdditionalData {

    addDataTitles:any = "";
    addDataRecords:any = "";

    // true to remove all columns with empty cells
    private removeEmptyColumnsFlag = true;

    // list of property keys and table display names to change display name and to sort columns
    private map={
        "Pharmacokinetics":  
            [["Species", "Species"],
            ["Strain", "Strain"],
            ["Sex", "Sex"],
            ["Route", "Route"],
            ["Cmax (ng eq/mL)", "Cmax (ng eq/mL)"],
            ["Cmax Unit", "Cmax Unit"],
            ["tmax (h)", "tmax (h)"],
            ["AUC0-t (ng eq/mL)", "AUC0-t (ng eq/mL)"],
            ["AUC unit", "AUC unit"],
            ["Dose (mg/kg bw/day)", "Dose (mg/kg bw/day)"],
            ["Protein Binding-%bound", "Protein Binding-%bound"],
            ["Protein Binding-%unbound", "Protein Binding-%unbound"],
            ["T-half", "T-half"],
            ["Database name", "Database Name"],
            ["Data Source", "Data Source"],
            ["Original DataSource", "Original Data Source"],
            ["REFERENCE", "Reference"],
            ["YEAR", "Year"],
            ["Title", "Title"],
            ["Authors", "Authors"]], 
        "Skin absorption": 
            [["Membrane - species", "Membrane - species"],
            ["Sex", "Sex"],
            ["Assay Type", "Assay Type"],
            ["Membrane type", "Membrane type"],
            ["Concentration", "Concentration"],
            ["Concentration Unit", "Concentration Unit"],
            ["Flux", "Flux"],
            ["Flux STDEV", "Flux STDEV"],
            ["Flux Unit", "Flux Unit"],
            ["Steady State Flux (Jss) (µg/cm2 h)", "Steady State Flux (Jss) (µg/cm2 h)"],
            ["Steady State Flux (Jss) STDEV", "Steady State Flux (Jss) STDEV"],
            ["Permeability Coefficient (Kp)", "Permeability Coefficient (Kp)"],
            ["Permeability Coefficient (Kp) STDEV", "Permeability Coefficient (Kp) STDEV"],
            ["Permeability Coefficient (Kp) Unit", "Permeability Coefficient (Kp) Unit"],
            ["Lag Time (min)", "Lag Time (min)"],
            ["Lag Time STDEV (min)", "Lag Time STDEV (min)"],
            ["Lag Time Unit", "Lag Time Unit"],
            ["Partitioning Characteristics (KH)", "Partitioning Characteristics (KH)"],
            ["Partitioning Characteristics (KH) STDEV", "Partitioning Characteristics (KH) STDEV"],
            ["Partitioning Characteristics (KH) Unit", "Partitioning Characteristics (KH) Unit"],
            ["Diffusive Parameter (D/H2) (cm-1)", "Diffusive Parameter (D/H2) (cm-1)"],
            ["Diffusive Parameter (D/H2) STDEV", "Diffusive Parameter (D/H2) STDEV"],
            ["Diffusive Parameter UNIT", "Diffusive Parameter UNIT"],
            ["Reference Citation", "Reference Citation"]]
    };


    @Input('compoundAdditionalDataDetails') set compoundAdditionalDataDetails(value) {
        this.addDataRecords = [];
        this.addDataTitles = [];
        console.log("Additional Data", value);
        if (value.data == null || value.label == null) {
            return;
        }
        var me = this;
        if (value.data) {
            // loop over list of objects

            let newTitles = [];
            if (!this.map[value.label]) {
                console.log("Map for", value.label, "missing in Additional Data");
            }
            this.map[value.label].forEach(function(key, i) {
                if (me.removeEmptyColumnsFlag) {
                    value.data.forEach(function(row, j){
                        if (row[key[0]] != "") {
                            if (me.addDataTitles.indexOf(key[0]) == -1) {
                                me.addDataTitles.push(key[0]);
                                newTitles.push(key[1]);
                            }
                        }
                    });
                } else {
                    me.addDataTitles.push(key[0]);
                    newTitles.push(key[1]);
                }
            });

            value.data.forEach(function(row, i) {
                let ret = [];
                me.addDataTitles.forEach(function(key, j) {
                    ret.push(row[key]);
                });
                me.addDataRecords.push(ret);
            });

            // replace Titles with names from map
            me.addDataTitles = newTitles;
        }
        //console.log("Additional Data records", this.addDataRecords);
    }

    constructor() {

    }
}

