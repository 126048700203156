import {
    Component,
    OnInit,
    Input,
    Output,
    EventEmitter,
    ViewChild,
    ViewChildren,
    QueryList,
    ElementRef,
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
} from '@angular/core';

//import { Subject } from 'rxjs/Subject';

import {
    ChemotypeSingleMatch,
    ChemotypeProfileMatch,
    ChemotypeProfileMatches,
    testdata,
    testmol
} from './chemotypermatcher.models';
import {MatCheckbox} from '@angular/material/checkbox';
import {MnJsmeDepict} from "./MnJsmeDepict";
import {MnStateViewChemotypeProfile} from "../MnStateView/MnStateViewChemotypeProfile";
import {JSMEevent} from "./JSME";

////////////////////////////////////////////////////////////////////////////////////////////////////

@Component({
    selector: 'app-chemotypesinglematchwidget',
    template: `   
  <div [ngClass]="{'highlightCheckBox': isHighlighted}">
  


  <mat-checkbox #checkBox (change)="onCheckBoxEvent($event)">{{chemotypeMatch.title}} {{chemotypeMatch.atoms.length}} atoms</mat-checkbox>
</div>
  `,
    styleUrls: ['./chemotypematcher.component.css'],
})
export class ChemotypeSinglematchwidgetComponent implements OnInit {


    @ViewChild('checkBox') checkBox: MatCheckbox;
    @Input()
    chemotypeMatch: ChemotypeSingleMatch;

    @Input()
    isHighlighted = false;


    @Output()
    selectionChanged = new EventEmitter<ChemotypeSingleMatch>();

    constructor(private cdRef: ChangeDetectorRef) {}

    onCheckBoxEvent(event) {
        this.chemotypeMatch.isSelected = this.checkBox.checked;
        this.selectionChanged.emit(this.chemotypeMatch);
    }

    ngOnInit() {
    }

    public setHighlight(newStatus: boolean) {
        const previousStatus = this.isHighlighted;
        console.log('checkbox newStatus ', newStatus, 'previousStatus', previousStatus);

        if(newStatus != previousStatus) {
            this.isHighlighted = newStatus;
            this.cdRef.detectChanges();
        }

    }
    public toggleChecked() {
        this.setChecked( ! this.checkBox.checked);

    }
    public setChecked(onOf: boolean) {
        this.checkBox.checked = onOf;
        this.cdRef.detectChanges();
        this.onCheckBoxEvent(null); //will update the JSME plot

    }

    public getChecked(): boolean {
        return this.checkBox.checked;
    }

}


////////////////////////////////////////////////////////////////////////////////////////////////////




@Component({
    selector: 'app-chemotypeprofilematch',
    template: `
<table style="width: 100%;height:100%">
  <tr>
  <td style="width: 300px;">
    
    <mn-jsme-depict [height]="320" [width]="300" options="depict,polarnitro,nosearchinchikey,nocontextmenu" #jsme> </mn-jsme-depict>
  

</td>
  <td>
  <div style="overflow-y:auto; max-height:300px; padding: 0.8rem 0.8rem 0.8rem 1.0rem;">
      <div *ngFor="let chemotypeMatch of chemotypeProfileMatch.matches">
        <p style="margin-bottom: 0rem;">
        <app-chemotypesinglematchwidget [chemotypeMatch]="chemotypeMatch" (selectionChanged)="onSelectionChange($event)"></app-chemotypesinglematchwidget>
     
        </p>
      </div>
      </div>
  </td>   
  </tr>
  </table>

  `,
    styleUrls: ['./chemotypematcher.component.css']
})


export class ChemotypeProfileMatchComponent implements AfterViewInit {
    @Input() chemotypeProfileMatch: ChemotypeProfileMatch;
    @ViewChild('jsme') mJsme: MnJsmeDepict;

    @Input()
    get mol(): string {
        return this.mMol;
    }

    set mol(v: string) {
        this.mMol = v;
        this.mJsme.setMol(v)
    }

    private mMol: string;


    // ViewChild is available only after ngAfterViewInit
    @ViewChildren(ChemotypeSinglematchwidgetComponent) matchWidgets: QueryList<ChemotypeSinglematchwidgetComponent>;

    protected setHighligtAllMatchWidgets(onOff: boolean) {
        this.matchWidgets.forEach((eachWidget: ChemotypeSinglematchwidgetComponent) => eachWidget.setHighlight(onOff));
    }

    protected unCheckAllBoxes() {
        this.matchWidgets.forEach((eachWidget: ChemotypeSinglematchwidgetComponent) => eachWidget.setChecked(false));
    }
    protected hasONeOrMoreCheckedBoxes() {
        const found: ChemotypeSinglematchwidgetComponent  = this.matchWidgets.find((eachWidget: ChemotypeSinglematchwidgetComponent) => eachWidget.getChecked());

        return !! found; //convert to boolean?

    }


    /**
     * Return a list of widgets that match all the provided atoms (e.g 1 for a single atom , 2 for a bond)
     * @param {number[]} atoms
     * @returns {ChemotypeSinglematchwidgetComponent[]}
     */
    protected findAllWidgetsMatchingAtoms(atoms: number[]): ChemotypeSinglematchwidgetComponent[] {

        let results:   ChemotypeSinglematchwidgetComponent[];

        results = this.matchWidgets.filter((eachWidget:ChemotypeSinglematchwidgetComponent )=>{
            let count = 0;
            for(const at of atoms) {
                const found: boolean = eachWidget.chemotypeMatch.atoms.indexOf(at.toString()) != -1;// TODO: the backend should provide atom numbers instead of strings
                if(found) count++;
            }

            return count == atoms.length;
        });

        return results;
    }


    ngAfterViewInit(): void {
       // this.mJsme.setOptions('polarnitro');





        this.mJsme.setCallBack("AtomHighlight", (event:JSMEevent)=>{
            //console.log(event.atom);
            this.setHighligtAllMatchWidgets(false);
;           this.findAllWidgetsMatchingAtoms([event.atomE]).forEach((eachWidget: ChemotypeSinglematchwidgetComponent) => eachWidget.setHighlight(true))
        });

        // toggle all checkboxes that match the clicked atom
        this.mJsme.setCallBack("AtomClicked", (event:JSMEevent)=> {


            this.findAllWidgetsMatchingAtoms([event.atomE]).forEach((eachWidget: ChemotypeSinglematchwidgetComponent) => eachWidget.toggleChecked());

        });


        this.mJsme.setCallBack("BondHighlight", (event:JSMEevent)=>{
            //console.log(event.atom);
            this.setHighligtAllMatchWidgets(false);
            this.findAllWidgetsMatchingAtoms(event.atomsE).forEach((eachWidget: ChemotypeSinglematchwidgetComponent) => eachWidget.setHighlight(true))

        });

        // toggle all checkboxes that match the clicked bond
        this.mJsme.setCallBack("BondClicked", (event:JSMEevent)=>{
            this.findAllWidgetsMatchingAtoms(event.atomsE).forEach((eachWidget: ChemotypeSinglematchwidgetComponent) => eachWidget.toggleChecked());


        });


    }


    onSelectionChange(event: any) {

        //const jsme: any = document.jsapplets[this.jsmeCountId];
        //jsme.resetAtomColors(1);
        let color = 0;
        let count = 0;
        const maxColors = this.mJsme.getNumberOfColorsForBackGroundPalette();
        let colorAndAtoms: number[] = [];
        this.matchWidgets.forEach((box: ChemotypeSinglematchwidgetComponent) => {
            //console.log(box.chemotypeMatch.isSelected, box.chemotypeMatch.subgraphId);

            color = (count % maxColors) + 1 ;
            console.assert(color >= 1 && color <= maxColors);
            count ++;

            if (box.chemotypeMatch.isSelected) {

                for (let at of box.chemotypeMatch.atoms) {
                    colorAndAtoms.push(at);
                    colorAndAtoms.push(color);

                }
                color++;


            }

        });
        const atColorCsv: string = colorAndAtoms.join(',');
        this.mJsme.resetAtomColors(0);
        if (atColorCsv) {

            this.mJsme.setAtomBackgroundColors(0, atColorCsv);
        }
        //console.log(atColorCsv);


    }

}

////////////////////////////////////////////////////////////////////////////////////////////////////

// Used by MnStateViewChemotypeProfile

@Component({
    selector: 'app-chemotypematcher',
    templateUrl: './chemotypematcher.component.html',
    styleUrls: ['./chemotypematcher.component.css']
})
export class ChemotypematcherComponent {
    @Input()
    cpMatches: ChemotypeProfileMatches;
    @Input()
    protected isRowSelected = true;
    @Input()
    protected errorMessage = '';
    @Input()
    mol: string;

    // @ViewChildren(ChemotypeSinglematchwidgetComponent) alertCheckBoxes: QueryList<ChemotypeSinglematchwidgetComponent>;


}
