/**
 * Implementation of the WOE combinations rules based on the document
 * "Read-Across Calculation Details and Examples" from Jim.
 *
 * Bruno May 2018
 */

import {ArrayUtils}  from "./sim_dist";

const nonInitValue:number = -1;

export class ProbabilityMass {
    public mP: number = nonInitValue;
    public mN: number = nonInitValue;
    public mU: number = nonInitValue;

    constructor(pos: number, uncertainty: number, neg: number) {
        this.mP = pos;
        this.mN = neg;
        this.mU = uncertainty;
    }

    public inspect(): void {
        console.log('mP=', this.mP);
        console.log('mU=', this.mU);
        console.log('mN=', this.mN);

    }
}


/**
 * Speed optimization: one can do a recursive combination, might be faster
 * 2 at a time
 * pm1 and pm2 => pm12
 *  pm12 and pm3 => pm123
 ...


 instead of
 pm1 and pm2 and pm3 => pm123

 combinatorial is different

 */
export class GroundProbabilityMass  {
    protected  au:ArrayUtils;

    public useRecursive = true; //much faster, same results


    // input
    public pmArray: ProbabilityMass[];

    // computation results
    public qP: number = nonInitValue;
    public qN: number = nonInitValue;
    public qU: number = nonInitValue;
    public qPN: number = nonInitValue;


    constructor( pmArray: ProbabilityMass[]) {
        this.pmArray = pmArray;
        this.au = new ArrayUtils();


    }

    public inspect(): void {
        console.log('qP=', this.qP);
        console.log('qU=', this.qU);
        console.log('qN=', this.qN);

    }

    public compute() : void {
        if(this.useRecursive) {
            this.computeRecursive();
        } else {
            this.computeOneShot();
        }

    }


    // very slow because of combinatorial explosion in method computeGroundProbabilityMass()
    public computeOneShot(): void {
        const allPositives: number [] = this.pmArray.map( (pm:ProbabilityMass)=>pm.mP);
        const allNegatives: number [] = this.pmArray.map( (pm:ProbabilityMass)=>pm.mN);
        const allUncertainties: number [] = this.pmArray.map( (pm:ProbabilityMass)=>pm.mU);


        this.qP = this.computeGroundProbabilityMass(allPositives, allUncertainties, allPositives.length);
        this.qN = this.computeGroundProbabilityMass(allNegatives, allUncertainties, allNegatives.length);

        this.qU = this.au.product(allUncertainties);

        this.qPN = 1 - this.qP - this.qN - this.qU;

    }

    public computeRecursive(): void {
        let pmArrayCopy = this.pmArray.slice(0);
        if(this.qP == nonInitValue) {
            const pm = pmArrayCopy.shift();
            this.qP = pm.mP;
            this.qU = pm.mU;
            this.qN = pm.mN;

        }
        for(let pm of pmArrayCopy) {
            this.addProbabilityMassToMySelf(pm);
        }
    }
    public addProbabilityMassToMySelf(pm:ProbabilityMass): void {

        const uncertainties : number[] = [this.qU, pm.mU];
        this.qP = this.computeGroundProbabilityMass([this.qP, pm.mP], uncertainties, 2);
        this.qN = this.computeGroundProbabilityMass([this.qN, pm.mN], uncertainties, 2);

        this.qU = this.au.product(uncertainties);

        this.qPN = 1 - this.qP - this.qN - this.qU;

    }



    // very slow with large number of values

    protected computeGroundProbabilityMass(positivesOrNegatives: number[], allUncertainties: number[], n: number): number {

        const combined = positivesOrNegatives.concat(allUncertainties);
        const validIndexCombinations = this.computeValidIndexCombinations(n);

        const result: number = this.au.sum(validIndexCombinations.map(c=>this.au.product(c.map(i=>combined[i]))));

        return result;

    }

    /**
     * Special combination of pos/neg with uncertainties.
     * very slow if large number of entries - does not scale well
     * @param {number} n
     * @returns {number[][]}
     */
    protected computeValidIndexCombinations(n: number) {
        let indices: number[] = this.au.range(n*2);
        const cGen = this.au.yieldCombinations(indices, n);

        let validCombinations: number[][] = [];

        let genResult: any =  cGen.next();
        while(genResult.done == false) {
            let c: number[] = genResult.value;
            let part1: number[] = c.filter(i=>i<n);  //pos/neg part
            let part2: number[] = c.filter(i=>i>=n); //uncertainties part

            // skip part2 if  same indices as part 1
            let part2InPart1 = part2.filter(i=>part1.indexOf(i - n) != -1);
            if(part2InPart1.length == 0) {
                validCombinations.push(c);
            }

            genResult = cGen.next();
        }


        //remove the last combination -> contains only uncertainties
        validCombinations.pop();

        return validCombinations;

    }

}

export abstract class ProbabilityMassCombinator extends ProbabilityMass {
    public pmArray: ProbabilityMass[];

    public c: number;


    constructor( pmArray: ProbabilityMass[]) {
        super(nonInitValue, nonInitValue, nonInitValue);
        this.pmArray = pmArray;
    }

    public reset(): void {
        this.mP = this.mN = this.mU = this.c = nonInitValue;

    }
    public compute(): void {

        const gpm: GroundProbabilityMass = new GroundProbabilityMass(this.pmArray);
        gpm.compute();
        const c: number = this.computeCparameter(gpm);
        this.c = c;

        console.assert(this.checkCParameter(c, gpm));

        // eq 3
        this.mP = (1 + c * gpm.qPN) * gpm.qP;
        this.mU = (1 + c * gpm.qPN) * (gpm.qU + gpm.qPN) - (c * gpm.qPN);
        this.mN = (1 + c * gpm.qPN) * gpm.qN;



    }



    public checkCParameter(c: number,  gpm: GroundProbabilityMass): boolean {
        return c >= 0 && c <= (1/(1-gpm.qPN-gpm.qU)); // eq 4
    }

    public abstract computeCparameter(gpm: GroundProbabilityMass): number ;


}




export class InagakiCombinationRule extends ProbabilityMassCombinator {

    public  computeCparameter(gpm: GroundProbabilityMass): number {
        return 0.5 / (1-gpm.qPN-gpm.qU); // eq 5
    }


}

export class DempsterCombinationRule extends ProbabilityMassCombinator {

    public  computeCparameter(gpm: GroundProbabilityMass): number {
        return 0.5 / (1-gpm.qPN);
    }
}
