import {Component, Input} from "@angular/core"

@Component({
    selector:'ct-compound-details-db-study',
    templateUrl:'./CtCompoundDetailsDBStudy.html',
    styleUrls: ['./CtCompoundDetailsDBStudy.css'],
})
export class CtCompoundDetailsDBStudy {

    mStudy:any = null;
    @Input() set study(value) {
        this.mStudy = value;
    }

    constructor(){
    }

    private pipeToBr(str){
        var ret = "";
        var arr = str.trim().split('|');
        for(var i=0; i<arr.length; i++){
            ret += arr[i];
            if(i!=arr.length-1)
                ret+='<br/>';
        }
        return ret;
    }


}