/**
 * Created by joerg on 7/17/17.
 */

// angular
import {Component} from '@angular/core';

//
import {MnWorkflowComponentStatic} from "../../MnWorkflowComponent";
import {MnActionConfigurator} from "../MnActionConfigurator";
import {MnActionConfigurationService} from "../MnActionConfigurationService";

@Component({
    selector: 'mn-action-configurator-missing',
    template: `
        Action Configurator Missing:
        <div [innerHtml]="mAcs.InitSubject.getValue().Action" ></div>
        <div [innerHtml]="mAcs.InitSubject.getValue().Configuration" ></div>
        <div [innerHtml]="mAcs.InitSubject.getValue().RowMarks" ></div>
    `,
    styles: []
})
export class Missing extends MnActionConfigurator {

    constructor(mAcs:MnActionConfigurationService) {
        super(mAcs);
        this.enable(false);
    }

    protected onInit(configuration:any) {};

    protected onStart() {};

    ngOnDestroy() {
        super.destroy()
    }
}
MnActionConfigurator.Registry.register("MISSING",new MnWorkflowComponentStatic(Missing));
