// taken from https://github.com/jiayihu/rx-polling

import { Observable } from 'rxjs/Observable';
import { Scheduler } from 'rxjs/Scheduler';

import 'rxjs/add/observable/empty';
import 'rxjs/add/observable/fromEvent';
import 'rxjs/add/observable/interval';
import 'rxjs/add/observable/timer';

import 'rxjs/add/operator/do';
import 'rxjs/add/operator/retryWhen';
import 'rxjs/add/operator/scan';
import 'rxjs/add/operator/startWith';
import 'rxjs/add/operator/switchMap';


export class MnPoll {

    /**
     * Run a polling stream for the source$
     * @param request Source Observable which will be ran every interval
     * @param options Polling options
     * @param scheduler Scheduler of internal timers. Useful for testing.
     */
    static run<T>( request: Observable<T>, options: MnPoll.Options, scheduler?: Scheduler): Observable<T> {
        const poll_options = Object.assign({}, default_options, options);

        /**
         * Currently any new error, after recover, continues the series of  increasing
         * delays, like 2 consequent errors would do. This is a bug of RxJS. To workaround
         * the issue we use the difference with the counter value at the last recover.
         * @see https://github.com/ReactiveX/rxjs/issues/1413
         */
        let allErrorsCount = 0;
        let lastRecoverCount = 0;

        return <Observable<T>> Observable.fromEvent(document, 'visibilitychange')
            .startWith(null)
            .switchMap(() => {
                if (isPageActive()) {
                    return Observable.interval(poll_options.interval, scheduler)
                        .startWith(null) // Immediately run the first call
                        .switchMap(() => request)
                        .retryWhen(errors$ => {
                            return errors$
                                .scan(({ errorCount, error }, err) => ({ errorCount: errorCount + 1, error: err }), {
                                    errorCount: 0,
                                    error: null,
                                })
                                .switchMap(({ errorCount, error }) => {
                                    allErrorsCount = errorCount;
                                    const consecutiveErrorsCount = allErrorsCount - lastRecoverCount;

                                    // If already tempted too many times don't retry
                                    if (consecutiveErrorsCount > poll_options.attempts) throw error;

                                    const delay = getStrategyDelay(consecutiveErrorsCount, poll_options);

                                    return Observable.timer(delay, null, scheduler);
                                });
                        });
                }
                return Observable.empty();
            })
            .do(() => {
                // Update the counter after every successful polling
                lastRecoverCount = allErrorsCount;
            });
    }
}

export namespace MnPoll {
    export interface Options {
        /**
         * Period of the interval to run the source$
         */
        interval: number;

        /**
         * How many attempts on error, before throwing definitely to polling subscriber
         */
        attempts?: number;

        /**
         * Strategy taken on source$ errors, with attempts to recover.
         *
         * 'exponential' will retry waiting an increasing exponential time between attempts.
         * You can pass the unit amount, which will be multiplied to the exponential factor.
         *
         * 'random' will retry waiting a random time between attempts. You can pass the range of randomness.
         *
         * 'consecutive' will retry waiting a constant time between attempts. You can
         * pass the constant, otherwise the polling interval will be used.
         */
        backoffStrategy?: 'exponential' | 'random' | 'consecutive';

        /**
         * Exponential delay factors (2, 4, 16, 32...) will be multiplied to the unit
         * to get final amount if 'exponential' strategy is used.
         */
        exponentialUnit?: number;

        /**
         * Range of milli-seconds to pick a random delay between error retries if 'random'
         * strategy is used.
         */
        randomRange?: [number, number];

        /**
         * Constant time to delay error retries if 'consecutive' strategy is used
         */
        constantTime?: number;
    }
}

const default_options: Partial<MnPoll.Options> = {
    attempts: 9,
    backoffStrategy: 'exponential',
    exponentialUnit: 1000, // 1 second
    randomRange: [1000, 10000],
};

function isPageActive(): boolean {
    return !Boolean(document.hidden);
}

function getStrategyDelay(consecutiveErrorsCount: number, options: MnPoll.Options): number {
    switch (options.backoffStrategy) {
        case 'exponential':
            return Math.pow(2, consecutiveErrorsCount) * options.exponentialUnit;

        case 'random':
            const [min, max] = options.randomRange;
            const range = max - min;
            return Math.floor(Math.random() * range) + min;

        case 'consecutive':
            return options.constantTime || options.interval;

        default:
            console.error(`${options.backoffStrategy} is not a backoff strategy supported by rx-polling`);
            // Return a value anyway to avoid throwing
            return options.constantTime || options.interval;
    }
}