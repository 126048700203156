/**
 * Created by joerg on 7/17/17.
 */

// rxjs
import 'rxjs/Rx';
import { Observable } from 'rxjs/Observable';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Subject } from 'rxjs/Subject';
import { Subscription } from 'rxjs/Subscription';

// angular
import {Injectable} from '@angular/core';

// mn
import {MnBackend, MnTextService} from "@mn/core";

//
import {Backend} from "./BackendInterfaces";
import {MnWorkflow} from './MnWorkflow';
import {MnPoll} from './MnPoll';
import {MnWorkflowTransactionInternal,MnWorkflowTransactionExternal,MnWorkflowTransactionStartTransition} from "./MnWorkflowTransactions";

@Injectable()
export class MnWorkflowService extends MnWorkflow{

    constructor(backend:MnBackend,text:MnTextService) {
        super(backend,text);
        console.log("constructor MnWorkflowSevice")
    }

    public goToExternal(identifiers:MnWorkflow.Identifiers) {
        this.startTransaction(
            new MnWorkflowTransactionExternal(),
            identifiers,
            {},
        );
    }

    public goToInternal(identifiers:MnWorkflow.Identifiers) {
        this.startTransaction(
            new MnWorkflowTransactionInternal(),
            identifiers,
            {},
        );
    }

    public goToStartTransition(identifiers:MnWorkflow.Identifiers,action_configuration:MnWorkflow.ActionConfiguration) {
        this.startTransaction(
            new MnWorkflowTransactionStartTransition(),
            identifiers,
            action_configuration,
        );
    }

    public reload() {
        this.goToInternal(this.Identifiers);
    }

    public loadWorkflow(wid:number):Observable<Backend.Workflow> {
        return this.backend.get(`/workflows/workflow/${wid}/?format=json`)
            .map( result => result.json() )
    }

    public loadState(sid:number):Observable<Backend.FullState> {
        return this.backend.get(`/workflows/state/${sid}/?format=json`)
            .map( result => result.json() )
    }

    public loadTransition(tid:number):Observable<Backend.FullTransition> {
        return this.backend.get(`/workflows/transition/${tid}/?format=json`)
            .map( result => result.json() )
    }

    public pollTransition(tid:number,
                          next:(transition:Backend.FullTransition)=>void,
                          done:(transition:Backend.FullTransition)=>void,
                          error:(any)=>void,
                          cancel:Observable<any>) {
        let poll = MnPoll.run<Backend.FullTransition>( this.loadTransition(tid),{interval:1000} )
            .takeUntil(cancel)
            .subscribe(
                transition => {
                    console.log("Workflow transition polling",transition);
                    switch (transition.task.state) {
                        case Backend.Task.State.Success:
                        case Backend.Task.State.Failure:
                        case Backend.Task.State.Revoked:
                            poll.unsubscribe();
                            done(transition);
                            break;
                        default:
                            next(transition);
                            break;
                    }
                },
                err => {
                    error(err);
                    console.error("Workflow transition error", err);
                }
            );
    }

    public startTransition(action_and_config:any,
                           done:(transition:Backend.FullTransition)=>void,
                           error:(any)=>void,
    ) {
        this.backend.post('/workflows/transition/', action_and_config)
            .map(res => {
                return res.json();
            })
            .subscribe(
                transition => {
                    console.log("Workflow transition create ",action_and_config,transition);
                    this.backend.patch('/workflows/transition/'+transition.id+'/execute/',{})
                        .map(res => {
                            return res.json();
                        })
                        .subscribe(
                            patched_transition => {
                                console.log("Workflow transition execute",action_and_config,patched_transition);
                                done(transition);
                            },
                            err => {
                                error(err);
                                console.error("Workflow transition execute error", err);
                            }
                        )
                },
                err => {
                    error(err);
                    console.error("Workflow transition create error", err);
                }
            );
    }

}


