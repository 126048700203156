//BB


// angular
import {Optional} from '@angular/core';

import {Backend} from "../BackendInterfaces";

// super class
import {CtValueView, CtValueViewRegisterDecorator, TextViewRegisterDecorator} from "../CtValueView";

import {CtAggregatedValueView, CtComplexValueComponentDecorator} from "./CtComplexValueView";
import {ListIdentifier} from "./CtListIdentifierView";
import {CtTable} from "../CtTable/CtTable";

/*

[
            {
          {
              "value": "7235-40-7",
              "value_type": "ACTIVE",
              "source": "CAS",
              "serial": 0,
              "visible": true,
              "harvested_from": ""
            },
             }]

*/



/*
    Display only the name with the sources
    name (src1, src2, src3 ...)
    name is Name.value and  source is Name.source

*/

@TextViewRegisterDecorator(CtTable.ColumnType.ListName)
export class CtListNameViewNoComponents extends CtAggregatedValueView<Backend.Name> {


    protected getKeyValue( d: Backend.Name): string {
        return d.value;
    }

    protected getOtherValue(d: Backend.Name): string {
        return d.source;
    }


}

@CtComplexValueComponentDecorator('ct-list-name-view')
@CtValueViewRegisterDecorator(CtValueView.Types.ListName)

export class CtListNameView extends CtListNameViewNoComponents {


    // @Optional is useful to use the component outside an ag-grid
    constructor(@Optional() service:CtValueView.Service) {
        super(service);
    }


}


