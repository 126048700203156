/**
 * Created by joerg on 7/17/17.
 */

// rxjs
import 'rxjs/Rx';
import { Subject } from 'rxjs/Subject';

//
import {MnActionConfigurationService} from "./MnActionConfigurationService";
import {MnWorkflow} from "../MnWorkflow";

export abstract class MnActionConfigurationObserver {

    protected onDestroy:Subject<void> = new Subject();

    constructor(private mConfigurationService:MnActionConfigurationService) {
        setTimeout(()=>{
            mConfigurationService.InitSubject.takeUntil(this.onDestroy).subscribe((v) => {
                if (v && v.Configuration) { this.onInit(v.Configuration); }
            });
            mConfigurationService.StartSubject.takeUntil(this.onDestroy).subscribe(() => this.onStart());
            mConfigurationService.FinishSubject.takeUntil(this.onDestroy).subscribe((v) => this.onFinish(v));
            mConfigurationService.EnableSubject.takeUntil(this.onDestroy).subscribe((value) => this.onEnable(value));
            mConfigurationService.EnableRowMarksSubject.takeUntil(this.onDestroy).subscribe((value) => this.onEnableRowMarks(value));
        });
    }

    protected init(v:MnWorkflow.ActionConfiguration) {
        this.mConfigurationService.InitSubject.next(v);
    }

    protected start() {
        this.mConfigurationService.StartSubject.next();
    }

    protected finish(configuration:any, runner:MnWorkflow.ActionConfiguration.Runner = null) {
        this.mConfigurationService.FinishSubject.next(
            new MnWorkflow.ActionConfiguration(
                this.mConfigurationService.InitSubject.getValue().Action,
                configuration,
                this.mConfigurationService.RowMarksSubject.getValue(),
                runner
            )
        );
    }

    protected enable(value:boolean) {
        this.mConfigurationService.EnableSubject.next(value);
    }

    protected enableRowMarks(value:boolean) {
        this.mConfigurationService.EnableRowMarksSubject.next(value);
    }

    protected abstract onInit(v:any);
    protected abstract onStart();
    protected abstract onFinish(v:MnWorkflow.ActionConfiguration);
    protected abstract onEnable(enabled:boolean);
    protected abstract onEnableRowMarks(enabled:boolean);

    protected destory() {
        this.onDestroy.next();
    }

}