/* Monkey path SVG images */

// TODO: move to SVGimage class

export class MnSvgFix {
    static apply(svg_image:string):string {

        //console.log(image);

        let imageData: string = svg_image;
        //imageData = 'data:image/svg+xml;base64, PD94bWwgdmVyc2lvbj0nMS4wJyBlbmNvZGluZz0naXNvLTg4NTktMSc/Pgo8c3ZnOnN2ZyB2aWV3Qm94PScwIDAgODAgODAnIHZlcnNpb249JzEuMScgYmFzZVByb2ZpbGU9J2Z1bGwnIHhtbG5zOnN2Zz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHhtbG5zOnhsaW5rPSdodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rJyB4bWw6c3BhY2U9J3ByZXNlcnZlJyB3aWR0aD0nODBweCcgaGVpZ2h0PSc4MHB4JyA+Cjxzdmc6cmVjdCBzdHlsZT0nb3BhY2l0eTowLjA7ZmlsbDojMzMzMzMzO3N0cm9rZTpub25lJyB3aWR0aD0nODAnIGhlaWdodD0nODAnIHg9JzUuMCcgeT0nNS4wJz4gPC9zdmc6cmVjdD4KPHN2Zzp0ZXh0IHg9JzUwJScgeT0nNDAlJyBzdHlsZT0nZm9udC1zaXplOjE4cHg7Zm9udC1zdHlsZTpub3JtYWw7Zm9udC13ZWlnaHQ6bm9ybWFsO2ZpbGwtb3BhY2l0eToxO3N0cm9rZTpub25lO2ZvbnQtZmFtaWx5OnNhbnMtc2VyaWY7dGV4dC1hbmNob3I6bWlkZGxlO2ZpbGw6IzMzMzMzMyc+PHN2Zzp0c3Bhbj5ObyBzdHJ1Y3R1cmU8L3N2Zzp0c3Bhbj48L3N2Zzp0ZXh0Pgo8c3ZnOnRleHQgeD0nNTAlJyB5PSc1OCUnIHN0eWxlPSdmb250LXNpemU6MThweDtmb250LXN0eWxlOm5vcm1hbDtmb250LXdlaWdodDpub3JtYWw7ZmlsbC1vcGFjaXR5OjE7c3Ryb2tlOm5vbmU7Zm9udC1mYW1pbHk6c2Fucy1zZXJpZjt0ZXh0LWFuY2hvcjptaWRkbGU7ZmlsbDojMzMzMzMzJz48c3ZnOnRzcGFuPmF2YWlsYWJsZTwvc3ZnOnRzcGFuPjwvc3ZnOnRleHQ+Cjwvc3ZnOnN2Zz4K';
        //imageData =  '<svg width="100" height="100">'+
        //    '<circle cx="50" cy="50" r="40" stroke="green" stroke-width="4" fill="yellow" />'+
        //   '</svg>';

        // JSME- OCL SVG does not work well: no dynamic box size
        //imageData= this.JME_OCL_SVG;

        //imageData = this.JME_SVG; // same problem
        // the best solution might be JSME in depict mode - it rescale it self automatically
        // it has a maximal bond length such that small compound are displayed correctly

        // TODO: reuse SVGimage class

        imageData = imageData.replace(/<(\/?)svg:/g, '<$1'); // XML namespace issue with RD kit SVG - the browser messes up the SVG

        // xlink:href is deprecated
        imageData = imageData.replace('xlink:href', 'href');

        // Ingigo SVG issue - looks like the one SVG is affected by the glyphs definition in another SVG because they
        // use the same ID's - this is a browser bug (Firefox and Chrome, may be others)
        // very visible in wid 7 id = 71

        const indigoHrefPatchRe = /\b(glyph[\d-]+)\b/g;
        imageData = imageData.replace(indigoHrefPatchRe, '$1'  + Math.abs(Date.now()-1526552327520) + '_' + parseInt(''+ (Math.random()*10000))); // addition of Date.now() not tested - need Indigo picts

        const re = /(width|height)=("|')\d+(pt|px)?("|')/;
        imageData = imageData.replace(re, '$1="100%"')
            .replace(re, '$1="100%"');

        return imageData;
    }

}