/**

BB
 Base helper classes and decorator used to create views for more complex data

 */


// angular
import {Component} from '@angular/core';
//
import {CtValueView} from "../CtValueView";
//import {Backend} from "../BackendInterfaces";


/**
 *  Decorator for views that need a formatted inner HTML
 *
 * Tip for visual debugging: uses css argument, e,g border: 3px solid red;
 */

let div_open = "<div class='ct-value-view'>";
let div_close = "</div>";

export function CtComplexValueComponentDecorator(selector: string, css: string = 'overflow: auto;'): any {
    return Component({
        selector: selector,
        template: `<div [innerHtml]="mvalueAsHtml"></div>`,
        styles: [
            `
            :host {
               /*  color: blue; */
               /* border: 3px solid red;*/
               /* overflow: auto; */
                /* text-overflow: ellipsis;*/
                ${css}
            }
        `
        ]
    });
}

/**
 * Deals woth HTML formatted cell content.
 * See also CtComplexValueComponentDecorator.
 */

export abstract class CtComplexValueView extends CtValueView {

    protected  mvalueAsHtml : string = '';

}


export class Aggregate {

    constructor( public key: string, public values: string[]) {}



}


/**
 * THere are  cases for which the data needs to be aggreagated
 *
 * Actually, the aggregation should have been done on the back end.
 * This code duplicated the Python code from AT that generates XLS
 */
export abstract class CtAggregatedValueView<T> extends CtComplexValueView {
    //protected aggregatedDataList: Aggregate[];

    protected abstract getKeyValue( d: T): string;

    protected abstract getOtherValue(d: T): string;


    protected update(parameters:CtValueView.Parameters) {

        let parts: Aggregate[] = this.generateParts(parameters.value);

        this.mvalueAsHtml = div_open+this.generateHtml(parts)+div_close;
        this.tooltip = this.generateToolTipContent(parts);
    }

    protected generateToolTipContent(parts: Aggregate[]): string {
        let textArray: string[] = [];
        for (let agg of parts) {
            let text = agg.key;
            text += ' (';

            text += agg.values.join(', ');
            text += ')';

            textArray.push(text);

        }

        const result = textArray.join('; '); // , comma was already used above

        return result;

    }

    protected generateHtml(parts: Aggregate[]): string {
        // html formatting
        let htmlArray: string[] = [];
        for (let agg of parts) {
            let html = agg.key;
            let valueHtmlList = agg.values.map(v => this.formatOtherValueHTML(v));

            html += this.formatOtherValues(valueHtmlList.join(', '));


            htmlArray.push(html);

        }

        const  result = htmlArray.join('<br>');

        //console.log(result);


        return result;


    }

    // to be used by non angular components
    // we could create the same same for html output
    public generateMultiLineText(cellContent:any): string {
        let parts: Aggregate[] = this.generateParts(cellContent);

        let htmlArray: string[] = [];
        for (let agg of parts) {
            let html = agg.key;
            let valueHtmlList = agg.values;

            html += this.formatOtherValues(valueHtmlList.join(', '));


            htmlArray.push(html);

        }


        return htmlArray.join('\n');


    }

    // default is italic
    protected formatOtherValueHTML(other: string): string {
        return '<i>' + other + '</i>';
    }

    protected formatOtherValues(othersAstring: string) {
        return ' (' + othersAstring + ')';
    }

    /**
     * one key can have more than one additional values, aggregation is needed.
     * Example : compounds name and the list of sources that cite the name
     * @returns {Aggregate[]}
     */
    protected generateParts(mValue: any): Aggregate[] {
        /* #AT python implementation

                parts = []
                if list_type == "list.name":
                    all_names = {}
                    for name in data:
                        all_names.setdefault(name["value"], set())
                        if name["source"]:
                            all_names.setdefault(name["value"], set()).add(name["source"])
                    for name, sources in sorted(all_names.items(), key=lambda x: x[0]):
                        parts.extend([
                            normal,
                            name,
                            " (",
                            italic,
                            ", ".join(sorted(sources)),
                            normal,
                            ")\n"
                        ])
                    if parts:
                        parts.append(normal)

         */

        // one could have used the Map and Set from ES6

        if (mValue == undefined) {
            return [];
        }

        const data: T[] = mValue;
        const keyToOtherValuesDict = {};

        for (let each of data) {
            keyToOtherValuesDict[this.getKeyValue(each)] = [];
        }

        for (let each of data) {
            const a = keyToOtherValuesDict[this.getKeyValue(each)];
            const s = this.getOtherValue(each);
            if (a.indexOf(s) == -1) { // avoid duplicates
                a.push(s);
            }
        }

        let keys = Object.keys(keyToOtherValuesDict);
        keys.sort();

        let parts: Aggregate[] = [];

        for (let k of keys) {
            let others = keyToOtherValuesDict[k];
            others.sort();
            // parts.push([name, sources]);
            parts.push(new Aggregate(k, others));
        }

        return parts;


    }
}

