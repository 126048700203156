/**
 * Created by joerg on 7/17/17.
 */

// rxjs
import 'rxjs/Rx';
import { Observable } from 'rxjs/Observable';

// angular
import {Component} from '@angular/core';

//
import {MnWorkflowComponentStatic} from "../../MnWorkflowComponent";
import {MnActionConfigurator} from "../MnActionConfigurator";
import {MnActionConfigurationService} from "../MnActionConfigurationService";
import {MnWorkflow} from "../../MnWorkflow";
import {CtTable} from "../../CtTable/CtTable";
import {MnBackend} from "@mn/core";
import {MnWorkflowService} from "../../MnWorkflowService";
import {MnFileDownloadService} from "@mn/file"

//the mols from backend are delivered with DOS new lines
let formats = {
    sdf: {
        suffix: 'sdf',
        key: 'mol',
        separator: '\r\n$$$$\r\n',
        label: "SD File"
    },
    smiles: {
        suffix: 'sml',
        key: 'smiles',
        separator: '\r\n',
        label: "Smiles File"
    }
};

/**
 * Remove $$$$ if present
 * @param {string} mol
 * @returns {string}
 */
function cleanupMol(mol: string) : string {
    //TODO
    return null;
}
export class ExportStructuresRunner extends MnWorkflow.ActionConfiguration.Runner {
    constructor(private filename:string, private rowmarks:string[], private table:CtTable, private backend:MnBackend, private format:ExportStructuresRunner.Format) {
        super();
    }
    public run() {
        console.log("Exporting",this.rowmarks,"to",this.filename, 'with format', this.format);
        if (this.table) {
            let rows:any[] = this.table.Rows;
            Observable.forkJoin(rows
                .filter((row) => {
                    return row.id && row.record_index && this.rowmarks.indexOf(row.record_index) != -1
                }).map(row => {
                    return this.backend.get(`/datasets/record/${row.id}/${this.format.key}/`).map(res => res.json())
                })
            ).subscribe(mols => {
                //console.log(mols);
                let all:string = mols.map(structures => structures[this.format.key]).join(this.format.separator);
                if (this.format.key == "mol") {
                    // add separator at end of SDF otherwise last exported structure cannot be read
                    all += this.format.separator;
                }
                //console.log(all);
                let saver = new MnFileDownloadService(null);
                saver.saveText(all,this.filename+'.'+this.format.suffix,"utf-8"); // if encoding is missing, exported files start with Byte order mark
            }, error => {
                this.error(error);
            }, () => {
                console.log("Done writing SDF/SMILES");
                this.done();
            })
        }
    }
}
export namespace ExportStructuresRunner {
    export interface Format {
        suffix: string,
        key: string,
        separator: string,
        label: string
    }
}

@Component({
    selector: 'mn-action-configurator-export-structures',
    template: `
        <mn-action-configurator-frame [scroll]="false">
            <mn-action-configurator-row-marks
                    line1
            ></mn-action-configurator-row-marks>
            <mat-form-field line2 style="width: 100%">
                <input matInput placeholder="File Name" [ngModel]="mConfiguration.filename" (ngModelChange)="onFilename($event)">
                <mat-hint align="end">File Name required</mat-hint>
                <mat-error align="end" >
                    File Name required
                </mat-error>
            </mat-form-field>
            <mat-form-field line3>
                <mat-select [(value)]="mConfiguration.format">
                    <mat-option *ngFor="let format of mFormats" [value]="format">{{format.label}}</mat-option>
                </mat-select>
            </mat-form-field>
        </mn-action-configurator-frame>
    `,
    styles: []
})
export class ExportStructures extends MnActionConfigurator {

    private mFormats = [
        formats.sdf,
        formats.smiles
    ];

    private mConfiguration:ExportStructures.Configuration = new ExportStructures.Configuration("",formats.sdf);
    constructor(private mAcs:MnActionConfigurationService, private mWorkflow:MnWorkflowService, private mBackend:MnBackend) {
        super(mAcs);
        this.enable(false);
    }

    protected onInit(configuration:any) {};

    protected onStart() {
        console.log("SDF/SMILES export", this.mAcs.RowMarksSubject.getValue(),this.mConfiguration);
        this.finish(this.mConfiguration,new ExportStructuresRunner(
            this.mConfiguration.filename,
            this.mAcs.RowMarksSubject.getValue(),
            this.mWorkflow.Table,
            this.mBackend,
            this.mConfiguration.format)
        );
    };

    private onFilename(filename) {
        this.enable(filename.length > 0);
        this.mConfiguration.filename = filename;
    };

    ngOnDestroy() {
        super.destroy()
    }
}
MnActionConfigurator.Registry.register("EXPORT_STRUCTURES",new MnWorkflowComponentStatic(ExportStructures));

export namespace ExportStructures {
    export class Configuration {
        constructor(public filename:string, public format:ExportStructuresRunner.Format) {}
    }
}