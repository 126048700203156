/**
 * Created by joerg on 7/17/17.
 */

// angular
import {Component,Input} from '@angular/core';

@Component({
    selector: 'mn-action-configurator-frame',
    template: `        
        <div class="acfb-box">
            <div class="acfb-fixed">
                <ng-content select="[line1]"></ng-content>
            </div>
            <div class="acfb-fixed">
                <ng-content select="[line2]"></ng-content>
            </div>
            <div class="acfb-fixed">
                <ng-content select="[line3]"></ng-content>
            </div>
            <div class="acfb-fixed">
                <ng-content select="[line4]"></ng-content>
            </div>
            <div class="acfb-fixed">
                <ng-content select="[line5]"></ng-content>
            </div>
            <div class="acfb-fixed">
                <ng-content select="[line6]"></ng-content>
            </div>
            <div class="acfb-fixed">
                <ng-content select="[line7]"></ng-content>
            </div>
            <div class="acfb-fixed">
                <ng-content select="[line8]"></ng-content>
            </div>
            <div class="acfb-fixed">
                <ng-content select="[line9]"></ng-content>
            </div>
            <div class="acfb-fixed">
                <ng-content select="[line10]"></ng-content>
            </div>
            <div class="acfb-scroll" *ngIf="scroll">
                <div style="padding-left: 16px;">
                    <ng-content select="[scroll]"></ng-content>
                </div>
                <div class="acfb-bottom"></div>
            </div>
        </div>
    `,
    styles: [`
        .acfb-box {
            display: flex;
            flex-flow: column;
            height: 100%;
            /*padding-left: 16px;*/
        }
        .acfb-box > .acfb-fixed {
            display: block;
            flex: 0 1 auto;
            padding: 0 16px;
        }
        .acfb-box > .acfb-scroll {
            width: 100%;
            flex: 1 1 auto;
            overflow: auto;
            background: #f0f0f0;
            border-top: 1px solid #d6d6d6;
        }
        .acfb-box > div {
            display: block;
        }
        .acfb-box > .acfb-scroll > .acfb-bottom {
            height: 100px;
            display: block;
        }
    `],
})
export class MnActionConfiguratorFrame {

    @Input() scroll:boolean = true;

    constructor() {}

}
