import {Optional} from '@angular/core';


import {CtValueView, CtValueViewRegisterDecorator, TextViewRegisterDecorator} from "../CtValueView";
import {CtAggregatedValueView, CtComplexValueComponentDecorator} from "./CtComplexValueView";
import {CtTable} from "../CtTable/CtTable";

/*
    [{
        "value": "7235-40-7",
        "value_type": "ACTIVE",
        "source": "CAS",
        "serial": 0,
        "visible": true,
        "harvested_from": ""
    }]
*/

// TS compiler issues with export and namespace
//export namespace Backend {
    export interface ListIdentifier {
        value: string;
        value_type: string;
        source: string;
        serial: number;
        visible: string;
        harvested_from: string;
    }
//}

@TextViewRegisterDecorator(CtTable.ColumnType.ListIdentifier)
export class CtListIdentifierViewNoComponents extends CtAggregatedValueView<ListIdentifier> {


    protected getKeyValue( d: ListIdentifier): string {
        return d.value;
    }

    protected getOtherValue(d: ListIdentifier): string {
        return d.value_type;
    }




}



@CtComplexValueComponentDecorator('ct-list-identifier-view')
@CtValueViewRegisterDecorator(CtValueView.Types.ListIdentifier)
export class CtListIdentifierView extends CtListIdentifierViewNoComponents {

    // @Optional is useful to use the component outside an ag-grid
    constructor(@Optional() service:CtValueView.Service) {
        super(service);
    }

}

