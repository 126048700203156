import {Component, Input} from "@angular/core"

@Component({
    selector:'ct-compound-details-db-pafa',
    templateUrl:'./CtCompoundDetailsDBPAFA.html',
    styleUrls: ['./CtCompoundDetailsDBPAFA.css','./CtCompoundDetailsDBSafetyEvaluation.css']
})
export class CtCompoundDetailsDBPAFA {

    // list of PAFA property names/values as shown in PAFA section
    private map = [
        ["document_number", "Document Number"],
        ["pafa_doc_type", "Type"],
        ["nas_number", "NAS Number"],
        ["fema_id", "FEMA ID"],
        ["gras_paper_number", "GRAS Paper Number"],
        ["regulation_types", "Regulated As"],
        ["cfr_list", "CFR"], 
        ["adi", "ADI"],
        ["edi", "EDI"], 
        ["cedi", "CEDI"],
        ["exposure_source", "Exposure Source"],
        ["market_disappearance", "Market Disappearance"],
        ["market_disappearance82", "Market Disappearance 82"],
        ["market_disappearance87", "Market Disappearance 87"], 
        ["beverage_use_flag", "Potential Beverage Use"],
        ["human_consumption", "Human Consumption [mg/kg bw/day]"], 
        ["jecfa_last_review_year", "JECFA last review year"],
        ["jecfa_adi", "JECFA ADI"], 
        ["pafa_jecfa_status", "JECFA status"],
        ["last_lit_update_date", "Last Lit update"], 
        ["red_book_min_test_level", "Red Book Min. test level"],
        ["red_book_chem_class", "Red Book chemical class"], 
        ["comments", "Comments"]
    ];

    // identifier_rowData
    public pafa_rowData=[];
    mCompoundPafaDetails:any;

    @Input('compoundPafaDetails') set compoundPafaDetails(value) {
        this.mCompoundPafaDetails = value;
        this.pafa_rowData = [];

        /* TODO: 
        in case of http://localhost:8000/chemtunes/reg/compound/33798/?format=json
        HTTP error code is 404
        Is there an example how to catch such errors?
        */

        /*let pafa_rowData_unsorted = [];
        this.mCompoundPafaDetails.data.forEach(row=>{ //set identifiers into identifier_rowData
            if (row.attribute.name == "PAFA Chemical Information") {
                for (let key in row.data) {
                    let _row = [
                        this.rename_pafa_name(key), this.format_pafa_value(key,row.data[key])
                    ];
                    pafa_rowData_unsorted.push(_row);
                }
            }
        });*/

        let pafa_rowData_unsorted = [];
        for (let key in value) {
            let _row = [
                this.rename_pafa_name(key), this.format_pafa_value(key,value[key])
            ];
            pafa_rowData_unsorted.push(_row);
        }


        // sort list of PAFA property names and values
        let result = [];
        this.map.forEach(function(key) {
            var found = false;
            pafa_rowData_unsorted = pafa_rowData_unsorted.filter(function(item) {
                if(!found && item[0] == key[1]) {
                    result.push(item);
                    found = true;
                    return false;
                } else {
                    return true;
                }
            })
        })
        this.pafa_rowData = result;
    }
    

    constructor(){

    }

    private rename_pafa_name(key){
        let new_name = "";
        this.map.forEach(item => {
            if (item[0] == key) {
                new_name = item[1];
            }
        });
        return new_name;
    };

    private format_pafa_value(key,values) {
        if (key.indexOf("cfr_list") !== -1) {
            let newvalue = "";
            values.forEach(element => {
                newvalue += element.Title + " " + element.Part + "." + element.Section;
                if (element.Subsection) {
                    newvalue += "." + element.Subsection;
                }
                if (element.Year) {
                    newvalue += " (" + element.Year + ")";
                }
                newvalue += "; ";
            });
            return newvalue.replace(/;\s*$/, ""); // remove "; " at the end
        } else if (key.indexOf("regulation_types") !== -1) {
            let newvalue = "";
            values.forEach(element =>{
                newvalue += element[0] + " (" + element[1] + "); ";
            });
            return newvalue.replace(/;\s*$/, "");
        } else if (key.indexOf("flag") !== -1) {
            return (values ? "yes" : "no");
        } else if (key.indexOf("pafa_jecfa_status") !== -1 || 
            key.indexOf("pafa_doc_type") !== -1 ||
            key.indexOf("exposure_source") !== -1) {
            let newvalue = "";
            values.forEach(element=> {
                if (element) {
                    newvalue += element + ", ";
                }
            });
            return newvalue.replace(/,\s*$/, "");
        } else {
            return values;
        }
    };

}