/**
 * Created by joerg on 7/17/17.
 */

// angular
import {Component,ViewChild} from '@angular/core';

// uirouter
import {Transition,StateService, TransitionService} from '@uirouter/angular';

//
import {MnWorkflow} from "./MnWorkflow";
import {MnWorkflowView} from "./MnWorkflowView";

@Component({
    selector: 'mn-workflow-url-sync',
    template: `
        <mn-workflow-view
                (identifiersChange)="onIdentsChange($event)">
        </mn-workflow-view>
    `
})
export class MnWorkflowUrlSync {

    //private mIdents:MnWorkflow.Idents;
    private mTransOnSuccessDeregister:any;

    @ViewChild(MnWorkflowView) mView:MnWorkflowView;

    constructor(private trans:Transition, private mTransService:TransitionService, private mStateService:StateService) {
        this.updateWorkflowIdsFromTransition(trans);
        this.mTransOnSuccessDeregister = this.mTransService.onSuccess({}, (trans) => this.updateWorkflowIdsFromTransition(trans) );
    }

    private updateWorkflowIdsFromTransition(trans:Transition) {
        console.log('x2',this.mView);
        setTimeout(()=>{
            if (this.mView) {
                let p = trans.params();
                console.log("x1",p);
                let ids = this.mView.Workflow.Identifiers;
                if (ids == null || ids.wid != p.wid || ids.sid != p.sid) {
                    this.mView.setIdentifiers({
                        wid: p.wid,
                        sid: p.sid,
                        aid: p.aid,
                    });
                }
            }
        })
        /*console.log("x0",this.mIdents);
        console.log("x1",p);
        this.mIdents = {
            wid: p.wid,
            sid: p.sid,
            //tid: p.tid,
            aid: p.aid,
        };*/
    }

    private onIdentsChange(idents:MnWorkflow.Identifiers){
        console.log("updateUrl");
        //this.mIdents = idents;
        this.mStateService.go('.',{wid:idents.wid,sid:idents.sid,tid:undefined,aid:undefined});
    }

    ngOnDestroy() {
        this.mTransOnSuccessDeregister();
    }
}
